import { http } from '../service/http';

type Result = {
  code: number;
  description?: string;
  data: Array<any>;
};
// 账号管理
export const getAccountList = (paramsStr: string) => {
  return http.request<Result>('get', `/agent/manage/user${paramsStr}`);
};
export const getRoleList = (paramsStr: string) => {
  return http.request<Result>('get', `/agent/manage/role${paramsStr}`);
};
export const addAccountUser = (params: object) => {
  return http.request<Result>('post', '/agent/manage/user', {
    data: params,
  });
};
export const updateAccountUser = (params: any) => {
  const id = params.id;
  delete params.id;
  return http.request<Result>('put', `/agent/manage/user/${id}`, {
    data: params,
  });
};
export const deleteAccountUser = (params: string) => {
  return http.request<Result>('delete', `/agent/manage/user/${params}`);
};
export const addQueue = (params: object) => {
  return http.request<Result>('post', '/agent/manage/queue', {
    data: params,
  });
};

export const putQueue = (params: any) => {
  const id = params.id;
  delete params.id;
  return http.request<Result>('put', `/agent/manage/queue/${id}`, {
    data: params,
  });
};

export const deleteQueue = (params: string) => {
  return http.request<Result>('delete', `/agent/manage/queue/${params}`);
};

export const queryQueue = (paramsStr: string) => {
  const url = encodeURI(`/agent/manage/queue${paramsStr}`);
  return http.request<Result>('get', url);
};

export const queryZendeskConfig = (page?: number, size?: number) => {
  return http.request<Result>('post', '/agent/zendesk/get_zendesk_configs', { data: { page, size } });
};

export const saveZendeskConfig = (params: object) => {
  return http.request<Result>('post', '/agent/zendesk/save_zendesk_config', {
    data: params,
  });
};
export const deleteZendeskConfig = (uid: string) => {
  return http.request<Result>('post', '/agent/zendesk/delete_zendesk_config', {
    data: { uid },
  });
};
export const queryZendeskSdkConfig = (page?: number, size?: number) => {
  return http.request<Result>('post', '/agent/zendesk/get_zendesk_sdk_configs', { data: { page, size } });
};

export const saveZendeskSdkConfig = (params: object) => {
  return http.request<Result>('post', '/agent/zendesk/save_zendesk_sdk_config', {
    data: params,
  });
};

export const deleteZendeskSdkConfig = (uid: string) => {
  return http.request<Result>('post', '/agent/zendesk/delete_zendesk_sdk_config', {
    data: { uid },
  });
};

// 国际化多语言相关

// 获取系统配置
export const getCorpInfo = () => {
  return http.request<Result>('get', `/agent/auth/get_corp_info`);
};

// 更新租户信息
// language: 'zh' | 'en'
export const updateCorpInfo = (params: object) => {
  return http.request<Result>('post', '/agent/auth/update_corp_info', {
    data: params,
  });
};

// 更新用户信息
// language: 'zh' | 'en'
export const updateUserInfo = (params: object) => {
  return http.request<Result>('post', '/agent/auth/update_user_info', {
    data: params,
  });
};

// 获取用户信息
export const getUserInfo = () => {
  return http.request<Result>('get', '/agent/auth/user');
};