import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Form, Input, Button, message, Switch, Select, Tooltip } from 'antd';
import { copyText } from '@/utils/common';
import { getPreAuthUrl } from '@/api/channel';
interface ChildProps {
  style?: React.CSSProperties;
  agentList: any;
  onGoBack: () => void;
  onSave: (data: any) => void;
}
// 定义暴露给父组件的方法类型
interface ChildMethods {
  openAuthorization: (value: any) => void;
}
// 使用`React.forwardRef`来获取`ref`传递
const Authorization = forwardRef<ChildMethods, ChildProps>((props, ref) => {
  const [isVisible, setIsVisible] = useState(true);
  const [form] = Form.useForm();
  const [urlForm] = Form.useForm();

  // 打开弹窗
  const openAuthorization = (data: any) => {
    debugger;
    if (data) {
      setIsVisible(false);
      form.setFieldsValue({
        account_id: data.account_id,
        account_name: data.account_name,
        agent_id: data.agent_id,
        enable_welcome_msg: data.enable_welcome_msg,
      });
    } else {
      getPreAuthUrl({})
        .then((res: any) => {
          if (res.code == 200 && res.data) {
            urlForm.setFieldsValue({ auth_url: res.data.auth_url || '' });
          } else {
            message.error('查询授权链接失败~');
          }
        })
        .catch(() => {
          message.error('查询授权链接失败~');
        });
      urlForm.setFieldsValue({ auth_url: '测试用的，后面接口调通可以删掉' });
      setIsVisible(true);
    }
  };
  const goBack = () => {
    props.onGoBack();
  };
  const handleSave = async () => {
    const values = await form.validateFields();
    if (!values) {
      return;
    }
    if (values.url / 2 < values.overlap) {
      message.error('overlap不能超过chunk size一半限制');
      return;
    }
    props.onSave({
      name: values.name,
      url: values.url,
      overlap: values.overlap,
    });
  };
  const goToNext = () => {};
  // 通过`useImperativeHandle`暴露特定的方法给父组件
  useImperativeHandle(ref, () => ({
    // 将`openAuthorization`方法暴露给父组件
    openAuthorization,
  }));

  return (
    <div style={props.style} className="authorization-box">
      <div className="authorization-top">
        <span className="iconfont fangxiang-zuo-xian" onClick={goBack}>
          {' '}
          &nbsp;返回
        </span>
      </div>
      <Form style={{ display: isVisible ? 'block' : 'none' }} form={urlForm} layout="vertical">
        <Form.Item
          name="auth_url"
          label="TikTok账户授权URL"
          rules={[{ required: true, message: '请输入TikTok账户授权URL' }]}
        >
          <Input
            disabled
            placeholder="请输入TikTok账户授权URL"
            suffix={
              <span
                className="iconfont fuzhi"
                onClick={() => copyText(urlForm.getFieldsValue().auth_url, false)}
              ></span>
            }
          />
        </Form.Item>
        <Form.Item>
          <Button key="submit" type="primary" onClick={() => setIsVisible(false)}>
            已授权，前往配置
          </Button>
        </Form.Item>
      </Form>
      <Form style={{ display: isVisible ? 'none' : 'block' }} form={form} layout="vertical">
        <Form.Item name="account_id" label="企业号id">
          <Input disabled placeholder="请输入企业号id" />
        </Form.Item>
        <Form.Item name="account_name" label="企业号名称">
          <Input disabled placeholder="请输入企业号名称" />
        </Form.Item>
        <Form.Item
          name="agent_id"
          label="应用的LLM Agent"
          rules={[{ required: true, message: '请选择应用的LLM Agent' }]}
        >
          <Select placeholder="请选择" allowClear showSearch>
            {props.agentList.map((item: any) => (
              <Select.Option key={item.agent_id} value={item.agent_id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="enable_welcome_msg"
          label={
            <Tooltip
              title="欢迎语计入消息条数计算，在收到来自TikTok用户的消息后的48小时内发送最多10条消息"
              placement="top"
            >
              会话欢迎语<span className="iconfont xinxi"></span>
            </Tooltip>
          }
          rules={[{ required: true, message: '请选择会话欢迎语' }]}
        >
          <Switch
            defaultChecked
            onChange={(data: any) => {
              form.setFieldValue('enable_welcome_msg', data);
            }}
          ></Switch>
        </Form.Item>
        <Form.Item>
          <Button style={{ float: 'right' }} key="submit" type="primary" onClick={handleSave}>
            保存
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});
Authorization.displayName = 'Authorization';
export default Authorization;
