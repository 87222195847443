import React, { useState, useEffect, FC } from 'react';
import { Select, message } from 'antd';
import {t} from 'i18next';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import {
  VAR_LIST_TYPE,
  queryVarListByAgentAction,
  selectOptions,
  updateVarListByAgentAction,
} from '@/store/modules/asyncOptions';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { cloneDeep } from 'lodash';

const { Option } = Select;

interface VarNameSelectPropsType {
  value: string | null;
  onChange: (val: string) => void;
}

const VarNameSelect: FC<VarNameSelectPropsType> = ({ value, onChange }) => {
  const currentAgent = useAppSelector(selectCurrentAgent);
  const [data, setData] = useState(value);
  const dispatch = useAppDispatch();

  const systemVar = [
    'cur_time',
    'cur_date',
    'last_input',
    'last_output',
    'last_output',
    'all_message',
    'last_intention',
    'llm_output',
    'llm_output_field',
    'cur_week',
  ];

  const varList = useAppSelector(selectOptions).varList;

  useEffect(() => {
    dispatch(queryVarListByAgentAction(currentAgent.currentAgent.agent_id));
  }, [currentAgent.currentAgent.agent_id]);

  const [varListOptions, setVarListOptions] = useState<VAR_LIST_TYPE[]>([]);
  useEffect(() => {
    setVarListOptions(cloneDeep(varList));
  }, [varList]);

  function onSearch(val: string) {
    console.log(val);
    if (!val) {
      console.log('清空');
      setVarListOptions(cloneDeep(varList));
      return;
    }
    const isIn = varList.find(i => i.varname.includes(val));
    if (!isIn) {
      setVarListOptions([
        ...varList,
        {
          agent_id: currentAgent.currentAgent.agent_id,
          varname: val,
          description: '',
        },
      ]);
    }
  }

  function onValueChange(val: string) {
    const isHasOptions = varList.find(i => i.varname === val);
    const isSystem = systemVar.includes(val);
    if (isSystem) {
      setData('');
      onChange('');
      // message.error(`${val}与系统变量重复，请重新输入`);
      message.error(t('components.agentForm.varNameSelect.valueTip', {title: val}));
      return;
    } else {
      if (!isHasOptions && !isSystem) {
        // 没有创建过 && 不是系统变量
        dispatch(
          updateVarListByAgentAction({
            varname: val,
            description: '',
            agent_id: currentAgent.currentAgent.agent_id,
          })
        ).then(() => {
          setData(val);
          onChange(val);
        });
      } else {
        setData(val);
        onChange(val);
      }
    }
  }
  return (
    <Select
      placeholder={t('components.agentForm.selectOrCreateVariable')}
      value={data}
      onSearch={val => onSearch(val)}
      onChange={val => onValueChange(val)}
      filterOption
      showSearch
      style={{ width: '100%' }}
    >
      {varListOptions.map((o, i) => (
        <Option key={i} value={o.varname}>
          {o.varname}
        </Option>
      ))}
    </Select>
  );
};

export default VarNameSelect;
