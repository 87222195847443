// Carts.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Tag, Tooltip, Button, Modal, Typography } from 'antd';
import { t } from 'i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Sortable from 'sortablejs';
import type { DataItem } from '@/store/modules/types';
import { roleTypeOptions } from '@/constant/options';
import { roleSort } from '@/api/agent';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import './Carts.scss';

interface CartsProps {
  selectName: string;
  toolMaps: DataItem[];
  delCart: (data: any) => void;
  copyCart: (data: any) => void;
  changeStatus: (data: any) => void;
  edit: (item: DataItem) => void;
  editCart: (data: DataItem) => void;
  refreshRole: () => void;
  moveToRole: (data: any) => void;
  onCartDrag: (data: any) => void;
}

const { Text } = Typography;

const Carts: React.FC<CartsProps> = ({
  selectName,
  toolMaps,
  delCart: delCartFun,
  copyCart: copyCartFun,
  changeStatus,
  edit,
  editCart,
  refreshRole,
  moveToRole,
  onCartDrag,
}) => {
  const [activeCart, setActiveCart] = useState<DataItem>({ uid: '' });
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const cartRef = useRef<HTMLDivElement>(null);
  const sort = useRef<any>(null);

  useEffect(() => {
    if (toolMaps.length) {
      initCartDrag();
    }
  }, [toolMaps]);

  const handleEdit = (cart: DataItem) => {
    setActiveCart(cart);
    edit(cart);
  };

  const delCart = (info: DataItem) => {
    Modal.confirm({
      title: t('common.confirmDeleteRole'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.confirmDeleteRoleTip'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: () => {
        delCartFun({ uid: info.uid, type: '' });
      },
    });
  };

  const copyCart = (info: DataItem) => {
    Modal.confirm({
      title: t('common.confirmCopy'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: () => {
        copyCartFun({ uid: info.uid, type: '' });
      },
    });
  };

  const showRoleLabel = (type: string) => roleTypeOptions.find(i => i.value === type)?.label || '';

  const showRoleType = (type: string) => (type === '0' ? t('common.answer') : type === '1' ? t('common.classification') : t('common.flow'));

  const isShowEditIcon = (agent: DataItem) => {
    let isShow = false;
    if (agent.role_type) {
      isShow = agent.role_type === '1' || agent.role_type === '2';
    } else if (agent.cate) {
      isShow = agent.cate === '2';
    }
    return isShow;
  };

  const initCartDrag = () => {
    if (cartRef.current) {
      sort.current?.destroy();
      sort.current = Sortable.create(cartRef.current, {
        draggable: '.cart-draggable .cart-item',
        onStart({ item }) {
          onCartDrag(item.dataset.uid);
        },
        onEnd({ newIndex, oldIndex }) {
          onCartDrag('');
          if (newIndex === oldIndex) {
            return;
          }
          if (newIndex === undefined || oldIndex === undefined) {
            return;
          }
          let moveType = '';
          let data: any[] = [];
          if (oldIndex > newIndex) {
            moveType = 'up';
            data = [
              {
                uid: toolMaps[oldIndex].uid,
                sort: toolMaps[oldIndex].sort,
              },
              {
                uid: toolMaps[newIndex].uid,
                sort: toolMaps[newIndex].sort,
              },
            ];
          } else {
            moveType = 'down';
            data = [
              {
                uid: toolMaps[newIndex].uid,
                sort: toolMaps[newIndex].sort,
              },
              {
                uid: toolMaps[oldIndex].uid,
                sort: toolMaps[oldIndex].sort,
              },
            ];
          }
          roleSort({
            data: data,
            move_type: moveType,
            agent_id: currentAgent.agent_id,
          }).then(res => {
            if (res.code === 200) {
              refreshRole();
            }
          });
        },
      });
    }
  };

  return (
    <div className="agent-engine-cart cart-draggable" ref={cartRef} onDrop={moveToRole}>
      {toolMaps.map(item => (
        <div
          key={item.uid}
          onClick={() => handleEdit(item)}
          className={`cart-item ${activeCart.uid === item.uid ? 'active-border' : 'bg-white'}`}
          data-uid={item.uid}
        >
          <div className="cart">
            <div className="top">
              <Tag
                color={
                  item.role_type === '2' ? 'blue' : item.role_type === '1' ? 'green' : 'orange'
                }
              >
                {showRoleType(item.role_type || '')}
              </Tag>
              <div className="title">{item.name}</div>
              <span className="iconfont tuodong-shu" />
            </div>
            <Text ellipsis={{ tooltip: item.desc }}>{item.desc}</Text>
            <div className="btn-wrap">
              <Tooltip title={item.status === '0' ? t('status.online') : t('status.offline')}>
                <span
                  className={`iconfont status-btn ${item.status == '0' ? 'shangxian' : 'xiaxian'}`}
                  onClick={e => {
                    e.stopPropagation();
                    changeStatus({ info: item, type: '' });
                  }}
                />
              </Tooltip>
              {isShowEditIcon(item) && (
                <span
                  className="iconfont bianji edit-btn"
                  onClick={e => {
                    e.stopPropagation();
                    editCart(item);
                  }}
                />
              )}
              <span
                className="iconfont shanchu del-icon"
                onClick={e => {
                  e.stopPropagation();
                  delCart(item);
                }}
              />
              <span
                className="iconfont fuzhi"
                onClick={e => {
                  e.stopPropagation();
                  copyCart(item);
                }}
              />
              <span
                className={`status-span ${item.status == '0' ? 'disabled-span' : ''}`}
                onClick={e => {
                  e.stopPropagation();
                  changeStatus({ info: item, type: '' });
                }}
              >
                <span className="iconfont wancheng-mian"></span>
                {item.status == '0' ? t('status.offlineStatus') : t('status.onlineStatus')}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Carts;
