import React, { useState, useEffect } from 'react';
import { Button, Pagination, Input, Modal, Form, message, Switch } from 'antd';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
// import LLMAgentCard from './LLMAgentCard';
import LLMAgentList from './LLMAgentList';
import { queryAgentList, saveAgentTemplate } from '@/api/agent';
import './index.scss';
import { useAppDispatch } from '@/store/hooks';
import { setCurrentAgent } from '@/store/modules/agentTemplate';
import { useTranslation } from 'react-i18next';

// import './index.scss';
type Agent = {
  agent_id: string;
  name: string;
  desc?: string;
};
// const defaultFormValues = {
//   name: '',
//   desc: '',
// };

export default function LlmAgent() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [list, setList] = useState<Agent[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const nav = useNavigate();
  const [pageConfig, setPageConfig] = useState({
    page: 1,
    size: 20,
  });

  useEffect(() => {
    getListData();
  }, [pageConfig.page, pageConfig.size]);

  const getListData = async () => {
    try {
      // 模拟API调用
      const { code, data } = await queryAgentList({ ...pageConfig });
      if (code === 200) {
        setList(data.result);
        setTotalCount(data.total_count);
      }
    } catch (error) {
      console.error(error);
      message.info(t('agent.getListFailed'));
    }
  };
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);
  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      if (!values) {
        return;
      }
      const formValues = form.getFieldsValue(true);
      const actionObj = { desc: formValues.desc || '', name: formValues.name, group_type: 'ykf',enable_test_number: formValues.enable_test_number, test_number: formValues.test_number };
      const { code, data } = await saveAgentTemplate({
        agent_id: '',
        ...actionObj,
      });
      if (code === 200) {
        message.success(t('agent.saveSuccess'));
        setIsModalVisible(false);
        form.resetFields();
        dispatch(
          setCurrentAgent({
            ...actionObj,
            agent_id: data,
          })
        );
        nav('/agentService/agentManager/agentEngine');
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  function onPageChange(page: number, size: number) {
    setPageConfig({
      page,
      size,
    });
  }

  function refreshList() {
    getListData();
  }

  return (
    <div className="llm-agent-box">
      <div className="llm-agent-header">
        <div className="llm-agent-header-title">Agent</div>
      </div>
      <div className="llm-agent-content">
        <Button icon={<PlusOutlined />} onClick={showModal}>
          {t('agent.createLLMAgent')}
        </Button>
        <div className="card-list">
        <LLMAgentList agents={list} refreshList={refreshList} />
        </div>
      </div>
      <div className="mt-3 pr-5">
        <Pagination
          style={{ marginTop: 16, textAlign: 'right' }}
          className="pagination"
          defaultCurrent={1}
          showSizeChanger
          showQuickJumper
          current={pageConfig.page}
          pageSize={pageConfig.size}
          total={totalCount}
          onChange={(page, pageSize) => onPageChange(page, pageSize)} // 更新页码逻辑
        />
      </div>
      <Modal
        title={t('agent.createLLMAgent')}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleSave}>
            {t('common.save')}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={t('agent.name')}
            rules={[{ required: true, message: t('agent.nameRequired') }]}
          >
            <Input maxLength={50} showCount placeholder={t('agent.namePlaceholder')} />
          </Form.Item>
          <Form.Item name="desc" label={t('agent.desc')}>
            <Input.TextArea rows={3} maxLength={256} showCount placeholder={t('agent.descPlaceholder')} />
          </Form.Item>
          <Form.Item name="enable_test_number" valuePropName="checked" label={t('agent.enableTestNumber')}>
            <Switch onChange={(val: any) => {
              console.log(val);
              if(val === true) {
                form.setFieldsValue({ test_number: Math.floor(100000 + Math.random() * 900000)+'' });
              }else {
                form.setFieldsValue({test_number: ''});
              }
            }} />
          </Form.Item>
          <Form.Item
              name="test_number"
              label={t('agent.testNumber')}
          >
            <Input placeholder={t('agent.testNumberPlaceholder')} disabled />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
