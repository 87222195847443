import React, { useState, useEffect, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Button, Form, Input, message, Modal, Pagination, DatePicker, Tooltip } from 'antd';
import {t} from 'i18next'
import { queryBlackList, unblockBlack } from '@/api/agent';
import dayjs from 'dayjs';

const BlackList: React.FC = () => {
  const [queryForm] = Form.useForm();
  const [state, setState] = useState<{
    tableData: any[];
    currentPage: number;
    pageSize: number;
    totalPages: number;
    totalCount: number;
  }>({
    tableData: [],
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  });

  const onPageChange = (val: number, newSize: number) => {
    fetchData(val, newSize);
  };

  const handleDel = async (id: string) => {
    Modal.confirm({
      title: t('common.unblock'),
      content: t('common.confirmUnblock', {visitor: id}),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        const res = await unblockBlack({
          visitor_id: id,
        });
        if (res.code === 200) {
          message.success(t('common.unblockSuccess'));
          setState({ ...state, currentPage: 1 });
          fetchData(1);
        } else {
          message.error(res.message || t('common.unblockFailed'));
        }
      },
      onCancel: () => {},
    });
  };

  const normalFetch = () => {
    setState({ ...state, currentPage: 1 });
    fetchData(1);
  };

  const fetchData = async (page?: number, pageSize?: number) => {
    console.log(queryForm.getFieldsValue());

    const params: any = {
      page: page || state.currentPage,
      pageSize: pageSize || state.pageSize,
    };
    const { visitor_id, time } = queryForm.getFieldsValue();
    if (visitor_id) {
      params.visitor_id = visitor_id;
    }
    if (time) {
      params.start_time = time[0].format('YYYY-MM-DD HH:mm:ss');
      params.end_time = time[1].format('YYYY-MM-DD HH:mm:ss');
    }
    const res: any = await queryBlackList(params);
    if (res.code === 200 && res.data) {
      setState({
        ...state,
        tableData: res.data.result,
        currentPage: res.data.page || 1,
        totalCount: res.data.total_count || 0,
        pageSize: pageSize || state.pageSize,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="account main-container account-group h-full overflow-y-scroll">
      <div className="title">
        <Form form={queryForm} layout="inline" style={{ marginBottom: 16 }}>
          <Form.Item label={t('common.visitorId')} name="visitor_id">
            <Input placeholder={t('common.inputVisitorId')} onPressEnter={normalFetch} />
          </Form.Item>
          <Form.Item label={t('common.blackTime')} name="time">
            <DatePicker.RangePicker
              showTime={{
                format: ' HH:mm:ss',
              }}
              placeholder={[t('common.startTime'), t('common.endTime')]}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="default"
              onClick={() => {
                queryForm.resetFields();
                fetchData(1);
              }}
            >
              {t('common.reset')}
            </Button>
            <Button type="primary" className="ml-2" onClick={() => fetchData(1)}>
              {t('common.search')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="account-content">
        <Table size="small" dataSource={state.tableData} bordered rowKey="id" scroll={{ x: 1500, y: 600 }} pagination={false}>
          <Table.Column width={360} title={t('common.visitorId')} dataIndex="visitor_id" />
          <Table.Column width={200} title={t('common.blackTime')} dataIndex="black_time" />
          <Table.Column
            title={t('common.blackReason')}
            dataIndex="black_reason"
            ellipsis={{
              showTitle: false,
            }}
            render={(description) => (
              <Tooltip placement="topLeft" title={description}>
                {description}
              </Tooltip>
            )}
          />
          <Table.Column
            title={t('common.operation')}
            width={100}
            render={(text, record: any) => (
              <>
                <Button type="link" onClick={() => handleDel(record?.visitor_id)}>
                  {t('common.unblock')}
                </Button>
              </>
            )}
          />
        </Table>
        <Pagination
          style={{ marginTop: 16, textAlign: 'right' }}
          className="justify-end"
          showSizeChanger
          showQuickJumper
          current={state.currentPage}
          total={state.totalCount}
          pageSize={state.pageSize}
          showTotal={totalCount => t('common.totalItems', {count: totalCount})}
          onChange={(page, pageSize) => onPageChange(page, pageSize)}
        />
      </div>
    </div>
  );
};

export default BlackList;
