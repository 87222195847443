import { http } from '../service/http';
import { RootState, store } from '../store';
export const getCurrentAgent = (): any => {
  // 获取整个Redux状态
  const state: RootState = store.getState();
  return state.agentTemplate.currentAgent;
};
type Result = {
  code: number;
  message?: string;
  data: any;
};
// 新建/编辑知识库
export const saveKb = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/save_kb', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
// 查询知识库
export const queryKb = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/query_kb', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
// 删除知识库
export const deleteKb = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/delete_kb', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
// 新增知识点
export const saveFiles = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/save_files', {
    data: params,
    headers: {
      Apikey: getCurrentAgent().agent_id,
      'Content-Type': 'multipart/form-data',
    },
  });
};
// 查询知识点列表
export const queryFiles = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/query_files', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
// 删除知识点
export const deleteFiles = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/delete_files', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
// 修改知识点状态
export const updateFileStatus = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/update_file_status', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
// 检测网页链接的title
export const checkUrls = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/check_urls', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
// 保存词典
export const saveEntry = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/save_entry', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
// 删除词典
export const deleteEntry = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/delete_entry', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
// 查询词典
export const queryEntries = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/query_entries', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
export const getDocDetail = (params: any) => {
  return http.request<Result>('post', '/agent/platform/v2/openapi/get_doc_detail', {
    data: params,
    headers: { Apikey: getCurrentAgent().agent_id },
  });
};
