import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import {
  Button,
  Radio,
  Input,
  Form,
  Tooltip,
  Select,
  message,
} from 'antd';
import { t } from 'i18next';
import {
  HolderOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import './scss/StandardStrategy.scss';
import { conditionRule } from '@/constant/options';
import { produce } from 'immer';
import { queryQueue } from '@/api/account';
import { cloneDeep } from 'lodash';
import Sortable from 'sortablejs';
const { Option } = Select;

interface standardStrategyForAgentProps {
  data: {
    rule_list: any[];
    floor_reply: string;
    strategy_type: string;
    kb_rule_cate: string;
    skill_num: string;
    turn_human_msg: string;
    ask_human_conf: any;
  };
  changeData: (data: any) => void;
  showImageOption?: boolean; // 新增控制多模态识图按钮显示的prop
}

const StandardStrategyForAgent = forwardRef(function StandardStrategyForAgent(props: standardStrategyForAgentProps, ref) {
  const dataChange = (e: any, key: string) => {
    const newData: any = cloneDeep(props.data);
    const val = e.target.value;
    newData[key] = val;
    if (key === 'strategy_type' && val === 'action') {
      newData['kb_rule_cate'] = '12';
    }
    props.changeData(newData);
  };

  const [groupList, setGroupList] = useState<
    {
      queue_id: string;
      queue_name: string;
    }[]
  >([]);
  const fetchGroupList = async () => {
    const filters = { filters: [] } as any;
    const newFilters = JSON.stringify(filters);
    const str = `?q=${newFilters}&page=1&results_per_page=1000`;
    const res: any = await queryQueue(str);
    setGroupList(res.objects);
  };

  const checkFields = () => {
    return form.validateFields();
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        checkFields,
      };
    },
    []
  );

  const [form] = Form.useForm();

  const handleFormChange1 = () => {
    const values = form.getFieldsValue();
    const data = {
      ...props.data,
      ask_human_conf: {
        ...props.data.ask_human_conf,
        ...values,
      },
    };
    props.changeData(cloneDeep(data));
  };

  useEffect(() => {
    form?.setFieldValue('queues', props.data.ask_human_conf?.queues || []);
    form?.setFieldValue('busy_answer', props.data.ask_human_conf?.busy_answer || '');
    form?.setFieldValue('remark', props.data.ask_human_conf?.remark || '');
    fetchGroupList();
  }, [props.data.ask_human_conf]);

  return (
    <div className="standardStrategyForAgent">
      <div>
        <Radio.Group
          value={props.data.strategy_type}
          onChange={e => dataChange(e, 'strategy_type')}
        >
          {props.showImageOption && <Radio value="image">{t('components.agentForm.standardStrategyForAgent.image')}</Radio>}
          <Radio value="answer">{t('components.agentForm.standardStrategyForAgent.answer')}</Radio>
          <Radio value="action">{t('components.agentForm.standardStrategyForAgent.action')}</Radio>
        </Radio.Group>
      </div>
      {props.data.strategy_type === 'answer' && (
        <Input.TextArea
          showCount
          maxLength={1000}
          className="mt-5"
          value={props.data.floor_reply}
          onChange={(e: any) => dataChange(e, 'floor_reply')}
          placeholder={t('common.floorReply')}
        />
      )}
      {props.data.strategy_type === 'action' && (
        <Radio.Group
          className="mt-5"
          value={props.data.kb_rule_cate}
          onChange={e => dataChange(e, 'kb_rule_cate')}
        >
          <Radio value="5">{t('components.agentForm.standardStrategyForAgent.humanService')}</Radio>
          <Radio value="12">{t('components.agentForm.standardStrategyForAgent.askHuman')}</Radio>
        </Radio.Group>
      )}
      {props.data.strategy_type === 'action' && props.data.kb_rule_cate === '12' && (
        <Form
          layout="vertical"
          className="mt-5"
          form={form}
          name="cotrol-hooks"
          initialValues={props.data.ask_human_conf}
          onValuesChange={handleFormChange1}
        >
          <Form.Item
            name="group_type"
            label={t('components.agentForm.standardStrategyForAgent.skillGroup')}
          >
            <Radio.Group value={props.data.ask_human_conf.group_type}>
              <Radio value={'agent'}>{t('components.agentForm.standardStrategyForAgent.agentSkillGroup')}</Radio>
              <Radio value={'ykf'}>{t('components.agentForm.standardStrategyForAgent.ykfSkillGroup')}</Radio>
            </Radio.Group>
          </Form.Item>
          {props.data.ask_human_conf.group_type === 'agent' && (
            <Form.Item
              name="queues"
              label={t('components.agentForm.standardStrategyForAgent.selectSkillGroup')}
              rules={[{ required: props.data.kb_rule_cate === '12', message: t('common.selectSkillGroup') }]}
            >
                <Select placeholder={t('common.select')} mode="multiple">
                {groupList.map((i) => (
                  <Option value={i.queue_id} key={i.queue_id}>
                    {i.queue_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {props.data.ask_human_conf.group_type === 'ykf' && (
            <Form.Item
              name="queue_data"
              label={t('components.agentForm.standardStrategyForAgent.inputSkillGroup')}
              rules={[{ required: false, message: t('common.inputSkillGroup') }]}
            >
              <Input
                maxLength={5000}
                placeholder={t('common.inputSkillGroup')}
              />
            </Form.Item>
          )}
          <Form.Item
            name="busy_answer"
            label={
              <div className="flex justify-between w-full">
                <span className="mr-1">{ props.data.ask_human_conf.group_type === 'ykf' ? t('components.agentForm.standardStrategyForAgent.askHumanTip2') : t('components.agentForm.standardStrategyForAgent.askHumanTip1')}</span>
                <Tooltip title={t('components.agentForm.standardStrategyForAgent.askHumanTip3')}>
                  <ExclamationCircleOutlined />
                </Tooltip>
              </div>
            }
            rules={[{ required: props.data.kb_rule_cate === '12', message: t('components.agentForm.standardStrategyForAgent.inputBusyAnswer') }]}
          >
            <Input.TextArea
              rows={3}
              maxLength={5000}
              placeholder={t('components.agentForm.standardStrategyForAgent.askHumanTip4')}
            />
          </Form.Item>
          {props.data.ask_human_conf.group_type === 'agent' && (
            <Form.Item name="remark" label={t('common.remark')}>
              <Input.TextArea rows={3} maxLength={5000} placeholder={t('common.input')} />
            </Form.Item>
          )}
        </Form>
      )}
      {props.data.strategy_type === 'action' && props.data.kb_rule_cate === '5' && (
        <>
          <Input
            className="mt-5"
            placeholder={t('flowForm.inputPrioritySkillGroup')}
            maxLength={50}
            value={props.data.skill_num}
            onChange={e => dataChange(e, 'skill_num')}
          />
          <Input.TextArea
            className="mt-5"
            placeholder="回复用户"
            rows={3}
            maxLength={5000}
            value={props.data.turn_human_msg}
            onChange={e => dataChange(e, 'turn_human_msg')}
          />
        </>
      )}
    </div>
  );
});

export default StandardStrategyForAgent;
