import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
} from 'react';
import { Button, Table, Radio, Input, Form, Select, Modal, Row, Col, message } from 'antd';
import { t } from 'i18next';
import { HolderOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { conditionRule } from '@/constant/options';
import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import { generateUUID } from '@/utils/common';
import Sortable, { SortableEvent } from 'sortablejs';
const { Option } = Select;
enum CondType {
  ALL = 'all',
  ANY = 'any',
}

interface judgmentStrategyProps {
  chain_check: any;
  changeData: (data: any) => void;
}

const ChainCheck = forwardRef(function ChainCheck(props: judgmentStrategyProps, ref) {
  const checkFields = () => {
    return form.validateFields();
  };
  const [drawerVisible, setDrawerVisible] = useState(false);
  // 取消操作
  const cancel = () => {
    setDrawerVisible(false);
  };

  function handleRemoveCondition(i: number) {
    setSubForm(
      produce(draft => {
        draft.rule.cond_group.splice(i, 1);
      })
    );
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        checkFields,
      };
    },
    []
  );
  function handleAddCondition() {
    setSubForm(
      produce(draft => {
        draft.rule.cond_group = [
          ...draft.rule.cond_group,
          {
            var_name: null,
            condition: null,
            value: '',
          },
        ];
      })
    );
  }
  const [form] = Form.useForm();
  const [subFormRef] = Form.useForm();
  const subInitForm = {
    name: '',
    rule: {
      cond_group: [
        {
          condition: null,
          value: '',
          var_name: null,
        },
      ],
      id: '',
      cond_type: 'all',
    },
  };
  const [subForm, setSubForm] = useState(subInitForm);
  const handleFormChange = () => {
    const values = subFormRef.getFieldsValue();
    values.rule?.cond_group?.map((item: any) => {
      if (!item.value) {
        item.value = '';
      }
    });
    setSubForm({ ...subForm, ...values });
  };
  // 保存策略
  const saveRule = () => {
    subFormRef
      .validateFields()
      .then(() => {
        const newData = cloneDeep(subForm);
        const tbDataClone = cloneDeep(tbList.current);
        debugger
        if (editIndex === -1) {
          const repeat = tbDataClone.filter((op: any, i: number) => newData.name === op.name);
          if (repeat && repeat.length > 0) {
            message.error(t('flowForm.chainCheck.duplicateStrategyName'));
            return false;
          }
          tbDataClone.unshift({ ...newData, id: `llmtask_${generateUUID()}` });
          props.changeData(tbDataClone);
          setTbData(tbDataClone);
          tbList.current = tbDataClone;
          setDrawerVisible(false);
        } else {
          const repeat = tbDataClone.filter(
            (op: any, i: number) => newData.name === op.name && i !== editIndex
          );
          if (repeat && repeat.length > 0) {
            message.error(t('flowForm.chainCheck.duplicateStrategyName'));
            return false;
          }
          tbDataClone[editIndex] = newData;
          setTbData(tbDataClone);
          tbList.current = tbDataClone;
          setDrawerVisible(false);
          props.changeData(tbDataClone);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleAddRule = () => {
    setEditIndex(-1);
    subFormRef.setFieldsValue(subInitForm);
    setSubForm(subInitForm);
    setDrawerVisible(true);
  };
  const [editIndex, setEditIndex] = useState<number>(-1);
  const handleEdit = (index: number, record: any) => {
    setEditIndex(index);
    const newEdit = cloneDeep(record);
    setSubForm(newEdit);
    subFormRef.setFieldsValue(newEdit);
    setDrawerVisible(true);
  };
  const handleDelete = (index: number) => {
    const newArr = cloneDeep(tbList.current);
    newArr.splice(index, 1);
    setTbData(newArr);
    tbList.current = newArr;
    props.changeData(newArr);
  };

  const sort: any = useRef(null);
  const [tbData, setTbData] = useState<any>([]);
  const tbList = useRef<any>([]);
  const initTableRowDrag = useCallback(() => {
    sort.current?.destroy();
    const tbody: HTMLElement | null = document.querySelector(
      '.chain-draggable .ant-table-content tbody'
    );
    if (tbody) {
      sort.current = new Sortable(tbody, {
        draggable: '.chain-draggable .ant-table-row',
        onEnd({ newIndex, oldIndex }) {
          if (newIndex === oldIndex) {
            return;
          }
          if (newIndex === undefined || oldIndex === undefined) {
            return;
          }
          const newList = cloneDeep(tbList.current);
          const [removedItem] = newList.splice(oldIndex, 1);
          newList.splice(newIndex, 0, removedItem);
          setTbData(
            produce(draft => {
              draft = newList;
            })
          );
          tbList.current = newList;
          props.changeData(newList);
        },
      });
    }
  }, [tbData]);
  useEffect(() => {
    initTableRowDrag();
  }, [initTableRowDrag, tbData]);
  useEffect(() => {
    setTbData(props.chain_check);
    tbList.current = props.chain_check || [];
  }, [props.chain_check]);
  function showRuleText(row: any): string {
    let text = '';
    const cond_group = row.rule.cond_group || [];
    cond_group.forEach((i: any) => {
      const { condition, value, var_name } = i;
      let ruleLabel = '';
      const rule = conditionRule.find(c => c.value === condition);
      if (rule) ruleLabel = rule.label;
      text += `${var_name} ${ruleLabel} ${value || ''},`;
    });
    return text.substring(0, text.length - 1);
  }

  const columns = [
    {
      title: t('flowForm.chainCheck.order'),
      dataIndex: 'order',
      key: 'order',
      width: '50',
      render: () => {
        return (
          <span className="drag-icon">
            <HolderOutlined style={{ cursor: 'move' }} />
          </span>
        );
      },
    },
    {
      title: t('flowForm.ruleName'),
      dataIndex: 'name',
      key: 'name',
      width: '160',
      ellipsis: true,
    },
    {
      title: t('flowForm.chainCheck.condition'),
      dataIndex: 'condition',
      key: 'condition',
      render: (text: string, row: any) => {
        return <span>{showRuleText(row)}</span>;
      },
    },
    {
      title: t('common.operation'),
      dataIndex: 'action',
      key: 'action',
      width: '140',
      render: (text: string, record: any, index: number) => (
        <>
          <Button type="link" size="small" onClick={() => handleEdit(index, record)}>
            {t('common.edit')}
          </Button>
          <Button type="link" danger size="small" onClick={() => handleDelete(index)}>
            {t('common.delete')}
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="px-5 pt-5 pb-1 bg-white rounded mt-5">
      <div>
        <span className="llm-modal-title">{t('flowForm.chainCheck.title')}</span>
        <span className="llm-modal-tip">
          {t('flowForm.chainCheck.tip')}
        </span>
      </div>
      <div className="chain-check-top">
        <span className="chain-check-title">{t('flowForm.chainCheck.strategyTitle')}</span>
        <span className="iconfont jiahao" onClick={handleAddRule}>
          {t('flowForm.chainCheck.addStrategy')}
        </span>
      </div>
      <Table
        className="chain-draggable"
        dataSource={tbData}
        pagination={false}
        columns={columns}
        rowKey="order"
      ></Table>
      <p className="tip-p">当以上条件均不满足时，将执行「默认」分支。</p>
      <Modal
        title={t('flowForm.chainCheck.modalTitle')}
        onCancel={cancel}
        open={drawerVisible}
        width={800}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={cancel} style={{ marginRight: 8 }}>
              {t('common.cancel')}
            </Button>
            <Button onClick={saveRule} type="primary">
              {t('common.save')}
            </Button>
          </div>
        }
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          form={subFormRef}
          initialValues={subForm}
          onValuesChange={handleFormChange}
        >
          <Form.Item
            name="name"
            label={t('flowForm.chainCheck.strategyName')}
            rules={[{ required: true, message: t('flowForm.chainCheck.strategyNameRequired') }]}
          >
            <Input maxLength={50} showCount placeholder={t('common.input')} />
          </Form.Item>

          <Row gutter={12}>
            <Form.Item noStyle>
              <Col span={24}>
                <div className="font-bold inline-block">
                  <span>{t('flowForm.chainCheck.condition')}</span>
                </div>
              </Col>
            </Form.Item>
          </Row>

          {subForm.rule.cond_group &&
            subForm.rule.cond_group.map((item, index) => (
              <div className="flex" style={{ gap: 16 }} key={index + 'cond'}>
                <span>{(index + 1).toString() + '：'}</span>
                <Form.Item name={['rule', 'cond_group', index, 'var_name']}>
                  <Input />
                </Form.Item>
                <Form.Item name={['rule', 'cond_group', index, 'condition']} style={{ width: 120 }}>
                  <Select placeholder={t('flowForm.selectOperator')}>
                    {conditionRule.map((i, idx) => (
                      <Option key={idx} value={i.value}>
                        {i.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {['C', 'D', 'E', 'F', 'GT', 'LT', 'LTE', 'GTE', 'REGX'].includes(
                  // @ts-expect-error known
                  subForm.rule.cond_group[index]?.condition
                ) && (
                  <Form.Item name={['rule', 'cond_group', index, 'value']}>
                    <Input placeholder={t('common.input')} />
                  </Form.Item>
                )}
                {subForm.rule.cond_group.length > 1 && (
                  <div
                    className="flex items-center h-8 cursor-pointer"
                    onClick={() => handleRemoveCondition(index)}
                  >
                    <MinusCircleOutlined />
                  </div>
                )}
              </div>
            ))}
          <Row gutter={12}>
            <div className="pb-4">
              <span className="iconfont jiahao" onClick={handleAddCondition}>
                {t('flowForm.addCondition')}
              </span>
            </div>
          </Row>
          <Form.Item label={t('flowForm.executeLogic')} name={['rule', 'cond_type']}>
            <Radio.Group value={subForm.rule.cond_type}>
              <Radio value="all">{t('flowForm.allCondition')}</Radio>
              <Radio value="any">{t('flowForm.anyCondition')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default ChainCheck;
