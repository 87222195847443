import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react';
import { Drawer, Form, Input, Button, Tag, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import type { InputRef } from 'antd';

interface EntryForm {
  id?: string;
  term: string;
  aliases: Array<string>;
  definition?: string;
}

interface ChildProps {
  onSave: (data: EntryForm) => void;
}
// 定义暴露给父组件的方法类型
interface ChildMethods {
  openDialog: (value: any) => void;
  closeDialog: () => void;
}
// 使用`React.forwardRef`来获取`ref`传递
const AddEntryModal = forwardRef<ChildMethods, ChildProps>((props, ref) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState<any>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentData, setCurrentData] = useState({ id: '' });
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [form] = Form.useForm<EntryForm>();
  const [bigTypeText, setBigTypeText] = useState('');
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag: string) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    if (tags && tags.length > 20) {
      return;
    }
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };
  const openDialog = (data?: any) => {
    setIsVisible(true);
    if (data) {
      setIsEdit(true);
      form.setFieldsValue(data);
      setTags(data.aliases || []);
      setCurrentData(data);
      setBigTypeText(data.definition);
    } else {
      setIsEdit(false);
      form.resetFields();
      setTags([]);
      setCurrentData({ id: '' });
      setBigTypeText('');
    }
  };
  const closeDialog = () => {
    setIsVisible(false);
    form.resetFields(); // 清空表单项
  };
  // 通过`useImperativeHandle`暴露特定的方法给父组件
  useImperativeHandle(ref, () => ({
    // 将`openDialog`方法暴露给父组件
    openDialog,
    closeDialog,
  }));

  const handleSave = () => {
    form
      .validateFields()
      .then(values => {
        props.onSave({
          term: values.term,
          aliases: tags || [],
          id: currentData.id,
          definition: values.definition,
        });
      })
      .catch(info => {
        console.error('Validate Failed:', info);
      });
  };

  const forMap = (tag: string) => (
    <span key={tag} style={{ display: 'inline-block', marginBottom: 10 }}>
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    </span>
  );

  const tagChild = tags.map(forMap);

  return (
    <Drawer
      title={<span>{isEdit ? t('common.editEntry') : t('common.addEntry')}</span>}
      width={600}
      onClose={() => setIsVisible(false)}
      open={isVisible}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={() => setIsVisible(false)} style={{ marginRight: 8 }}>
            {t('common.cancel')}
          </Button>
          <Button onClick={handleSave} type="primary">
            {t('common.save')}
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="term"
          label={t('common.entryName')}
          rules={[{ required: true, message: t('common.entryNamePlaceholder') }]}
        >
          <Input maxLength={50} showCount placeholder={t('common.entryNamePlaceholder')} />
        </Form.Item>
        <Form.Item name="aliases" label={t('common.entryAlias')}>
          <div style={{ marginBottom: 6 }}>{tagChild}</div>
          {inputVisible ? (
            <Input
              ref={inputRef}
              type="text"
              size="small"
              maxLength={50}
              style={{ width: 100 }}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          ) : (
            <Tag
              onClick={showInput}
              className={tags && tags.length > 20 ? 'entry-disabled-tag' : 'entry-tag'}
            >
              <span className="iconfont jiahao" /> {t('common.label')}
            </Tag>
          )}
        </Form.Item>
        <Form.Item
          name="definition"
          className="custom-label"
          rules={[{ required: true, message: t('common.entryContentPlaceholder') }]}
          label={
            <div className="label-div">
              <span>{t('common.entryContent')}</span>
              <span className="label-btn">
                <span className="iconfont quanping" onClick={() => setDialogVisible(true)}>
                  {t('common.fullScreen')}
                </span>
              </span>
            </div>
          }
        >
          <Input.TextArea
            onChange={e => {
              setBigTypeText(e.target.value);
            }}
            rows={8}
            maxLength={1000}
            showCount
            placeholder={t('agent.descPlaceholder')}
          />
        </Form.Item>
      </Form>
      <Modal
        open={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        footer={null}
        width="100%"
        className="full-screen-modal"
        style={{ top: 0 }}
      >
        <div className="modal-header mb-3">
          <span>{t('common.entryContent')}</span>
        </div>
        <Input.TextArea
          value={bigTypeText}
          onChange={e => {
            form.setFieldValue('definition', e.target.value);
            setBigTypeText(e.target.value);
          }}
          rows={8}
          maxLength={1000}
          showCount
          placeholder={t('agent.descPlaceholder')}
        />
        <div className="modal-footer text-right mt-6">
          <Button type="primary" onClick={() => setDialogVisible(false)}>
            {t('common.close')}
          </Button>
        </div>
      </Modal>
    </Drawer>
  );
});
AddEntryModal.displayName = 'AddEntryModal';
export default AddEntryModal;
