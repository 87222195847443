import { closeSession, initSession } from '@/api/agent';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { generateUUID } from '@/utils/common';
import { Drawer, message } from 'antd';
import { clone, cloneDeep } from 'lodash';
import React, { FC, useRef, useState, useEffect } from 'react';
import SendMsgBox from './SendMsgBox';
import { produce } from 'immer';
import MessageList from './MessageList';
import DebugLog from './DebugLog';
import CheckDoc from '@/components/CheckDoc/index';
import './scss/index.scss';

const TestChat: FC = () => {
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const [dialog, setDialog] = useState(false);
  const [size, setSize] = useState(600);
  const [messages, setMessages] = useState<any[]>([]);
  const [imageList, setImageList] = useState<string[]>([]);
  const [dialogTableVisible, setDialogTableVisible] = useState<boolean>(false);
  const [debugInfo, setDebugInfo] = useState<any[]>([]);
  const [docDetail, setDocDetail] = useState<any>({});
  const [checkDocVisible, setCheckDocVisible] = useState<boolean>(false);
  useEffect(() => {
    const dragElement = (element: any) => {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      element.onmousedown = dragMouseDown;
      let isDragging = false;
      function dragMouseDown(e: any) {
        e.preventDefault();
        isDragging = false;
        // 获取鼠标光标位置
        pos3 = e.clientX;
        pos4 = e.clientY;
        // 当光标移动时调用函数
        document.onmousemove = elementDrag;
        document.onmouseup = closeDragElement;
      }
      function elementDrag(e: any) {
        e.preventDefault();
        isDragging = true;
        // 计算新的光标位置
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // 计算新 positions
        let newTop = element.offsetTop - pos2;
        let newLeft = element.offsetLeft - pos1;
        // 边界检测
        const rightBoundary = window.innerWidth - element.offsetWidth;
        const bottomBoundary = window.innerHeight - element.offsetHeight;
        // 确保不会拖出界面，也不会拖到菜单栏上
        if (newLeft < 210) {
          newLeft = 210;
        } else if (newLeft > rightBoundary) {
          newLeft = rightBoundary - 10;
        }
        if (newTop < 0) {
          newTop = 0;
        } else if (newTop > bottomBoundary) {
          newTop = bottomBoundary;
        }
        // 设置元素的新位置
        element.style.top = newTop + 'px';
        element.style.left = newLeft + 'px';
      }
      function closeDragElement() {
        // 释放鼠标按钮时停止移动
        document.onmouseup = null;
        document.onmousemove = null;
        if (isDragging) {
          element.onclick = (e: any) => e.stopImmediatePropagation();
        } else {
          element.onclick = openChat;
        }
      }
    };
    const draggableDiv = document.querySelector('.test-chat-btn');
    dragElement(draggableDiv);
  }, []);
  function openChat() {
    setDialog(true);
    setSize(600);
    setDialogTableVisible(false);
    setCheckDocVisible(false);
    setMessages([]);
    initCurrentSession();
  }

  // 初始化会话
  const sid = useRef<string>('');
  function setSid(val: string) {
    sid.current = val;
  }
  const initCurrentSession = () => {
    sid.current = generateUUID();
    initSession({
      sid: sid.current, // 会话id
      session_source: 'test',
      agent_id: currentAgent.agent_id, // agent id
      system_args: {}, // 随路参数
      msg_receive_url: 'http://chat-task-based-addon/ac/callback', // 消息接受地址
    })
      .then((res: any) => {
        if (res.agent_info && res.agent_info.enable_welcome_msg && res.agent_info.welcome_msg) {
          // 欢迎语开关打开 && 配置了欢迎语
          // 发送消息的时间，取msg_time

          setMessages(prevMessages =>
            produce(prevMessages, draft => {
              draft.splice(draft.length, 0, {
                type: 'assistant',
                welcome: true,
                text: res.agent_info.welcome_msg,
                msg_time: res.start_time || '',
              });
            })
          );
        }
        connectSocket();
      })
      .catch(() => {
        message.error('初始化会话失败~');
      });
  };

  let websocket: any;
  // 关闭当前会话
  const closeCurrentSession = () => {
    closeSession({ sid: sid.current }).then(res => {
      if (res.code == 200) {
        setDialog(false);
        websocket && websocket.close();
      } else {
        message.error(res.message || '关闭会话失败~');
      }
    });
  };
  // 初始化socket,用于主动引导。为什么socket和http并存？=》 详看readme。
  const connectSocket = () => {
    const token = window.sessionStorage.getItem('token');
    let host = window.location.host;
    if (window.location.hostname == 'localhost') {
      host = 'ai-agent-test.7moor.com';
    }
    const url = `wss://${host}/agent/websocket?Token=${token}`;
    if ('WebSocket' in window) {
      websocket = new WebSocket(url);
    } else if ('MozWebSocket' in window) {
      // @ts-expect-error known
      websocket = new MozWebSocket(url);
    }
    websocket.onopen = () => {
      websocket.send(
        JSON.stringify({
          type: 'register',
          sid: sid.current,
        })
      );
    };
    websocket.onclose = (event: any) => {
      console.log(event);
    };
    websocket.onmessage = (event: any) => {
      console.log(event);
      const data = JSON.parse(event.data);
      if (data.msg && data.type !== 'askhuman') {
        // 欢迎语开关打开 && 配置了欢迎语
        // 发送消息的时间，取msg_time
        setMessages(
          produce((draft: any) => {
            draft.splice(draft.length, 0, {
              type: 'assistant',
              text: data.msg,
              debug: data.debug,
              log: data.log,
              msg_time: data.msg_time || '',
            });
            // @ts-expect-error known
            draft.sort((a: any, b: any) => new Date(a.msg_time) - new Date(b.msg_time));
          })
        );
      } else if (data.type === 'askhuman') {
        setMessages(
          produce((draft: any) => {
            draft.splice(draft.length, 0, {
              type: 'askhuman',
              text: data.message,
              origin_message: data.origin_message || '',
              content_type: data.content_type || '',
              msg_time: data.msg_time || '',
              user_nickname: data.user_nickname || data.askhuman_user_nickname,
            });
            // @ts-expect-error known
            draft.sort((a: any, b: any) => new Date(a.msg_time) - new Date(b.msg_time));
          })
        );
        console.log(message);
        // 根据发送时间再排序一下
      }
    };
    websocket.onerror = (event: any) => {
      console.log(event);
    };
  };

  function addMessage2List(data: any) {
    setMessages(prevMessages =>
      produce(prevMessages, draft => {
        draft.splice(draft.length, 0, data);
      })
    );
  }
  function addImage2List(url: string) {
    setImageList([...imageList, url]);
  }
  const closeDebug = () => {
    setSize(600);
    setDialogTableVisible(false);
  };
  const closeDoc = () => {
    setSize(600);
    setCheckDocVisible(false);
  };

  return (
    <>
      <div className="test-chat-btn iconfont ceshirukou" />
      <Drawer
        open={dialog}
        title={currentAgent.name}
        onClose={closeCurrentSession}
        placement="right"
        className="session-drawer"
        width={size}
      >
        <div className="chat-message-container flex h-full">
          <div className="test-chat-container session-container">
            <div className="chat-window">
              <div className="start-time">
                {messages && messages.length > 0 ? messages[0].msg_time : ''}
              </div>
              <MessageList
                messages={messages}
                imageList={imageList}
                debugInfo={debugInfo}
                setSize={setSize}
                setDebugInfo={setDebugInfo}
                setDialogTableVisible={setDialogTableVisible}
                setCheckDocVisible={setCheckDocVisible}
                setDocDetail={setDocDetail}
              ></MessageList>
              <SendMsgBox
                sid={sid}
                setSid={setSid}
                messages={messages}
                setMessages={setMessages}
                addMessage2List={data => addMessage2List(data)}
                addImage2List={addImage2List}
              ></SendMsgBox>
            </div>
          </div>
          {dialogTableVisible && (
            <DebugLog
              debugInfo={debugInfo}
              setDebugInfo={setDebugInfo}
              closeDebug={closeDebug}
            ></DebugLog>
          )}
          <CheckDoc
            onCloseDoc={closeDoc}
            style={{ display: checkDocVisible ? 'block' : 'none' }}
            docDetail={docDetail}
          />
        </div>
      </Drawer>
    </>
  );
};

export default TestChat;
