// React组件中采用antd和hooks进行重写
import React, { useState, useEffect, useRef, FC, useImperativeHandle, forwardRef } from 'react';
import {
  Form,
  Radio,
  Input,
  Select,
  message,
  Space,
  Modal,
  Button,
  Tooltip,
  RadioChangeEvent,
} from 'antd';
import { t } from 'i18next';
import { queryKb as queryKbApi } from '@/api/km'; // 假定API路径
import { cateTypes } from '@/constant/options';
import './scss/StandardStrategy.scss';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent, selectModelEnum } from '@/store/modules/agentTemplate';
import { DataItem } from '@/store/modules/types';
import Panel from '@/components/Panel/index';
import OssUpload from '@/components/OssUpload/index';
import CustomObjKeySelect from './CustomObjKeySelect';
import { queryQueue } from '@/api/account';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import JudgmentStrategy from '@/components/JudgmentStrategy';

const { TextArea } = Input;
const { Option } = Select;

interface StandardStrategyProps {
  showTitle?: boolean;
  isConditionModule?: boolean;
  formState?: DataItem; // 根据实际情况声明更精确的类型
  ref?: any;
}
const StandardStrategy: FC<StandardStrategyProps> = forwardRef(function StandardStrategy(
  // eslint-disable-next-line react/prop-types
  { formState, showTitle = true, isConditionModule = false },
  ref
) {
  const currentAgent = useAppSelector(selectCurrentAgent);
  const modelEnum = selectModelEnum();
  const [form] = Form.useForm();
  const [kbList, setKbList] = useState<any[]>([]);
  const [strategyType, setStrategyType] = useState<string>('');
  // 假定这是从props或外部数据获取的strategyTypes和answerTypes
  const strategyTypes = [
    {
      label: t('common.reply'),
      value: 'answer',
    },
    {
      label: 'Action',
      value: 'action',
    },
  ];
  const [formInfo, setFormInfo] = useState<any>({});
  const defaultChain = {
    rule_list: [],
    floor_reply: t('components.agentForm.standardStrategyForAgent.askHumanTip4'),
    strategy_type: 'answer',
    kb_rule_cate: '3',
    skill_num: '',
    turn_human_msg: '',
    ask_human_conf: {
      queues: [],
      remark: '',
      busy_answer: t('components.agentForm.standardStrategyForAgent.askHumanTip4'),
      group_type: 'ykf',
      queue_data: ''
    },
  };
  useEffect(() => {
    if (formState) {
      // eslint-disable-next-line react/prop-types
      if (!formState.think_chain_config || (formState.think_chain_config && Object.keys(formState.think_chain_config).length <= 0)) {
        setFormInfo(defaultChain);
      } else {
        // eslint-disable-next-line react/prop-types
        setFormInfo(formState.think_chain_config);
      }
    }
    form.setFieldsValue(formState);
    // eslint-disable-next-line react/prop-types
    setAnswerType(formState?.cate || formState?.rule_cate || '1');
    // eslint-disable-next-line react/prop-types
    if (formState?.json_parser === '3') {
      setShowJudge(true);
    }
    // eslint-disable-next-line react/prop-types
    setIsOpenPrompt(formState?.is_custom_prompt || false);
    handleChangeAskHumanGroupType();
  }, [formState]);
  useEffect(() => {
    queryKbList();
    handleSetAnswerOptions();
    handleChangeAnswerType();
    handleChangeShowJudge();
    fetchGroupList();
  }, []);

  const queryKbList = async () => {
    // 调用API查询知识库列表
    const res = await queryKbApi({
      page: 1,
      per_page: 99999,
      title: '',
    });
    if (res.code === 200) {
      setKbList(res.data?.list || []);
    } else {
      message.error(res.message || t('components.agentForm.standardStrategy.getKbFailed'));
    }
  };

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };

  interface AnswerOptionsType {
    label: string;
    value: string;
  }
  const [answerOptions, setAnswerOptions] = useState<AnswerOptionsType[]>([]);

  /**
   * @description: 设置回复类型下面的选项
   * @return {*}
   */
  function handleSetAnswerOptions() {
    if (!formState) {
      return;
    }
    let strategy_type = form.getFieldsValue().strategy_type;
    if (!strategy_type) {
      // eslint-disable-next-line react/prop-types
      const cate = (isConditionModule ? formState.rule_cate : formState.cate) || '';
      strategy_type = ['1', '3', '4'].includes(cate) ? 'answer' : 'action';
    }
    const options = cateTypes.find(i => i.type === strategy_type)?.options || [];
    setAnswerOptions(options);
  }

  const [answerType, setAnswerType] = useState<string>('');
  const [isOpenPrompt, setIsOpenPrompt] = useState<boolean>(false);
  const [showJudge, setShowJudge] = useState<boolean>(false);
  const [askHumanGroupType, setAskHumanGroupType] = useState<'agent' | 'ykf'>('agent');

  const parserChange = (e: any) => {
    if (e.target?.value === '3') {
      setShowJudge(true);
    } else {
      setShowJudge(false);
    }
  };
  /**
   * @description: 切换回复类型
   * @return {*}
   */
  function handleChangeAnswerType() {
    const cate = form.getFieldsValue().cate || form.getFieldsValue().rule_cate;
    setAnswerType(cate);
  }
  function handleChangeAskHumanGroupType() {
    const ask_human_conf = form.getFieldsValue().ask_human_conf
    if (ask_human_conf) {
      const { group_type } = ask_human_conf;
      setAskHumanGroupType(group_type)
    }
  }
  function handleChangeShowJudge() {
    const isHas = form.getFieldsValue().json_parser === '3';
    setShowJudge(isHas);
  }

  function onValuesChange(values: any) {
    const { strategy_type, rule_cate, cate, ask_human_conf } = values;
    // 设置回复类型单选项
    if (strategy_type) {
      handleSetAnswerOptions();
    }
    if (rule_cate || cate) {
      handleChangeAnswerType();
    }
    if (ask_human_conf && ask_human_conf.group_type) {
      const { group_type } = ask_human_conf;
      setAskHumanGroupType(group_type)
    }
  }

  function getFormFieldsValue() {
    return form.getFieldsValue(true);
  }
  async function validateFormFields() {
    form.setFieldsValue({ think_chain_config: formInfo });
    if (formInfo.kb_rule_cate === '12' && judgmentRef.current) {
      return judgmentRef.current.checkFields().then(() => {
        return form.validateFields();
      });
    } else {
      return form.validateFields();
    }
  }
  const judgmentRef = useRef<any | null>(null);
  useImperativeHandle(
    ref,
    () => {
      return {
        getFormFieldsValue,
        validateFormFields,
      };
    },
    [showJudge, formInfo]
  );
  const [auxiliary, setAuxiliary] = useState(false);
  const [bigAuxiliary, setBigAuxiliary] = useState(false);
  const handleAuxiliary = (type: string) => {
    if (type) {
      setAuxiliary(false);
      setBigAuxiliary(true);
    } else {
      setAuxiliary(true);
      setBigAuxiliary(false);
    }
  };
  const [bigType, setBigType] = useState('');
  const [bigTypeText, setBigTypeText] = useState('');
  const [dialogVisible, setDialogVisible] = useState(false);

  const openInputDialog = (type: string) => {
    setBigTypeText(form.getFieldValue(type));
    setBigType(type);
    setDialogVisible(true);
  };
  const changeData = (data: any) => {
    console.log(data);
    setFormInfo({
      ...formInfo,
      ...data,
    });
  };

  const filtersTitle = (type: string) => {
    const typeMap = {
      net_search_prompt: t('agentEngine.replyType4'),
      direct_answer: t('agentEngine.scriptContent'),
      llm_prompt: t('agentEngine.answerPrompt'),
      polish_prompt: t('agentEngine.polishContent'),
    };
    // @ts-expect-error known
    return typeMap[type];
  };

  const [groupList, setGroupList] = useState<
    {
      queue_id: string;
      queue_name: string;
    }[]
  >([]);
  const fetchGroupList = async () => {
    const filters = { filters: [] } as any;
    const newFilters = JSON.stringify(filters);
    const str = `?q=${newFilters}&page=1&results_per_page=1000`;
    const res: any = await queryQueue(str);
    setGroupList(res.objects);
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        layout="vertical"
        className="strandardStrategyForm"
        initialValues={{ strategy_type: 'answer', tool_llm: '' }}
      >
        <div className="mt-5 px-5 pt-5 pb-1 bg-white rounded">
          {showTitle && (
            <div className="bg-white rounded pb-3">
              <div className="pre-label before:bg-primary_blue flex items-center font-bold">
                <span className="ml-4">{t('components.agentForm.standardStrategy.standardStrategy')}</span>
              </div>
            </div>
          )}
          <Form.Item
            label={
              <div className="flex items-center">
                <svg className="icon text-base" aria-hidden="true">
                  <use xlinkHref="#celveleixing" />
                </svg>
                <span className="ml-1">{t('components.agentForm.standardStrategy.standardStrategy')}</span>
              </div>
            }
            name="strategy_type"
          >
            <Radio.Group>
              <Radio value="answer">{t('common.reply')}</Radio>
              <Radio value="action">{t('components.agentForm.standardStrategyForAgent.action')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <div className="flex items-center">
                <svg className="icon text-base" aria-hidden="true">
                  <use xlinkHref="#huifuleixing" />
                </svg>
                <span className="ml-1">{t('agentEngine.replyType')}</span>
              </div>
            }
            name={isConditionModule ? 'rule_cate' : 'cate'}
            rules={[{ required: true, message: t('agentEngine.selectReplyType') }]}
          >
            <Radio.Group>
              {answerOptions.map(i => {
                return (
                  <Radio key={i.value} value={i.value}>
                    {i.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
          {/* 知识库 */}
          {
            <div className={!['8'].includes(answerType) ? 'hidden' : ''}>
              <Form.Item name="kbs" label={t('agentEngine.knowledgeBaseName')}>
                <CustomObjKeySelect
                  mode="multiple"
                  value={form.getFieldValue('kbs')}
                  items={kbList}
                  onChange={(val: any) => {
                    console.log(val);
                    form.setFieldsValue({ kbs: val });
                  }}
                />
              </Form.Item>
            </div>
          }
          {/* 回答模型 */}
          {
            <div className={!['1', '4', '8'].includes(answerType) ? 'hidden' : ''}>
              <Form.Item
                name="tool_llm"
                label={
                  <div className="flex items-center">
                    <svg className="icon text-base" aria-hidden="true">
                      <use xlinkHref="#huidamoxing" />
                    </svg>
                    <span className="ml-1">{t('components.agentForm.standardStrategyForAgent.answerModel')}</span>
                  </div>
                }
                rules={[{ required: ['1', '4'].includes(answerType), message: t('components.agentForm.standardStrategyForAgent.selectAnswerModel') }]}
              >
                <Select placeholder={t('common.select')}>
                  <Option value="default" key="default">
                    {t('common.default')}
                  </Option>
                  {modelEnum.map(i => {
                    return (
                      <Option value={i} key={i}>
                        {i}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          }
          {/* 知识库 */}
          {
            <div className={!['8'].includes(answerType) ? 'hidden' : ''}>
              <Form.Item name="is_custom_prompt" label={t('agentEngine.prompt')}>
                <Radio.Group
                  onChange={(e: RadioChangeEvent) => {
                    setIsOpenPrompt(e.target.value);
                  }}
                >
                  <Radio value={false}>{t('common.default')}</Radio>
                  <Radio value={true}>{t('common.custom')}</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          }
          {/* 回答提示词 && json */}
          {
            <div
              className={
                ['1'].includes(answerType) || (['8'].includes(answerType) && isOpenPrompt)
                  ? ''
                  : 'hidden'
              }
            >
              <Form.Item
                name="llm_prompt"
                className="full-width-label"
                rules={[
                  {
                    required:
                      ['1'].includes(answerType) || (['8'].includes(answerType) && isOpenPrompt),
                    message: t('agentEngine.inputAnswerPrompt'),
                  },
                ]}
                label={
                  <div className="flex justify-between w-full">
                    <span>{t('agentEngine.answerPrompt')}</span>
                    <div>
                      <Space>
                        <span
                          className="iconfont fuzhuxinxi ml-2 cursor-pointer text-xs"
                          onClick={() => handleAuxiliary('')}
                          style={{ color: '#909399' }}
                        >
                          &nbsp;{t('common.auxiliaryInfo')}
                        </span>
                        <span
                          className="iconfont quanping ml-2 cursor-pointer text-xs"
                          style={{ color: '#909399' }}
                          onClick={() => openInputDialog('llm_prompt')}
                        >
                          &nbsp;{t('common.fullScreen')}
                        </span>
                      </Space>
                    </div>
                  </div>
                }
              >
                <TextArea rows={4} showCount maxLength={5000} />
              </Form.Item>
              <Form.Item name="json_parser" label={t('agentEngine.promptWord.answerContentProcessing')} rules={[{ required: false }]}>
                <Radio.Group onChange={parserChange}>
                  <Radio value="1">{t('agentEngine.promptWord.allContentOutput')}</Radio>
                  <Radio value="2">{t('agentEngine.promptWord.jsonParse')}</Radio>
                  <Radio value="3" className={!['8', '1'].includes(answerType) ? 'hidden' : ''}>
                    {t('agentEngine.promptWord.chainJudgment')}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              {showJudge && ['8', '1'].includes(answerType) && (
                <Form.Item noStyle name="think_chain_config">
                  <JudgmentStrategy ref={judgmentRef} data={formInfo} changeData={changeData} />
                </Form.Item>
              )}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.json_parser !== currentValues.json_parser
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('json_parser') === '2' ? (
                    <Form.Item
                      name="json_field"
                      label={<div className="flex items-center">
                        <svg className="icon text-base" aria-hidden="true">
                          <use xlinkHref="#json" />
                        </svg>
                        <span className="ml-1">{t('agentEngine.promptWord.jsonField')}</span>
                      </div>}
                      rules={[
                        { required: ['1'].includes(answerType), message: t('agentEngine.promptWord.inputJsonField') },
                      ]}
                    >
                      <Input placeholder={t('agentEngine.promptWord.inputJsonField')} />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            </div>
          }
          {['2'].includes(answerType) && (
            <div>
              <p className="mb-3 text-sm">{t('components.agentForm.standardStrategy.conversationEnterAgentAssist')}</p>
            </div>
          )}
          {/* 固定话术 */}
          {
            <div className={!['3'].includes(answerType) ? 'hidden' : ''}>
              <Form.Item
                className="full-width-label"
                name="direct_answer"
                label={
                  <div className="flex justify-between w-full">
                    <span>{t('agentEngine.scriptContent')}</span>
                    <div>
                      <Space>
                        <span
                          className="iconfont fuzhuxinxi ml-2 cursor-pointer text-xs"
                          onClick={() => handleAuxiliary('')}
                          style={{ color: '#909399' }}
                        >
                          &nbsp;{t('common.auxiliaryInfo')}
                        </span>
                        <span
                          className="iconfont quanping ml-2 cursor-pointer text-xs"
                          style={{ color: '#909399' }}
                          onClick={() => openInputDialog('direct_answer')}
                        >
                          &nbsp;{t('common.fullScreen')}
                        </span>
                      </Space>
                    </div>
                  </div>
                }
                rules={[{ required: ['3'].includes(answerType), message: t('agentEngine.inputScriptContent') }]}
              >
                <TextArea rows={3} showCount maxLength={5000} placeholder={t('agentEngine.inputScriptContent')} />
              </Form.Item>
            </div>
          }
          {/* 润色回复 */}
          {
            <div className={!['4'].includes(answerType) ? 'hidden' : ''}>
              <Form.Item
                name="polish_prompt"
                className="full-width-label"
                rules={[{ required: ['4'].includes(answerType), message: t('agentEngine.inputPolishContent') }]}
                label={
                  <div className="flex justify-between w-full">
                    <span>{t('agentEngine.polishContent')}</span>
                    <div>
                      <Space>
                        <span
                          className="iconfont fuzhuxinxi ml-2 cursor-pointer text-xs"
                          onClick={() => handleAuxiliary('')}
                          style={{ color: '#909399' }}
                        >
                          &nbsp;{t('common.auxiliaryInfo')}
                        </span>
                        <span
                          className="iconfont quanping ml-2 cursor-pointer text-xs"
                          style={{ color: '#909399' }}
                          onClick={() => openInputDialog('polish_prompt')}
                        >
                          &nbsp;{t('common.fullScreen')}
                        </span>
                      </Space>
                    </div>
                  </div>
                }
              >
                <TextArea rows={4} showCount maxLength={5000} />
              </Form.Item>
            </div>
          }
          {/* 优先技技能组 */}
          {
            <div className={!['5'].includes(answerType) ? 'hidden' : ''}>
              <Form.Item name="skill_num" label={t('flowForm.prioritySkillGroup')}>
                <Input placeholder={t('flowForm.inputPrioritySkillGroup')} maxLength={50} />
              </Form.Item>
              <Form.Item name="turn_human_msg" label={t('flowForm.replyUser')}>
                <TextArea
                  rows={3}
                  maxLength={5000}
                  placeholder={t('flowForm.inputReplyToUser')}
                />
              </Form.Item>
            </div>
          }
          {/* 按键场景 */}
          {
            <div className={!['6'].includes(answerType) ? 'hidden' : ''}>
              <Form.Item name="keyboard_scene" label={t('flowForm.keyboardScene')}>
                <Input
                  placeholder={t('flowForm.inputKeyboardScene')}
                  maxLength={50}
                />
              </Form.Item>
            </div>
          }
          {/* 挂机服务 */}
          {['7'].includes(answerType) && (
            <div>
              <p className="mb-3 text-sm">{t('flowForm.agentEndConversation')}</p>
            </div>
          )}
          {/* 附件 */}
          {
            <div className={!['1', '3', '4'].includes(answerType) ? 'hidden' : ''}>
              <Form.Item
                name="answer_res_list"
                className="custom-form-item"
                label={
                  <>
                    <span>{t('flowForm.attachment')}</span>
                    <span className="right-tip">{t('flowForm.attachmentTip')}</span>
                  </>
                }
              >
                <div className="oss-upload">
                  <OssUpload
                    // eslint-disable-next-line react/prop-types
                    propList={formState?.answer_res_list || []}
                    updateUploadList={data => {
                      form.setFieldValue('answer_res_list', data);
                    }}
                  />
                </div>
              </Form.Item>
            </div>
          }
          {/* 网络搜索 */}
          {
            <div className={!['11'].includes(answerType) ? 'hidden' : ''}>
              <Form.Item
                className="full-width-label"
                name="net_search_prompt"
                label={
                  <div className="flex justify-between w-full">
                    <span>{t('agentEngine.searchPrompt')}</span>
                    <div>
                      <Space>
                        <span
                          className="iconfont fuzhuxinxi ml-2 cursor-pointer text-xs"
                          onClick={() => handleAuxiliary('')}
                          style={{ color: '#909399' }}
                        >
                          &nbsp;{t('common.auxiliaryInfo')}
                        </span>
                        <span
                          className="iconfont quanping ml-2 cursor-pointer text-xs"
                          style={{ color: '#909399' }}
                          onClick={() => openInputDialog('net_search_prompt')}
                        >
                          &nbsp;{t('common.fullScreen')}
                        </span>
                      </Space>
                    </div>
                  </div>
                }
                rules={[{ required: ['11'].includes(answerType), message: t('agentEngine.inputSearchPrompt') }]}
              >
                <TextArea rows={3} showCount maxLength={10000} placeholder={t('agentEngine.inputSearchPrompt')} />
              </Form.Item>
            </div>
          }
          {/* ask Human */}
          <div className={!['12'].includes(answerType) ? 'hidden' : ''}>
            {/* 技能组类型 */}
            <Form.Item
              name={['ask_human_conf', 'group_type']}
              label={t('agentEngine.groupType')}
            >
              <Radio.Group>
                <Radio value={'agent'}>{t('agentEngine.groupType1')}</Radio>
                <Radio value={'ykf'}>{t('agentEngine.groupType2')}</Radio>
              </Radio.Group>
            </Form.Item>
              {/* agent技能组 */}
              <div className={askHumanGroupType !== 'agent' ? 'hidden' : ''}>
                <Form.Item
                  name={['ask_human_conf', 'queues']}
                  label={t('components.agentForm.standardStrategyForAgent.selectSkillGroup')}
                  rules={[{ required: ['12'].includes(answerType) && askHumanGroupType === 'agent', message: t('components.agentForm.standardStrategyForAgent.selectSkillGroup') }]}
                >
                  <Select placeholder={t('common.select')} mode="multiple">
                    {groupList.map(i => {
                      return (
                        <Option value={i.queue_id} key={i.queue_id}>
                          {i.queue_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            {/* 云客服技能组 */}
            <div className={askHumanGroupType === 'agent' ? 'hidden' : ''}>
              <Form.Item
                name={['ask_human_conf','queue_data']}
                label={
                  <div className="flex justify-between w-full">
                    <span className="mr-1">{t('components.agentForm.standardStrategyForAgent.selectSkillGroup')}</span>
                    {/* <Tooltip title="多个技能组编号请用英文,隔开">
                      <ExclamationCircleOutlined />
                    </Tooltip> */}
                  </div>
                }
                rules={[{ required: false, message: t('components.agentForm.standardStrategyForAgent.inputSkillGroup') }]}
              >
                <Input
                  maxLength={5000}
                  placeholder={t('components.agentForm.standardStrategyForAgent.inputSkillGroup')}
                />
              </Form.Item>
            </div>
            <Form.Item
              name={['ask_human_conf', 'busy_answer']}
              label={
                <div className="flex justify-between w-full">
                  <span className="mr-1">{ askHumanGroupType === 'ykf' ? t('components.agentForm.standardStrategyForAgent.askHumanTip2') : t('components.agentForm.standardStrategyForAgent.askHumanTip1')}</span>
                  <Tooltip title={t('components.agentForm.standardStrategyForAgent.askHumanTip3')}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </div>
              }
              rules={[{ required: ['12'].includes(answerType), message: t('components.agentForm.standardStrategyForAgent.inputBusyAnswer') }]}
            >
              <TextArea
                rows={3}
                maxLength={5000}
                placeholder={t('components.agentForm.standardStrategyForAgent.askHumanTip4')}
              />
            </Form.Item>
            <div className={askHumanGroupType !== 'agent' ? 'hidden' : ''}>
              <Form.Item name={['ask_human_conf', 'remark']} label={t('common.remark')}>
                <TextArea rows={3} maxLength={5000} placeholder={t('common.input')} />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      {auxiliary && <Panel onClosePanel={() => setAuxiliary(false)} />}
      <Modal
        open={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        footer={null}
        width="100%"
        className="full-screen-modal"
        style={{ top: 0 }}
      >
        <div className="modal-header mb-3">
          <span>{filtersTitle(bigType)}</span>
          <span
            className="iconfont fuzhuxinxi auxiliary-icon"
            onClick={() => handleAuxiliary('big')}
          >
            {t('common.auxiliaryInfo')}
          </span>
        </div>
        <Input.TextArea
          value={bigTypeText}
          onChange={e => {
            form.setFieldValue(bigType, e.target.value);
            setBigTypeText(e.target.value);
          }}
          rows={22}
          maxLength={bigType === 'net_search_prompt' ? 10000 : 5000}
          showCount
          placeholder={`${t('common.inputPrompt', { title: filtersTitle(bigType) })}`}
        />
        {bigAuxiliary && (
          <Panel className="big-content-panel" onClosePanel={() => setBigAuxiliary(false)} />
        )}
        <div className="modal-footer text-right mt-6">
          <Button type="primary" onClick={() => setDialogVisible(false)}>
            {t('common.close')}
          </Button>
        </div>
      </Modal>
    </>
  );
});

export default StandardStrategy;
