import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';

interface KnowledgeForm {
  id?: string;
  title: string;
  description: string;
}

interface ChildProps {
  onSave: (data: KnowledgeForm) => void;
}
// 定义暴露给父组件的方法类型
interface ChildMethods {
  openDialog: (value: any) => void;
}
// 使用`React.forwardRef`来获取`ref`传递
const AddKnowledgeModal = forwardRef<ChildMethods, ChildProps>((props, ref) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentData, setCurrentData] = useState({ id: '', title: '', description: '' });
  const [form] = Form.useForm<KnowledgeForm>();
  const openDialog = (data?: any) => {
    setIsVisible(true);
    if (data) {
      setIsEdit(true);
      form.setFieldsValue(data);
      setCurrentData(data);
    } else {
      setIsEdit(false);
      form.resetFields();
      setCurrentData({ id: '', title: '', description: '' });
    }
  };
  // 通过`useImperativeHandle`暴露特定的方法给父组件
  useImperativeHandle(ref, () => ({
    // 将`openDialog`方法暴露给父组件
    openDialog,
  }));

  const handleSave = () => {
    form
      .validateFields()
      .then(values => {
        props.onSave({
          title: values.title,
          description: values.description || '',
          id: currentData.id,
        });
        setIsVisible(false);
        form.resetFields(); // 清空表单项
      })
      .catch(info => {
        console.error('Validate Failed:', info);
      });
  };

  const validateTitle = async (_: any, value: string) => {
    if (!value) {
      return Promise.resolve();
    }
    const reg = /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/;
    if (reg.test(value)) {
      if (value.charAt(0) === '_') {
        return Promise.reject(new Error(t('knowledge.error_underline')));
      }
      return Promise.resolve();
    }
    return Promise.reject(new Error(t('knowledge.error_underline2')));
  };

  return (
    <Modal
      title={isEdit ? t('knowledge.edit') : t('knowledge.create')}
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      footer={[
        <Button key="back" onClick={() => setIsVisible(false)}>
          {t('common.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={handleSave}>
          {t('common.save')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSave}
      >
        <Form.Item
          name="title"
          label={t('knowledge.knowledgeName')}
          labelCol={{ style: { marginBottom: 0 } }}
          wrapperCol={{ style: { marginTop: 0 } }}
          rules={[{ required: true, message: t('knowledge.konwledgePlaceholder') }, { validator: validateTitle }]}
        >
          <Input placeholder={t('common.input')} showCount maxLength={50} />
        </Form.Item>
        <Form.Item
          labelCol={{ style: { marginBottom: 0 } }}
          wrapperCol={{ style: { marginTop: 0 } }}
          name="description"
          label={t('common.desc')}
          rules={[{ required: false }]}
        >
          <Input.TextArea rows={3} showCount maxLength={256} placeholder="t('agent.remarkPlaceholder')" />
        </Form.Item>
      </Form>
    </Modal>
  );
});
AddKnowledgeModal.displayName = 'AddKnowledgeModal';
export default AddKnowledgeModal;
