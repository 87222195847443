import React, { useEffect, useState, useRef } from 'react';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { queryQueue } from '@/api/account';
import { queryAgentConfig, saveAskHumanConfig } from '@/api/agent';
import { Form, Input, Select, Switch, Button, message, Radio, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useAppSelector } from '@/store/hooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface AskForm {
  ask_human_rag_flag: boolean;
  ask_human_rag_remark: string;
  ask_human_non_text_flag: boolean;
  ask_human_non_text_remark: string;
  ask_human_queues: string[];
  ask_human_floor_msg: string;
  group_type: string,
  queue_data: string,
}

const AskHumanSet: React.FC = () => {
  const { t } = useTranslation();
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const [askForm, setAskForm] = useState<AskForm>({
    ask_human_rag_flag: false,
    ask_human_rag_remark: '',
    ask_human_non_text_flag: false,
    ask_human_non_text_remark: '',
    ask_human_queues: [],
    ask_human_floor_msg: '',
    group_type: '',
    queue_data: '',
  });
  const [groupList, setGroupList] = useState([]);
  const [form] = Form.useForm();

  const validateQueue = (rule: any, value: any) => {
    if (!value || value.length === 0) {
      return Promise.reject(new Error(t('businessSetup.askHuman.selectGroup')));
    } else {
      return Promise.resolve();
    }
  };

  const rules = {
    ask_human_queues: [{ required: true, validator: validateQueue }],
    ask_human_floor_msg: [{ required: true, message: t('components.agentForm.standardStrategyForAgent.inputBusyAnswer') }],
  };

  const fetchGroupList = async () => {
    const filters = { filters: [] } as any;
    const newFilters = JSON.stringify(filters);
    const str = `?q=${newFilters}&page=1&results_per_page=1000`;
    const res: any = await queryQueue(str);
    setGroupList(res.objects);
  };

  const saveConfig = async () => {
    try {
      const values = await form.validateFields();
      await saveAskHumanConfig({
        agent_id: currentAgent.agent_id,
        ...askForm,
      }).then((res: any) => {
        if (res.code === 200) {
          message.success(t('common.saveSuccess'));
        } else {
          message.error(res.message ? res.message : t('common.queryFailed'));
        }
      });
    } catch (error) {
      console.log('Validation failed:', error);
    }
  };

  const initData = async () => {
    if (!currentAgent.agent_id) return;
    const {
      code,
      data,
      message: msg,
    } = await queryAgentConfig({
      agent_id: currentAgent.agent_id,
    });
    if (code === 200) {
      const params = {
        ask_human_rag_flag: data.ask_human_rag_flag || false,
        ask_human_rag_remark: data.ask_human_rag_remark || t('businessSetup.askHuman.cannotFindAnswer'),
        ask_human_non_text_flag: data.ask_human_non_text_flag || false,
        ask_human_non_text_remark: data.ask_human_non_text_remark || t('businessSetup.askHuman.visitorSendNonTextMessage'),
        ask_human_queues: data.ask_human_queues || [],
        ask_human_floor_msg:
          data.ask_human_floor_msg || t('common.floorReply'),
          group_type: data.group_type || 'agent',
          queue_data: data.queue_data || '',
      };
      setAskForm(params);
      form.setFieldsValue(params);
    } else {
      message.error(msg ? msg : t('common.queryFailed'));
    }
  };

  useEffect(() => {
    fetchGroupList();
    initData();
  }, []);

  return (
    <div className="ask-human-config h-full overflow-y-scroll">
      <Form
        layout="vertical"
        form={form}
        initialValues={askForm}
        onValuesChange={(changedValues, allValues) => {
          setAskForm({
            ...askForm,
            ...allValues
          })}
        }
      >
        <div className="askHuman-setup-cont">
          <p className="title p-4 flex items-center justify-between">
            <span>{t('common.askHumanScene')}</span>
            <Button type="primary" className="save-btn" onClick={saveConfig}>
              {t('common.save')}
            </Button>
          </p>
          <div>
            <div className="flex items-center content-title">
              <span />
              {t('businessSetup.askHuman.ragNoAnswer')}
              <Switch
                size="small"
                checked={askForm.ask_human_rag_flag}
                onChange={checked => setAskForm({ ...askForm, ask_human_rag_flag: checked })}
              />
            </div>
            {askForm.ask_human_rag_flag && (
              <Form.Item label={t('businessSetup.askHuman.transferHumanTip')}>
                <Input
                  value={askForm.ask_human_rag_remark}
                  placeholder={t('businessSetup.askHuman.cannotFindAnswer')}
                  onChange={e =>
                    setAskForm({
                      ...askForm,
                      ask_human_rag_remark: e.target.value,
                    })
                  }
                />
              </Form.Item>
            )}
            <div className="flex items-center content-title">
              <span />
              {t('businessSetup.askHuman.nonTextMessage')}
              <Switch
                size="small"
                checked={askForm.ask_human_non_text_flag}
                onChange={checked => setAskForm({ ...askForm, ask_human_non_text_flag: checked })}
              />
            </div>
            {askForm.ask_human_non_text_flag && (
              <Form.Item label={t('businessSetup.askHuman.transferHumanTip')}>
                <Input
                  value={askForm.ask_human_non_text_remark}
                  placeholder={t('businessSetup.askHuman.visitorSendNonTextMessage')}
                  onChange={e =>
                    setAskForm({
                      ...askForm,
                      ask_human_non_text_remark: e.target.value,
                    })
                  }
                />
              </Form.Item>
            )}
            <div className="content-title">
              <span />
              {t('businessSetup.askHuman.transferToSkillGroup')}
            </div>
            <Form.Item name="group_type" label={t('businessSetup.askHuman.skillGroupType')}>
              <Radio.Group value={askForm.group_type}>
                <Radio value={'agent'}>{t('components.agentForm.standardStrategyForAgent.agentSkillGroup')}</Radio>
                <Radio value={'ykf'}>{t('components.agentForm.standardStrategyForAgent.ykfSkillGroup')}</Radio>
              </Radio.Group>
            </Form.Item>
            {/* agent技能组 */}
            {askForm.group_type === 'agent' && (
              <Form.Item label={t('components.agentForm.standardStrategyForAgent.selectSkillGroup')} name="ask_human_queues" rules={[{ required: askForm.group_type === 'agent', message: t('businessSetup.askHuman.inputBusyReply') }]}>
                <Select
                  mode="multiple"
                  placeholder={t('common.select')}
                  style={{ width: '100%' }}
                  value={askForm.ask_human_queues}
                  onChange={value => setAskForm({ ...askForm, ask_human_queues: value })}
                >
                  {groupList.map((item: any) => (
                    <Option key={item.queue_id} value={item.queue_id}>
                      {item.queue_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          {/* 云客服技能组 */}
          {
           askForm.group_type === 'ykf' && (
              <>
                <Form.Item
                  name="queue_data"
                  label={
                    <div className="flex justify-between w-full">
                      <span className="mr-1">{t('components.agentForm.standardStrategyForAgent.inputSkillGroup')}</span>
                      {/* <Tooltip title="多个技能组编号请用英文,隔开">
                        <ExclamationCircleOutlined />
                      </Tooltip> */}
                    </div>
                  }
                  rules={[{ required: false, message: t('components.agentForm.standardStrategyForAgent.inputSkillGroup') }]}
                >
                  <Input
                    maxLength={5000}
                    placeholder={t('components.agentForm.standardStrategyForAgent.inputSkillGroup')}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              label={askForm.group_type === 'agent' ? t('components.agentForm.standardStrategyForAgent.askHumanTip1') : t('components.agentForm.standardStrategyForAgent.askHumanTip2')}
              name="ask_human_floor_msg"
              rules={rules.ask_human_floor_msg}
            >
              <Input.TextArea
                value={askForm.ask_human_floor_msg}
                rows={4}
                placeholder={t('components.agentForm.standardStrategyForAgent.askHumanTip4')}
                onChange={e =>
                  setAskForm({
                    ...askForm,
                    ask_human_floor_msg: e.target.value,
                  })
                }
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AskHumanSet;
