// src/views/agentService/agentManager/tableManager/index.tsx
import React, { useEffect, useState, useRef } from 'react';
import { message as antMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { refreshBaserowToken } from '@/api/agent';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import './table.scss';

const TableManager: React.FC = () => {
  const { t } = useTranslation();
  const [iframeUrl, setIframeUrl] = useState('');
  const baserowRef = useRef<HTMLIFrameElement>(null);
  const currentAgent = useAppSelector(selectCurrentAgent);
  const agent_id = currentAgent.currentAgent.agent_id;
  // 封装fetchToken函数
  const fetchToken = () => {
    refreshBaserowToken({ agent_id: agent_id }).then(res => {
      if (res.code === 200) {
        const resObj = res.data || {};
        baserowRef.current?.contentWindow?.postMessage(
          JSON.stringify({ ...resObj, ...{ agent_id } }),
          'https://baserow-dev.7moor.com/login'
        );
      } else {
        antMessage.error(res.message || t('knowledge.getListFailed'));
      }
    });
  };
  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (e.data === 'baserowLoad') {
        fetchToken();
      }
    };

    window.addEventListener('message', handleMessage);

    setIframeUrl(`https://baserow-dev.7moor.com/login?agent_id=${agent_id}`);

    // 组件卸载时执行的操作
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className="table-manager">
      <iframe width="100%" ref={baserowRef} height="100%" src={iframeUrl} />
    </div>
  );
};

export default TableManager;
