import React, { useState } from 'react';
import { Tabs } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import BaseRowList from '@/components/Panel/BaseRowList';
import VarList from '@/components/Panel/VarList';
import './scss/index.scss';

const { TabPane } = Tabs;

interface PanelProps {
  onClosePanel: () => void;
  className?: string;
}

const Panel: React.FC<PanelProps> = ({ onClosePanel, className }) => {
  const [activeKey, setActiveKey] = useState<string>('var');

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const closePanel = () => {
    onClosePanel();
  };

  return (
    <div className={`${className ? className : ''} panel-wrap px-5`}>
      <Tabs activeKey={activeKey} onChange={handleTabChange}>
        <TabPane tab="变量" key="var" />
        <TabPane tab="表格数据库" key="baserow" />
      </Tabs>
      <span className="iconfont guanbiquan close-icon" onClick={closePanel}></span>
      {activeKey === 'baserow' && <BaseRowList />}
      {activeKey === 'var' && <VarList />}
    </div>
  );
};

export default Panel;
