import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Form, Input, Button, Modal, message } from 'antd';
import 'bytemd/dist/index.css';
import gfm from '@bytemd/plugin-gfm';
import { Editor } from '@bytemd/react';
import mediumZoom from '@bytemd/plugin-medium-zoom';
import axios from 'axios';
import { getOssToken } from '@/api/agent';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { useAppSelector } from '@/store/hooks';
import { useTranslation } from 'react-i18next'; // 导入useTranslation钩子

interface ChildProps {
  style?: React.CSSProperties;
  onGoBack: () => void;
  onSave: (data: any) => void;
}
// 定义暴露给父组件的方法类型
interface ChildMethods {
  openDialog: (value: any) => void;
}
interface Image {
  title: string;
  url: string;
  alt: string;
}
// 使用`React.forwardRef`来获取`ref`传递
const OnlineDoc = forwardRef<ChildMethods, ChildProps>((props, ref) => {
  const { t } = useTranslation(); // 使用useTranslation钩子
  const [isVisible, setIsVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [value, setValue] = useState('');
  const [form] = Form.useForm();
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const plugins = [
    gfm(),
    mediumZoom(), // 图片放大预览
  ];
  // 打开弹窗
  const openDialog = (data: any) => {
    if (data) {
      form.setFieldsValue({
        name: data.file_name.split('.')[0],
        overlap: data.overlap,
        chunk_size: data.chunk_size,
      });
      getTextByMd(data.file_download_url);
    } else {
      form.setFieldsValue({
        name: t('knowledge.untitledDocument'),
        overlap: 50,
        chunk_size: 256,
      });
      setValue('');
    }
  };
  const getTextByMd = (markdownUrl: string) => {
    // 使用fetch获取Markdown文件内容
    fetch(markdownUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(markdown => {
        // 在这里我们就得到了Markdown文件的内容字符串
        setValue(markdown);
      })
      .catch(error => {
        console.error('Error fetching the markdown file:', error);
      });
  };
  const uploadImages = async (files: File[]): Promise<Pick<Image, 'url' | 'alt'>[]> => {
    try {
      const res: any = await getOssToken({
        agent_id: currentAgent.agent_id,
      });
      const file = files[0];
      const formData = new FormData();
      const name = file.name || '';
      const key = `${res.dir}${name}`;
      formData.append('ossaccessKeyId', res.accessid);
      formData.append('host', res.host);
      formData.append('policy', res.policy);
      formData.append('key', key);
      formData.append('signature', res.signature);
      formData.append('file', file);
      await axios.post(res.host, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': `attachment; filename*=UTF-8''${encodeURIComponent(name)}`,
        },
      });
      console.log(files, 'files=====');
      const uploadedImages = files.map(file => ({
        // title: file.name,
        // url: `${res.host}/${key}?x-oss-process=image/resize,w_100,h_100`,
        url: `${res.host}/${key}`,
        alt: file.name,
      }));
      return uploadedImages;
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error(t('common.uploadFailed'));
      throw error; // rethrow the error to handle it in the caller if needed
    }
  };
  const goBack = () => {
    setIsVisible(false);
    props.onGoBack();
  };
  const handleSave = async () => {
    const values = await form.validateFields();
    if (!values) {
      return;
    }
    if (values.chunk_size / 2 < values.overlap) {
      message.error(t('knowledge.overlapExceedsHalfChunkSize'));
      return;
    }
    props.onSave({
      name: values.name,
      chunk_size: values.chunk_size,
      overlap: values.overlap,
      value,
    });
    setModalVisible(false);
  };
  const openModal = () => {
    if (!value) {
      message.error(t('knowledge.emptyDocumentContent'));
      return;
    }
    setModalVisible(true);
  };
  // 通过`useImperativeHandle`暴露特定的方法给父组件
  useImperativeHandle(ref, () => ({
    // 将`openDialog`方法暴露给父组件
    openDialog,
  }));

  return (
    <div style={props.style} className="online-doc-box">
      <div className="online-doc-top">
        <span
          className="iconfont fangxiang-zuo-xian"
          style={{ fontSize: '22px' }}
          onClick={goBack}
        ></span>
        <div
          className="online-doc-title"
          style={{ display: isVisible ? 'block' : 'none' }}
          onClick={e => {
            e.stopPropagation();
            setIsVisible(false);
          }}
        >
          {form.getFieldsValue().name}
        </div>
        <div style={{ display: isVisible ? 'none' : 'block' }}>
          <Form form={form} layout="vertical" initialValues={{ name: t('knowledge.untitledDocument') }}>
            <Form.Item name="name" rules={[{ required: true, message: t('knowledge.enterTitle') }]}>
              <Input
                style={{ width: 200 }}
                maxLength={50}
                placeholder={t('knowledge.enterTitle')}
                onBlur={() => setIsVisible(true)}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="online-btn-wrap">
          <Button type="primary" onClick={() => openModal()}>
            {t('common.save')}
          </Button>
        </div>
      </div>
      <Editor
        value={value}
        plugins={plugins}
        uploadImages={uploadImages}
        onChange={v => {
          setValue(v);
        }}
      />
      <Modal
        title={t('knowledge.sliceSettings')}
        open={modalVisible}
        width={600}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleSave}>
            {t('common.save')}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" initialValues={{ chunk_size: 256, overlap: 50 }}>
          <Form.Item
            name="chunk_size"
            className="custom-form-item"
            label={
              <>
                <span>{t('knowledge.chunkSizeLabel')}</span>
                <span className="right-tip">
                  {t('knowledge.chunkSizeTip')}
                </span>
              </>
            }
            rules={[
              { required: true, message: t('knowledge.chunkSizePlaceholder') },
              {
                pattern: /^(12[89]|1[3-9][0-9]|[23][0-9]{2}|4[0-9]{2}|50[0-9]|51[0-2])$/,
                message: t('knowledge.chunkSizeError'),
              },
            ]}
          >
            <Input placeholder={t('knowledge.chunkSizePlaceholder')} />
          </Form.Item>
          <Form.Item
            name="overlap"
            className="custom-form-item"
            label={
              <>
                <span>{t('knowledge.overlapLabel')}</span>
                <span className="right-tip">
                  {t('knowledge.overlapTip')}
                </span>
              </>
            }
            rules={[{ required: true, message: t('knowledge.overlapPlaceholder') }]}
          >
            <Input placeholder={t('knowledge.overlapPlaceholder')} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
OnlineDoc.displayName = 'OnlineDoc';
export default OnlineDoc;
