import React, { useState, useEffect } from 'react';
import { Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getOssToken } from '@/api/agent';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { useAppSelector } from '@/store/hooks';
import { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';

import './index.scss';

interface Props {
  avatar: UploadFile | undefined;
  updateUploadList: (list: UploadFile[]) => void;
}

const UploadComponent: React.FC<Props> = ({ avatar, updateUploadList }) => {
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const [uploadList, setUploadList] = useState<UploadFile[]>(avatar ? [avatar] : []);

  useEffect(() => {
    setUploadList(avatar ? [avatar] : []);
  }, [avatar]);

  const beforeUpload = (file: File) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('只能上传图片文件!');
      return Upload.LIST_IGNORE;
    }
    const isLimitSize = file.size / 1024 / 1024 > 5;
    if (isLimitSize) {
      message.error('图片大小不能超过5MB!');
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const customUpload = async ({ file, onSuccess, onError }: any) => {
    try {
      const res: any = await getOssToken({
        agent_id: currentAgent.agent_id,
      });
      const formData = new FormData();
      const name = file.name || '';
      const key = `${res.dir}${name}`;
      formData.append('ossaccessKeyId', res.accessid);
      formData.append('host', res.host);
      formData.append('policy', res.policy);
      formData.append('key', key);
      formData.append('signature', res.signature);
      formData.append('file', file);

      await axios.post(res.host, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': `attachment; filename*=UTF-8''${encodeURIComponent(name)}`,
        },
      });

      message.success('上传成功');
      const newFile: UploadFile = {
        uid: file.uid,
        url: `${res.host}/${key}`,
        name,
        status: 'done' as UploadFileStatus,
      };
      setUploadList([newFile]);
      updateUploadList([newFile]);
      onSuccess();
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('上传失败');
      onError(error);
    }
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setUploadList(fileList);
    updateUploadList(fileList);
  };

  return (
    <Upload 
      listType="picture-card" 
      maxCount={1}
      customRequest={customUpload}
      beforeUpload={beforeUpload}
      fileList={uploadList}
      onChange={handleChange}
    >
      {uploadList.length === 0 && (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>上传</div>
        </div>
      )}
    </Upload>
  );
};

export default UploadComponent;
