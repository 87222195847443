import React, { useState, useEffect} from 'react';
import {Form,message,Select,Button} from 'antd';
import { useTranslation } from 'react-i18next';
import { getUserInfo, updateUserInfo} from '@/api/account';
import './style.scss';

const { Option } = Select;
const AccountUserSettingIndex: React.FC = () => {
    const [userForm] = Form.useForm();
    const [userInfo, setUserInfo] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      fetchUserInfo();
    }, []);
    

    useEffect(() => {
        if (userInfo && typeof userInfo === 'object') {
            const formattedUserInfo = {
                ...userInfo,
                language: userInfo.language === null ? '' : userInfo.language
            };
            userForm.setFieldsValue(formattedUserInfo);
        }
    }, [userInfo, userForm]);


    // 获取用户信息
    const fetchUserInfo = async () => {
      try {
        setLoading(true);
        const response = await getUserInfo();
        console.log(response.data);
        if (response.data && typeof response.data === 'object') {
            setUserInfo(response.data);
        } else {
          console.error(t('common.getListFailed'));
        }
      } catch (error) {
        console.error(t('common.getListFailed'));
      }finally{
        setLoading(false);
      }
    };
  
    // 更新用户信息
    const handleSubmitUser = async (values: any) => {
      try {
        setLoading(true);
        const response = await updateUserInfo(values);
        console.log(response.data);
        message.success(t('common.saveSuccess'));
        fetchUserInfo();
      } catch (error) {
        message.error(t('common.saveFailed'));
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        <div className="setting-title">
            <h2>{t('systemSetting.userSetting')}</h2>
        </div>
      <div className="account main-container account-group h-full overflow-y-scroll">
        <Form
          form={userForm}
          onFinish={handleSubmitUser}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 400, marginTop: 20 }}
        >
          <Form.Item name="language" label={t('systemSetting.language')} rules={[{ required: true, message: t('systemSetting.languageRequired') }]}>
            <Select>
              <Option value="">{t('common.default')}</Option>
              <Option value="zh">{t('systemSetting.chinese')}</Option>
              <Option value="en">{t('systemSetting.english')}</Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loading}style={{ width: '30%' }}>
              {t('common.save')}
            </Button>
          </Form.Item>
        </Form>
    </div>
    </>
  );
};

export default AccountUserSettingIndex;
