import { createSlice } from '@reduxjs/toolkit';
import { setToken, removeToken, sessionKey } from '@/utils/auth';
import { RefreshTokenResult, refreshTokenApi } from '@/api/user';
import { useNavigate } from 'react-router-dom';
import { RootState } from '..';
import { decode, encode } from 'js-base64';

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` 将从 `initialState` 参数推断 state 类型
  initialState: {
    token: window.sessionStorage.getItem('token') || '',
    // 用户名
    username: JSON.parse(window.sessionStorage.getItem(sessionKey) || '{}')?.username ?? '',
    // 页面级别权限
    roles: JSON.parse(window.sessionStorage.getItem(sessionKey) || '{}')?.roles ?? [],
    loginData: '',
    // 登录方式 account: 账号密码登录（默认）  sign: sign登录
    loginType: 'account'
  },
  reducers: {
    /** 存储用户名 */
    setUserName: (state, action) => {
      state.username = action.payload;
    },
    /** 存储角色 */
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setStoreToken: (state, action) => {
      state.token = action.payload;
    },
    setLoginData: (state, action) => {
      try {
        state.loginData = encode(JSON.stringify(action.payload));
      } catch (error) {
        /* empty */
      }
    },

    /**
     * @description: 设置登录方式
     * @return {*}
     */
    setLoginType: (state, action) => {
      state.loginType = action.payload;
      sessionStorage.setItem('loginType', action.payload)
    },
  },
});
/** 前端登出（不调用接口） */
export const logOut = () => {
  setUserName('');
  setRoles([]);
  removeToken();
  // useMultiTagsStoreHook().handleTags('equal', [...routerArrays]);
  // resetRouter();
  window.localStorage.clear();
  const navigate = useNavigate();
  navigate('/agentService/llmAgent', { replace: true });
  window.location.href = '/login';
};
/** 刷新`token` */
export const handRefreshToken = (data: any) => {
  return new Promise<RefreshTokenResult>((resolve, reject) => {
    refreshTokenApi(data)
      .then((data: any) => {
        if (data) {
          setToken(data.data);
          resolve(data);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const { setUserName, setRoles, setStoreToken, setLoginData, setLoginType } = userSlice.actions;
export const selectToken = (state: RootState) => state.user.token;
export const selectRoles = (state: RootState) => state.user.roles;
export const selectLoginData = (state: RootState) => state.user.loginData;
export const selectUsername = (state: RootState) => state.user.username;
export const selectLoginType = (state: RootState) => state.user.loginType;

export default userSlice.reducer;
