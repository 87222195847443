import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, message, Modal, Row, Col, Select, Switch, Radio } from 'antd';
import { cloneDeep } from 'lodash';
import { saveVoiceConfig, queryAgentConfig } from '@/api/agent';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent, selectModelEnum } from '@/store/modules/agentTemplate';
import Panel from '@/components/Panel';
import './voiceConfig.scss';

const { Option } = Select;

const VoiceConfig: React.FC = () => {
  const { t } = useTranslation();
  
  const currentAgent = useAppSelector(selectCurrentAgent);
  const modelEnum = selectModelEnum();
  const [form] = Form.useForm();
  const [bigType, setBigType] = useState<string>('');
  const [auxiliary, setAuxiliary] = useState<boolean>(false);
  const [bigAuxiliary, setBigAuxiliary] = useState<boolean>(false);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [bizMode, setBizMode] = useState<string>('0');
  const [initForm, setInitForm] = useState<any>({
    is_small_talk: false,
    tool_llm: 'gpt3.5-turbo',
    small_talk_prompt: '',
    business_spliter: '',
    business_flag: '',
    no_business_flag: '',
    biz_mode: '0',
    key_prompt: '',
    key_biz: '',
    val_has_biz: '',
    val_no_biz: '',
  });

  const rules = useMemo(() => {
    return {
      small_talk_prompt: [{ required: true, message: t('agentEngine.voiceSet.small_talk_prompt') }],
      business_spliter: [{ required: bizMode === '0', message: t('agentEngine.voiceSet.business_spliter') }],
      business_flag: [{ required: bizMode === '0', message: t('agentEngine.voiceSet.business_flag') }],
      no_business_flag: [{ required: bizMode === '0', message: t('agentEngine.voiceSet.no_business_flag') }],
      key_prompt: [{ required: bizMode === '1', message: t('agentEngine.voiceSet.key_prompt') }],
      key_biz: [{ required: bizMode === '1', message: t('agentEngine.voiceSet.key_biz') }],
      val_has_biz: [{ required: bizMode === '1', message: t('agentEngine.voiceSet.val_has_biz') }],
      val_no_biz: [{ required: bizMode === '1', message: t('agentEngine.voiceSet.val_no_biz') }],
    }
  }, [bizMode]);

  const initData = () => {
    queryAgentConfig({
      agent_id: currentAgent.currentAgent.agent_id,
    })
      .then(res => {
        if (res.code === 200 && res.data) {
          const small_talk_config = res.data.small_talk_config || {};

          setInitForm({
            is_small_talk: small_talk_config?.is_small_talk || false,
            tool_llm: small_talk_config?.tool_llm || 'gpt3.5-turbo',
            small_talk_prompt: small_talk_config?.small_talk_prompt || '',
            business_spliter: small_talk_config?.business_spliter || '',
            business_flag: small_talk_config?.business_flag || '',
            no_business_flag: small_talk_config?.no_business_flag || '',
            biz_mode: small_talk_config?.biz_mode || '0',
            key_prompt: small_talk_config?.key_prompt || '',
            key_biz: small_talk_config?.key_biz || '',
            val_has_biz: small_talk_config?.val_has_biz || '',
            val_no_biz: small_talk_config?.val_no_biz || '',
          });
        } else {
          message.error(res.message || t('common.queryFailed'));
        }
      })
      .catch(() => {
        message.error(t('common.queryFailed'));
      });
  };

  const saveConfig = () => {
    form.validateFields().then(values => {
      const info = currentAgent.currentAgent as any;
      const actionForm = cloneDeep(values);
      const agent_id = info.agent_id;
      const params = {
        agent_id,
        small_talk_config: actionForm,
      };
      saveVoiceConfig(params).then(res => {
        if (res.code === 200) {
          message.success(t('common.saveSuccess'));
        } else {
          message.error(res.message ? res.message : t('common.saveFailed'));
        }
      });
    });
  };

  const openInputDialog = (type: string) => {
    setBigType(type);
    setDialogVisible(true);
  };

  const filtersTitle = (type: string): string => {
    const typeMap: Record<string, string> = {
      small_talk_prompt: t('agentEngine.voiceSet.small_talk_prompt_2')
    };
    return typeMap[type];
  };

  const handleAuxiliary = (type?: string) => {
    if (type) {
      setBigAuxiliary(true);
      setAuxiliary(false);
    } else {
      setAuxiliary(true);
      setBigAuxiliary(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);
  useEffect(() => {
    form.setFieldsValue(initForm);
    setBizMode(initForm.biz_mode);
  }, [initForm]);
  return (
    <div className="voice-config">
      <div className="bg-white rounded">
        <Form
          form={form}
          initialValues={initForm}
          onFinish={saveConfig}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <div className="flex justify-between border-b border-solid border-gray-200">
            <div>
              <Form.Item name="is_small_talk" valuePropName="checked">
                <span className="mr-4 text-gray-900">{t('agentEngine.voiceSet.name')}</span>
                <Switch
                  value={initForm.is_small_talk}
                  onChange={checked =>
                    setInitForm({ ...form.getFieldsValue(), is_small_talk: checked })
                  }
                />
                <span className="ml-4 text-gray-800">{t('agentEngine.voiceSet.voiceSetDesc')}</span>
              </Form.Item>
            </div>
            <div>
              <Button type="primary" htmlType="submit">
                {t('common.save')}
              </Button>
            </div>
          </div>
          <div>
              <Form.Item label={t('agentEngine.voiceSet.smallTalkModel')} name="tool_llm" style={{ marginTop: 16 }}>
              <Select placeholder={t('agentEngine.voiceSet.smallTalkModelPlaceholder')}>
                {modelEnum.map((item: string, index: number) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="small_talk_prompt"
              rules={rules.small_talk_prompt}
              style={{ marginTop: 0 }}
              className="custom-label"
              label={
                <div className="label-div">
                  <span>{t('agentEngine.voiceSet.small_talk_prompt_2')}</span>
                  <span className="label-btn">
                    <span
                      className="iconfont quanping"
                      onClick={() => openInputDialog('small_talk_prompt')}
                    >
                      {t('common.fullScreen')}
                    </span>
                    <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                      {t('common.auxiliaryInfo')}
                    </span>
                  </span>
                </div>
              }
            >
              <Input.TextArea rows={5} maxLength={10000} showCount />
            </Form.Item>
          </div>
          <Form.Item label={t('agentEngine.voiceSet.bizMode')} name="biz_mode" style={{ marginTop: 16 }}>
            <Radio.Group value={bizMode} onChange={e => setBizMode(e.target.value)}>
              <Radio value="0">{t('agentEngine.voiceSet.bizMode1')}</Radio>
              <Radio value="1">{t('agentEngine.voiceSet.bizMode2')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Row gutter={20} style={{ display: bizMode === '0' ? 'flex' : 'none' }}>
            <Col span={8}>
              <Form.Item label={t('agentEngine.voiceSet.bizSpliter')} name="business_spliter" rules={rules.business_spliter}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t('agentEngine.voiceSet.bizFlag')} name="business_flag" rules={rules.business_flag}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t('agentEngine.voiceSet.noBizFlag')}
                name="no_business_flag"
                rules={rules.no_business_flag}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} style={{ display: bizMode === '1' ? 'flex' : 'none' }}>
            <Col span={6}>
              <Form.Item label={t('agentEngine.voiceSet.keyPrompt')} name="key_prompt" rules={rules.key_prompt}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t('agentEngine.voiceSet.keyBiz')} name="key_biz" rules={rules.key_biz}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t('agentEngine.voiceSet.valHasBiz')} name="val_has_biz" rules={rules.val_has_biz}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={t('agentEngine.voiceSet.valNoBiz')} name="val_no_biz" rules={rules.val_no_biz}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {auxiliary && <Panel className="voice-panel" onClosePanel={() => setAuxiliary(false)} />}
      <Modal
        open={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        footer={null}
        width="100%"
        className="full-screen-modal"
        style={{ top: 0 }}
      >
        <div className="modal-header mb-3">
          <span>{filtersTitle(bigType)}</span>
          <span
            className="iconfont fuzhuxinxi auxiliary-icon"
            onClick={() => handleAuxiliary('big')}
          >
            {t('common.auxiliaryInfo')}
          </span>
        </div>
        <Input.TextArea
          value={initForm[bigType]}
          onChange={e => setInitForm({ ...initForm, [bigType]: e.target.value })}
          rows={22}
          maxLength={5000}
          showCount
          // todo: 这里需要根据bigType来显示不同的placeholder, 可能有bug，目前不显示为空
          // placeholder={`请输入${filtersTitle(bigType)}`}
          placeholder={t('agentEngine.voiceSet.inputPrompt', { title: filtersTitle(bigType) })}
        />
        {bigAuxiliary && (
          <Panel className="big-content-panel" onClosePanel={() => setBigAuxiliary(false)} />
        )}
        <div className="modal-footer text-right mt-6">
          <Button type="primary" onClick={() => setDialogVisible(false)}>
            {t('common.close')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default VoiceConfig;
