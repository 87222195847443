import React, { useEffect, useState } from 'react';
import { InputNumber, message } from 'antd';
import Sortable, { SortableEvent } from 'sortablejs';
import { cloneDeep } from 'lodash';
import './activeGuidance.scss'; // 确保.scss文件是现有的，且样式名等符合要求
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { saveGuidance, queryGuidanceList, guidanceSort, queryAgentConfig } from '@/api/agent';
import { useTranslation } from 'react-i18next';

interface Guidance {
  uid: string;
  guidance_sort?: number;
  name: string;
  desc: string;
}

const ActiveGuidance: React.FC = () => {
  const { t } = useTranslation();
  const [guidanceInterval, setGuidanceInterval] = useState<number>(10);
  const [moveList, setMoveList] = useState<Guidance[]>([]);
  const currentAgent = useAppSelector(selectCurrentAgent);
  const agent_id = currentAgent.currentAgent.agent_id;

  useEffect(() => {
    initData();
    getGuidanceInfo();
  }, []);

  useEffect(() => {
    initListDrag();
  }, [moveList]); // 每次moveList更新后重新绑定Sortable

  const initData = () => {
    queryAgentConfig({
      agent_id: agent_id,
    })
      .then(res => {
        if (res.code == 200 && res.data) {
          setGuidanceInterval(res.data.guidance_interval || 10);
        } else {
          message.error(res.message || t('common.queryFailed'));
        }
      })
      .catch(() => {
        message.error(t('common.queryFailed'));
      });
  };
  const getGuidanceInfo = () => {
    queryGuidanceList({ agent_id: agent_id })
      .then(res => {
        if (res.code == 200) {
          setMoveList(res.data || []);
        } else {
          message.error(res.message || t('agentEngine.activeGuidance.queryFailed'));
        }
      })
      .catch(() => {
        message.error(t('agentEngine.activeGuidance.queryFailed'));
      });
  };

  const blurAction = (value: any) => {
    if (!value) return;

    saveGuidance({
      guidance_interval: value,
      agent_id: agent_id,
    })
      .then(res => {
        if (res.code !== 200) {
          message.error(res.message || t('common.saveFailed'));
        }
      })
      .catch(() => {
        message.error(t('common.saveFailed'));
      });
  };

  const initListDrag = () => {
    const container = document.querySelector('.guidace-draggable') as HTMLElement;
    if (container) {
      Sortable.create(container, {
        draggable: '.guidace-cart-item',
        onEnd: (event: SortableEvent) => {
          const { newIndex, oldIndex } = event;
          if (newIndex == null || oldIndex == null) return;

          setMoveList(prevList => {
            const newList = cloneDeep(prevList);
            const [removedItem] = newList.splice(oldIndex, 1);
            newList.splice(newIndex, 0, removedItem);
            guidanceSort({
              agent_id: agent_id,
              data: newList,
            }).then(res => {
              if (res.code == 200) {
                getGuidanceInfo();
              }
            });
            return newList;
          });
        },
      });
    }
  };

  return (
    <div className="active-guidance-config bg-white">
      <p className="tip">
        <span className="iconfont jinggao" />
        {t('agentEngine.activeGuidance.tip')}
      </p>
      <div className="bg-white rounded">
        <p className="title-p">{t('agentEngine.activeGuidance.silentGuidance')}</p>
        <div>
          {t('agentEngine.activeGuidance.silentGuidanceDesc')}
          <InputNumber
            min={5}
            max={300}
            defaultValue={10}
            value={guidanceInterval}
            onChange={(value: any) => {
              setGuidanceInterval(value);
              blurAction(value);
            }}
          />
          {t('agentEngine.activeGuidance.silentGuidanceUnit')}
        </div>
      </div>
      <div className="bg-white rounded">
        <p className="title-p">{t('agentEngine.activeGuidance.activeStages')}</p>
        <div className="cart-wrap">
          {moveList.length <= 0 ? (
            <p>{t('agentEngine.activeGuidance.noActiveStages')}</p>
          ) : (
            <div className="guidace-draggable">
              {moveList.map((item: any) => (
                <div className="guidace-cart-item" key={item.uid}>
                  <div className="iconfont tuodong-shu" />
                  <div className="cart-num">{item.guidance_sort || ''}</div>
                  <div className="cart-name">{item.name}</div>
                  <div className="cart-memo">{item.desc}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveGuidance;
