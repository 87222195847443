import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { convert_bytes } from '@/utils/common';

interface IDataItem {
  id: string;
  title: string;
  description: string;
  count: number;
  bytes: number;
  default?: number
  characters: number;
}

interface ICartsProps {
  kbList: IDataItem[];
  handleClickItem: (cart: IDataItem) => void;
  delCart: (id: string) => void;
  editCart: (cart: IDataItem) => void;
}

interface IcartsChildProps {
  handleItemClick: (cart: IDataItem) => void;
}

const Carts = forwardRef<IcartsChildProps, ICartsProps>((props, ref) => {
  const { t } = useTranslation();

  const [activeCart, setActiveCart] = useState<IDataItem | null>(null);

  const handleItemClick = (cart: IDataItem) => {
    setActiveCart(cart);
    props.handleClickItem(cart);
  };
  // 删除
  const delCartConfirm = (info: any) => {
    Modal.confirm({
      title: t('knowledge.isDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: () => {
        props.delCart(info.id);
      },
    });
  };

  useImperativeHandle(ref, () => ({
    // 将`openDialog`方法暴露给父组件
    handleItemClick,
  }));

  return (
    <div className="km-cart cart-draggable">
      {props.kbList.map(item => (
        <div key={item.id} className="cart-item" onClick={() => handleItemClick(item)}>
          <div className={`cart ${activeCart?.id === item.id ? 'active-border' : ''}`}>
            <div className="top">
              <div className="title" title={item.title}>
                <span className="iconfont zhishiku-mian" />
                {item.title}
              </div>
              <div className="cart-actions">
                <span
                  className="iconfont bianji editBtn"
                  onClick={e => {
                    e.stopPropagation();
                    props.editCart(item);
                  }}
                ></span>
                {!item.default && (<span
                  className="iconfont shanchu delIcon"
                  onClick={e => {
                    e.stopPropagation();
                    delCartConfirm(item);
                  }}
                ></span>)}
              </div>
            </div>
            <div className="middle" title={item.description}>
              {item.description}
            </div>
            <div className="bottom">
              <div className="flex-div">
                <span className="bottom-blod">{item.count}</span>
                <p>{t('knowledge.fileCount')}</p>
              </div>
              <div className="flex-div">
                <span className="bottom-blod">{convert_bytes(item.bytes)}</span>
                <p>{t('knowledge.fileSize')}</p>
              </div>
              <div className="flex-div">
                <span className="bottom-blod">{item.characters}</span>
                <p>{t('knowledge.totalCharacters')}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
});
Carts.displayName = 'Carts';
export default Carts;
