import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { Locale } from 'antd/es/locale';

import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
/* import jaJP from 'antd/locale/ja_JP';
import koKR from 'antd/locale/ko_KR'; */
import i18n from '@/contexts/locales/i18n';

// 导入其他需要的语言包

interface LanguageOption {
  key: string;
  label: string;
  locale: Locale;
}

const languageOptions: LanguageOption[] = [
  { key: 'zh', label: '中文', locale: zhCN },
  { key: 'en', label: 'English', locale: enUS },
/*   { key: 'ja', label: '日本語', locale: jaJP },
  { key: 'ko', label: '한국어', locale: koKR } */
  // 添加其他语言选项
];

interface LanguageContextType {
  currentLanguage: LanguageOption;
  setLanguage: (key: string) => void;
  languageOptions: LanguageOption[];
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<LanguageOption>(() => {
    const savedLanguage = localStorage.getItem('userLanguage');
    const defaultLanguage = languageOptions.find(option => option.key === savedLanguage);
    return defaultLanguage || languageOptions[0];
  });

  const setLanguage = (key: string) => {
    const language = languageOptions.find(option => option.key === key);
    if (language) {
      setCurrentLanguage(language);
      localStorage.setItem('userLanguage', key);
      i18n.changeLanguage(key); // 同步 i18next 的语言
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('userLanguage');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ currentLanguage, setLanguage, languageOptions }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};