import { t } from 'i18next';

// 辅助函数：确保返回的是字符串数组
function ensureStringArray(value: unknown): string[] {
  if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
    return value;
  }
  return [String(value)];
}

// 角色分类prompt默认文本
export const DEFAULT_TOOL_PROMPT = ensureStringArray(t('agent.defaultToolPrompt', { returnObjects: true })).join('\n');

// 标准润色回复prompt 默认文本
export const DEFAULT_POLISH_PROMPT = ensureStringArray(t('agent.defaultPolishPrompt', { returnObjects: true })).join('\n');


// 知识库回复prompt
export const DEFAULT_PERSONALITY = ensureStringArray(t('agent.defaultPersonality', { returnObjects: true })).join('\n');

export const LLM_TASK_PROMPT = ensureStringArray(t('agent.llmTaskPrompt', { returnObjects: true })).join('\n');

export const DEFAULT_ASK_HUMAN_CONFIG = {
  queues: [],
  remark: '',
  busy_answer: t('agent.defaultAskHumanConfig.busyAnswer'),
  group_type: 'ykf',
  queue_data: ''
}
