import React, { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import { CopyOutlined, DatabaseOutlined, TableOutlined } from '@ant-design/icons';
import { getBaserowInfo } from '@/api/agent';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { useAppSelector } from '@/store/hooks';
import './scss/BaseRowList.scss';

interface BaserowItem {
  rest_token?: string;
  name: string;
  id: string;
  tables: { name: string; id: string }[];
}

const BaseRowList: React.FC = () => {
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const [baserowList, setBaserowList] = useState<BaserowItem[]>([]);

  const queryBaserowInfo = async () => {
    try {
      const res = await getBaserowInfo({ agent_id: currentAgent.agent_id });
      if (res.code === 200) {
        setBaserowList(res.data || []);
      } else {
        message.error(res.message || '获取表格数据库失败');
      }
    } catch {
      message.error('获取表格数据库失败');
    }
  };

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('该内容已复制到剪切板');
    });
  };

  useEffect(() => {
    queryBaserowInfo();
  }, []);

  return (
    <div className="baserow-wrap">
      {baserowList.map((item, index) => (
        <div key={index}>
          {item.rest_token && (
            <div className="token-wrap">
              <p>Database token:</p>
              <Input
                value={item.rest_token}
                readOnly
                addonAfter={
                  // @ts-expect-error known
                  <span className="iconfont fuzhi" onClick={() => copyText(item.rest_token)}>
                    <CopyOutlined />
                  </span>
                }
              />
            </div>
          )}
          <div className="database-wrap">
            <DatabaseOutlined className="iconfont shujuku" />
            <span className="database-name">{item.name}</span>
            <span>数据库ID：{item.id}</span>
            <CopyOutlined className="iconfont fuzhi" onClick={() => copyText(item.id)} />
          </div>
          {item.tables.map((tableItem, tableIndex) => (
            <div className="table-wrap" key={tableIndex}>
              <TableOutlined className="iconfont biaoge" />
              <span className="table-name">{tableItem.name}</span>
              <span>表格ID：{tableItem.id}</span>
              <CopyOutlined className="iconfont fuzhi" onClick={() => copyText(tableItem.id)} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default BaseRowList;
