import React, { FC, useEffect, useState } from 'react';
import {
  getLogDetail,
  addBlack,
  querySessionDetailAround,
  queryWorkbenchDetailAround,
} from '@/api/agent';
import { Drawer, Modal, Button, Input, message } from 'antd';
import MessageList from '@/pages/agentService/agentManager/agentEngine/parts/roleParts/TestChat/MessageList';
import DebugLog from '@/pages/agentService/agentManager/agentEngine/parts/roleParts/TestChat/DebugLog';
import './scss/SessionDetail.scss';
import '@/pages/agentService/agentManager/agentEngine/parts/roleParts/TestChat/scss/index.scss';
import CheckDoc from '@/components/CheckDoc/index';
import { useTranslation } from 'react-i18next';

interface SessionDetailPropsType {
  dialog: boolean;
  setDialog: (val: boolean) => void;
  setSelectedRowId: (val: any) => void;
  agent: any;
  query: any;
  moduleFlag: string;
}

const SessionDetail: FC<SessionDetailPropsType> = ({
  agent,
  dialog,
  setDialog,
  setSelectedRowId,
  query,
  moduleFlag,
}) => {
  const [currentAgent, setCurrentAgent] = useState<any>({});
  const [size, setSize] = useState(600);
  const [messages, setMessages] = useState<any[]>([]);
  const [imageList, setImageList] = useState<string[]>([]);
  const [dialogTableVisible, setDialogTableVisible] = useState<boolean>(false);
  const [debugInfo, setDebugInfo] = useState<any[]>([]);
  const [docDetail, setDocDetail] = useState<any>({});
  const [checkDocVisible, setCheckDocVisible] = useState<boolean>(false);
  const [openBlack, setOpenBlack] = useState(false);
  const [blackInfo, setBlackInfo] = useState<any>({});
  const { t } = useTranslation();

  useEffect(() => {
    if (dialog && agent.agent_id) {
      openDetail(agent);
    }
  }, [dialog, agent]);

  const openDetail = async (row: any) => {
    setCurrentAgent(row);
    setSize(600);
    setDialogTableVisible(false);
    setCheckDocVisible(false);
    const res: any = await getLogDetail({ sid: row.sid });
    if (res.code == 200) {
      handleMessage(res.data || []);
    }
  };

  const handleMessage = (data: any) => {
    const arr: any[] = [];
    const imgArr: any[] = [];
    data.forEach((item: any) => {
      // 改造数据 ----- start ----
      item.text = item.message;
      delete item.message;
      // 改造数据 ------ end -----
      arr.push(item);
      if (item.answer_res_list && item.answer_res_list.length > 0) {
        arr.push({ ...{ showFileList: true }, ...item });
      }
      if (['image'].includes(item.content_type)) {
        imgArr.push(item.origin_message);
      }
    });
    setImageList(imgArr);
    setMessages(arr);
  };
  // 初始化会话

  const closeDebug = () => {
    setSize(600);
    setDialogTableVisible(false);
  };
  const closeDoc = () => {
    setSize(600);
    setCheckDocVisible(false);
  };

  const intoBlack = (item: any) => {
    setBlackInfo({
      visitor_id: item.visitor_id,
      black_reason: '',
    });
    setOpenBlack(true);
  };

  const setReason = (e: any) => {
    setBlackInfo({
      ...blackInfo,
      black_reason: e.target.value,
    });
  };

  const saveBlackInfo = async () => {
    console.log(blackInfo);
    if (blackInfo.black_reason === '') {
      message.error(t('dataAnalysis.sessionLog.inputBanReason'));
      return false;
    }
    try {
      const res = await addBlack(blackInfo);
      if (res.code === 200) {
        message.success(t('common.operationSuccess'));
        setOpenBlack(false);
      } else {
        message.error(res.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const goToOtherDetail = (sort: number) => {
    console.log(query);
    const lastQuery = query as any;
    if (lastQuery.time_range && lastQuery.time_range.length) {
      lastQuery.start_time = lastQuery.time_range[0].format('YYYY-MM-DD') + ' 00:00:00';
      lastQuery.end_time = lastQuery.time_range[1].format('YYYY-MM-DD') + ' 23:59:59';
    }
    delete lastQuery.time_range;
    let action = querySessionDetailAround;
    if (moduleFlag === 'workbench') {
      action = queryWorkbenchDetailAround;
    }
    action({
      ...lastQuery,
      agent_id: currentAgent.agent_id,
      _id: currentAgent._id,
      sort,
    }).then((res: any) => {
      if (res.code === 200 && res.data) {
        // 更新currentAgent数据
        setCurrentAgent(res.data.info);
        handleMessage(res.data.detail || []);
        setSelectedRowId(res.data.info.sid || '');
      } else {
        message.error(res.message || t('common.queryFailed'));
      }
    });
  };

  return (
    <>
      <Drawer
        open={dialog}
        title={
          <div className="flex session-detail-top">
            <span className="session-detail-title">{t('dataAnalysis.sessionLog.sessionDetails')}</span>
            <div className="session-detail-top-btn">
              <Button
                type="primary"
                onClick={() => goToOtherDetail(1)}
                style={{ marginRight: 16 }}
                icon={<span className="iconfont fangxiang-zuo-xian" />}
              >
                {t('common.previousPage')}
              </Button>
              <Button
                type="primary"
                onClick={() => goToOtherDetail(-1)}
                icon={<span className="iconfont fangxiang-you-xian" />}
              >
                {t('common.nextPage')}
              </Button>
            </div>
          </div>
        }
        onClose={() => setDialog(false)}
        placement="right"
        className="session-drawer"
        width={size}
      >
        <div className="chat-message-container flex h-full">
          <div className="session-container">
            <div className="session-window">
              <div className="card">
                <div className="title">{t('dataAnalysis.sessionLog.basicInfo')}</div>
                <div className="base-content">
                  <div className="base-content--item">
                    <div>{t('dataAnalysis.sessionLog.sessionId')}</div>
                    {currentAgent.sid}
                  </div>
                  <div className="base-content--item">
                    <div>{t('dataAnalysis.sessionLog.agentName')}</div>
                    {currentAgent.agent_name}
                  </div>
                  <div className="base-content--item">
                    <div>{t('dataAnalysis.sessionLog.startTime')}</div>
                    {currentAgent.start_time}
                  </div>
                  <div className="base-content--item">
                    <div>{t('dataAnalysis.sessionLog.lastDialogueTime')}</div>
                    {currentAgent.last_time}
                  </div>
                  <div className="base-content--item">
                    <div>{t('dataAnalysis.sessionLog.visitorId')}</div>
                    {currentAgent.visitor_id}
                    <span
                      className="iconfont lahei mt-0.5 ml-2 cursor-pointer text-xs"
                      style={{ fontSize: '18px' }}
                      onClick={() => intoBlack(currentAgent)}
                    ></span>
                  </div>
                  <div className="base-content--item">
                    <div>{t('dataAnalysis.sessionLog.visitorPhone')}</div>
                    {currentAgent.visitor_phone}
                  </div>
                  <div className="base-content--item">
                    <div>{t('dataAnalysis.sessionLog.relayNumber')}</div>
                    {currentAgent.relay_number}
                  </div>
                  
                  {currentAgent.recording_file && (
                    <div className="base-content--item">
                      <audio controls>
                        <source src={currentAgent.recording_file} type="audio/mpeg" />
                      </audio>
                    </div>
                  )}
                  {currentAgent.assign_remark && (
                    <div className="base-content--item">
                      <div>{t('common.remark')}</div>
                      {currentAgent.assign_remark}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="chat-window">
              <div className="start-time">
                {messages && messages.length > 0 ? messages[0].msg_time : ''}
              </div>
              <MessageList
                messages={messages}
                imageList={imageList}
                debugInfo={debugInfo}
                setSize={setSize}
                setDebugInfo={setDebugInfo}
                setDialogTableVisible={setDialogTableVisible}
                setCheckDocVisible={setCheckDocVisible}
                setDocDetail={setDocDetail}
              ></MessageList>
            </div>
          </div>
          {dialogTableVisible && (
            <DebugLog
              debugInfo={debugInfo}
              setDebugInfo={setDebugInfo}
              closeDebug={closeDebug}
            ></DebugLog>
          )}
          <CheckDoc
            onCloseDoc={closeDoc}
            style={{ display: checkDocVisible ? 'block' : 'none' }}
            docDetail={docDetail}
          />
        </div>
      </Drawer>
      <Modal
        open={openBlack}
        title={t('dataAnalysis.sessionLog.addToBlacklist')}
        onCancel={() => setOpenBlack(false)}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => setOpenBlack(false)} style={{ marginRight: 8 }}>
              {t('common.cancel')}
            </Button>
            <Button onClick={saveBlackInfo} type="primary">
              {t('common.save')}
            </Button>
          </div>
        }
      >
        <p>{t('dataAnalysis.sessionLog.confirmAddToBlacklist', {visitor_id: blackInfo.visitor_id})}</p>
        <Input.TextArea
          className="mt-2"
          placeholder={t('dataAnalysis.sessionLog.banReason')}
          value={blackInfo.black_reason}
          onChange={setReason}
          rows={3}
        />
      </Modal>
    </>
  );
};

export default SessionDetail;
