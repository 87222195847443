import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 导入翻译文件
import enTranslations from '../locales/languages/en.json';
import zhTranslations from '../locales/languages/zh.json';
/* import jaTranslations from '../locales/languages/ja.json';
import koTranslations from '../locales/languages/ko.json';
import ruTranslations from '../locales/languages/ru.json';
import arTranslations from '../locales/languages/ar.json'; */

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      zh: { translation: zhTranslations },
/*       ja: { translation: jaTranslations },
      ko: { translation: koTranslations },
      ru: { translation: ruTranslations },
      ar: { translation: arTranslations }, */
    },
    fallbackLng: 'zh',
    interpolation: {
      escapeValue: false,
      // 复数形式
      format: (value, format, lng) => {
        if (format === 'scene') {
          return i18n.t('common.scene', { lng });
        }
        if (format === 'scene' && lng === 'ru') {
          const forms = ['сцена', 'сцены', 'сцен'];
          return russianPlurals(value, forms);
        }
        if (format === 'scene' && lng === 'ar') {
          const forms = ['مشهد', 'مشهدان', 'مشاهد', 'مشهدًا', 'مشهد'];
          return arabicPlurals(value, forms);
        }
        return value;
      }
    },
  });
  // 俄语复数形式
  function russianPlurals(count: number, forms: string[]) {
    const cases = [2, 0, 1, 1, 1, 2];
    return forms[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)]];
  }
  // 阿拉伯语复数形式
  function arabicPlurals(count: number, forms: string[]) {
    if (count === 0) return forms[0];
    if (count === 1) return forms[0];
    if (count === 2) return forms[1];
    if (count % 100 >= 3 && count % 100 <= 10) return forms[2];
    if (count % 100 >= 11 && count % 100 <= 99) return forms[3];
    return forms[4];
  }

export default i18n;