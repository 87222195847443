import React, { lazy } from 'react';
import { Navigate, RouteObject, createHashRouter } from 'react-router-dom';
import Page404 from '../pages/Page404/Page404';
import Layout from '../layout/index';
import LoginPage from '@/pages/login/login';
import RequireAuth from './RequireAuth';
import LoginBySign from '@/pages/sign/loginBySign';

// @ts-expect-error: 防止require语法报错
const modules = require.context('../pages', true, /\/index\.tsx$/);
const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to={'/login'} />,
  },
];

modules.keys().forEach((key: string) => {
  const path = key.replace(/^\.\/|\/index\.tsx$/g, '');
  routes.push({
    path: '/' + path,
    id: '/' + path,
    Component: lazy(() => import(`../pages/${path}/index.tsx`)),
  });
});

const router = createHashRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        element: <RequireAuth></RequireAuth>,
        children: routes,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/sign',
    element: <LoginBySign />,
  },
  {
    path: '*',
    element: <Page404 />,
  },
]);

export default router;
