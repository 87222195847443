import React from 'react';
import { t } from 'i18next';
import useDragAndDrop from './useDnD';
import { LLM_TASK_PROMPT } from '@/constant/agent';
type NodeItem = {
  label: string;
  iconfont: string;
  bgcolor: string;
  type: string;
  flowType: string;
  tip?: string;
  form?: object;
};

type SideBarProps = {
  direction: boolean;
};

const nodeList: NodeItem[] = [
  {
    label: t('flowSideBar.replyNode'),
    iconfont: 'huifujiedian',
    bgcolor: '#1972F5',
    type: 'custom',
    flowType: 'reply',
    tip: t('flowSideBar.replyNodeTip'),
    form: {
      name: t('flowSideBar.replyNode'),
      strategy_type: 'answer',
      keyboard_scene: '',
      cate: '3',
      direct_answer: '',
      net_search_prompt: t('agentEngine.role.netSearchPrompt'),
      skill_num: '',
      turn_human_msg: '',
      polish_prompt: '',
      llm_prompt: '',
      tool_llm: 'minimax',
      json_parser: '1',
      json_field: '',
      kbs: [],
      answer_res_list: [],
    },
  },
  {
    label: t('flowSideBar.variableNode'),
    iconfont: 'fuzhijiedian',
    bgcolor: '#67C23A',
    type: 'custom',
    flowType: 'variable',
    tip: t('flowSideBar.variableNodeTip'),
    form: {
      name: t('flowSideBar.variableNode'),
      form: [],
    },
  },
  {
    label: t('flowSideBar.functionNode'),
    iconfont: 'hanshujiedian',
    bgcolor: '#E6A23C',
    type: 'custom',
    flowType: 'func',
    tip: t('flowSideBar.functionNodeTip'),
    form: {
      name: t('flowSideBar.functionNode'),
      function_name: '',
      func_alias: '',
      parameter: [],
    },
  },
  {
    label: t('flowSideBar.conditionNode'),
    iconfont: 'tiaojianpanduan',
    bgcolor: '#1094A3',
    type: 'moreOutput',
    flowType: 'condition',
    tip: t('flowSideBar.conditionNodeTip'),
    form: {
      name: t('flowSideBar.conditionNode'),
      value: [],
    },
  },
  {
    label: t('flowSideBar.llmNode'),
    iconfont: 'LLMjiedian',
    bgcolor: '#6F25B8',
    type: 'custom',
    flowType: 'llm',
    tip: t('flowSideBar.llmNodeTip'),
    form: {
      name: t('flowSideBar.llmNode'),
      llm_prompt: '',
      tool_llm: 'gpt4o',
      chat_turns: 0,
    },
  },
  {
    label: t('flowSideBar.llmtaskNode'),
    iconfont: 'LLMrenwuchuli',
    bgcolor: '#3075DE',
    type: 'moreOutput',
    flowType: 'llmtask',
    tip: t('flowSideBar.llmtaskNodeTip'),
    form: {
      name: t('flowSideBar.llmtaskNode'),
      llm_prompt: LLM_TASK_PROMPT,
      tool_llm: 'gpt4o',
      chat_turns: 0,
      params: [],
      chain_check: [],
    },
  },
  {
    label: t('flowSideBar.endNode'),
    iconfont: 'yindaojieshu',
    bgcolor: '#F56C6C',
    type: 'custom',
    flowType: 'end',
  },
  {
    label: t('flowSideBar.nextNode'),
    iconfont: 'xiayihuanjie',
    bgcolor: '#FACA0A',
    type: 'custom',
    flowType: 'next',
  },
];

const SideBar: React.FC<SideBarProps> = ({ direction }) => {
  const { onDragStart } = useDragAndDrop();
  return (
    <aside>
      <div className="nodes">
        {nodeList.map((item, index) => (
          <div
            key={index}
            className={`node-item ${direction ? '' : 'no-lable'}`}
            draggable
            onDragStart={event => onDragStart(event, item)}
          >
            <span
              className={`iconfont ${item.iconfont}`}
              style={{ backgroundColor: item.bgcolor, color: '#fff', borderRadius: '4px' }}
            />
            {direction ? item.label : ''}
          </div>
        ))}
      </div>
    </aside>
  );
};

export default SideBar;
