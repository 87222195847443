import React, { useState } from 'react';
import { Button } from 'antd';
import { t } from 'i18next';
import { CloseCircleOutlined } from '@ant-design/icons';
import './checkDoc.scss';
interface Props {
  onCloseDoc: (val: any) => void;
  style: any;
  docDetail: any;
}
const CheckDoc: React.FC<Props> = ({ onCloseDoc, style, docDetail }) => {
  const closeDoc = () => {
    onCloseDoc('');
  };

  const openNewPage = (obj: any) => {
    console.log(obj);
    const href = window.location.href.split('#');
    window.open(
      `${href[0]}#/agentService/agentManager/knowledgeManager?kb_id=${obj.kb_id}&doc_id=${obj.doc_id}`,
      '_blank'
    );
  };

  return (
    <div className="doc-check-container" style={style}>
      <div className="debug-title">
        <Button
          icon={<CloseCircleOutlined />}
          type="text"
          shape="circle"
          className="close-btn"
          onClick={closeDoc}
        />
        {t('common.reference')}
      </div>
      <div className="doc-content">
        <div className="flex">
          <span className="iconfont wenxian1"></span>
          <span className="doc-source-name">{docDetail.source}</span>
          <span
            className="iconfont bianji toEdit-btn"
            onClick={() => openNewPage(docDetail)}
          ></span>
        </div>
        <div className="doc-detail">{docDetail.page_content}</div>
      </div>
    </div>
  );
};
export default CheckDoc;
