import React, { useState, useEffect } from 'react';
import { Table, Button, Pagination, Input, Modal, Form, message, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { getAiEntity, saveEntity, delEntity } from '@/api/agent';
import './entity.scss';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { t } from 'i18next';

interface DataItem {
  entity_name: string;
  entity_desc: string;
  regex: string;
  create_datetime?: string;
  entity_id?: string;
}

const EntityManager: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [formDataLoading, setFormDataLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState<DataItem[]>([]);
  const [dataItem, setDataItem] = useState<DataItem>({
    entity_name: '',
    entity_desc: '',
    regex: '',
    entity_id: '',
  });
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [form] = Form.useForm();
  const [operate, setOperate] = useState('');
  const currentAgent = useAppSelector(selectCurrentAgent);
  const agent_id = currentAgent.currentAgent.agent_id;

  useEffect(() => {
    getList();
  }, [page, size]);

  const getList = async () => {
    setLoading(true);
    try {
      const res = await getAiEntity({
        page,
        size,
        agent_id: agent_id,
      });
      if (res.code === 200) {
        setTableData(res.data?.result || []);
        setTotalCount(res.data?.total_count || 0);
      } else {
        message.error(t('common.getListFailed'));
      }
    } catch (error) {
      message.error(t('common.getListFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (row?: DataItem) => {
    setEditDialogVisible(true);
    if (row) {
      setOperate(t('common.editEntity'));
      setDataItem(row);
      form.setFieldsValue(row);
    } else {
      setOperate(t('common.addEntity'));
      setDataItem({ entity_name: '', entity_desc: '', regex: '' }); // 重置表单
      form.setFieldsValue({ entity_name: '', entity_desc: '', regex: '' });
    }
  };

  const saveData = async () => {
    try {
      const values = await form.validateFields();
      setFormDataLoading(true);
      const actionForm = {
        ...values,
        agent_id: agent_id,
      };
      if (dataItem.entity_id) {
        actionForm.entity_id = dataItem.entity_id;
      }
      const res = await saveEntity(actionForm);
      if (res.code === 200) {
        message.success(t('common.saveSuccess'));
        setEditDialogVisible(false);
        getList();
      } else {
        message.error(res.description);
      }
    } catch (errorInfo) {
      console.error('Failed:', errorInfo);
    } finally {
      setFormDataLoading(false);
    }
  };

  const deleteHandle = (entity_id: string) => {
    Modal.confirm({
      title: t('common.confirmDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const res = await delEntity({ entity_id });
          if (res.code === 200) {
            message.success(t('common.deleteSuccess'));
            getList();
          } else {
            message.error(res.message);
          }
        } catch (error) {
          message.error(t('common.deleteFailed'));
        }
      },
    });
  };

  const columns = [
    {
      title: t('common.name'),
      dataIndex: 'entity_name',
      key: 'entity_name',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (name: string) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: t('common.createType'),
      dataIndex: 'entity_id',
      key: 'entity_id',
      width: 100,
      render: (text: string) => (text && text.startsWith('system_ent') ? t('common.systemBuiltin') : t('common.custom')),
    },
    {
      title: t('common.description'),
      dataIndex: 'entity_desc',
      key: 'entity_desc',
      width: 225,
      ellipsis: {
        showTitle: false,
      },
      render: (description: string) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
    {
      title: t('common.entityMembers'),
      dataIndex: 'regex',
      key: 'regex',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string) =>
        text ? (
          <Tooltip placement="topLeft" title={text}>
            <span className="regex-display">{text}</span>
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      title: t('common.createTime'),
      dataIndex: 'create_datetime',
      key: 'create_datetime',
      width: 225,
    },
    {
      title: t('common.operation'),
      key: 'action',
      width: 120,
      render: (text: any, record: any) => (
        <div>
          <Button style={{ padding: '0 7px' }} type="link" onClick={() => handleEdit(record)}>
            {t('common.edit')}
          </Button>
          <Button
            style={{ padding: '0 7px' }}
            type="link"
            danger
            onClick={() => deleteHandle(record.entity_id)}
          >
            {t('common.delete')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="entity-manager p-5 h-full overflow-y-scroll">
      <div className="title">
        <div className="title-text">{t('common.entityManagement')}</div>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => handleEdit()}>
          {t('common.addEntity')}
        </Button>
      </div>
      <Table
        size="small"
        columns={columns}
        dataSource={tableData}
        rowKey="entity_id"
        loading={loading}
        pagination={false}
        bordered
      />
      <Pagination
        style={{ marginTop: 16, textAlign: 'right' }}
        className="justify-end"
        current={page}
        pageSize={size}
        total={totalCount}
        showQuickJumper
        showTotal={totalCount => `${t('common.totalItems', { count: totalCount })}`}
        onChange={(page, pageSize) => {
          setPage(page);
          setSize(pageSize || 20);
        }}
        showSizeChanger
        pageSizeOptions={['10', '20', '50', '100']}
      />
      <Modal
        title={operate}
        open={editDialogVisible}
        onCancel={() => setEditDialogVisible(false)}
        confirmLoading={formDataLoading}
        footer={[
          <Button key="back" onClick={() => setEditDialogVisible(false)}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={saveData}>
            {t('common.save')}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" initialValues={dataItem}>
          <Form.Item
            name="entity_name"
            label={t('common.name')}
            rules={[{ required: true, message: t('agent.nameRequired') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="entity_desc" label={t('common.description')}>
            <Input.TextArea rows={2} />
          </Form.Item>
          <Form.Item
            name="regex"
            label={t('common.regexMatch')}
            rules={[{ required: true, message: t('common.regexExpression') }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EntityManager;
