import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Form, Input, Select, message } from 'antd';
import { t } from 'i18next';
import { useForm } from 'antd/lib/form/Form';

const { Option } = Select;

interface FormNode {
  name: string;
  function_name: string;
  func_alias: string;
  parameter: Array<{ value: string }>;
}

interface Rule {
  required: boolean;
  message: string;
}
interface FuncFormProps {
  form: Record<string, any>;
  id: string;
  funcList: Array<{ name: string; otherName: string; returnParams?: any[] }>;
}

const FuncForm = forwardRef<any, FuncFormProps>(({ form, id, funcList }, ref) => {
  const [formRef] = useForm();
  const [formNode, setFormNode] = useState<FormNode>({
    name: t('flowSideBar.funcNode'),
    function_name: '',
    func_alias: '',
    parameter: [],
  });

  useEffect(() => {
    formRef.setFieldsValue(form);
    setFormNode({ ...formNode, ...form });
  }, [form]);

  const rules: { [key: string]: Rule[] } = {
    name: [{ required: true, message: t('agent.nameRequired') }],
    function_name: [{ required: true, message: t('flowForm.funcForm.selectFunc') }],
  };

  const changeSelect = (cur: string) => {
    const funObj = funcList.find((item: any) => item.name === cur);
    if (funObj) {
      setFormNode(prev => ({
        ...prev,
        parameter: funObj.returnParams || [],
        func_alias: funObj.otherName || '',
      }));
      formRef.setFieldsValue({
        ...formRef,
        parameter: funObj.returnParams || [],
        func_alias: funObj.otherName || '',
      });
    }
  };

  const validate = async () => {
    try {
      const values = await formRef.validateFields();
      console.log(values);
      return values;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <Form
      form={formRef}
      className="px-5 pt-5 pb-1 bg-white rounded"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={formNode}
    >
      <Form.Item label={t('flowForm.funcName')} name="name" rules={rules.name}>
        <Input
          value={formNode.name}
          onChange={e => setFormNode(prev => ({ ...prev, name: e.target.value }))}
          autoComplete="off"
          maxLength={50}
          showCount
          placeholder={t('common.input')}
        />
      </Form.Item>
      <Form.Item label={t('flowForm.funcForm.funcService')} name="function_name" rules={rules.function_name}>
        <Select
          value={formNode.function_name}
          onChange={value => {
            setFormNode(prev => ({ ...prev, function_name: value }));
            changeSelect(value);
          }}
          placeholder={t('common.select')}
          style={{ width: '100%' }}
          filterOption={(input, option) =>
            (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          {funcList.map((item: any, index) => (
            <Option value={item.name} key={item.name + index}>
              {item.otherName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {formNode.function_name && (
        <Form.Item label={t('flowForm.funcForm.returnParameter')} name="parameter">
          {formNode.parameter.map((item, index) => (
            <div key={item.value + index}>
              <Input
                style={{ marginBottom: '10px' }}
                value={item.value}
                placeholder={t('flowForm.funcForm..inputParameterName')}
                disabled
                addonBefore={t('flowForm.funcForm.parameterName')}
              />
            </div>
          ))}
        </Form.Item>
      )}
    </Form>
  );
});

FuncForm.displayName = 'FuncForm';
export default FuncForm;
