import React, { useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next'; 
import KmManager from './kmManager';
import EntryManager from './entryManager';

const { TabPane } = Tabs;

const KnowledgeManager = () => {
  const { t } = useTranslation();
  const [activeName, setActiveName] = useState('km');

  return (
    <div className="knowledge-box h-full">
      <Tabs className="h-full" defaultActiveKey="km" tabBarStyle={{ padding: '0 16px', marginBottom: '0' }} onChange={setActiveName}>
        <TabPane tab={t('knowledge.knowledge')} key="km">
          {activeName === 'km' && <KmManager />}
        </TabPane>
        <TabPane tab={t('knowledge.entry')} key="entry">
          {activeName === 'entry' && <EntryManager />}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default KnowledgeManager;
