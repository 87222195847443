import React, { useState } from 'react';
import { Modal, Button, Input, Select, Tooltip, Spin } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent, selectModelEnum } from '@/store/modules/agentTemplate';
import { queryLlm } from '@/api/agent';
import { produce } from 'immer';

const { TextArea } = Input;
const { Option } = Select;

interface DebugProps {
  dialogPromptVisible: boolean;
  debugInfo: any[];
  currentDebug: any;
  // currentDebug: {
  //   title: string;
  //   content: string;
  //   tool_llm: any;
  //   response: string;
  //   add_info: {
  //     prompt_tokens: number;
  //     completion_tokens: number;
  //     total_tokens: number;
  //     billing: string;
  //   };
  // };
  setDialogPromptVisible: (visible: boolean) => void;
  setCurrentDebug: (content: string) => void;
}

const PromptDebug: React.FC<DebugProps> = ({
  dialogPromptVisible,
  debugInfo,
  currentDebug,
  setDialogPromptVisible,
  setCurrentDebug,
}) => {
  const ModelEnum = selectModelEnum();
  const titleMap: { [key: string]: string } = {
    tool_prompt: '角色',
    tool_map: '回答策略',
    flow_log: '流程',
    image_floor_log: '图片兜底',
    small_talk_log: '寒暄机器人',
  };
  const [fullDialogVisible, setFullDialogVisible] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const handleFullScreen = () => {
    setFullDialogVisible(true);
  };

  const testPrompt = async () => {
    setQueryLoading(false);
    const params: any = {
      sid: currentDebug.sid,
      content: currentDebug.content,
      prompt: currentDebug.prompt,
      llm_name: currentDebug.tool_llm,
      messages: currentDebug.messages,
      json_parser: currentDebug.json_parser,
      option: currentDebug.option || [],
    };
    // debugInfo.forEach(item => {
    //   if (item.title == 'tool_prompt') {
    //     params.json_parser = item.json_parser;
    //   }
    // });
    // query_log里面有rag_log，但是没有rounds，传3
    // query_log里面有rag_log，并且有rounds，传rounds
    const { rag_log } = currentDebug;
    if (rag_log) {
      const { rounds } = rag_log;
      if (rounds != null) {
        params.rounds = rounds;
      } else {
        params.rounds = 3;
      }
    }
    try {
      const res: any = await queryLlm(params);
      console.log('保存响应', res);
      if (res.code == 200) {
        setCurrentDebug(
          // @ts-expect-error unknown
          produce((draft: any) => {
            draft.response = res.data;
            if (res.add_info && res.add_info) {
              draft.add_info = res.add_info;
            }
          })
        );
      } else {
        setCurrentDebug(
          // @ts-expect-error unknown
          produce((draft: any) => {
            draft.response = res.message;
          })
        );
      }
      setQueryLoading(false);
    } catch (error) {
      setCurrentDebug(
        // @ts-expect-error unknown
        produce((draft: any) => {
          draft.response = '请求异常！';
        })
      );
      setQueryLoading(false);
    }
  };
  return (
    <>
      <Modal
        open={dialogPromptVisible}
        title="prompt调试"
        onCancel={() => setDialogPromptVisible(false)}
        footer={null}
        width="600px"
      >
        <div>
          <div className="current-title">{titleMap[currentDebug.title] || currentDebug.title}</div>
          <div className="prompt-content">
            <div className="flex-column align-center">
              <div
                className="flex text-black"
                style={{ justifyContent: 'space-between', marginBottom: '8px' }}
              >
                <div>请求</div>
                <div className="flex flex-end">
                  <Tooltip title="点击全屏">
                    <div className="pointer" onClick={handleFullScreen}>
                      <FullscreenOutlined />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            <TextArea
              value={currentDebug.content}
              rows={15}
              onChange={e =>
                setCurrentDebug(
                  // @ts-expect-error unknown
                  produce((draft: any) => {
                    draft.content = e.target.value;
                  })
                )
              }
            />
            <div className="flex select-model mt-2 mb-2">
              <Select
                value={currentDebug.tool_llm}
                onChange={val => {
                  setCurrentDebug(
                    // @ts-expect-error unknown
                    produce((draft: any) => {
                      draft.tool_llm = val;
                    })
                  );
                }}
                placeholder="请选择模型"
                className="model flex-1"
                style={{ marginRight: '10px' }}
              >
                {ModelEnum.map((model: string) => {
                  return (
                    <Option value={model} key={model}>
                      {model}
                    </Option>
                  );
                })}
              </Select>
              <Button type="primary" onClick={testPrompt}>
                测试
              </Button>
            </div>
          </div>
          <div className="flex">
            <div className="text-black" style={{ flexShrink: 0, width: '80px' }}>
              响应
            </div>
            <Spin spinning={queryLoading}>
              <div>{currentDebug.response}</div>
            </Spin>
          </div>
          <div className="flex">
            <div className="text-black" style={{ flexShrink: 0, width: '80px' }}>
              模型
            </div>
            <div>{currentDebug.tool_llm}</div>
          </div>
          <div className="flex">
            <div className="text-black" style={{ flexShrink: 0, width: '80px' }}>
              token
            </div>
            <div>
              请求：{currentDebug.add_info?.prompt_tokens} 响应：
              {currentDebug.add_info?.completion_tokens} 总数：{currentDebug.add_info?.total_tokens}
            </div>
          </div>
          <div className="flex">
            <div className="text-black" style={{ flexShrink: 0, width: '80px' }}>
              消费金额
            </div>
            <div>{currentDebug.add_info?.billing}</div>
          </div>
        </div>
      </Modal>
      <Modal
        open={fullDialogVisible}
        title="编辑prompt"
        onCancel={() => setFullDialogVisible(false)}
        footer={null}
        width="100%"
        className="full-screen-modal"
      >
        <TextArea
          value={currentDebug.content}
          rows={40}
          onChange={e => {
            setCurrentDebug(
              // @ts-expect-error unknown
              produce((draft: any) => {
                draft.content = e.target.value;
              })
            );
          }}
        />
      </Modal>
    </>
  );
};

export default PromptDebug;
