import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, DatePicker, Select, Table, Pagination, Tooltip } from 'antd';
import { getLogList } from '@/api/agent'; // This needs to be changed based on the actual API import path in your React project
import SessionDetail from './SessionDetail';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import dayjs from 'dayjs';
// 导入 useTranslation hook
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface DataItem {
  sid: string;
  agent_name: string;
  source: number;
  source_name: string;
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
  billing: string;
  start_time: string;
  last_time: string;
}

// 使用 t 函数进行翻译
const sourcArr = [
  { label: 'common.all', value: null },
  { label: 'common.conversation', value: 0 },
  { label: 'common.auxiliary', value: 1 },
];

const SessionLog: React.FC = () => {
  // 使用 useTranslation hook
  const { t } = useTranslation();
  
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DataItem[]>([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0 });
  const [form] = Form.useForm();
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const columns = [
    { title: t('dataAnalysis.sessionLog.sessionId'), dataIndex: 'sid', key: 'sid', width: 340 },
    {
      title: t('dataAnalysis.sessionLog.agentName'),
      dataIndex: 'agent_name',
      key: 'agent_name',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (text: string) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: t('dataAnalysis.sessionLog.applicationScenario'),
      dataIndex: 'source',
      key: 'source',
      width: 80,
      render: (text: any, row: any) => {
        let str = ''
        const item = sourcArr.find((item) => item.value === row.source);
        if (item) {
          str = t(item.label);
        }
        return str;
      },
    },
    {
      title: t('dataAnalysis.sessionLog.humanReception'),
      key: 'ask_human_serviced',
      width: 110,
      render: (text: any, row: any) => {
        return row.ask_human_serviced ? t('common.yes') : t('common.no');
      },
    },
    {
      title: t('dataAnalysis.sessionLog.sessionSource'),
      dataIndex: 'session_source',
      key: 'session_source',
      width: 80,
      render: (text: any, row: any) => {
        return row.session_source === 'x_bot' ? 'xbot' : row.session_source;
      },
    },
    { title: t('dataAnalysis.sessionLog.requestTokens'), dataIndex: 'prompt_tokens', key: 'prompt_tokens', width: 100 },
    { title: t('dataAnalysis.sessionLog.responseTokens'), dataIndex: 'completion_tokens', key: 'completion_tokens', width: 100 },
    { title: t('dataAnalysis.sessionLog.totalTokens'), dataIndex: 'total_tokens', key: 'total_tokens', width: 100 },
    {
      title: t('dataAnalysis.sessionLog.consumptionAmount'),
      dataIndex: 'billing',
      key: 'billing',
      width: 90,
      render: (text: any, row: any) => {
        return row.billing && row.billing.toFixed(4);
      },
    },
    { title: t('dataAnalysis.sessionLog.startTime'), dataIndex: 'start_time', key: 'start_time', width: 180 },
    {
      title: t('dataAnalysis.sessionLog.lastDialogueTime'),
      dataIndex: 'last_time',
      key: 'last_time',
      width: 180,
      render: (text: any, row: any) => {
        return row.last_time;
      },
    },
    {
      title: t('common.operation'),
      dataIndex: '',
      key: 'op',
      width: 90,
      render: (row: any) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setAgent(row);
              setDetailVisible(true);
              handleSelectedRowId(row.sid);
            }}
          >
            {t('common.details')}
          </Button>
        );
      },
    },
  ];
  const [detailVisible, setDetailVisible] = useState(false);
  const [agent, setAgent] = useState({});
  const onPageChange = (newPage: number, newSize: number) => {
    setPagination({ ...pagination, current: newPage, pageSize: newSize });
  };
  const fetchLogs = useCallback(
    async (params: any) => {
      setLoading(true);
      // Simulating an API call
      const data: any = {
        ...params,
        agent_id: currentAgent.agent_id,
        source: params.source || null,
        session_source: params.session_source || null,
        ask_human_serviced:
          params.ask_human_serviced === 1 ? true : params.ask_human_serviced === 0 ? false : null,
        visitor_reply:
          params.visitor_reply === 1 ? true : params.visitor_reply === 0 ? false : null,
      };
      const { time_range } = params as any;
      if (time_range && time_range.length) {
        data.start_time = time_range[0].format('YYYY-MM-DD') + ' 00:00:00';
        data.end_time = time_range[1].format('YYYY-MM-DD') + ' 23:59:59';
      }
      delete data.time_range;
      const result = await getLogList(data);
      if (result.code === 200) {
        setData(result.data.result);
        setPagination({ ...pagination, total: result.data.total_count });
      }
      setLoading(false);
    },
    [pagination.current, pagination.pageSize]
  );
  useEffect(() => {
    fetchLogs({
      page: pagination.current,
      size: pagination.pageSize,
      time_range: [dayjs().subtract(7, 'day'), dayjs()],
      ...form.getFieldsValue(),
    });
  }, [fetchLogs, pagination.current, pagination.pageSize]);
  useEffect(() => {
    const localId = window.localStorage.getItem('currentSid') || '';
    setSelectedRowId(localId);
  }, []);

  const handleTableChange = (newPagination: any) => {
    fetchLogs({
      page: newPagination.current,
      size: newPagination.pageSize,
      ...form.getFieldsValue(),
    });
  };

  const onSearch = () => {
    fetchLogs({
      ...form.getFieldsValue(),
      page: 1,
      size: pagination.pageSize,
    });
  };
  const handleSelectedRowId = (val: any) => {
    setSelectedRowId(val);
    window.localStorage.setItem('currentSid', val);
  };
  const isHumanServiced = [
    { label: t('common.all'), value: null },
    { label: t('common.yes'), value: 1 },
    { label: t('common.no'), value: 0 },
  ];
  const chatSources = [
    { label: t('common.all'), value: null },
    { label: '呼入', value: 'incoming_call' },
    { label: 'test', value: 'test' },
    { label: 'other', value: 'other' },
  ];
  const isReplyValid = [
    { label: t('common.all'), value: null },
    { label: t('dataAnalysis.sessionLog.validSession'), value: 1 },
    { label: t('dataAnalysis.sessionLog.invalidSession'), value: 0 },
  ];

  return (
    <div className="p-4 h-full overflow-y-scroll">
      <Form
        form={form}
        className="mb-5"
        layout="inline"
        onFinish={onSearch}
        initialValues={{
          time_range: [dayjs().subtract(7, 'day'), dayjs()],
          ask_human_serviced: null,
          session_source: null,
          source: null,
          visitor_reply: null,
        }}
      >
        <Form.Item name="sid" label={t('dataAnalysis.sessionLog.sessionId')}>
          <Input placeholder={t('common.inputPrompt', { title: t('dataAnalysis.sessionLog.sessionId') })} allowClear />
        </Form.Item>
        <Form.Item name="time_range" label={t('dataAnalysis.sessionLog.startTime')}>
          <RangePicker defaultValue={[dayjs().subtract(7, 'day'), dayjs()]} />
        </Form.Item>
        <Form.Item name="source" label={t('dataAnalysis.sessionLog.applicationScenario')}>
          <Select placeholder={t('common.select')} allowClear>
            {sourcArr.map(item => (
              <Option key={item.value} value={item.value}>
                {t(item.label)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="ask_human_serviced" label={t('dataAnalysis.sessionLog.humanReception')}>
          <Select placeholder={t('common.select')} allowClear>
            {isHumanServiced.map(item => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="session_source" label={t('dataAnalysis.sessionLog.sessionSource')}>
          <Select placeholder={t('common.select')} allowClear>
            {chatSources.map(item => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="visitor_reply" label={t('dataAnalysis.sessionLog.sessionValidity')}>
          <Select placeholder={t('common.select')} allowClear>
            {isReplyValid.map(item => (
              <Option key={item.value + '1'} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('common.search')}
          </Button>
        </Form.Item>
      </Form>
      <Table
        size="small"
        scroll={{ y: 600 }}
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        onChange={handleTableChange}
        rowKey="sid"
        bordered
        rowClassName={record => (record.sid === selectedRowId ? 'session-selected-row' : '')}
      />
      <Pagination
        style={{ marginTop: 16, textAlign: 'right' }}
        showSizeChanger
        showQuickJumper
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={total => t('common.totalItems', { count: total })}
        onChange={(page, pageSize) => onPageChange(page, pageSize)}
      />
      <SessionDetail
        agent={agent}
        dialog={detailVisible}
        setDialog={setDetailVisible}
        setSelectedRowId={handleSelectedRowId}
        query={form.getFieldsValue()}
        moduleFlag="session"
      ></SessionDetail>
    </div>
  );
};

export default SessionLog;
