import { Tabs } from 'antd';
import { t } from 'i18next';
import TabPane from 'antd/es/tabs/TabPane';
import React, { FC } from 'react';
import Website from "./website";
import Zendesk from "./zendesk";
import WebsiteEdit from "./website/edit";

const ChannelIntegrations: FC = () => {
    return (
        <div className="h-full" style={{backgroundColor: '#FAFAFA'}}>
            <div className="agentEngine">
                <Tabs defaultActiveKey="role" tabBarStyle={{ padding: '0 16px', marginBottom: '0', backgroundColor: '#fff', borderRadius: '10px 10px 0 0' }}>
                    <TabPane tab={t('account.website')} key="website">
                        <div>
                            <Website />
                        </div>
                    </TabPane>
                    <TabPane tab="zendesk" key="zendesk">
                        <div>
                            <Zendesk />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default ChannelIntegrations;
