import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Form, Input, message, Modal, Pagination, Select, Table, Tabs, Upload} from 'antd';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {deleteZendeskConfig, queryZendeskConfig, saveZendeskConfig,} from '@/api/account';
import {queryAgentList} from "@/api/agent";
import OssAvatarUpload from "@/components/OssAvatarUpload";
import { UploadFile } from 'antd/es/upload/interface';
import TabPane from "antd/es/tabs/TabPane";
import ChatConfig from "@/pages/businessSetup/sessionProcess/chatConfig/chatConfig";
import { useTranslation } from 'react-i18next';

const { Option } = Select;
interface ZendeskConfigFormFields {
  name: string;
  description: string;
  agent_id: string;
  webhook_url: string;
  app_id: string;
  api_key: string;
  api_secret: string;
  avatar: string;
}

// interface UploadFile {
//   uid?: string;
//   name?: string;
//   url?: string;
//   status?: string;
//   type?: string;
// }

const Zendesk: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [queryForm] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [agentList, setAgentList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currentObj, setCurrentObj] = useState({ uid: '',avatar: '' });
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const formRef = useRef<any>();
  const [currentAvatar, setCurrentAvatar] = useState<UploadFile | undefined>();

  useEffect(() => {
    fetchAgentList();
    fetchZendeskConfigList();
  }, []);

  const onPageChange = (val: number, newSize: number) => {
    setCurrentPage(val);
    setPageSize(newSize);
    fetchZendeskConfigList(val, newSize);
  };

  const handleTableData = (data: any) => {
    return data;
  };

  const fetchZendeskConfigList = async (page?: number, pageSize?: number) => {
    await queryZendeskConfig(page, pageSize).then((res: any) => {
      setTableData(handleTableData(res.data.result || []));
      setTotalCount(res.total_count || 0);
    });
  };

  const fetchAgentList = async () => {
    queryAgentList({ page: 1, size: 10000 }).then((res: any) => {
      if (res.code === 200) {
        setAgentList(res.data.result || []);
      }
    });
  };

  const onSearch = () => {
    setCurrentPage(1);
    fetchZendeskConfigList();
  };

  const onAddOrEdit = (record: any) => {
    setEditDialogVisible(true);
    if (record) {
      setIsEdit(true);
      setCurrentObj(record);
      form.setFieldsValue(record);
      setCurrentAvatar(record.avatar ? { uid: '-1', name: 'avatar', url: record.avatar } : undefined);

    } else {
      setCurrentObj({ uid: '',avatar: '' });
      form.resetFields();
      setIsEdit(false);
      setCurrentAvatar(undefined);
    }
  };

  const onDelete = async (uid: string) => {
    Modal.confirm({
      title: t('common.confirm'),
      content: t('common.confirmDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        await deleteZendeskConfig(uid);
        message.success(t('common.deleteSuccess'));
        fetchZendeskConfigList();
      },
    });
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const actionFun = saveZendeskConfig;
      const actionForm = {
        name: values.name,
        description: values.description,
        agent_id: values.agent_id,
        webhook_url: values.webhook_url,
        app_id: values.app_id,
        api_key: values.api_key,
        api_secret: values.api_secret,
        avatar: currentAvatar?.url || '',
      } as any;
      if (isEdit) {
        actionForm.uid = currentObj.uid;
      }
      actionFun(actionForm)
        .then(() => {
          message.success(t('common.saveSuccess'));
          setEditDialogVisible(false);
          setCurrentPage(1);
          fetchZendeskConfigList();
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            message.error(error.response.data.message);
          } else {
            message.error(t('common.saveFailed'));
          }
        });
    } catch (errorInfo) {
      console.log('验证失败:', errorInfo);
    }
  };

  const columns = [
    { title: t('account.name'), dataIndex: 'name', key: 'name' },
    { title: t('account.description'), dataIndex: 'description', key: 'description' },
    { title: t('account.lastUpdateTime'), dataIndex: 'update_time', key: 'update_time' },
    {
      title: t('common.operation'),
      key: 'action',
      render: (_text: any, record: any) => (
        <>
          <Button type="link" size="small" onClick={() => onAddOrEdit(record)}>
            {t('common.edit')}
          </Button>
          <Button type="link" danger size="small" onClick={() => onDelete(record.uid)}>
            {t('common.delete')}
          </Button>
        </>
      ),
    },
  ];

  return (
      <div className="p-4 h-full overflow-y-scroll">
        <Alert
            style={{marginBottom: 10}}
            showIcon
            closable
            message={t('account.zendeskConfigTip')}
            type="warning"
        />
        <Form form={queryForm} layout="inline" onFinish={onSearch} style={{marginBottom: 16}}>
          <Form.Item>
            <Button type="primary" icon={<PlusOutlined/>} onClick={() => onAddOrEdit(null)}>
              {t('account.bindZendesk')}
            </Button>
          </Form.Item>
        </Form>
        <Table bordered size="small" scroll={{y: 600}} columns={columns} dataSource={tableData}
               pagination={false} rowKey="id"/>
        <Pagination
            style={{marginTop: 16, textAlign: 'right'}}
            showSizeChanger
            showQuickJumper
            total={totalCount}
            current={currentPage}
            pageSize={pageSize}
            showTotal={totalCount => t('knowledge.totalItems', { count: totalCount })}
            onChange={(page, pageSize) => onPageChange(page, pageSize)}
        />
        <Modal
            title={isEdit ? t('account.editZendeskConfig') : t('account.addZendeskConfig')}
            open={editDialogVisible}
            onCancel={() => setEditDialogVisible(false)}
            footer={[
              <Button key="back" onClick={() => setEditDialogVisible(false)}>
                {t('common.cancel')}
              </Button>,
              <Button key="submit" type="primary" onClick={onSave}>
                {t('common.save')}
              </Button>,
            ]}
        >
          <Form form={form} ref={formRef} layout="vertical">
            <Form.Item
                name="name"
                label={t('account.name')}
                rules={[{required: true, message: t('common.inputPrompt', { title: t('account.name') })}]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="avatar"
                label={t('account.avatar')}
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e && e.fileList;
                }}
            >
              <OssAvatarUpload
                  avatar={currentAvatar}
                  updateUploadList={data => {
                    console.log('data------>', data);
                    if (data.length > 0 && data[0].url) {
                      form.setFieldValue('avatar', data[0].url);
                      setCurrentAvatar(data[0]);
                    }
                  }}
              />
            </Form.Item>
            <Form.Item
                name="description"
                label={t('account.description')}
                rules={[{required: true, message: t('agent.descPlaceholder')}]}
            >
              <Input.TextArea/>
            </Form.Item>
            <Form.Item
                name="agent_id"
                label={t('common.agent')}
                rules={[{required: true, message: t('common.selectAgent')}]}
            >
              <Select placeholder={ t('common.selectAgent') }>
                {agentList.map((agent: any) => (
                    <Option key={agent.agent_id} value={agent.agent_id}>
                      {agent.name}
                    </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
                name="webhook_url"
                label={t('account.webhookUrl')}
                rules={[{required: true, message: t('common.inputPrompt', { title: t('account.webhookUrl') })}]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="app_id"
                label={t('account.appId')}
                rules={[{required: true, message: t('account.appIdPlaceholder')}]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="api_key"
                label={t('account.apiKey')}
                rules={[{required: true, message: t('account.apiKeyPlaceholder')}]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                name="api_secret"
                label={t('account.apiSecret')}
                rules={[{required: true, message: t('account.apiSecretPlaceholder')}]}
            >
              <Input.Password/>
            </Form.Item>
          </Form>
        </Modal>
      </div>
  );
};

export default Zendesk;
