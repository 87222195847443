import React, { CSSProperties } from 'react';
import { Select } from 'antd';
import { useLanguage } from '@/contexts/languageContext';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface LanguageSwitcherProps {
  style?: CSSProperties;
}
const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ style }) => {
  const { currentLanguage, setLanguage, languageOptions } = useLanguage();
  const { t, i18n } = useTranslation();
  const handleChange = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };

  const defaultStyle: CSSProperties = {
    width: 120,
    opacity: 0.7
  };

  return (
    <Select
      value={currentLanguage.key}
      style={{ ...defaultStyle, ...style }}
      onChange={handleChange}
    >
      {languageOptions.map(option => (
        <Option key={option.key} value={option.key}>{t(`${option.label}`)}</Option>
      ))}
    </Select>
  );
};

export default LanguageSwitcher;