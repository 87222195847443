import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';

const { Option } = Select;

interface ItemType {
  id: string;
  title: string;
}

interface CustomSelectProps extends Omit<SelectProps<ItemType>, 'onChange'> {
  items: ItemType[];
  value?: any;
  placeholder?: string;
  mode?: 'multiple' | 'tags' | undefined;
  onChange?: (value: ItemType) => void;
}

const CustomObjKeySelect: React.FC<CustomSelectProps> = ({
  items,
  value,
  onChange,
  placeholder,
  mode,
}) => {
  const [selectedValue, setSelectedValue] = useState<any>(value);

  useEffect(() => {
    const idArr = value && value.map((item: any) => item.id);
    setSelectedValue(idArr);
  }, [value]);

  const handleChange = (ides: any) => {
    const selectedItem = [] as any;
    items.forEach((item: any) => {
      if (ides.includes(item.id)) {
        selectedItem.push(item);
      }
    });
    setSelectedValue(selectedItem);
    if (onChange && selectedItem) {
      onChange(selectedItem);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      mode={mode}
      allowClear
      placeholder={placeholder}
      value={selectedValue}
      onChange={id => handleChange(id as string)} // AntD's Select returns the value as string | number
    >
      {items.map((item: any) => (
        <Option key={item.id} value={item.id}>
          {item.title}
        </Option>
      ))}
    </Select>
  );
};

export default CustomObjKeySelect;
