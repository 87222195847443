import React from 'react';
import { Button, Input, Tag } from 'antd';
import { CloseCircleOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import './index.scss';

interface DebugLogProps {
  log: {
    path: Array<{
      type: string;
      name: string;
      debug: {
        name?: string;
        error?: string;
        content?: string;
        response?: string;
        add_info?: {
          model?: string;
          prompt_tokens?: number;
          completion_tokens?: number;
          total_tokens?: number;
          billing?: string;
        };
        query_log?: {
          content?: string;
          messages?: string;
          response?: string;
          rag_log?: {
            question?: string;
            prompt?: string;
          };
        };
      };
    }>;
    log: {
      strategy_type: string;
      action: string;
      skill_num?: string;
      keyboard_scene?: string;
    };
  };
  debugPrompt: (item: any) => void;
}

const FlowLog: React.FC<DebugLogProps> = ({ log, debugPrompt }) => {
  const filterCateName = (cate: string) => {
    const catMap: { [key: string]: string } = {
      '1': 'LLM回答',
      '3': '固定话术',
      '4': '润色回复',
      '5': '人工服务',
      '6': '按键服务',
      '7': '挂机服务',
      '8': '知识库回答',
      '11': '网络搜索问答',
    };
    return catMap[cate] || '';
  };

  const nodeIconMap: { [key: string]: { iconfont: string; bgColor: string } } = {
    reply: { iconfont: 'huifujiedian', bgColor: '#1972F5' },
    variable: { iconfont: 'fuzhijiedian', bgColor: '#67C23A' },
    func: { iconfont: 'hanshujiedian', bgColor: '#E6A23C' },
    condition: { iconfont: 'tiaojianpanduan', bgColor: '#1094A3' },
    llm: { iconfont: 'LLMjiedian', bgColor: '#6F25B8' },
    llmtask: { iconfont: 'LLMrenwuchuli', bgColor: '#3075DE' },
    end: { iconfont: 'yindaojieshu', bgColor: '#F56C6C' },
    next: { iconfont: 'xiayihuanjie', bgColor: '#FACA0A' },
  };

  const handleVar = (data: any) => {
    let str = '';
    Object.keys(data).forEach(item => {
      str += `“${item}”: “${data[item]}” 、`;
    });
    return (str = str.substr(0, str.length - 1));
  };

  const handleFunc = (data: any) => {
    let str = '';
    Object.keys(data).forEach(item => {
      str += `${item} = ${data[item]} 、`;
    });
    return (str = str.substr(0, str.length - 1));
  };

  const handleDebugPrompt = (item: any) => {
    const debug = item.debug;
    const obj = {
      add_info: debug.add_info,
      content: debug.content,
      response: debug.response,
      tool_llm: debug.tool_llm,
      title: 'flow_log',
      messages: item.debug ? item.debug.messages : undefined,
    };
    debugPrompt({ ...obj, ...debug.query_log });
  };

  return (
    <div className="flow-wrap">
      {log.path.map((item, index) => (
        <div key={index}>
          {!['start', 'end', 'next'].includes(item.type) && (
            <div className="flow-item">
              <div className="flow-name">
                <span
                  className={`iconfont ${nodeIconMap[item.type].iconfont}`}
                  style={{ backgroundColor: nodeIconMap[item.type].bgColor }}
                />
                <span className="name">{item.name}</span>
              </div>
              {['llm', 'reply', 'llmtask'].includes(item.type) && (
                <div>
                  {item.type === 'llm' && (
                    <>
                      <div className="text-black mg-bot8">提示词</div>
                      <Input.TextArea value={item.debug.content} rows={2} disabled />
                    </>
                  )}
                  {item.type === 'reply' && (
                    <>
                      <Tag style={{ marginRight: 10 }}>
                        {log.log.strategy_type === 'action' ? 'action' : '回复'}
                      </Tag>
                      <Tag>{filterCateName(log.log.action)}</Tag>
                    </>
                  )}
                  {((['llm', 'reply'].includes(item.type) &&
                    ['1', '4', '11'].includes(log.log.action)) ||
                    ['llmtask'].includes(item.type)) && (
                    <>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">执行分支</div>
                        </div>
                        <div className="content">{item.debug.name || item.debug.error}</div>
                      </div>
                      <div className="sub-title">
                        <label style={{ fontWeight: 500 }}>请求</label>
                        <Button type="link" onClick={() => handleDebugPrompt(item)}>
                          调试
                        </Button>
                      </div>
                      {(item.debug.content || item.debug.query_log?.content) && (
                        <Input.TextArea
                          value={item.debug.content || item.debug.query_log?.content}
                          rows={2}
                          disabled
                        />
                      )}
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">messages</div>
                        </div>
                        <div className="content">
                          {' '}
                          {Array.isArray(item.debug.query_log?.messages)
                            ? JSON.stringify(item.debug.query_log?.messages)
                            : item.debug.query_log?.messages}
                        </div>
                      </div>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">响应</div>
                        </div>
                        <div className="content">
                          {item.debug.response || item.debug.query_log?.response}
                        </div>
                      </div>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">模型</div>
                        </div>
                        <div className="font-style">{item.debug.add_info?.model}</div>
                      </div>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">token</div>
                        </div>
                        <div className="font-style">
                          请求：{item.debug.add_info?.prompt_tokens} 响应：
                          {item.debug.add_info?.completion_tokens} 总数：
                          {item.debug.add_info?.total_tokens}
                        </div>
                      </div>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">消费金额</div>
                        </div>
                        <div className="font-style">{item.debug.add_info?.billing}</div>
                      </div>
                    </>
                  )}
                  {item.type === 'reply' && log.log.action === '3' && (
                    <span className="gray-box" style={{ marginTop: 10 }}>
                      <span className="flex-1">{item.debug.query_log?.content}</span>
                    </span>
                  )}
                  {item.type === 'reply' && log.log.action === '5' && (
                    <span className="gray-box" style={{ marginTop: 10 }}>
                      <span className="font-blod">优先技能组：</span>
                      <span className="flex-1">{log.log.skill_num}</span>
                    </span>
                  )}
                  {item.type === 'reply' && log.log.action === '6' && (
                    <span className="gray-box" style={{ marginTop: 10 }}>
                      <span className="font-blod">按键场景：</span>
                      <span className="flex-1">{log.log.keyboard_scene}</span>
                    </span>
                  )}
                  {item.type === 'reply' && log.log.action === '8' && (
                    <>
                      <div className="text-black mg-bot8">最终问题</div>
                      {item.debug.query_log?.rag_log?.question && (
                        <span className="gray-box" style={{ marginTop: 10 }}>
                          <span className="flex-1">{item.debug.query_log?.rag_log?.question}</span>
                        </span>
                      )}
                      <div className="text-black mg-bot8">prompt</div>
                      {item.debug.query_log?.rag_log?.prompt && (
                        <Input.TextArea
                          value={item.debug.query_log.rag_log.prompt}
                          rows={15}
                          disabled
                        />
                      )}
                    </>
                  )}
                </div>
              )}
              {item.type === 'variable' && (
                <span className="gray-box">
                  <span className="font-blod">赋值结果：</span>
                  <span className="flex-1">{handleVar(item.debug)}</span>
                </span>
              )}
              {item.type === 'condition' && (
                <span className="gray-box">
                  <span className="font-blod">匹配到条件：</span>
                  <span className="flex-1">{item.debug.name || item.debug.error}</span>
                </span>
              )}
              {item.type === 'func' && (
                <span className="gray-box">
                  <span className="font-blod">返回参数：</span>
                  <span className="flex-1">{handleFunc(item.debug.response)}</span>
                </span>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FlowLog;
