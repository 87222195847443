import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, message, Drawer, Form } from 'antd';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  Background,
  BackgroundVariant,
  useReactFlow,
  useEdgesState,
  Connection,
  Edge,
  Position,
} from 'reactflow';
import 'reactflow/dist/style.css';
import Sidebar from './SideBar';
import FuncForm from './flowForm/FuncForm';
import LLMForm from './flowForm/LLMForm';
import LLMTaskForm from './flowForm/LLMTaskForm';
import ReplyForm from './flowForm/ReplyForm';
import VariableForm from './flowForm/VariableForm';
import ConditionForm from './flowForm/ConditionForm';
import { generateUUID, getAppId } from '@/utils/common';
import { queryFunctionList, getAiEntity } from '@/api/agent';
import { queryKb } from '@/api/km';
import { saveFlow, upFlow } from '@/api/agent';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { useAppSelector } from '@/store/hooks';
import './flow.scss';
import useDragAndDrop from './useDnD';
import CustomNode from './CustomNode';
import MoreOutputNode from './MoreOutputNode';
const nodeTypes = {
  custom: CustomNode, // 注册自定义节点
  moreOutput: MoreOutputNode,
};
interface NodeData {
  form: any;
  name?: string;
  flowType: string;
  [key: string]: any;
}
interface Props {
  propsFlowData: any;
  pid: any;
  isSenesFlow: boolean;
  emitGoBack: () => void;
}
const Flow: React.FC<Props> = ({ propsFlowData, pid, isSenesFlow, emitGoBack }) => {
  const [form] = Form.useForm();
  const { onDragOver, onDrop, onDragLeave } = useDragAndDrop();
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([
    {
      id: `start_${generateUUID()}`,
      selectable: false,
      data: {
        label: '开始',
        flowType: 'start',
      },
      position: { x: 50, y: 50 },
      type: 'custom',
      sourcePosition: Position.Right, // 确保设置在右边
    },
  ]);
  const { getNodes, getEdges } = useReactFlow();
  const currentAgent = useAppSelector(selectCurrentAgent);
  const interTimer = useRef<any>(null);
  const showIndex = useRef<number>(0);
  const formRef = useRef<any>(null);
  const [showTip, setShowTip] = useState(false);
  const [kbList, setKbList] = useState([]);
  const [funcList, setFuncList] = useState([]);
  const [aiEntityList, setAiEntityList] = useState([]);
  const [flowData, setFlowData] = useState<any>({});
  const flowDataRef = useRef(flowData); // 新增 useRef
  const [direction, setDirection] = useState(true);
  const [currentNode, setCurrentNode] = useState<any>({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  useEffect(() => {
    const data = propsFlowData;
    setFlowData((prevFlowData: any) => ({
      ...prevFlowData,
      name: data.name,
      uid: data.uid,
      edit_flow_datetime: data.edit_flow_datetime,
      edit_flow_json: data.edit_flow_json,
      up_flow_datetime: data.up_flow_datetime,
      up_flow_json: data.up_flow_json,
    }));
    if (data.edit_flow_json) {
      const jsonData = JSON.parse(data.edit_flow_json);
      setNodes(jsonData.nodes);
      setEdges(jsonData.edges);
    }
    setShowTip(data.edit_flow_datetime !== data.up_flow_datetime);
  }, [propsFlowData]);
  useEffect(() => {
    flowDataRef.current = flowData; // 每次更新 flowData 时同步更新 flowDataRef
  }, [flowData]);
  const handelFormRule = (data: any, flowType: string) => {
    const form = data.form;
    let message = '';
    if (flowType === 'func' && !form.function_name) {
      message += `<span>请选择【${form.name}】的函数服务</span></br>`;
    } else if (flowType === 'llm') {
      if (!form.tool_llm) {
        message += `<span>请选择【${form.name}】的模型</span></br>`;
      }
      if (!form.llm_prompt) {
        message += `<span>请填写【${form.name}】的提示词</span></br>`;
      }
    } else if (
      flowType === 'variable' &&
      ((form && form.length <= 0) || (form.form && form.form.length <= 0))
    ) {
      message += `<span>【${data.label}】中没有变量赋值</span></br>`;
    } else if (flowType === 'reply') {
      if (form.cate === '1' && !form.llm_prompt) {
        message += `<span>请填写【${form.name}】的回答提示词</span></br>`;
      } else if (form.cate === '3' && !form.direct_answer) {
        message += `<span>请填写【${form.name}】的话术内容</span></br>`;
      } else if (form.cate === '4' && !form.polish_prompt) {
        message += `<span>请填写【${form.name}】的润色内容</span></br>`;
      }
    } else if (flowType === 'condition' && form) {
      if (form.value.length > 0) {
        form.value.forEach((item: any) => {
          if (item.rule && item.rule.cond_group.length <= 0 && item.rule_type === 'custom') {
            message += `<span>【${item.name}】中没有自定义条件</span></br>`;
          }
        });
      }
    }
    return message;
  };
  const renderMessage = (messageStr: string) => {
    return <span dangerouslySetInnerHTML={{ __html: messageStr }} />;
  };
  const handleFlowData = (type: string) => {
    const allData = {} as any;
    const lastData = [] as any;
    let upFlag = true;
    let messageStr = '';
    const nodes = getNodes();
    const edges = getEdges();
    nodes.forEach((item: any) => {
      item.inputs = [];
      item.outputs = [];
      item.label = item.data.label;
      item.flowType = item.data.flowType;
      let detaultId = '';
      if (item.flowType === 'condition') {
        detaultId = `condition_${generateUUID()}`;
      } else if (item.flowType === 'llmtask') {
        detaultId = `llmtask_${generateUUID()}`;
      }
      const sourceObj = edges.filter((edge: any) => edge.source === item.id);
      const targetObj = edges.filter((edge: any) => edge.target === item.id);
      if (!['end', 'next'].includes(item.data.flowType)) {
        if (sourceObj.length > 0) {
          if (['condition', 'llmtask'].includes(item.data.flowType)) {
            // 条件节点的key对应的是下一个分支的数据
            sourceObj.forEach(sourceItem => {
              if (sourceItem.sourceHandle === `${item.data.flowType}_defalut`) {
                item.outputs.push({
                  key: detaultId,
                  targets: [{ id: sourceItem.target }],
                });
              } else {
                const dataList =
                  item.data.flowType === 'condition'
                    ? item.data.form?.value || []
                    : item.data.form?.chain_check || [];
                if (dataList.length > 0) {
                  const outputsFilter = dataList.filter(
                    (formValue: any) =>
                      sourceItem.sourceHandle && sourceItem.sourceHandle.indexOf(formValue.id) >= 0
                  );
                  if (outputsFilter.length > 0) {
                    item.outputs.push({
                      key: outputsFilter[0].id || '',
                      targets: [{ id: sourceItem.target }],
                    });
                  }
                }
              }
            });
          } else {
            item.outputs.push({
              key: 'next',
              targets: [{ id: sourceObj[0].target }],
            });
          }
        }
      }
      if (!['start', 'end', 'next'].includes(item.data.flowType)) {
        if (targetObj.length > 0) {
          if (['condition', 'llmtask'].includes(item.data.flowType)) {
            if (sourceObj.length > 0) {
              const formArr = [] as any;
              const dataList =
                item.data.flowType === 'condition'
                  ? item.data.form?.value || []
                  : item.data.form?.chain_check || [];
              dataList.forEach((formItem: any) => {
                formArr.push({
                  ...{ value: formItem },
                  ...{ key: formItem.id },
                });
              });
              // 默认数据，不需要客户编辑，key传递
              formArr.push({
                value: {
                  name: '默认',
                  rule_type: 'default',
                  rule: {},
                },
                key: detaultId,
              });
              if (item.data.flowType === 'condition') {
                item.inputs.push({
                  connected: true,
                  value: formArr,
                });
              } else {
                item.inputs.push({
                  connected: true,
                  value: { ...item.data.form, chain_check: formArr },
                });
              }
            }
          } else if (item.data.flowType === 'variable') {
            item.inputs.push({
              connected: true,
              name: item.data.form.name,
              value: item.data.form.form || [],
            });
          } else {
            item.inputs.push({ connected: true, value: item.data.form || {} });
          }
        }
      }
      if (targetObj.length <= 0 && sourceObj.length <= 0) {
        upFlag = false;
      }
      lastData.push(item);
      messageStr += handelFormRule(item.data, item.data.flowType);
    });
    allData.nodes = lastData;
    allData.edges = edges;
    if (type === 'up' && !upFlag) {
      message.error('流程中存在未连线的节点，请检查');
      return false;
    }
    if (type === 'up' && messageStr) {
      message.error({
        content: renderMessage(messageStr),
        duration: 3,
      });
      return false;
    }
    return allData;
  };
  const autoSaveFlow = () => {
    const allData = handleFlowData('save');
    const actionData = {
      uid: flowDataRef.current.uid,
      agent_id: currentAgent.currentAgent.agent_id,
      pid: !isSenesFlow ? pid : '',
      edit_flow_json: JSON.stringify(allData),
    };
    saveFlow(actionData).then((res: any) => {
      if (res.code === 200 && res.data) {
        setFlowData((prevFlowData: any) => ({
          ...prevFlowData,
          edit_flow_datetime: res.data.edit_flow_datetime,
          edit_flow_json: res.data.edit_flow_json,
          up_flow_datetime: res.data.up_flow_datetime,
          up_flow_json: res.data.up_flow_json,
        }));
        setShowTip(res.data.edit_flow_datetime !== res.data.up_flow_datetime);
      }
    });
  };
  const releaseFlow = () => {
    const allData = handleFlowData('up');
    console.log(allData);
    if (!allData) {
      return false;
    }
    const actionData = {
      uid: flowDataRef.current.uid,
      agent_id: currentAgent.currentAgent.agent_id,
      pid: !isSenesFlow ? pid : '',
      up_flow_json: JSON.stringify(allData), // 使用 flatted 替代 JSON.stringify
    };
    upFlow(actionData)
      .then((res: any) => {
        if (res.code === 200 && res.data) {
          setFlowData((prevFlowData: any) => ({
            ...prevFlowData,
            edit_flow_datetime: res.data.edit_flow_datetime,
            edit_flow_json: res.data.edit_flow_json,
            up_flow_datetime: res.data.up_flow_datetime,
            up_flow_json: res.data.up_flow_json,
          }));
          setShowTip(false);
          message.success('发布成功');
        } else {
          message.error(res.message || '发布失败');
        }
      })
      .catch(() => {
        message.error('发布失败');
      });
  };
  const closeTip = () => {
    setShowTip(false);
    showIndex.current++;
  };
  const goBack = () => {
    autoSaveFlow();
    emitGoBack();
  };
  const editNode = (e: any, data: any) => {
    setCurrentNode(data || {});
    if (!['end', 'next', 'start'].includes(data.flowType)) {
      setDrawerVisible(true);
    }
  };
  const cancel = () => {
    setDrawerVisible(false);
  };
  const updateCurrentNodeOrEdge = (values: any) => {
    const flowType = currentNode.data.flowType;
    const propsData: NodeData = {
      label: flowType === 'condition' ? '条件判断' : values.name,
      iconfont: currentNode.data.iconfont,
      bgcolor: currentNode.data.bgcolor,
      type: currentNode.data.type,
      flowType: flowType,
      form: values,
    };
    setNodes(nds =>
      nds.map(node => {
        if (node.id === currentNode.id) {
          node.data = {
            ...node.data,
            ...propsData,
            label: values.name,
          };
        }
        return node;
      })
    );
    if (flowType === 'condition') {
      // 更新连线
      // const newEdges = edges.map(edge => {
      //   if (edge.source === currentNode.id) {
      //     // 重新设置目标节点连接
      //     const sortedOutputNodes = values.value.map((item: any, index: number) => ({
      //       id: item.id,
      //       index,
      //     }));
      //     edge.sourceHandle = sortedOutputNodes.find((item: any) => `${item.id}_custom` === edge.sourceHandle)?.index.toString();
      //   }
      //   return edge;
      // });
      //     // 保存更新后的边
      //     setEdges(newEdges);
      //   console.log('后面补上排序之后，连的链接也重置')
    }
    setDrawerVisible(false);
  };
  const saveNodeForm = async () => {
    try {
      const values = await formRef.current?.validate();
      updateCurrentNodeOrEdge(values);
    } catch (error) {
      console.error('验证失败:', error);
    }
  };
  // 处理连接事件
  const onConnect = (params: Edge | Connection) => {
    // 检查是否允许连接
    const isConnectionValid = validateConnection(params);
    if (isConnectionValid) {
      setEdges(eds => addEdge(params, eds));
    } else {
      console.log('连接无效！');
    }
  };
  // 判断是否可以连接
  const validateConnection = (params: Edge | Connection) => {
    const source = (params.source && params.source.split('_')[0]) || '';
    const target = (params.target && params.target.split('_')[0]) || '';
    let flag = true;
    if (source === 'start' && ['next', 'end'].includes(target)) {
      flag = false;
    } else if (
      source === 'reply' &&
      ['reply', 'variable', 'func', 'condition', 'llmtask'].includes(target)
    ) {
      flag = false;
    } else if (source === 'variable' && ['variable', 'next', 'end'].includes(target)) {
      flag = false;
    } else if (source === 'func' && ['func', 'end'].includes(target)) {
      flag = false;
    } else if (source === 'condition' && ['condition', 'end'].includes(target)) {
      flag = false;
    } else if (source === 'llm' && ['llm', 'next', 'end'].includes(target)) {
      flag = false;
    } else if (source === 'llmtask' && ['llmtask', 'condition', 'end'].includes(target)) {
      flag = false;
    }
    if (!flag) {
      message.error('不允许连接此节点');
    }
    return flag;
  };
  // 查询知识库列表
  const queryKbList = async () => {
    await queryKb({
      page: 1,
      per_page: 99999,
      title: '',
    }).then((res: any) => {
      if (res.code === 200) {
        setKbList(res.data?.list || []);
      }
    });
  };
  // 查询函数列表
  const queryFuncList = () => {
    queryFunctionList({
      page: '1',
      size: '10000',
      otherName: '',
      agent_id: currentAgent.currentAgent.agent_id,
      bot_id: getAppId(),
    }).then((res: any) => {
      if (res.code === 200) {
        setFuncList(res.data?.result || []);
      }
    });
  };
  const queryAiEntity = () => {
    getAiEntity({
      page: 1,
      size: 10000,
      agent_id: currentAgent.currentAgent.agent_id,
    }).then((res: any) => {
      if (res.code === 200) {
        setAiEntityList(res.data?.result || []);
      }
    });
  };
  useEffect(() => {
    queryKbList();
    queryFuncList();
    queryAiEntity();
    if (interTimer.current === null) {
      interTimer.current = setInterval(() => {
        if (flowDataRef.current.uid) {
          // setInterval取不到flowData的新数据，所以要去ref里面的
          autoSaveFlow();
        }
      }, 60000);
    }
    return () => {
      clearInterval(interTimer.current);
    };
  }, []);
  // 自定义头部组件
  const CustomDrawerHeader = ({ title, iconfont, bgcolor, tip, onSave, onCancel }: any) => {
    return (
      <div className="flex justify-between items-center">
        <div className="title-top">
          <span className={`iconfont ${iconfont}`} style={{ backgroundColor: bgcolor }} />
          <span className="title-text">{title}</span>
          <p>{tip || ''}</p>
        </div>
        <div>
          <Button style={{ marginRight: 12 }} onClick={onCancel}>
            取消
          </Button>
          <Button type="primary" onClick={onSave}>
            保存
          </Button>
        </div>
      </div>
    );
  };
  return (
    <div className="bpmn-box-wrap">
      <div className="top-box">
        <div className="top-title">
          <span className="iconfont fanhui" onClick={goBack} />
          <span className="agent-name" onClick={goBack}>
            {flowData.name}
          </span>
          <Button type="primary" className="save-btn" onClick={releaseFlow}>
            发布
          </Button>
        </div>
        <div className="status-box">
          <span className="status-span mg-r-8">
            <span
              className={`iconfont ${flowData.up_flow_datetime ? 'wancheng-mian' : 'quxiao-mian'}`}
            />
            {flowData.up_flow_datetime ? '已发布' : '未发布'}
          </span>
          <span className="mg-r-8 gray-bg">最近发布时间：{flowData.up_flow_datetime || ''}</span>
          <span className="mg-r-8 gray-bg">
            自动保存：
            {(flowData.edit_flow_datetime && flowData.edit_flow_datetime.split(' ')[1]) || ''}
          </span>
        </div>
      </div>
      <div className="dndflow" onDrop={onDrop} ref={reactFlowWrapper}>
        <Sidebar direction={direction} />
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          edges={edges}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeDoubleClick={editNode}
          onConnect={onConnect}
          nodeOrigin={[0.5, 0]}
        >
          <span
            className={`direction-btn iconfont ${direction ? 'fangxiang-zuo-xian' : 'fangxiang-you-xian'}`}
            onClick={() => setDirection(!direction)}
          />
          <div
            className="flow-tip"
            style={{ display: showTip && showIndex.current < 1 ? 'block' : 'none' }}
          >
            {/* 提示框只提示一次，点击X之后不再提醒 */}
            <span className="iconfont jinggao" />
            您已经重新编辑了流程。在其重新发布之前，Agent使用的仍然是之前发布的版本。
            <span className="iconfont quxiao-mian" onClick={closeTip}></span>
          </div>
          <Background color="#9f9f9f" size={4} variant={BackgroundVariant.Dots} />
        </ReactFlow>
      </div>
      <Drawer
        open={drawerVisible}
        onClose={cancel}
        placement="right"
        destroyOnClose
        closeIcon={false}
        className="custom-drawer"
        width={currentNode.data?.flowType == 'condition' ? 900 : 700}
        title={
          <CustomDrawerHeader
            title={currentNode.data?.label}
            iconfont={currentNode.data?.iconfont}
            bgcolor={currentNode.data?.bgcolor}
            tip={currentNode.data?.tip}
            onSave={saveNodeForm}
            onCancel={cancel}
          />
        }
      >
        {currentNode.data?.flowType === 'func' && (
          <FuncForm
            ref={formRef}
            form={currentNode.data?.form}
            id={currentNode.id}
            funcList={funcList}
          />
        )}
        {currentNode.data?.flowType === 'llm' && (
          <LLMForm ref={formRef} form={currentNode.data?.form} id={currentNode.id} />
        )}
        {currentNode.data?.flowType === 'llmtask' && (
          <LLMTaskForm ref={formRef} form={currentNode.data?.form} id={currentNode.id} />
        )}
        {currentNode.data?.flowType === 'reply' && (
          <ReplyForm
            ref={formRef}
            form={currentNode.data?.form}
            id={currentNode.id}
            kbList={kbList}
          />
        )}
        {currentNode.data?.flowType === 'variable' && (
          <VariableForm
            ref={formRef}
            form={currentNode.data?.form}
            id={currentNode.id}
            aiEntityList={aiEntityList}
          />
        )}
        {currentNode.data?.flowType === 'condition' && (
          <ConditionForm ref={formRef} form={currentNode.data?.form} id={currentNode.id} />
        )}
      </Drawer>
    </div>
  );
};
function FlowWithProvider(props: any) {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  );
}
export default FlowWithProvider;
