import { useAppSelector } from '@/store/hooks';
import { selectToken } from '@/store/modules/user';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const RequireAuth = () => {
  const token = useAppSelector(selectToken);

  if (!token) {
    // 如果没有token，重定向到登录页
    return <Navigate to="/login" />;
  }

  // 否则，允许访问
  return <Outlet />;
};

export default RequireAuth;
