import React, { useState, useEffect, useImperativeHandle, forwardRef, ChangeEvent } from 'react';
import { Form, Input, Select, Button, Modal, InputNumber, Checkbox, message } from 'antd';
import { t } from 'i18next';
import Panel from '@/components/Panel';
import { selectModelEnum } from '@/store/modules/agentTemplate';
import { LLM_TASK_PROMPT } from '@/constant/agent';
import ChainCheck from './ChainCheck';
const { TextArea } = Input;
const { Option } = Select;

interface LLMFormProps {
  form: Record<string, any>;
  id: string;
}

interface FormNode {
  name: string;
  llm_prompt: string;
  tool_llm: string;
  chat_turns: number;
  params: ParamItem[];
  chain_check: any;
}

interface ParamItem {
  name: string;
  desc: string;
  example: string;
  required: boolean;
}

interface Rule {
  required: boolean;
  message: string;
  trigger?: string;
}

const LLMTaskForm = forwardRef<any, LLMFormProps>(({ form, id }, ref) => {
  const [formRef] = Form.useForm();
  const [formNode, setFormNode] = useState<FormNode>({
    name: t('flowSideBar.llmtaskNode'),
    llm_prompt: LLM_TASK_PROMPT,
    tool_llm: 'gpt4o',
    chat_turns: 0,
    params: [],
    chain_check: [],
  });
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [paramsVisible, setParamsVisible] = useState<boolean>(false);
  const [auxiliary, setAuxiliary] = useState<boolean>(false);
  const [bigAuxiliary, setBigAuxiliary] = useState<boolean>(false);
  const [dynamicItems, setDynamicItems] = useState<ParamItem[]>(formNode.params); // 用于临时存储，参数信息新增之后，不点击保存，点击取消的情况
  const modelEnum = selectModelEnum();

  useEffect(() => {
    formRef.setFieldsValue(form);
    setFormNode({
      name: form.name,
      llm_prompt: form.llm_prompt || LLM_TASK_PROMPT,
      tool_llm: form.tool_llm || 'gpt4o',
      chat_turns: form.chat_turns || 0,
      params: form.params || [],
      chain_check: form.chain_check || [],
    });
    setDynamicItems(form.params || []);
  }, [form]);

  const rules: { [key: string]: Rule[] } = {
    name: [{ required: true, message: t('flowForm.llmForm.inputLLMName'), trigger: 'blur' }],
    llm_prompt: [{ required: true, message: t('flowForm.llmForm.inputLLMPrompt'), trigger: 'blur' }],
    tool_llm: [{ required: true, message: t('flowForm.llmForm.selectLLMModel'), trigger: 'change' }],
  };

  // 动态表单项相关逻辑
  const addFormItem = () => {
    const newItem: ParamItem = {
      name: '',
      desc: '',
      example: '',
      required: true,
    };
    setDynamicItems([...dynamicItems, newItem]);
    // setFormNode(prev => ({ ...prev, params: [...prev.params, newItem] }));
  };

  const updateFormItem = (e: ChangeEvent<HTMLInputElement>, index: number, key: string) => {
    if (key === 'name') {
      const value = e.target.value;
      const namePattern = /^[a-zA-Z0-9][a-zA-Z0-9_]*$/; // 只允许字母、数字、下划线，且不能以下划线开头
      if (value && !namePattern.test(value)) {
        message.error(t('flowForm.llmForm.paramNameTip'));
        return;
      }
    }
    const newItems = [...dynamicItems] as any;
    newItems[index][key] = e.target.value;
    setDynamicItems(newItems);
  };

  const removeFormItem = (index: number) => {
    const newItems = [...dynamicItems];
    newItems.splice(index, 1);
    setDynamicItems(newItems);
    // setFormNode(prev => ({ ...prev, params: newItems }));
  };

  const validate = async () => {
    try {
      const values = await formRef.validateFields();
      if (values) {
        debugger;
        const obj = { ...formNode, ...values };
        console.log(obj);
        return obj;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const resetFields = () => {
    formRef.resetFields();
  };

  const getForm = () => {
    return formRef.getFieldsValue();
  };

  const handleAuxiliary = (type: string) => {
    if (type) {
      setBigAuxiliary(true);
      setAuxiliary(false);
    } else {
      setAuxiliary(true);
      setBigAuxiliary(false);
    }
  };
  const handleSave = () => {
    const isDuplicate = dynamicItems.some(
      (rule, index, array) =>
        !rule.name || !rule.desc || array.findIndex(item => item.name === rule.name) !== index
    );
    if (isDuplicate) {
      message.error(t('flowForm.llmForm.paramNameDescTip'));
      return;
    }
    setFormNode(prev => ({ ...prev, params: dynamicItems }));
    setParamsVisible(false);
  };

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <>
      <Form
        form={formRef}
        className="px-5 pt-5 pb-1 bg-white rounded"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={formNode}
      >
        <Form.Item label={t('flowForm.llmForm.nodeName')} name="name" rules={rules.name}>
          <Input
            value={formNode.name}
            onChange={e => setFormNode(prev => ({ ...prev, name: e.target.value }))}
            autoComplete="off"
            maxLength={50}
            showCount
            placeholder={t('common.input')}
          />
        </Form.Item>

        <Form.Item
          className="custom-label"
          label={
            <div className="label-div">
              <span>{t('flowForm.llmForm.prompt')}</span>
              <span className="label-btn">
                <span className="iconfont quanping" onClick={() => setDialogVisible(true)}>
                  {t('common.fullScreen')}
                </span>
                <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                  {t('common.auxiliary')}
                </span>
                <span className="iconfont canshuxinxi" onClick={() => setParamsVisible(true)}>
                  {t('common.parameterInfo')}
                </span>
              </span>
            </div>
          }
          name="llm_prompt"
          rules={rules.llm_prompt}
        >
          <TextArea
            value={formNode.llm_prompt}
            onChange={e => setFormNode(prev => ({ ...prev, llm_prompt: e.target.value }))}
            rows={10}
            maxLength={5000}
            showCount
            placeholder={t('flowForm.llmForm.inputCallword')}
          />
        </Form.Item>

        <Form.Item label={t('flowForm.llmForm.model')} name="tool_llm" rules={rules.tool_llm}>
          <Select
            value={formNode.tool_llm}
            onChange={value => setFormNode(prev => ({ ...prev, tool_llm: value }))}
            placeholder={t('common.select')}
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            {modelEnum.map((item: string, index: number) => (
              <Option value={item} key={index}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('flowForm.llmForm.dialogMemory')} name="chat_turns">
          <InputNumber min={0} max={50} />
        </Form.Item>
      </Form>
      <ChainCheck
        chain_check={formNode.chain_check}
        changeData={(val: any) => {
          setFormNode(prev => ({ ...prev, chain_check: val }));
        }}
      />
      {auxiliary && <Panel className="llm-panel" onClosePanel={() => setAuxiliary(false)} />}
      <Modal
        open={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        footer={null}
        width="100%"
        className="full-screen-modal"
        style={{ top: 0 }}
      >
        <div className="modal-header mb-3">
          <span>{t('flowForm.llmForm.prompt')}</span>
          <span
            className="iconfont fuzhuxinxi auxiliary-icon"
            onClick={() => handleAuxiliary('big')}
          >
            {t('common.auxiliary')}
          </span>
        </div>
        <TextArea
          value={formNode.llm_prompt}
          onChange={e => {
            formRef.setFieldValue('llm_prompt', e.target.value);
            setFormNode(prev => ({ ...prev, llm_prompt: e.target.value }));
          }}
          rows={22}
          maxLength={5000}
          showCount
          placeholder={t('flowForm.llmForm.inputCallword')}
        />
        {bigAuxiliary && (
          <Panel className="big-content-panel" onClosePanel={() => setBigAuxiliary(false)} />
        )}
        <div className="modal-footer text-right mt-6">
          <Button type="primary" onClick={() => setDialogVisible(false)}>
            {t('common.close')}
          </Button>
        </div>
      </Modal>
      <Modal
        open={paramsVisible}
        onCancel={() => setParamsVisible(false)}
        title={
          <>
            <span className="llm-modal-title">{t('common.parameterInfo')}</span>
            {/* <span className="llm-modal-tip">{`若提示词不使用{{parameters_list}}{{parameters_complete}}参数占位，参数信息可以为空`}</span> */}
            <span className="llm-modal-tip">{t('flowForm.llmForm.paramTip')}</span>
          </>
        }
        width={800}
        footer={[
          <Button key="back" onClick={() => setParamsVisible(false)}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleSave}>
            {t('common.confirm')}
          </Button>,
        ]}
      >
        <div className="llm-params-wrap">
          <div className="flex llm-params-title">
            <span className="flex-1">{t('common.name')}</span>
            <span className="flex-1">{t('common.description')}</span>
            <span className="flex-1">{t('common.formatExample')}</span>
            <span>{t('common.isRequired')}</span>
          </div>
          {dynamicItems.map((item, index) => (
            <>
              <div className="flex llm-params-flex">
                <Input
                  className="flex-1"
                  value={item.name}
                  onChange={(e: any) => updateFormItem(e, index, 'name')}
                  placeholder={t('common.inputParameterName')}
                  maxLength={20}
                  showCount
                />
                <Input
                  className="flex-1"
                  value={item.desc}
                  onChange={(e: any) => updateFormItem(e, index, 'desc')}
                  placeholder={t('common.inputParameterDescription')}
                  maxLength={250}
                  showCount
                />
                <Input
                  className="flex-1"
                  value={item.example}
                  onChange={(e: any) => updateFormItem(e, index, 'example')}
                  placeholder={t('common.inputParameterFormatExample')}
                  maxLength={250}
                  showCount
                />
                <span>
                  <Checkbox
                    checked={item.required}
                    onChange={(e: any) => updateFormItem(e, index, 'required')}
                  />
                  <span
                    className="iconfont shanchuquan"
                    onClick={() => removeFormItem(index)}
                  ></span>
                </span>
              </div>
            </>
          ))}
          <div className="iconfont jiahao" onClick={addFormItem}>
            {t('flowForm.llmForm.addParam')}
          </div>
        </div>
      </Modal>
    </>
  );
});

LLMTaskForm.displayName = 'LLMTaskForm';
export default LLMTaskForm;
