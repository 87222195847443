import React, { useState, useRef } from 'react';
import { Button, Input } from 'antd';
import { CloseCircleOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import FlowLog from '@/components/FlowLog/index';
import './scss/index.scss';
import { cloneDeep } from 'lodash';
import PromptDebug from './PromptDebug';
import { produce } from 'immer';

// interface DebugInfoItem {
//   title: string;
//   showMore: boolean;
//   question?: string;
//   prompt?: string;
//   guidance_name?: string;
//   name?: string;
//   response?: string;
//   path_link?: string;
//   add_info?: {
//     prompt_tokens?: number;
//     completion_tokens?: number;
//     total_tokens?: number;
//     billing?: string;
//     model?: string;
//   };
//   content?: string;
//   messages?: string;
//   ai_slots?: string;
// }

interface DebugLogProps {
  debugInfo: any[];
  setDebugInfo: (data: any[]) => void;
  closeDebug: () => void;
}

const DebugLog: React.FC<DebugLogProps> = ({ debugInfo, setDebugInfo, closeDebug }) => {
  const getTitle = (title: string) => {
    switch (title) {
      case 'tool_prompt':
        return '角色';
      case 'scene_log':
        return '场景';
      case 'tool_map':
        return '回答策略';
      case 'rag_log':
        return '最终问题';
      case 'rag_prompt':
        return 'prompt';
      case 'guidance_log':
        return '主动引导';
      case 'flow_log':
        return '流程';
      case 'image_log':
        return '图片解析';
      case 'image_floor_log':
        return '图片兜底';
      case 'small_talk_log':
        return '寒暄机器人';
      case 'answer_log':
        return '回答内容处理';
      case 'frs_log':
        return '追粉话术';
      default:
        return title;
    }
  };
  const [currentDebug, setCurrentDebug] = useState<any>({});
  const [dialogPromptVisible, setDialogPromptVisible] = useState(false);
  const [checkDocVisible, setCheckDocVisible] = useState(false);
  const checkDocRef = useRef<any>(null);

  const debugPrompt = (item: any, title?: string) => {
    setDialogPromptVisible(true);
    // if (item.title === 'tool_map') {
    //   const obj = cloneDeep(item)
    //   debugInfo.forEach((ops: any) => {
    //     if (ops.title === 'rag_prompt') {
    //       obj.content = ops.prompt
    //     }
    //   })
    //   setCurrentDebug(obj)
    // } else {
    //   setCurrentDebug(item);
    // }
    setCurrentDebug(item);
  };

  return (
    <>
      <div className="log-container">
        <div className="debug-title">
          <Button
            icon={<CloseCircleOutlined />}
            shape="circle"
            type="text"
            className="close-btn"
            onClick={closeDebug}
          />
          调用日志
        </div>
        <div className="log-view">
          {debugInfo.map((item, index) => (
            <div className="debug-content" key={index}>
              <div className="el-form-item__label">
                <div className="pre-label before:bg-primary_blue">
                  <span className="ml-2">{getTitle(item.title)}</span>
                  {!['rag_prompt', 'guidance_log', 'rag_log', 'answer_log'].includes(
                    item.title
                  ) && (
                    <span
                      className={`iconfont direction-icon log-direction-icon ${
                        item.showMore ? 'fangxiang-shang-mian-xiao' : 'fangxiang-xia-mian-xiao'
                      }`}
                      onClick={() => {
                        console.log(item);
                        const arr = cloneDeep(debugInfo);
                        arr[index].showMore = !item.showMore;
                        console.log(arr);
                        setDebugInfo(arr);
                      }}
                    ></span>
                  )}
                  {/* { item.title === 'tool_map' && (
                    <Button className='testBtn' type="link" onClick={() => debugPrompt(item, 'tool_map')}>
                      调试
                    </Button>
                  )} */}
                </div>
              </div>
              {item.title === 'frs_log' && item.log && item.log.action === '3' ? (
                <div className="flex flex-container">
                  <div className="flex-column align-center">
                    <div className="text-black">固定话术</div>
                  </div>
                  <div className="font-style">{item.response}</div>
                </div>
              ) : item.title === 'rag_log' ? (
                <div className="rag-ques">{item.question}</div>
              ) : item.title === 'rag_prompt' ? (
                <Input.TextArea value={item.prompt} rows={8} disabled />
              ) : item.title === 'answer_log' ? (
                <>
                  {item.answer_log.map((answerItem: any, answerIndex: number) => (
                    <div key={answerIndex}>
                      {Object.entries(answerItem).map(([answerKey, answerValue]) => (
                        <div className="flex flex-container" key={answerKey}>
                          <div className="flex-column align-center">
                            <div className="text-black">{`${answerKey}`}</div>
                          </div>
                          <div className="font-style">{`${answerValue}`}</div>
                        </div>
                      ))}
                    </div>
                  ))}
                </>
              ) : item.title === 'guidance_log' ? (
                <>
                  <div className="flex flex-container">
                    <div className="flex-column align-center">
                      <div className="text-black">触发类型</div>
                    </div>
                    <div className="font-style">{item.guidance_name}</div>
                  </div>
                  {item.showMore && (
                    <>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">主动环节</div>
                        </div>
                        <div className="font-style">{item.name}</div>
                      </div>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">响应</div>
                        </div>
                        <div className="font-style">{item.response}</div>
                      </div>
                    </>
                  )}
                </>
              ) : item.title === 'flow_log' ? (
                <>
                  <div className="flex flex-container">
                    <div className="flex-column align-center">
                      <div className="text-black">运行节点</div>
                    </div>
                    <div className="font-style">{item.path_link}</div>
                  </div>
                  <div className="flex flex-container">
                    <div className="flex-column align-center">
                      <div className="text-black">响应</div>
                    </div>
                    <div className="font-style">{item.response}</div>
                  </div>
                  {item.showMore && (
                    <div>
                      <div className="text-black mb-5 mt-5">节点流转日志</div>
                      {<FlowLog log={item} debugPrompt={debugPrompt} />}
                    </div>
                  )}
                </>
              ) : item.title === 'image_log' ? (
                <>
                  <div className="flex flex-container">
                    <div className="flex-column align-center">
                      <div className="text-black">响应</div>
                    </div>
                    <div className="font-style">{item.response}</div>
                  </div>
                  {item.showMore && (
                    <>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">token</div>
                        </div>
                        <div className="font-style">
                          请求：{item.add_info?.prompt_tokens} 响应：
                          {item.add_info?.completion_tokens} 总数：{item.add_info?.total_tokens}
                        </div>
                      </div>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">消费金额</div>
                        </div>
                        <div className="font-style">{item.add_info?.billing}</div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div>
                  {item.content && (
                    <>
                      {(item.showMore ||
                        ['image_floor_log', 'tool_map', 'frs_log'].includes(item.title)) && (
                        <div className="sub-title">
                          <label style={{ fontWeight: 500 }}>请求</label>
                          <Button type="link" onClick={() => debugPrompt(item)}>
                            调试
                          </Button>
                        </div>
                      )}
                      {(item.showMore ||
                        ['image_floor_log', 'tool_map', 'frs_log'].includes(item.title)) && (
                        <Input.TextArea value={item.content} rows={8} disabled />
                      )}
                    </>
                  )}
                  {item.title === 'tool_prompt' && item.showMore && (
                    <div className="flex flex-container">
                      <div className="flex-column align-center">
                        <div className="text-black">prompt</div>
                      </div>
                      <div className="content">{item.prompt}</div>
                    </div>
                  )}
                  {item.showMore && !['small_talk_log', 'image_floor_log'].includes(item.title) && (
                    <div className="flex flex-container">
                      <div className="flex-column align-center">
                        <div className="text-black">messages</div>
                      </div>
                      <div className="content">
                        {Array.isArray(item.messages)
                          ? JSON.stringify(item.messages)
                          : item.messages}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-container">
                    <div className="flex-column align-center">
                      <div className="text-black">响应</div>
                    </div>
                    <div className="content">{item.response}</div>
                  </div>
                  {(item.showMore || item.title === 'tool_prompt') &&
                    !['small_talk_log', 'image_floor_log'].includes(item.title) && (
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">变量</div>
                        </div>
                        <Input.TextArea value={item.ai_slots} rows={5} disabled />
                      </div>
                    )}
                  {item.showMore && !['image_floor_log'].includes(item.title) && (
                    <div className="flex flex-container">
                      <div className="flex-column align-center">
                        <div className="text-black">模型</div>
                      </div>
                      <div className="font-style">{item.add_info?.model}</div>
                    </div>
                  )}
                  {item.showMore && (
                    <>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">token</div>
                        </div>
                        <div className="font-style">
                          请求：{item.add_info?.prompt_tokens} 响应：
                          {item.add_info?.completion_tokens} 总数：{item.add_info?.total_tokens}
                        </div>
                      </div>
                      <div className="flex flex-container">
                        <div className="flex-column align-center">
                          <div className="text-black">消费金额</div>
                        </div>
                        <div className="font-style">{item.add_info?.billing}</div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <PromptDebug
        debugInfo={debugInfo}
        currentDebug={currentDebug}
        setCurrentDebug={setCurrentDebug}
        dialogPromptVisible={dialogPromptVisible}
        setDialogPromptVisible={setDialogPromptVisible}
      ></PromptDebug>
    </>
  );
};

export default DebugLog;
