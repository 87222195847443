import React, { useState, useEffect} from 'react';
import {Form,message,Select,Button} from 'antd';
import { useTranslation } from 'react-i18next';
import { getCorpInfo, updateCorpInfo} from '@/api/account';
import './style.scss';

const { Option } = Select;
const AccountSystemSettingIndex: React.FC = () => {
    const [systemForm] = Form.useForm();
    const [corpInfo, setCorpInfo] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
  
    useEffect(() => {
      fetchCorpInfo();
    }, []);
    
    useEffect(() => {
        if (corpInfo && typeof corpInfo === 'object') {
            systemForm.setFieldsValue(corpInfo);
        }
    }, [corpInfo, systemForm]);


    // 获取系统信息
    const fetchCorpInfo = async () => {
      try {
        setLoading(true);
        const response = await getCorpInfo();
        console.log(response.data);
        if (response.data && typeof response.data === 'object') {
            setCorpInfo(response.data);
        } else {
            console.error(t('common.getListFailed'));
        }
      } catch (error) {
        message.error(t('common.getListFailed'));
      } finally {
        setLoading(false);
      }
    };

  
    // 更新系统信息
    const handleSubmit = async (values: any) => {
      try {
        setLoading(true);
        const response = await updateCorpInfo(values);
        console.log(response.data);
        message.success(t('common.saveSuccess'));
        fetchCorpInfo();
      } catch (error) {
        message.error(t('common.saveFailed'));
      } finally {
        setLoading(false);
      }
    };


    return (
        <>
        <div className="setting-title">
            <h2>{t('systemSetting.stytemSetting')}</h2>
        </div>
        <div className="account main-container account-group h-full overflow-y-scroll">
            <Form
                form={systemForm}
                onFinish={handleSubmit}
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 400, marginTop: 20 }}
            >
                <Form.Item name="language" label={t('systemSetting.language')} rules={[{ required: true, message: t('systemSetting.languageRequired') }]}>
                    <Select>
                        <Option value="zh">{t('systemSetting.chinese')}</Option>
                        <Option value="en">{t('systemSetting.english')}</Option>
                    </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" loading={loading} style={{ width: '30%' }}>
                        {t('common.save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    </>
  );
};

export default AccountSystemSettingIndex;
