import React, { useState, useEffect } from 'react';
import { Select, Input, message } from 'antd';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import { queryVarListByAgent } from '@/api/agent';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import './scss/VarList.scss';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { updateVarListByAgentAction } from '@/store/modules/asyncOptions';
const { Option } = Select;

interface VarItem {
  varname: string;
  description: string;
  showInput?: boolean;
}

const VarList: React.FC = () => {
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const dispatch = useAppDispatch();
  const [lastVarList, setLastVarList] = useState<VarItem[]>([]);
  const [filterName, setFilterName] = useState<string>('all');

  const filterList = [
    { label: '所有变量', value: 'all' },
    { label: '系统变量', value: 'system' },
    { label: '自定义变量', value: 'custom' },
  ];

  const systemVar = [
    { label: '当前时间', value: 'cur_time' },
    { label: '当前日期', value: 'cur_date' },
    { label: '当前星期', value: 'cur_week' },
    { label: '用户最新消息', value: 'last_input' },
    { label: 'Agent最新消息', value: 'last_output' },
    { label: '全部历史消息', value: 'all_message' },
    { label: '用户最新意图', value: 'last_intention' },
    { label: 'LLM回复', value: 'llm_output' },
    { label: 'LLM回复的指定信息', value: 'llm_output_field' },
  ];

  const editVar = (data: VarItem) => {
    setLastVarList(prev =>
      prev.map(item =>
        item.varname === data.varname ? { ...item, showInput: true } : { ...item, showInput: false }
      )
    );
  };

  const saveVar = (item: VarItem) => {
    item.showInput = false;
    dispatch(
      updateVarListByAgentAction({
        varname: item.varname,
        description: item.description,
        agent_id: currentAgent.agent_id,
      })
    );
  };

  const queryVarList = async () => {
    try {
      const res = await queryVarListByAgent({
        agent_id: currentAgent.agent_id,
      });
      if (res.code === 200 && res.data) {
        setLastVarList(res.data.result || []);
      } else {
        message.error(res.message || '获取自定义变量失败');
      }
    } catch {
      message.error('获取自定义变量失败');
    }
  };

  const copyText = (text: string) => {
    const lastText = `\${${text}}`;
    navigator.clipboard.writeText(lastText).then(() => {
      message.success('该内容已复制到剪切板');
    });
  };

  useEffect(() => {
    queryVarList();
  }, []);

  return (
    <div className="var-wrap">
      <Select
        value={filterName}
        onChange={setFilterName}
        placeholder="请选择"
        style={{ width: '100%' }}
        filterOption
      >
        {filterList.map(item => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
      {['all', 'system'].includes(filterName) && (
        <>
          <p className="p-title pre-label before:bg-primary_blue flex items-center">系统变量</p>
          {systemVar.map((item, index) => (
            <div className="var-item" key={index}>
              <span className="var-name">{item.value}</span>
              <span className="var-des">{item.label}</span>
              <CopyOutlined className="iconfont fuzhi" onClick={() => copyText(item.value)} />
            </div>
          ))}
        </>
      )}
      {['all', 'custom'].includes(filterName) && (
        <>
          <p
            className="p-title pre-label before:bg-primary_blue flex items-center"
            style={{ paddingTop: '14px' }}
          >
            自定义变量
          </p>
          {lastVarList.map((item, index) => (
            <div className="var-item" key={index}>
              <span className="var-name cursor-name">{item.varname}</span>
              {item.showInput ? (
                <Input
                  value={item.description}
                  onChange={e => {
                    const description = e.target.value;
                    setLastVarList(prev =>
                      prev.map(varItem =>
                        varItem.varname === item.varname ? { ...varItem, description } : varItem
                      )
                    );
                  }}
                  onBlur={() => saveVar(item)}
                  placeholder="请输入"
                />
              ) : (
                <span className="var-des" onClick={() => editVar(item)}>
                  {item.description}
                </span>
              )}
              <CopyOutlined className="iconfont fuzhi" onClick={() => copyText(item.varname)} />
              <EditOutlined className="iconfont bianji" onClick={() => editVar(item)} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default VarList;
