import React, { useEffect, useState } from 'react';
import { Form, Switch, InputNumber, Button, message, Slider, Tooltip } from 'antd';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { saveRagConfig } from '@/api/agent';
import { useTranslation } from 'react-i18next';
import './ragConfig.scss';

interface IRagConfigForm {
  multi_wheel: boolean;
  use_image: boolean;
  top_k: number;
  temperature: number;
  top_p: number;
  max_tokens: number;
  threshold: number;
}

const RagConfig: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IRagConfigForm>();
  const [initForm, setInitForm] = useState<IRagConfigForm>({
    multi_wheel: true,
    use_image: false,
    temperature: 0.01,
    top_p: 0.85,
    top_k: 5,
    max_tokens: 512,
    threshold: 0.4,
  });
  const currentAgent = useAppSelector(selectCurrentAgent);
  useEffect(() => {
    const { rag_config } = currentAgent.currentAgent as any;
    const initData = {
      multi_wheel: rag_config?.multi_wheel,
      use_image: rag_config?.use_image || false,
      temperature: rag_config?.temperature || 0.01,
      top_p: rag_config?.top_p || 0.85,
      top_k: rag_config?.top_k || 5,
      max_tokens: rag_config?.max_tokens || 512,
      threshold: rag_config?.threshold || 0.4,
    };
    setInitForm(initData);
  }, []);
  useEffect(() => {
    form.setFieldsValue(initForm);
  }, [initForm]);
  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const agent_id = currentAgent.currentAgent.agent_id;
      saveRagConfig({
        agent_id,
        rag_config: {
          ...values,
        },
      }).then(res => {
        if (res.code === 200) {
          message.success(t('common.saveSuccess'));
        } else {
          message.error(res.message ? res.message : t('common.saveFailed'));
        }
      });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
      message.error(t('common.saveFailed') + ', ' + t('common.checkInput'));
    }
  };

  return (
    <div className="rag-config">
      <div className="bg-white rounded">
        <div className="config-title">
          {t('agentEngine.ragConfig.title')}
          <Button style={{ float: 'right' }} type="primary" onClick={onSave}>
            {t('common.save')}
          </Button>
        </div>
        <Form
          style={{ padding: 16 }}
          form={form}
          layout="vertical"
          initialValues={initForm}
          className="rag-form"
        >
          <div className="rag-flex-box">
            <Form.Item style={{ marginRight: '20px' }} name="multi_wheel" label={t('agentEngine.ragConfig.multiWheel')}>
              <Switch
                value={initForm.multi_wheel}
                onChange={(checked: any) => form.setFieldsValue({ multi_wheel: checked })}
              />
            </Form.Item>
            <Form.Item style={{ marginRight: '20px' }} name="top_k" label={t('agentEngine.ragConfig.topK')}>
              <InputNumber min={1} max={20} />
            </Form.Item>
            <Form.Item
              name="use_image"
              label={
                <Tooltip placement="top" title={t('agentEngine.ragConfig.useImageTooltip')}>
                  {t('agentEngine.ragConfig.useImage')}<span className="iconfont xinxi"></span>
                </Tooltip>
              }
            >
              <Switch
                value={initForm.use_image}
                onChange={(checked: any) => form.setFieldsValue({ use_image: checked })}
              />
            </Form.Item>
          </div>
          <div className="rag-flex-box">
            <div style={{ marginRight: '20px' }}>
              <Form.Item
                style={{ marginBottom: '10px' }}
                name="temperature"
                label={t('agentEngine.ragConfig.temperature')}
              >
                <Slider value={initForm.temperature} step={0.01} max={1} min={0} />
              </Form.Item>
              <Form.Item name="temperature">
                <InputNumber
                  value={initForm.temperature}
                  min={0}
                  max={1}
                  step={0.01}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div style={{ marginRight: '20px' }}>
              <Form.Item style={{ marginBottom: '10px' }} name="top_p" label={t('agentEngine.ragConfig.topP')}>
                <Slider
                  value={initForm.top_p}
                  className="slider-left"
                  step={0.01}
                  max={1}
                  min={0}
                />
              </Form.Item>
              <Form.Item name="top_p">
                <InputNumber
                  value={initForm.top_p}
                  min={0}
                  max={1}
                  step={0.01}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div style={{ marginRight: '20px' }}>
              <Form.Item
                style={{ marginBottom: '10px' }}
                name="max_tokens"
                label={t('agentEngine.ragConfig.maxTokens')}
              >
                <Slider value={initForm.max_tokens} step={1} max={2048} min={512} />
              </Form.Item>
              <Form.Item name="max_tokens">
                <InputNumber
                  value={initForm.max_tokens}
                  min={512}
                  max={2048}
                  step={1}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div style={{ marginRight: '20px' }}>
              <Form.Item
                style={{ marginBottom: '10px' }}
                name="threshold"
                label={t('agentEngine.ragConfig.threshold')}
              >
                <Slider value={initForm.threshold} step={0.01} max={1} min={0} />
              </Form.Item>
              <Form.Item name="threshold">
                <InputNumber
                  value={initForm.threshold}
                  min={0}
                  max={1}
                  step={0.01}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RagConfig;
