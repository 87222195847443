import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Button, Space } from 'antd';
import { t } from 'i18next';
import SceneInfoForm from '@/components/AgentForm/SceneInfoForm'; // 假设已经转换
import { DataItem } from '@/store/modules/types';
import ScenePreAction from '@/components/AgentForm/ScenePreAction'; // 假设已经转换
import ConditionalStrategy from '@/components/AgentForm/ConditionalStrategy'; // 假设已经转换
import StandardStrategy from '@/components/AgentForm/StandardStrategy'; // 假设已经转换
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';

interface IProps {
  visible: boolean;
  module?: string;
  form: DataItem;
  updateVisible: (visible: boolean) => void;
  updateInfo: (info: DataItem) => void;
  onClose: () => void;
}

const AnswerRoleAdd: React.FC<IProps> = ({
  visible,
  module = 'answer',
  form,
  updateVisible,
  updateInfo,
}) => {
  const currentAgent = useAppSelector(selectCurrentAgent);
  const [drawerVisible, setDrawerVisible] = useState(visible);
  // const [formState, setFormState] = useState<DataItem>(form);

  // 相当于vue的watchEffect
  useEffect(() => {
    setDrawerVisible(visible);
  }, [visible, form]);

  const handleSave = async () => {
    try {
      // @ts-expect-error unknown
      const pass1 = await sceneInfoRef.current?.validateFormFields();
      // @ts-expect-error unknown
      const pass2 = await preActionRef.current?.validateFormFields();
      // @ts-expect-error unknown
      const pass3 = await conditionRef.current?.validateFormFields();
      // @ts-expect-error unknown
      const pass4 = await standardRef.current?.validateFormFields();
      const agentForm = {
        ...form,
        ...pass1,
        ...pass2,
        ...pass3,
        ...pass4,
      };
      console.log(pass4);
      updateInfo(agentForm);
    } catch (error) {
      console.log('err--', error);
    }

    // 表单验证和保存逻辑，需要结合Form.useForm实现
  };

  const cancelAction = () => {
    setDrawerVisible(false);
    updateVisible(false);
  };

  // 场景信息
  const sceneInfoRef = useRef(null);
  const [sceneInfoForm, setSceneInfoForm] = useState<DataItem>({});
  useEffect(() => {
    form &&
      setSceneInfoForm({
        name: form.name,
        desc: form.desc,
        input: form.input,
      });
  }, [form]);

  // 场景前置动作
  const preActionRef = useRef(null);
  const [preActionForm, setPreActionForm] = useState<DataItem>({
    entity_arr: [],
  });
  useEffect(() => {
    form &&
      setPreActionForm({
        is_enable_entity: form.is_enable_entity,
        entity_arr: form.entity_arr,
        entity_type: form.entity_type,
        is_enable_function: form.is_enable_function,
        function_name: form.function_name,
      });
  }, [form]);

  // 条件策略
  const conditionRef = useRef(null);
  const [conditionForm, setConditionForm] = useState<DataItem>({
    condition_answer_arr: [],
  });
  useEffect(() => {
    form &&
      setConditionForm({
        is_condition_answer: form.is_condition_answer,
        condition_answer_arr: form.condition_answer_arr || [],
      });
  }, [form]);

  // 标准策略
  const standardRef = useRef(null);
  const [standardForm, setStandardForm] = useState<DataItem>({});
  useEffect(() => {
    if (form) {
      let strategy_type = form.strategy_type;
      if (!strategy_type && form.cate) {
        if (['1', '3', '4'].includes(form.cate)) {
          strategy_type = 'answer';
        } else {
          strategy_type = 'action';
        }
      }

      // 兼容老数据value
      let llm_prompt, direct_answer, polish_prompt;
      if (form.cate === '1') {
        llm_prompt = form.value;
      }
      if (form.cate === '3') {
        direct_answer = form.value;
      }
      if (form.cate === '4') {
        polish_prompt = form.value;
      }

      const defaultChain = {
        rule_list: [],
        floor_reply: t('common.default'),
        strategy_type: 'answer',
        kb_rule_cate: '3',
        skill_num: '',
        turn_human_msg: '',
        ask_human_conf: {
          queues: [],
          remark: '',
          busy_answer: t('common.floorReply'),
          group_type: 'ykf',
          queue_data: ''
        },
      };

      if (form.ask_human_conf && form.ask_human_conf.group_type == null) {
        form.ask_human_conf.group_type = 'agent';
      }
      if (form.think_chain_config && form.think_chain_config.ask_human_conf && form.think_chain_config.ask_human_conf.group_type == null) {
        form.think_chain_config.ask_human_conf.group_type = 'agent';
      }

      setStandardForm({
        strategy_type,
        cate: form.cate,
        value: form.value,
        tool_llm: form.tool_llm || 'default',
        json_parser: form.json_parser,
        json_field: form.json_field,
        skill_num: form.skill_num,
        turn_human_msg: form.turn_human_msg,
        keyboard_scene: form.keyboard_scene,
        kbs: form.kbs,
        answer_res_list: form.answer_res_list,
        llm_prompt: form.llm_prompt || llm_prompt || '',
        direct_answer: form.direct_answer || direct_answer || '',
        net_search_prompt: form.net_search_prompt || '',
        polish_prompt: form.polish_prompt || polish_prompt || '',
        ask_human_conf: form.ask_human_conf || {
          queues: [],
          busy_answer: t('common.floorReply'),
          remark: '',
          group_type: 'ykf',
          queue_data: ''
        },
        is_custom_prompt: form.is_custom_prompt || false,
        think_chain_config: form.think_chain_config || defaultChain,
      });
    }
  }, [form]);
  return (
    <Drawer
      className="custom-drawer"
      // title={`${form && form.uid ? '编辑' : '添加'}Agent${module === 'answer' ? '角色' : '场景'}`}
      title={`${form && form.uid ? 
        (module === 'answer' ? t('agentEngine.roleParts.editRole2') : t('agentEngine.roleParts.editScene2')) : 
        (module === 'answer' ? t('agentEngine.roleParts.addRole2') : t('agentEngine.roleParts.addScene2'))}`
      }

      placement="right"
      onClose={cancelAction}
      open={drawerVisible}
      width={800}
      extra={
        <Space>
          <Button onClick={cancelAction}>{t('common.cancel')}</Button>
          <Button type="primary" onClick={handleSave}>
            {t('common.save')}
          </Button>
        </Space>
      }
    >
      {drawerVisible && (
        <div className="answer-role-add">
          <div>
            <SceneInfoForm ref={sceneInfoRef} formState={sceneInfoForm} />
          </div>
          <div className="mt-5">
            <ScenePreAction ref={preActionRef} actionText="role" formState={preActionForm} />
          </div>
          <div className="mt-5 px-5 pt-5 pb-1 bg-white rounded">
            <h4 className="text-text_color_black text-base font-medium">{t('agentEngine.roleParts.sceneModel')}</h4>
            <ConditionalStrategy ref={conditionRef} formState={conditionForm} />
          </div>
          <div className="px-5 pb-1 bg-white rounded">
            <StandardStrategy ref={standardRef} formState={standardForm} />
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default AnswerRoleAdd;
