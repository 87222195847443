import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Form, Input, Select, Button, Modal, InputNumber } from 'antd';
import { t } from 'i18next';
import Panel from '@/components/Panel';
import { selectModelEnum } from '@/store/modules/agentTemplate';
const { TextArea } = Input;
const { Option } = Select;

interface LLMFormProps {
  form: Record<string, any>;
  id: string;
}

interface FormNode {
  name: string;
  llm_prompt: string;
  tool_llm: string;
  chat_turns: number;
}

interface Rule {
  required: boolean;
  message: string;
  trigger?: string;
}

const LLMForm = forwardRef<any, LLMFormProps>(({ form, id }, ref) => {
  const [formRef] = Form.useForm();
  const [formNode, setFormNode] = useState<FormNode>({
    name: t('flowSideBar.llmNode'),
    llm_prompt: '',
    tool_llm: 'gpt4o',
    chat_turns: 0,
  });
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [auxiliary, setAuxiliary] = useState<boolean>(false);
  const [bigAuxiliary, setBigAuxiliary] = useState<boolean>(false);
  const modelEnum = selectModelEnum();

  useEffect(() => {
    formRef.setFieldsValue(form);
  }, [form]);

  const rules: { [key: string]: Rule[] } = {
    name: [{ required: true, message: t('flowForm.llmForm.inputLLMName'), trigger: 'blur' }],
    llm_prompt: [{ required: true, message: t('flowForm.llmForm.inputLLMPrompt'), trigger: 'blur' }],
    tool_llm: [{ required: true, message: t('flowForm.llmForm.selectLLMModel'), trigger: 'change' }],
  };

  const validate = async () => {
    try {
      const values = await formRef.validateFields();
      console.log(values);
      return values;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const resetFields = () => {
    formRef.resetFields();
  };

  const getForm = () => {
    return formRef.getFieldsValue();
  };

  const handleAuxiliary = (type: string) => {
    if (type) {
      setBigAuxiliary(true);
      setAuxiliary(false);
    } else {
      setAuxiliary(true);
      setBigAuxiliary(false);
    }
  };

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <>
      <Form
        form={formRef}
        className="px-5 pt-5 pb-1 bg-white rounded"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={formNode}
      >
        <Form.Item label={t('flowForm.llmForm.nodeName')} name="name" rules={rules.name}>
          <Input
            value={formNode.name}
            onChange={e => setFormNode(prev => ({ ...prev, name: e.target.value }))}
            autoComplete="off"
            maxLength={50}
            showCount
            placeholder={t('common.input')}
          />
        </Form.Item>

        <Form.Item
          className="custom-label"
          label={
            <div className="label-div">
              <span>{t('flowForm.llmForm.prompt')}</span>
              <span className="label-btn">
                <span className="iconfont quanping" onClick={() => setDialogVisible(true)}>
                  {t('common.fullScreen')}
                </span>
                <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                  {t('common.auxiliaryInfo')}
                </span>
              </span>
            </div>
          }
          name="llm_prompt"
          rules={rules.llm_prompt}
        >
          <TextArea
            value={formNode.llm_prompt}
            onChange={e => setFormNode(prev => ({ ...prev, llm_prompt: e.target.value }))}
            rows={10}
            maxLength={5000}
            showCount
            placeholder={t('flowForm.llmForm.inputCallword')}
          />
        </Form.Item>

        <Form.Item label={t('flowForm.llmForm.model')} name="tool_llm" rules={rules.tool_llm}>
          <Select
            value={formNode.tool_llm}
            onChange={value => setFormNode(prev => ({ ...prev, tool_llm: value }))}
            placeholder={t('common.select')}
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            {modelEnum.map((item: string, index: number) => (
              <Option value={item} key={index}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('flowForm.llmForm.dialogMemory')} name="chat_turns">
          <InputNumber min={0} max={50} />
        </Form.Item>
      </Form>

      {auxiliary && <Panel className="llm-panel" onClosePanel={() => setAuxiliary(false)} />}
      <Modal
        open={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        footer={null}
        width="100%"
        className="full-screen-modal"
        style={{ top: 0 }}
      >
        <div className="modal-header mb-3">
          <span>{t('flowForm.llmForm.prompt')}</span>
          <span
            className="iconfont fuzhuxinxi auxiliary-icon"
            onClick={() => handleAuxiliary('big')}
          >
            {t('common.auxiliaryInfo')}
          </span>
        </div>
        <TextArea
          value={formNode.llm_prompt}
          onChange={e => {
            formRef.setFieldValue('llm_prompt', e.target.value);
            setFormNode(prev => ({ ...prev, llm_prompt: e.target.value }));
          }}
          rows={22}
          maxLength={5000}
          showCount
          placeholder={t('flowForm.llmForm.inputCallword')}
        />
        {bigAuxiliary && (
          <Panel className="big-content-panel" onClosePanel={() => setBigAuxiliary(false)} />
        )}
        <div className="modal-footer text-right mt-6">
          <Button type="primary" onClick={() => setDialogVisible(false)}>
            {t('common.close')}
          </Button>
        </div>
      </Modal>
    </>
  );
});
LLMForm.displayName = 'LLMForm';
export default LLMForm;
