import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import {
  Button,
  Table,
  Radio,
  Input,
  Form,
  Tooltip,
  Select,
  Drawer,
  Row,
  Col,
  message,
} from 'antd';
import {t} from 'i18next'
import {
  HolderOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import './index.scss';
import { conditionRule } from '@/constant/options';
import { produce } from 'immer';
import { queryQueue } from '@/api/account';
import { cloneDeep } from 'lodash';
import Sortable, { SortableEvent } from 'sortablejs';
const { Option } = Select;
enum CondType {
  ALL = 'all',
  ANY = 'any',
}

interface judgmentStrategyProps {
  data: {
    rule_list: any[];
    floor_reply: string;
    strategy_type: string;
    kb_rule_cate: string;
    skill_num: string;
    turn_human_msg: string;
    ask_human_conf: any;
  };
  changeData: (data: any) => void;
}

const JudgmentStrategy = forwardRef(function JudgmentStrategy(props: judgmentStrategyProps, ref) {
  const dataChange = (e: any, key: string) => {
    const newData: any = cloneDeep(props.data);
    const val = e.target.value;
    newData[key] = val;
    if (key === 'strategy_type' && val === 'action') {
      newData['kb_rule_cate'] = '12';
    }
    props.changeData(newData);
  };
  const innerFormChange = (e: any, key: string) => {
    const val = e.target.value;
    if (key === 'strategy_type' && val === 'action') {
      subFormRef.setFieldsValue({
        kb_rule_cate: '12',
      });
    }
  };
  const [groupList, setGroupList] = useState<
    {
      queue_id: string;
      queue_name: string;
    }[]
  >([]);
  const fetchGroupList = async () => {
    const filters = { filters: [] } as any;
    const newFilters = JSON.stringify(filters);
    const str = `?q=${newFilters}&page=1&results_per_page=1000`;
    const res: any = await queryQueue(str);
    setGroupList(res.objects);
  };

  const checkFields = () => {
    // const newData:any = cloneDeep(props.data);
    // newData.ask_human_conf = form.getFieldsValue()
    // props.changeData(newData)
    return form.validateFields();
  };
  const [drawerVisible, setDrawerVisible] = useState(false);
  // 取消操作
  const cancel = () => {
    setDrawerVisible(false);
  };

  function handleRemoveCondition(i: number) {
    setSubForm(
      produce(draft => {
        draft.cond_group.splice(i, 1);
      })
    );
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        checkFields,
      };
    },
    []
  );
  function handleAddCondition() {
    setSubForm(
      produce(draft => {
        draft.cond_group = [
          ...draft.cond_group,
          {
            var_name: null,
            condition: null,
            value: '',
          },
        ];
      })
    );
  }
  const [form] = Form.useForm();
  const [subFormRef] = Form.useForm();
  const subInitForm = {
    name: '',
    cond_group: [
      {
        condition: null,
        value: '',
        var_name: null,
      },
    ],
    cond_type: 'all',
    strategy_type: 'answer',
    kb_rule_cate: '3',
    skill_num: '',
    turn_human_msg: '',
    ask_human_conf: {
      queues: [],
      busy_answer: '非常抱歉，您反馈的问题已为您记录登记，稍后将反馈专员处理。',
      remark: '',
      group_type: 'ykf',
      queue_data: '',
    },
    reply_key: '',
  };
  const [subForm, setSubForm] = useState(subInitForm);
  const handleFormChange = () => {
    const values = subFormRef.getFieldsValue();
    setSubForm({ ...subForm, ...values });
  };
  const handleFormChange1 = () => {
    const values = form.getFieldsValue();
    const data = {
      ...props.data,
      ask_human_conf: {
        ...props.data.ask_human_conf,
        ...values,
      },
    };
    props.changeData(cloneDeep(data));
  };
  // 保存策略
  const saveRule = () => {
    subFormRef
      .validateFields()
      .then(() => {
        const newData = cloneDeep(subForm);
        if (newData.strategy_type === 'answer') {
          newData.kb_rule_cate = '13';
        }
        const newOuterData = cloneDeep(props.data);
        const tbDataClone = cloneDeep(tbList.current);
        if (editIndex === -1) {
          const repeat = tbDataClone.filter((op: any, i: number) => newData.name === op.name);
          if (repeat && repeat.length > 0) {
            message.error(t('components.judgmentStrategy.ruleNameDuplicate'));
            return false;
          }
          tbDataClone.unshift(newData);
          setOrder(tbDataClone);
          newOuterData.rule_list = tbDataClone;
          props.changeData(newOuterData);
          setTbData(tbDataClone);
          tbList.current = tbDataClone;
          setDrawerVisible(false);
        } else {
          const repeat = tbDataClone.filter(
            (op: any, i: number) => newData.name === op.name && i !== editIndex
          );
          if (repeat && repeat.length > 0) {
            message.error(t('components.judgmentStrategy.ruleNameDuplicate'));
            return false;
          }
          tbDataClone[editIndex] = newData;
          setOrder(tbDataClone);
          newOuterData.rule_list = tbDataClone;
          setTbData(tbDataClone);
          tbList.current = tbDataClone;
          setDrawerVisible(false);
          props.changeData(newOuterData);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleAddRule = () => {
    setEditIndex(-1);
    subFormRef.setFieldsValue(subInitForm);
    setSubForm(subInitForm);
    setDrawerVisible(true);
  };
  const [editIndex, setEditIndex] = useState<number>(-1);
  const handleEdit = (index: number, record: any) => {
    setEditIndex(index);
    const newEdit = cloneDeep(record);
    if (newEdit.ask_human_conf && newEdit.ask_human_conf.group_type == null) {
      newEdit.ask_human_conf.group_type = 'agent';
    }
    setSubForm(newEdit);
    subFormRef.setFieldsValue(newEdit);
    setDrawerVisible(true);
  };
  const handleDelete = (index: number) => {
    const newArr = cloneDeep(tbList.current);
    newArr.splice(index, 1);
    setOrder(newArr);
    setTbData(newArr);
    tbList.current = newArr;
    props.changeData({
      ...props.data,
      rule_list: newArr,
    });
  };

  const setOrder = (arr: any) => {
    const upData = arr.map((item: any, i: number) => ({
      ...item,
      order: i,
    }));
    return upData;
  };

  const sort: any = useRef(null);
  const [tbData, setTbData] = useState<any>(setOrder(props.data.rule_list || []));
  const tbList = useRef<any>(setOrder(props.data.rule_list || []));

  const initTableRowDrag = () => {
    sort.current?.destroy();
    const tbody: HTMLElement | null = document.querySelector(
      '.judge-draggable .ant-table-content tbody'
    );
    if (tbody) {
      sort.current = new Sortable(tbody, {
        //  可被拖拽的子元素
        draggable: '.judge-draggable .ant-table-row',
        onEnd({ newIndex, oldIndex }) {
          if (newIndex === oldIndex) {
            return;
          }
          if (newIndex === undefined || oldIndex === undefined) {
            return;
          }
          const newList = cloneDeep(tbList.current);
          const [removedItem] = newList.splice(oldIndex, 1);
          newList.splice(newIndex, 0, removedItem);
          setOrder(newList);
          // setTbData(
          //   produce(draft => {
          //     draft = newList;
          //   })
          // );
          setTbData(newList);
          tbList.current = newList;
          const newOuterData = cloneDeep(props.data);
          newOuterData.rule_list = newList;
          props.changeData(newOuterData);
          // props.changeData({
          //   ...props.data,
          //   rule_list: arr
          // })
          // form.setFieldValue('condition_answer_arr', arr.condition_answer_arr);
        },
      });
    }
  };

  useEffect(() => {
    form?.setFieldValue('queues', props.data.ask_human_conf?.queues || []);
    form?.setFieldValue('busy_answer', props.data.ask_human_conf?.busy_answer || '');
    form?.setFieldValue('remark', props.data.ask_human_conf?.remark || '');
    fetchGroupList();
  }, [props.data.ask_human_conf]);

  useEffect(() => {
    initTableRowDrag();
  }, [tbData]);

  const showReplay = (row: any) => {
    if (row.kb_rule_cate === '13') {
      return row.reply_key;
    } else {
      return '';
    }
  };

  function showRuleText(row: any): string {
    let text = '';
    const cond_group = row.cond_group || [];
    cond_group.forEach((i: any) => {
      const { condition, value, var_name } = i;
      let ruleLabel = '';
      const rule = conditionRule.find(c => c.value === condition);
      if (rule) ruleLabel = rule.label;
      text += `${var_name} ${ruleLabel} ${value || ''},`;
    });
    return text.substring(0, text.length - 1);
  }

  const columns = [
    {
      title: t('flowForm.order'),
      dataIndex: 'order',
      key: 'order',
      width: '50',
      render: () => {
        return (
          <span className="drag-icon">
            <HolderOutlined style={{ cursor: 'move' }} />
          </span>
        );
      },
    },
    {
      title: t('flowForm.ruleName'),
      dataIndex: 'name',
      key: 'name',
      width: '160',
      ellipsis: true,
    },
    {
      title: t('common.condition'),
      dataIndex: 'condition',
      key: 'condition',
      render: (text: string, row: any) => {
        return <span>{showRuleText(row)}</span>;
      },
    },
    {
      title: t('common.action'),
      dataIndex: 'kb_rule_cate',
      key: 'kb_rule_cate',
      render: (text: string, row: any) => {
        return (
          <span className="whitespace-nowrap overflow-hidden text-ellipsis">
            {text === '5' && t('common.humanService')}
            {text === '12' && t('common.askHuman')}
            {text === '13' && t('common.reply')}
          </span>
        );
      },
    },
    {
      title: t('common.replyKey'),
      dataIndex: 'reply_key',
      key: 'reply_key',
      render: (text: string, row: any) => {
        return (
          <Tooltip title={showReplay(row)}>
            <div className="w-100 whitespace-nowrap overflow-hidden text-ellipsis">
              {showReplay(row)}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t('common.operation'),
      dataIndex: 'action',
      key: 'action',
      width: '140',
      render: (text: string, record: any, index: number) => (
        <>
          <Button type="link" size="small" onClick={() => handleEdit(index, record)}>
            {t('common.edit')}
          </Button>
          <Button type="link" danger size="small" onClick={() => handleDelete(index)}>
            {t('common.delete')}
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="judgmentStrategy">
      <div>
        <span>{t('components.judgmentStrategy.title')}</span>
        <Button type="text" className="addStrategy" onClick={handleAddRule}>
          + {t('components.judgmentStrategy.addStrategy')}
        </Button>
      </div>
      <div>
        <div className="judge-draggable">
          <Table dataSource={tbData} pagination={false} columns={columns} rowKey="order"></Table>
        </div>
      </div>
      <div className="judgment-tip">{t('components.judgmentStrategy.judgmentTip')}</div>
      <div>
        <Radio.Group
          value={props.data.strategy_type}
          onChange={e => dataChange(e, 'strategy_type')}
        >
          <Radio value="answer">{t('components.agentForm.standardStrategyForAgent.answer')}</Radio>
          <Radio value="action">{t('components.agentForm.standardStrategyForAgent.action')}</Radio>
        </Radio.Group>
      </div>
      {props.data.strategy_type === 'answer' && (
        <Input.TextArea
          showCount
          maxLength={1000}
          className="mt-5"
          value={props.data.floor_reply}
          onChange={(e: any) => dataChange(e, 'floor_reply')}
          placeholder={t('common.floorReply')}
        />
      )}
      {props.data.strategy_type === 'action' && (
        <Radio.Group
          className="mt-5"
          value={props.data.kb_rule_cate}
          onChange={e => dataChange(e, 'kb_rule_cate')}
        >
          <Radio value="5">{t('flowForm.humanService')}</Radio>
          <Radio value="12">{t('flowForm.askHuman')}</Radio>
        </Radio.Group>
      )}
      {props.data.strategy_type === 'action' && props.data.kb_rule_cate === '12' && (
        <Form
          layout="vertical"
          className="mt-5"
          form={form}
          name="cotrol-hooks"
          initialValues={props.data.ask_human_conf}
          onValuesChange={handleFormChange1}
        >
          {/* 技能组类型 */}
          <Form.Item
            name="group_type"
            label={t('components.agentForm.standardStrategyForAgent.skillGroup')}
          >
            <Radio.Group value={props.data.ask_human_conf.group_type}>
              <Radio value={'agent'}>{t('components.agentForm.standardStrategyForAgent.agentSkillGroup')}</Radio>
              <Radio value={'ykf'}>{t('components.agentForm.standardStrategyForAgent.ykfSkillGroup')}</Radio>
            </Radio.Group>
          </Form.Item>
          {/* agent技能组 */}
          {
            props.data.ask_human_conf.group_type === 'agent' && (
              <>
                <Form.Item
                  name="queues"
                  label={t('components.judgmentStrategy.selectSkillGroupTip')}
                  rules={[{ required: props.data.kb_rule_cate === '12', message: t('components.judgmentStrategy.selectSkillGroup') }]}
                >
                  <Select placeholder={t('common.select')} mode="multiple">
                    {groupList.map((i, index) => {
                      return (
                        <Option value={i.queue_id} key={i.queue_id}>
                          {i.queue_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </>
            )
          }
          {/* 云客服技能组 */}
          {
            props.data.ask_human_conf.group_type === 'ykf' && (
              <>
                <Form.Item
                  name="queue_data"
                  label={
                    <div className="flex justify-between w-full">
                      <span className="mr-1">{t('components.judgmentStrategy.inputCloudServiceSkillGroupNumber')}</span>
                      {/* <Tooltip title="多个技能组编号请用英文,隔开">
                        <ExclamationCircleOutlined />
                      </Tooltip> */}
                    </div>
                  }
                  rules={[{ required: false, message: t('components.judgmentStrategy.inputCloudServiceSkillGroupNumber') }]}
                >
                  <Input
                    maxLength={5000}
                    placeholder={t('components.judgmentStrategy.inputCloudServiceSkillGroupNumber')}
                  />
                </Form.Item>
              </>
            )
          }
          <Form.Item
            name="busy_answer"
            label={
              <div className="flex justify-between w-full">
                <span className="mr-1">{ props.data.ask_human_conf.group_type === 'ykf' ? t('components.judgmentStrategy.transferFailTip') : t('components.judgmentStrategy.askHumanFailTip')}</span>
                <Tooltip title={t('components.judgmentStrategy.busyAnswerTip')}>
                  <ExclamationCircleOutlined />
                </Tooltip>
              </div>
            }
            rules={[{ required: props.data.kb_rule_cate === '12', message: t('components.agentForm.standardStrategyForAgent.inputBusyAnswer') }]}
          >
            <Input.TextArea
              rows={3}
              maxLength={5000}
              placeholder={t('common.floorReply')}
            />
          </Form.Item>
          {
            props.data.ask_human_conf.group_type === 'agent' && (
              <>
                <Form.Item name="remark" label={t('common.remark')}>
                  <Input.TextArea rows={3} maxLength={5000} placeholder={t('common.input')} />
                </Form.Item>
              </>
            )
          }
        </Form>
      )}
      {props.data.strategy_type === 'action' && props.data.kb_rule_cate === '5' && (
        <>
          <div className='mt-5' style={{color: "#606266"}}>{t('components.judgmentStrategy.prioritySkillGroup')}</div>
          <Input
            className="mt-2"
            placeholder={t('components.judgmentStrategy.inputPrioritySkillGroup')}
            maxLength={50}
            value={props.data.skill_num}
            onChange={e => dataChange(e, 'skill_num')}
          />
          <div className='mt-5' style={{color: "#606266"}}>{t('flowForm.replyToUser')}</div>
          <Input.TextArea
            className="mt-2"
            rows={3}
            maxLength={5000}
            placeholder={t('flowForm.inputReplyToUser')}
            value={props.data.turn_human_msg}
            onChange={e => dataChange(e, 'turn_human_msg')}
          />
        </>
      )}
      <Drawer
        title={editIndex === -1 ? t('flowForm.addRule') : t('flowForm.editRule')}
        placement="right"
        onClose={cancel}
        open={drawerVisible}
        width={800}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button onClick={cancel} style={{ marginRight: 8 }}>
              {t('common.cancel')}
            </Button>
            <Button onClick={saveRule} type="primary">
              {t('common.save')}
            </Button>
          </div>
        }
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          form={subFormRef}
          initialValues={subForm}
          onValuesChange={handleFormChange}
        >
          <Form.Item
            name="name"
            label={t('flowForm.ruleName')}
            rules={[{ required: true, message: t('flowForm.inputRuleName') }]}
          >
            <Input maxLength={50} showCount placeholder={t('common.input')} />
          </Form.Item>

          <Row gutter={12}>
            <Form.Item noStyle>
              <Col span={24}>
                <div className="font-bold inline-block">
                  <span>{t('common.condition')}</span>
                </div>
              </Col>
            </Form.Item>
          </Row>

          {subForm.cond_group.map((item, index) => (
            <Row gutter={12} key={index + 'cond'}>
              <Col span={1}>
                <Form.Item>{(index + 1).toString() + '：'}</Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name={['cond_group', index, 'var_name']}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={['cond_group', index, 'condition']}>
                  <Select placeholder={t('components.judgmentStrategy.selectOperator')}>
                    {conditionRule.map((i, idx) => (
                      <Option key={idx} value={i.value}>
                        {i.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                {['C', 'D', 'E', 'F', 'GT', 'LT', 'LTE', 'GTE', 'REGX'].includes(
                  // @ts-expect-error known
                  subForm.cond_group[index]?.condition
                ) && (
                  <Form.Item name={['cond_group', index, 'value']}>
                    <Input placeholder={t('common.input')} />
                  </Form.Item>
                )}
              </Col>
              <Col span={2}>
                {subForm.cond_group.length > 1 && (
                  <div
                    className="flex items-center h-8 cursor-pointer"
                    onClick={() => handleRemoveCondition(index)}
                  >
                    <MinusCircleOutlined />
                  </div>
                )}
              </Col>
            </Row>
          ))}

          <Row gutter={12}>
            <div className="pb-4">
              <Button type="primary" icon={<PlusOutlined />} onClick={handleAddCondition}>
                {t('flowForm.addCondition')}
              </Button>
            </div>
          </Row>

          <Row gutter={12}>
            <Form.Item name={['cond_type']} label={t('flowForm.executeLogic')}>
              <Radio.Group>
                <Radio value={CondType.ALL}>{t('flowForm.allConditions')}</Radio>
                <Radio value={CondType.ANY}>{t('flowForm.anyCondition')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>

          <Row gutter={12}>
            <Form.Item noStyle>
              <Col span={24}>
                <div className="font-bold inline-block">
                  <span>{t('common.action')}</span>
                </div>
              </Col>
            </Form.Item>
          </Row>

          <Row>
            <Form.Item noStyle name={['strategy_type']}>
              <Col span={12}>
                <Radio.Group
                  className="mt-5"
                  value={subForm.strategy_type}
                  onChange={e => innerFormChange(e, 'strategy_type')}
                >
                  <Radio value="answer">{t('common.replyKey')}</Radio>
                  <Radio value="action">{t('common.action')}</Radio>
                </Radio.Group>
              </Col>
            </Form.Item>
          </Row>
          {subForm.strategy_type === 'answer' && (
            <Form.Item noStyle name={['reply_key']}>
              <Input className="mt-5" placeholder={t('common.inputReplyKey')} maxLength={50} name="reply_key" />
            </Form.Item>
          )}
          <Row>
            <Form.Item noStyle name={['kb_rule_cate']}>
              <div></div>
            </Form.Item>
          </Row>
          {subForm.strategy_type === 'action' && (
            <Row gutter={24}>
              <Form.Item noStyle name={['kb_rule_cate']}>
                <Col span={24}>
                  <Radio.Group className="mt-5" value={subForm.kb_rule_cate}>
                    <Radio value="5">{t('common.humanService')}</Radio>
                    <Radio value="12">{t('common.askHuman')}</Radio>
                  </Radio.Group>
                </Col>
              </Form.Item>
            </Row>
          )}
          {subForm.strategy_type === 'action' && subForm.kb_rule_cate === '12' && (
            <div className="mt-5">
              {/* 技能组类型 */}
              <Form.Item
                name={['ask_human_conf', 'group_type']}
                label={t('components.agentForm.standardStrategyForAgent.skillGroup')}
                rules={[{ required: subForm.kb_rule_cate === '12', message: t('components.agentForm.standardStrategyForAgent.selectSkillGroup') }]}
              >
                <Radio.Group>
                  <Radio value={'agent'}>{t('components.agentForm.standardStrategyForAgent.agentSkillGroup')}</Radio>
                  <Radio value={'ykf'}>{t('components.agentForm.standardStrategyForAgent.ykfSkillGroup')}</Radio>
                </Radio.Group>
              </Form.Item>
              {/* agent技能组 */}
              {
                subForm.ask_human_conf.group_type === 'agent' && (
                  <>
                    <Form.Item
                      name={['ask_human_conf', 'queues']}
                      label={t('components.judgmentStrategy.selectSkillGroup')}
                      rules={[{ required: subForm.kb_rule_cate === '12', message: t('components.judgmentStrategy.selectSkillGroup') }]}
                    >
                      <Select placeholder={t('common.select')} mode="multiple">
                        {groupList.map(i => {
                          return (
                            <Option value={i.queue_id} key={i.queue_id}>
                              {i.queue_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </>
                )
              }
              {/* 云客服技能组 */}
              {
                subForm.ask_human_conf.group_type === 'ykf' && (
                  <>
                    <Form.Item
                      name={['ask_human_conf', 'queue_data']}
                      label={
                        <div className="flex justify-between w-full">
                          <span className="mr-1">{t('components.judgmentStrategy.cloudServiceSkillGroupNumber')}</span>
                          {/* <Tooltip title="多个技能组编号请用英文,隔开">
                            <ExclamationCircleOutlined />
                          </Tooltip> */}
                        </div>
                      }
                      rules={[{ required: false, message: t('components.judgmentStrategy.inputCloudServiceSkillGroupNumber') }]}
                    >
                      <Input
                        maxLength={5000}
                        placeholder={t('components.judgmentStrategy.inputCloudServiceSkillGroupNumber')}
                      />
                    </Form.Item>
                  </>
                )
              }
              <Form.Item
                name={['ask_human_conf', 'busy_answer']}
                label={
                  <div className="flex justify-between w-full">
                    <span className="mr-1">{ subForm.ask_human_conf.group_type === 'ykf' ? t('components.judgmentStrategy.transferFailTip') : t('components.judgmentStrategy.askHumanFailTip')}</span>
                    <Tooltip title={t('components.judgmentStrategy.busyAnswerTip')}>
                      <ExclamationCircleOutlined />
                    </Tooltip>
                  </div>
                }
                rules={[{ required: subForm.kb_rule_cate === '12', message: t('components.agentForm.standardStrategyForAgent.inputBusyAnswer') }]}
              >
                <Input.TextArea
                  rows={3}
                  maxLength={5000}
                  placeholder={t('common.floorReply')}
                />
              </Form.Item>
              {
                subForm.ask_human_conf.group_type === 'agent' && (                  
                  <Form.Item name={['ask_human_conf', 'remark']} label={t('common.remark')}>
                    <Input.TextArea rows={3} maxLength={5000} placeholder={t('common.input')} />
                  </Form.Item>
                )
              }
            </div>
          )}
          {subForm.strategy_type === 'action' && subForm.kb_rule_cate === '5' && (
            <>
              <Form.Item name={['skill_num']}>
                <Input
                  className="mt-5"
                  placeholder={t('components.judgmentStrategy.inputPrioritySkillGroup')}
                  maxLength={50}
                  name="skill_num"
                />
              </Form.Item>
              <Form.Item name={['turn_human_msg']} label={t('flowForm.replyToUser')}>
                <Input.TextArea
                  rows={3}
                  maxLength={5000}
                  placeholder={t('flowForm.inputReplyToUser')}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Drawer>
    </div>
  );
});

export default JudgmentStrategy;
