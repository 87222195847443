import { queryAgentConfig } from '@/api/agent';
import { getUserInfo, loginBySignService } from '@/api/user';
import { useAppDispatch } from '@/store/hooks';
import { resetCurrentAgent, setCurrentAgent } from '@/store/modules/agentTemplate';
import {
  setLoginData,
  setLoginType,
  setRoles,
  setStoreToken,
  setUserName,
} from '@/store/modules/user';
import { setToken } from '@/utils/auth';
import { Button, message, Spin } from 'antd';
import React, { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const LoginBySign: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const sign = searchParams.get('sign') || '';
  const agent_id = searchParams.get('agent_id') || '';
  console.log(sign, 'sign');

  const [showReLogin, setShowReLogin] = React.useState(false);

  const handleSignLogin = async () => {
    try {
      const res = await loginBySignService(decodeURIComponent(sign));
      if (res && res.code === 200) {
        dispatch(resetCurrentAgent());
        const data1 = {
          // username: data.username,
          // // 一个用户可能有多个角色
          // roles: ['admin'],
          accessToken: res.data as any,
          refreshToken: 'eyJhbGciOiJIUzUxMiJ9.adminRefresh',
          expires: new Date('2033/10/30 00:00:00'),
        };
        dispatch(setStoreToken(res.data));
        setToken(data1);
        const res1: any = await getUserInfo();
        if (res1.code === 200) {
          const data2 = {
            ...data1,
            username: res1.data.nickname,
            roles: res1.data.roles.map((item: any) => item.name),
          };
          setToken(data2);
          dispatch(setUserName(data2.username));
          dispatch(setRoles(data2.roles));
          if (agent_id) {
            await queryCurrentAgent(agent_id);
            navigate('/agentService/agentManager/knowledgeManager');
          } else {
            if (data2.roles.length === 1 && data2.roles.includes('Agent协助')) {
              navigate('/agentService/workspace', { replace: true });
            } else {
              navigate('/agentService/llmAgent', { replace: true });
            }
          }
          setShowReLogin(false);
        } else {
          message.error('登录失败');
          setShowReLogin(true);
        }
      } else {
        setShowReLogin(true);
        // message.error('登录失败');
      }
    } catch (error) {
      setShowReLogin(true);
      // navigate('/login');
    }
  };

  /**
   * @description: 查询当前agent信息
   * @param {string} agent_id
   * @return {*}
   */
  async function queryCurrentAgent(agent_id: string) {
    try {
      const { code, data, message: msg } = await queryAgentConfig({ agent_id });
      console.log(data);
      if (code === 200) {
        dispatch(setCurrentAgent(data));
      } else {
        message.error(msg || '查询失败！');
      }
    } catch (error) {
      message.error('查询失败！');
    }
  }
  if (sign) {
    dispatch(setLoginType('sign'));
    handleSignLogin();
  }

  function handleReLogin() {
    window.parent && window.parent.postMessage({
      type: 'reSign',
    }, '*')
  }
  return (
    <div className="h-full flex justify-center items-center">
      {showReLogin ? <Button type="primary" onClick={handleReLogin}>重试</Button> : <Spin />}
    </div>
  );
};

export default LoginBySign;
