import React, { useEffect, useState, useRef } from 'react';
import { InputNumber, message, Button, Drawer, Space, Table, Switch, Modal, Tooltip } from 'antd';
import type { TableColumnsType } from 'antd';
import Sortable, { SortableEvent } from 'sortablejs';
import { cloneDeep } from 'lodash';
import './customerRetention.scss'; // 确保.scss文件是现有的，且样式名等符合要求
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import {
  queryAgentConfig,
  queryFanList,
  saveFrsConfig,
  saveFanRetention,
  deleteFanRetention,
  sortFanList,
} from '@/api/agent';
import { DataItem } from '@/store/modules/types';
import ConditionalStrategy from '@/components/AgentForm/ConditionalStrategy';
import StandardStrategyForFan from '@/components/AgentForm/StandardStrategyForFan';
import { produce } from 'immer';
import { HolderOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface Retention {
  uid: string;
  guidance_sort?: number;
  name: string;
  desc: string;
}

const CustomerRetention: React.FC = () => {
  const { t } = useTranslation();
  const [frs_first_trigger_timeout, setFrs_first_trigger_timeout] = useState<number>(60);
  const [frs_trigger_interval, setFrs_trigger_interval] = useState<number>(30);
  const [moveList, setMoveList] = useState<Retention[]>([]);
  const currentAgent = useAppSelector(selectCurrentAgent);
  const agent_id = currentAgent.currentAgent.agent_id;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    initData();
    getFanInfo();
  }, []);

  useEffect(() => {
    initListDrag();
  }, [moveList]); // 每次moveList更新后重新绑定Sortable

  const initData = () => {
    queryAgentConfig({
      agent_id: agent_id,
    })
      .then(res => {
        if (res.code == 200 && res.data) {
          setFrs_first_trigger_timeout(res.data.frs_first_trigger_timeout || 60);
          setFrs_trigger_interval(res.data.frs_trigger_interval || 30);
        } else {
          message.error(res.message || t('common.queryFailed'));
        }
      })
      .catch(() => {
        message.error(t('common.queryFailed'));
      });
  };
  const getFanInfo = () => {
    queryFanList({ agent_id: agent_id })
      .then((res: any) => {
        if (res.code == 200) {
          setMoveList([]);
          const newArr = setOrder(res.data || []);
          setMoveList(newArr);
          saveData.current = newArr;
        } else {
          message.error(res.message || t('common.queryFailed'));
        }
      })
      .catch(() => {
        message.error(t('common.queryFailed'));
      });
  };

  const saveData = useRef([]);

  const setOrder = (arr: any) => {
    const upData = cloneDeep(arr);
    upData.forEach((item: any, i: number) => {
      item.sort = i;
    });
    return upData;
  };
  const sort: any = useRef(null);
  const initListDrag = () => {
    sort.current?.destroy();
    const tbody: HTMLElement | null = document.querySelector(
      '.retention-draggable .ant-table-content tbody'
    );
    if (tbody) {
      sort.current = new Sortable(tbody, {
        //  可被拖拽的子元素
        draggable: '.retention-draggable .ant-table-row',
        onEnd({ newIndex, oldIndex }) {
          if (newIndex === oldIndex) {
            return;
          }
          if (newIndex === undefined || oldIndex === undefined) {
            return;
          }
          const newList = cloneDeep(saveData.current);
          const [removedItem] = newList.splice(oldIndex, 1);
          newList.splice(newIndex, 0, removedItem);
          const arr = setOrder(newList);
          setMoveList(
            produce(draft => {
              draft = [...arr];
            })
          );
          updateSort(arr);
          saveData.current = arr;
        },
      });
    }
  };

  const updateSort = async (arr: any) => {
    try {
      const res = await sortFanList({
        agent_id,
        sort: arr.map((item: any) => item.uid),
      });
      if (res.code !== 200) {
        message.error(t('common.sortFailed'));
        getFanInfo();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const saveRetention = async (data: any) => {
    // if (!frs_first_trigger_timeout)
    try {
      const res = await saveFrsConfig({
        agent_id,
        frs_first_trigger_timeout: data.trigger_timeout || frs_first_trigger_timeout,
        frs_trigger_interval: data.trigger_interval || frs_trigger_interval,
      });
      if (res.code === 200) {
        message.success(t('common.saveSuccess'));
      } else {
        message.error(res.message || t('common.saveFailed'));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cancelAction = () => {
    setDrawerVisible(false);
  };

  const handleSave = async () => {
    try {
      // @ts-expect-error unknown
      const pass1 = await conditionRef.current?.validateFormFields();
      // @ts-expect-error unknown
      const pass2 = await standardRef.current?.validateFormFields();
      const agentForm = {
        ...form,
        ...pass1,
        ...pass2,
      };
      saveFan(agentForm);
    } catch (error) {
      console.log('err--', error);
    }
  };

  const addFan = () => {
    setForm({ ...setForm });
    setDrawerVisible(true);
    setIsEdit(false);
  };

  const saveFan = async (data: any) => {
    const params: any = {
      agent_id: agent_id,
      is_condition_answer: data.is_condition_answer || false,
      condition_answer_arr: data.condition_answer_arr || [],
      strategy_type: data.strategy_type || '',
      cate: data.cate || '',
      tool_llm: data.tool_llm || 'default',
      json_parser: data.json_parser || '1',
      json_field: data.json_field || '',
      llm_prompt: data.llm_prompt || '',
      direct_answer: data.direct_answer || '',
      polish_prompt: data.polish_prompt || '',
      status: data.status || 0,
    };
    if (data.uid) {
      params.uid = data.uid;
    }
    try {
      const res = await saveFanRetention(params);
      if (res.code === 200) {
        message.success(t('common.saveSuccess'));
        setDrawerVisible(false);
        getFanInfo();
      } else {
        message.error(t('common.saveFailed'));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const initForm = {
    agent_id: agent_id,
    is_condition_answer: false,
    condition_answer_arr: [],
    strategy_type: '',
    cate: '',
    tool_llm: 'default',
    json_parser: '1',
    json_field: '',
    llm_prompt: '',
    direct_answer: '',
    polish_prompt: '',
  };

  const [form, setForm] = useState<DataItem>(initForm);

  useEffect(() => {
    console.log(form);
    setConditionForm({
      is_condition_answer: form.is_condition_answer,
      condition_answer_arr: form.condition_answer_arr || [],
    });
    setStandardForm({
      strategy_type: form.strategy_type || 'answer',
      cate: form.cate || '1',
      tool_llm: form.tool_llm || 'default',
      json_parser: form.json_parser || '1',
      json_field: form.json_field || '',
      llm_prompt: form.llm_prompt || '',
      direct_answer: form.direct_answer || '',
      polish_prompt: form.polish_prompt || '',
    });
  }, [form]);

  const conditionRef = useRef(null);
  const [conditionForm, setConditionForm] = useState<DataItem>({
    condition_answer_arr: [],
    is_condition_answer: false,
  });

  // 标准策略
  const standardRef = useRef(null);
  const [standardForm, setStandardForm] = useState<DataItem>({});

  const handleDelete = (record: any) => {
    Modal.confirm({
      title: t('common.delete'),
      content: t('common.confirmDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        const res = await deleteFanRetention({
          uid: record.uid,
          agent_id,
        });
        if (res.code === 200) {
          message.success(t('common.deleteSuccess'));
          getFanInfo();
        }
      },
      onCancel: () => {
        message.error(t('common.deleteFailed'));
      },
    });
  };
  const handleEdit = (data: any, record: any) => {
    setForm({ ...record });
    setTimeout(() => {
      setDrawerVisible(true);
    });
    setIsEdit(true);
  };

  const changeStatus = (e: any, record: any) => {
    console.log(e);
    console.log(record);
    const params = {
      ...record,
    };
    if (e) {
      params.status = 1;
    } else {
      params.status = 0;
    }
    saveFan(params);
  };

  const columns: TableColumnsType = [
    {
      title: t('common.sort'),
      dataIndex: 'order',
      key: 'order',
      width: 50,
      render: () => {
        return (
          <span className="drag-icon">
            <HolderOutlined style={{ cursor: 'move' }} />
          </span>
        );
      },
    },
    {
      title: t('marketingCustomer.customerRetention.fanScript2'),
      dataIndex: 'cate',
      key: 'cate',
      ellipsis: {
        showTitle: false,
      },
      render: (text: string, row: any) => {
        return (
          <Tooltip
            placement="topLeft"
            title={
              text === '1'
                ? row.llm_prompt
                : text === '3'
                  ? row.direct_answer
                  : text === '4'
                    ? row.polish_prompt
                    : ''
            }
          >
            <span className="whitespace-nowrap overflow-hidden text-ellipsis">
              {text === '1' && row.llm_prompt}
              {text === '3' && row.direct_answer}
              {text === '4' && row.polish_prompt}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: t('marketingCustomer.customerRetention.scriptStrategy'),
      dataIndex: 'cate',
      key: 'cate',
      width: 200,
      render: (text: string, row: any) => {
        return (
          <span className="whitespace-nowrap overflow-hidden text-ellipsis">
            {text === '1' && t('marketingCustomer.customerRetention.llmAnswer')}
            {text === '3' && t('marketingCustomer.customerRetention.fixedScript')}
            {text === '4' && t('marketingCustomer.customerRetention.polishReply')}
          </span>
        );
      },
    },
    {
      title: t('common.status.name'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text: number, row: any) => {
        return (
          <div>
            <Switch defaultChecked={text === 1} onChange={e => changeStatus(e, row)} />
          </div>
        );
      },
    },
    {
      title: t('common.operation'),
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: 188,
      render: (text: string, record: any, index: number) => (
        <>
          <Button type="link" size="small" onClick={() => handleEdit(index, record)}>
            {t('common.edit')}
          </Button>
          <Button type="link" danger size="small" onClick={() => handleDelete(record)}>
            {t('common.delete')}
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="customerRetention-config p-5 h-full overflow-y-scroll">
      <div className="bg-white rounded">
        <div className="config-header flex justify-between items-center pb-4">
          <p className="title-p">
            {t('marketingCustomer.customerRetention.fanScript')}
            {/* <Button type='primary' className='float-right' onClick={saveRetention}>保存</Button> */}
          </p>
          <p className="title-p">
            <Button type="primary" onClick={addFan}>
              {t('marketingCustomer.customerRetention.addFanScript')}
            </Button>
          </p>
        </div>
        <div className="py-5">
          <InputNumber
            min={5}
            max={300}
            defaultValue={60}
            value={frs_first_trigger_timeout}
            onChange={(value: any) => {
              value && setFrs_first_trigger_timeout(value);
              saveRetention({ trigger_timeout: value });
            }}
          />
          &nbsp;&nbsp;{t('marketingCustomer.customerRetention.secondsNoMessage')}&nbsp;&nbsp;
          <InputNumber
            min={5}
            max={300}
            defaultValue={10}
            value={frs_trigger_interval}
            onChange={(value: any) => {
              value && setFrs_trigger_interval(value);
              saveRetention({ trigger_interval: value });
            }}
          />
          &nbsp;&nbsp;{t('marketingCustomer.customerRetention.secondsNextScript')}
        </div>
      </div>
      <div className="bg-white rounded">
        <div className="retention-draggable">
          {moveList.length > 0 && (
            <Table
              size="small"
              dataSource={moveList}
              pagination={false}
              columns={columns}
              rowKey="order"
              bordered
            ></Table>
          )}
        </div>
      </div>
      <Drawer
        className="customRetention-drawer"
        title={isEdit ? t('marketingCustomer.customerRetention.editFanScript') : t('marketingCustomer.customerRetention.addFanScript')}
        placement="right"
        onClose={cancelAction}
        open={drawerVisible}
        width={800}
        destroyOnClose={true}
        extra={
          <Space>
            <Button onClick={cancelAction}>{t('common.cancel')}</Button>
            <Button type="primary" onClick={handleSave}>
              {t('common.save')}
            </Button>
          </Space>
        }
      >
        <div className="customRetention-drawer-content">
          <h4 className="text-text_color_black text-base font-medium">{t('marketingCustomer.customerRetention.scriptStrategy')}</h4>
          <ConditionalStrategy ref={conditionRef} formState={conditionForm} type="2" />
        </div>
        <div>
          <StandardStrategyForFan ref={standardRef} formState={standardForm} />
        </div>
      </Drawer>
    </div>
  );
};

export default CustomerRetention;
