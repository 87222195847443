import { http } from '../service/http';

type Result = {
  code: number;
  description?: string;
  data: Array<any>;
};
// tiktok渠道
export const fetchTiktokList = (params: object) => {
  return http.request<Result>('post', '/tiktok/config/list', {
    data: params,
  });
};
export const deleteTiktok = (params: object) => {
  return http.request<Result>('post', '/tiktok/config/delete', {
    data: params,
  });
};
export const getPreAuthUrl = (params: object) => {
  return http.request<Result>('post', '/tiktok/config/get_pre_auth_url', {
    data: params,
  });
};
