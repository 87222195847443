import React from 'react';
import { Handle, Position, useReactFlow, useNodes } from 'reactflow';
import { generateUUID } from '@/utils/common';
import { Modal, message, Dropdown, Menu, Tag, MenuProps } from 'antd';
import { t } from 'i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type SlotProps = {
  id: string;
  type: string;
  label: string;
  data: any;
  xPos: number;
  yPos: number;
};

const { confirm } = Modal;

const CustomNode = (slotProps: any) => {
  const { addNodes, deleteElements } = useReactFlow();
  // console.log(slotProps, 'slotProps=======');
  const copyNode = (copyData: SlotProps) => {
    const newNode = {
      id: `${copyData.data.flowType}_${generateUUID()}`,
      type: copyData.type,
      position: {
        x: copyData.xPos,
        y: copyData.yPos + 100,
      },
      label: copyData.data.label,
      data: copyData.data,
    };
    addNodes([newNode]);
  };

  const deleteNode = (data: SlotProps) => {
    confirm({
      title: t('common.confirmDeleteNode'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk() {
        deleteElements({ nodes: [data] });
        message.success(t('common.deleteSuccess'));
      },
    });
  };

  const filterCateName = (cate: number) => {
    const catMap: { [key: number]: string } = {
      1: t('agentEngine.replyType3'),
      3: t('agentEngine.replyType1'),
      4: t('agentEngine.replyType2'),
      5: t('flowForm.humanService'),
      6: t('flowForm.keyboardService'),
      7: t('flowForm.hangupService'),
      8: t('flowForm.knowledgeBaseAnswer'),
      11: t('agentEngine.replyType4'),
    };
    return catMap[cate] || '';
  };

  const filterCon = (form: any) => {
    switch (form.cate) {
      case '1':
        return form.llm_prompt;
      case '3':
        return form.direct_answer;
      case '4':
        return form.polish_prompt;
      case '5':
        return form.skill_num;
      case '6':
        return form.keyboard_scene;
      case '11':
        return form.net_search_prompt;
      default:
        return '';
    }
  };
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <span onClick={() => copyNode(slotProps)}>{t('common.copy')}</span>,
    },
    {
      key: '2',
      label: <span onClick={() => deleteNode(slotProps)}>{t('common.delete')}</span>,
    },
  ];

  return (
    <div
      className={`custom-node ${['start', 'end', 'next'].includes(slotProps.data.flowType) ? 'input-node' : ''}`}
    >
      {slotProps.data && (
        <>
          {slotProps.data.flowType !== 'start' && <Handle type="target" position={Position.Left} />}
          <div className="node-div">
            <span
              className={`iconfont node-icon ${slotProps.data.flowType === 'start' ? 'kaishi' : slotProps.data.iconfont}`}
              style={{ backgroundColor: slotProps.data.bgcolor }}
            />
            {slotProps.data.label}
            {['start', 'end', 'next'].includes(slotProps.data.flowType) ? null : (
              <Dropdown menu={{ items }}>
                <span className="el-dropdown-link">
                  <span className="iconfont gengduo-heng" />
                </span>
              </Dropdown>
            )}
            {['start', 'end', 'next'].includes(slotProps.data.flowType) ? null : (
              <div className="filter-box">
                {slotProps.data.flowType === 'llm' && slotProps.data.form.llm_prompt && (
                  <div className="text-div" title={slotProps.data.form.llm_prompt}>
                    {slotProps.data.form.llm_prompt}
                  </div>
                )}
                {slotProps.data.flowType === 'func' && slotProps.data.form.func_alias && (
                  <div className="text-div" title={slotProps.data.form.func_alias}>
                    {slotProps.data.form.func_alias}
                  </div>
                )}
                {slotProps.data.flowType === 'variable' &&
                  slotProps.data.form.form &&
                  slotProps.data.form.form.length > 0 && (
                    <div className="text-div">
                      {slotProps.data.form.form.map((item: any, index: number) => (
                        <div key={index} className="var-item" title={item.var_name}>
                          {item.var_name}
                        </div>
                      ))}
                    </div>
                  )}
                {slotProps.data.flowType === 'reply' && (
                  <div className="text-div">
                    <div className="tag-div">
                      <Tag style={{ marginRight: 10 }} color="processing">
                        {slotProps.data.form.strategy_type === 'action'
                          ? t('common.action')
                          : t('common.reply')}
                      </Tag>
                      <Tag color="processing">{filterCateName(slotProps.data.form.cate)}</Tag>
                    </div>
                    <div className="con-div" title={filterCon(slotProps.data.form)}>
                      {filterCon(slotProps.data.form)}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {!['end', 'next'].includes(slotProps.data.flowType) && (
            <Handle type="source" position={Position.Right} />
          )}
        </>
      )}
    </div>
  );
};

export default CustomNode;
