import React, { useEffect, useState } from 'react';
import { Tooltip, Image, Button, Collapse, Tag } from 'antd';
import {t} from 'i18next';
import { FileOutlined } from '@ant-design/icons';
import './scss/index.scss';
import robotAvatar from '@/assets/images/headR.png';
import userAvatar from '@/assets/images/headF.png';
import codeAvatar from '@/assets/images/code.png';
import kefuAvatar from '@/assets/images/kefu.png';
import agentAvatar from '@/assets/images/headA.png';
import partTag from '@/assets/images/partTag.png';
import greenCircle from '@/assets/images/greenCircle.png';
import docImg from '@/assets/images/doc.png';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { cloneDeep } from 'lodash';
import { produce } from 'immer';
import TextCollapse from './TextCollapse';
import { createRoot } from 'react-dom/client';
import ImagePreview from './ImagePreview';

interface Message {
  type: string;
  msg_time?: string;
  welcome?: boolean;
  log?: any;
  text?: string;
  code?: string;
  showFileList?: boolean;
  answer_res_list?: Array<any>;
  origin_message?: string;
  content_type?: string;
  showDoc?: boolean;
  source?: number;
  msg_tag?: number;
  agent_msg?: string;
}

interface MessageListProps {
  messages: Message[];
  imageList: any[];
  setDialogTableVisible: (data: boolean) => void;
  debugInfo: any[];
  setDebugInfo: (data: any[]) => void;
  setDocDetail: (data: any[]) => void;
  setCheckDocVisible: (data: boolean) => void;
  setSize?: (data: number) => void;
}
const LogMap = {
  '1': t('agentEngine.replyType3'),
  '2': t('common.classification'),
  '3': t('agentEngine.replyType1'),
  '4': t('agentEngine.replyType2'),
  '5': t('flowForm.humanService'),
  '6': t('flowForm.keyboardService'),
  '7': t('flowForm.hangupService'),
  '11': t('agentEngine.replyType4'),
  '12': t('agentEngine.replyType5')
};
const codeMap = {
  4007: 'jinggao-sanjiao',
  4008: 'quxiao-mian',
};
const msgTypeMap = {
  4: t('status.notEnabled'),
  3: t('status.partiallyAdopted'),
  2: t('status.fullyAdopted'),
  1: t('status.notAdopted'),
};
function addPreviewToImages(html: string, openPreview: (url: string) => void): string {
  const div = document.createElement('div');
  div.innerHTML = html;
  const images = div.getElementsByTagName('img');
  console.log(images);
  if (images.length === 0) {
    return html;
  }
  for (let i = 0; i < images.length; i++) {
    images[i].setAttribute('data-src', images[i].src);
    // 使用React的事件处理
    images[i].onclick = () => openPreview(images[i].getAttribute('data-src')!); // 修改此处使其获得data-src作为参数
    images[i].classList.add('previewable-image');
  }
  return div.innerHTML;
}

const MessageList: React.FC<MessageListProps> = ({
  messages,
  imageList,
  setDialogTableVisible,
  debugInfo,
  setDebugInfo,
  setDocDetail,
  setCheckDocVisible,
  setSize,
}) => {
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  // 预览图片的函数
  const openPreview = (url: string) => {
    const images = document.querySelectorAll('.previewable-image');
    const urls = Array.from(images).map((img: Element) => {
      return img.getAttribute('data-src') || '';
    });
    const currentIndex = urls.indexOf(url);
    const container = document.createElement('div');
    container.style.display = 'none';
    document.body.appendChild(container);
    const root = createRoot(container);
    root.render(
      <Image.PreviewGroup
        preview={{
          current: currentIndex,
          visible: true,
          onVisibleChange: visible => {
            if (!visible) {
              root.unmount();
              document.body.removeChild(container);
            }
          },
        }}
      >
        {urls.map((src, index) => (
          <Image key={index} src={src} />
        ))}
      </Image.PreviewGroup>
    );
  };
  const handleImageClick = (e: any) => {
    const url = (e.target as HTMLImageElement).getAttribute('data-src')!;
    openPreview(url);
  };
  useEffect(() => {
    const images = document.querySelectorAll('.previewable-image');
    images.forEach(img => {
      img.addEventListener('click', handleImageClick);
    });
    return () => {
      images.forEach(img => {
        img.removeEventListener('click', handleImageClick);
      });
    };
  }, [messages]);
  // 开始调试
  const startDebug = (row: any) => {
    setSize && setSize(1200);
    setDialogTableVisible(true);
    setCheckDocVisible(false);
    const log = row.debug || row.debug_info || [];
    const debugArr: any[] = [];
    // 有调试信息
    log.forEach((o: any) => {
      let one;
      let key = 'tool_prompt';
      if ('tool_log' in o) {
        key = 'tool_prompt';
        one = o['tool_log'];
      }
      if ('query_log' in o) {
        key = 'tool_map';
        one = o['query_log'];
      }
      if ('scene_log' in o) {
        key = 'scene_log';
        one = o['scene_log'];
      }
      if ('image_floor_log' in o) {
        key = 'image_floor_log';
        one = o['image_floor_log'];
      }
      if ('small_talk_log' in o) {
        key = 'small_talk_log';
        one = o['small_talk_log'];
      }
      if ('frs_log' in o) {
        key = 'frs_log';
        one = o['frs_log'];
      }
      if (o['query_log'] && 'rag_log' in o['query_log']) {
        if (o['query_log'].rag_log) {
          debugArr.push({
            title: 'rag_log',
            question: o['query_log'].rag_log.question || '',
          });
        }
      }
      if (one) {
        console.log(one);
        const newOne = {
          sid: log['sid'],
          title: key,
          content: one['content'],
          prompt: one['prompt'],
          response: one['response'],
          info: one['info'],
          tool_llm: 'tool_llm' in one ? one['tool_llm'] : 'minimax',
          ai_slots: 'ai_slots' in one ? JSON.stringify(one['ai_slots']) : '{}',
          messages: 'messages' in one ? one['messages'] : null,
          add_info: 'add_info' in o ? o['add_info'] : {},
          json_parser: one['json_parser'] || '',
          option: one['option'] || [],
          rag_log: 'rag_log' in one ? one['rag_log'] : null,
        } as any;
        if (key === 'frs_log') {
          // 追粉话术如选择的是固定话术，调试日志跟其他类型要有区别
          newOne.log = row['log'];
        }
        if (key === 'tool_map' && newOne.content === '') {
          if (one['rag_log'] && 'prompt' in one['rag_log']) {
            newOne.content = one['rag_log']['prompt'] || '';
          }
        }
        debugArr.push(newOne);
      }
      if (o['query_log'] && o['query_log'].answer_log) {
        // 这个产品要求顺序要在下面
        debugArr.push({
          title: 'answer_log',
          answer_log: o['query_log'].answer_log || [],
        });
      }
      if (o['guidance_log']) {
        debugArr.push({
          title: 'guidance_log',
          guidance_name: o['guidance_log'].guidance_name || '',
          name: o['guidance_log'].name || '',
          response: o['guidance_log'].response || '',
        });
      }
      const flowLog = o['flow_log'];
      if (flowLog) {
        debugArr.push({
          title: 'flow_log',
          path_link: flowLog.path_link || '',
          response: row.text || '',
          path: flowLog.path,
          log: row.log,
        });
      }
      if (o['image_log']) {
        debugArr.push({
          title: 'image_log',
          response: o['image_log'].response || '',
          add_info: 'add_info' in o ? o['add_info'] : {},
        });
      }
    });
    console.log(debugArr);
    setDebugInfo(debugArr);
  };
  // 查看知识库文档
  const showDoc = (data: any) => {
    setSize && setSize(1200);
    setCheckDocVisible(true);
    setDialogTableVisible(false);
    setDocDetail(data);
  };

  const handleDebug = (row: any) => {
    const obj = { related_docs: [] };
    const log = row.debug || row.debug_info || []; // 测试窗口是debug, 会话日志是debug_info
    // 有调试信息
    log.forEach((item: any) => {
      if (item.query_log && item.query_log.rag_log) {
        Object.assign(obj, item.query_log.rag_log);
      }
    });
    return obj;
  };

  function renderMsgClassName(type: string | number) {
    let className = '';
    if (['assistant', 1].includes(type)) {
      className = 'robot-message';
    }
    if (['user', 2].includes(type)) {
      className = 'user-message';
    }
    if (['askhuman', 3].includes(type)) {
      className = 'robot-message';
    }
    return className;
  }
  function renderAvatar(type: string | number) {
    if (['assistant', 1].includes(type)) {
      return robotAvatar;
    }
    if (['user', 2].includes(type)) {
      return userAvatar;
    }
    if (['askhuman', 3].includes(type)) {
      return agentAvatar;
    }
    return '';
  }
  function renderName(message: any) {
    if (['assistant', 1].includes(message.type)) {
      return currentAgent.name;
    }
    if (['user', 2].includes(message.type)) {
      return t('common.visitor');
    }
    if (['askhuman', 3].includes(message.type)) {
      return message.user_nickname || message.askhuman_user_nickname;
    }
    return '';
  }
  return (
    <div className="message-list">
      {messages.map((message: any, index) => {
        const processedText = addPreviewToImages(message.text || '', openPreview);
        return (
          <div key={index} className={renderMsgClassName(message.type)}>
            <div className="message-content">
              {/* 头像 */}
              <div className="avatar-img">
                <img src={renderAvatar(message.type)} alt="avatar" className="avatar" />
              </div>

              <div className="msg-block">
                {/* 名称以及时间 */}
                <div className="flex align-center agent-name">
                  {renderName(message)}&nbsp;
                  {message.msg_time && message.msg_time.split(' ')[1]}
                  {message && message.log && message.log.biz_msg_type === '1' && (
                    <Tag className="chasing-fans-tag" color="success">
                      {t('common.chasingFans')}
                    </Tag>
                  )}
                  {['assistant', 1].includes(message.type) && !message.welcome && (
                    <img
                      onClick={() => startDebug(message)}
                      className="pointer code"
                      src={codeAvatar}
                      alt=""
                    />
                  )}
                  {['assistant', 1].includes(message.type) && message.welcome && (
                    <Tooltip title={t('common.welcome')}>
                      <span className="pointer iconfont huanyingyu" />
                    </Tooltip>
                  )}
                </div>
                {message.log &&
                  message.log.action != '8' &&
                  !message.text &&
                  !message.content_type && (
                    <div className="msg" style={{ color: '#09c' }}>
                      {
                        <p>
                          Action: &nbsp;
                          {
                            // @ts-expect-error unknown
                            LogMap[message.log.action]
                          }
                        </p>
                      }
                      {['5', '6'].includes(message.log.action) && (
                        <p style={{ marginTop: '10px' }}>
                          {message.log.action === '5' ? t('flowForm.prioritySkillGroup') : t('flowForm.keyboardScene')}
                          &nbsp;
                          {message.log.action === '5'
                            ? message.log.skill_num
                            : message.log.keyboard_scene}
                        </p>
                      )}
                      {['12'].includes(message.log.action) && message.log.skill_num && (
                      // {['12'.includes(message.log.action)] && message.log.skill_num && (
                        <p style={{ marginTop: '10px' }}>
                          {t('flowForm.cloudServiceSkillGroupNumber')}:
                          &nbsp;
                          {message.log.skill_num}
                        </p>
                      )}
                    </div>
                  )}
                <div
                  className={`msg ${message.code ? 'error-code' : ''}`}
                  hidden={
                    message.log &&
                    message.log.action !== '8' &&
                    !message.text &&
                    !message.content_type
                  }
                >
                  {message.showFileList ? (
                    message.answer_res_list?.map((fileItem: any, index: number) => (
                      <div className="file-list-item" key={index}>
                        {['jpg', 'jpeg','png', 'gif', 'bmp', 'tiff'].includes(fileItem.type) && (
                          <img src={fileItem.url} alt={fileItem.name} />
                        )}
                        {['mp4', 'avi', 'wmv', 'flv', 'mov'].includes(fileItem.type) && (
                          <video src={fileItem.url} controls />
                        )}
                        {![
                          'jpg',
                          'jpeg',
                          'png',
                          'gif',
                          'bmp',
                          'tiff',
                          'mp4',
                          'avi',
                          'wmv',
                          'flv',
                          'mov',
                        ].includes(fileItem.type) && (
                          <a
                            href={fileItem.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            download={fileItem.url}
                            className="file-a"
                          >
                            <Image style={{ width: '20px', height: '20px' }} src={docImg} />
                            <Button className="file-name" type="link">
                              {fileItem.name}
                            </Button>
                            <span className="iconfont xiazai" />
                          </a>
                        )}
                      </div>
                    ))
                  ) : (
                    <>
                      {!message.origin_message && (
                        <span>
                          <pre>
                            <div dangerouslySetInnerHTML={{ __html: processedText }} />
                          </pre>
                          <TextCollapse message={message} />
                        </span>
                      )}
                      {['image'].includes(message.content_type) && (
                        <ImagePreview imageList={imageList} message={message} messages={messages} />
                      )}
                      {['file'].includes(message.content_type) && (
                        <a
                          href={message.origin_message}
                          download
                          target="_blank"
                          className="webchat-file underline"
                          rel="noreferrer"
                        >
                          {message.origin_message.substring(
                            message.origin_message.lastIndexOf('/') + 1,
                            message.origin_message.length
                          )}
                        </a>
                      )}
                      {message.log && (message.log.action === '8' || message.log.rag_flag === 1) && (
                        <div>
                          <Collapse
                            ghost
                            items={[
                              {
                                key: index,
                                showArrow: false,
                                label: (
                                  <div className="icon-div">
                                    {t('common.viewDocument')}
                                    <span className="iconfont direction-icon fangxiang-xia-mian-xiao" />
                                  </div>
                                ),
                                children: (
                                  <div className={'flex flex-wrap'}>
                                    {handleDebug(message).related_docs.map(
                                      (item: any, index: number) => (
                                        <div className="docs-list" key={index}>
                                          <span className="iconfont wenxian1"></span>
                                          <span className="border-span">{index + 1}、</span>
                                          <span
                                            className="source-span"
                                            onClick={() => showDoc(item)}
                                          >
                                            {item.source}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ),
                              },
                            ]}
                          ></Collapse>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {['assistant', 1].includes(message.type) && message.source === 1 && (
                  <div className="flex msg-tag" style={{ backgroundColor: '#ecf5ff' }}>
                    <div style={{ flexShrink: 0 }}>
                      <div className={`font-12 tag-${message.msg_tag}`}>
                        {
                          // @ts-expect-error unknown
                          msgTypeMap[message.msg_tag]
                        }
                        {message.msg_tag === 2 && (
                          <img src={greenCircle} style={{ width: '12px', marginLeft: '8px' }} />
                        )}
                        {message.msg_tag === 3 && (
                          <img src={partTag} style={{ width: '12px', marginLeft: '8px' }} />
                        )}
                      </div>
                    </div>
                    <div className="flex">
                      <img className="kefu" src={kefuAvatar} alt="" />
                      <div className="tag-content">{message.agent_msg}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MessageList;
