import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal, Form, Input, Button, Upload, message } from 'antd';
import { useTranslation } from 'react-i18next';
import uploadImg from '@/assets/images/upload.png';

interface UploadFile {
  uid: string;
  name: string;
  status: 'done' | 'uploading' | 'error';
  url?: string;
}
interface KnowledgeForm {
  file: string;
  bytes: number;
  chunk_size: number;
  overlap: number;
}
interface ChildProps {
  onSave: (data: any, fileList: UploadFile[]) => void;
}
// 定义暴露给父组件的方法类型
interface ChildMethods {
  openDialog: (value: any) => void;
}

// 使用`React.forwardRef`来获取`ref`传递
const ImportKm = forwardRef<ChildMethods, ChildProps>((props, ref) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm<KnowledgeForm>();
  // 打开弹窗
  const openDialog = () => {
    setIsVisible(true);
    setFileList([]);
    form.resetFields();
  };
  // 通过`useImperativeHandle`暴露特定的方法给父组件
  useImperativeHandle(ref, () => ({
    // 将`openDialog`方法暴露给父组件
    openDialog,
  }));
  const onClose = () => setIsVisible(false);
  const handleSave = () => {
    form
      .validateFields()
      .then(values => {
        if (!values) {
          return;
        }
        if (values.chunk_size / 2 < values.overlap) {
          message.error(t('knowledge.overlapError'));
          return;
        }
        if (fileList.length <= 0) {
          message.error(t('knowledge.uploadError'));
          return;
        }
        props.onSave(values, fileList);
        setIsVisible(false);
      })
      .catch(info => {
        console.error('Validate Failed:', info);
      });
  };
  const fileProps = {
    multiple: true,
    accept: '.txt,.pdf,.docx,.xls,.xlsx,.csv',
    beforeUpload: (file: File) => {
      if (file.size / 1024 / 1024 > 50) {
        message.error(t('knowledge.fileSizeError'));
      }
      return false;
    },
    onChange(info: any) {
      console.log(info.fileList);
      setFileList(info.fileList);
    },
    onDrop(e: any) {
      setFileList(e.dataTransfer.files);
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <Modal
      title={t('knowledge.importDocument')}
      open={isVisible}
      onCancel={onClose}
      width={600}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('common.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={handleSave}>
          {t('common.save')}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" initialValues={{ chunk_size: 256, overlap: 50 }}>
        <Form.Item name="file" rules={[{ required: true, message: t('knowledge.uploadFile') }]}>
          <Upload.Dragger {...fileProps}>
            <div className="km-upload-img">
              <img src={uploadImg} />
              {t('knowledge.dragFileHere')} <span>{t('knowledge.clickUpload')}</span>
            </div>
          </Upload.Dragger>
        </Form.Item>
        <p className="km-upload-tip">{t('knowledge.uploadTip')}</p>
        <Form.Item
          name="chunk_size"
          className="custom-form-item"
          label={
            <>
              <span>{t('knowledge.chunkSizeLabel')}</span>
              <span className="right-tip">{t('knowledge.chunkSizeTip')}</span>
            </>
          }
          rules={[
            { required: true, message: t('knowledge.chunkSizePlaceholder') },
            {
              pattern: /^(12[89]|1[3-9][0-9]|[23][0-9]{2}|4[0-9]{2}|50[0-9]|51[0-2])$/,
              message: t('knowledge.chunkSizeError'),
            },
          ]}
        >
          <Input placeholder={t('knowledge.chunkSizePlaceholder')} />
        </Form.Item>
        <Form.Item
          name="overlap"
          className="custom-form-item"
          label={
            <>
              <span>{t('knowledge.overlapLabel')}</span>
              <span className="right-tip">{t('knowledge.overlapTip')}</span>
            </>
          }
          rules={[{ required: true, message: t('knowledge.overlapPlaceholder') }]}
        >
          <Input placeholder={t('knowledge.overlapPlaceholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
ImportKm.displayName = 'ImportKm';
export default ImportKm;
