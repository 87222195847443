import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Form, Input, Radio, Select } from 'antd';
import { t } from 'i18next';
import { useForm } from 'antd/lib/form/Form';
import { conditionRule } from '@/constant/options';
import VarNameSelect from '@/components/AgentForm/VarNameSelect';
const { Option } = Select;

interface ConditionFormProps {
  form: Record<string, any>;
  id: string;
}

type ConditionType = {
  condition: string;
  value: string;
  var_name: string;
  json_parser: string;
  type: string;
};

type FormValues = {
  name: string;
  rule: {
    cond_group: ConditionType[];
    cond_type: string;
  };
};

const ConditionRuleForm = forwardRef<any, ConditionFormProps>(({ form }, ref) => {
  const [formRef] = useForm();
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    rule: {
      cond_group: [
        {
          condition: 'B',
          value: '',
          var_name: 'action',
          json_parser: '2',
          type: 'llm',
        },
      ],
      cond_type: 'all',
    },
  });

  const typeArr = [
    { label: t('flowForm.variable'), value: 'variable' },
    { label: t('flowForm.llmResult'), value: 'llm' },
  ];

  const parseArr = [
    { label: t('flowForm.allInfo'), value: '1' },
    { label: t('flowForm.specifiedInfo'), value: '2' },
  ];

  const addCondition = () => {
    setFormValues(prev => ({
      ...prev,
      rule: {
        ...prev.rule,
        cond_group: [
          ...prev.rule.cond_group,
          {
            condition: 'B',
            value: '',
            var_name: 'action',
            json_parser: '2',
            type: 'llm',
          },
        ],
      },
    }));
  };

  const deleteCondition = (index: number) => {
    setFormValues(prev => ({
      ...prev,
      rule: {
        ...prev.rule,
        cond_group: prev.rule.cond_group.filter((_, i) => i !== index),
      },
    }));
  };
  const validate = async () => {
    try {
      const values = await formRef.validateFields();
      console.log({ ...values, ...formValues });
      return { ...values, ...formValues };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const resetFields = () => {
    formRef.resetFields();
  };
  useEffect(() => {
    formRef.setFieldsValue(form);
    setFormValues({ name: form.name, rule: form.rule });
  }, [form]);
  useImperativeHandle(ref, () => ({
    validate,
    resetFields,
  }));
  return (
    <Form
      form={formRef}
      layout="vertical"
      className="px-5 pt-5 pb-1 bg-white rounded"
      initialValues={formValues}
    >
      <Form.Item
        label={t('flowForm.ruleName')}
        name={['name']}
        rules={[{ required: true, message: t('flowForm.inputRuleName') }]}
      >
        <Input
          maxLength={50}
          placeholder={t('common.input')}
          onChange={e => {
            setFormValues({ ...formValues, name: e.target.value });
          }}
        />
      </Form.Item>
      <Form.Item>
        {formValues.rule.cond_group.map((listItem, index) => (
          <div className="variable-box" key={index}>
            <Select
              className="mg-r-12"
              value={listItem.type}
              placeholder={t('common.select')}
              filterOption={(input, option) =>
                (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              onChange={value =>
                setFormValues(prev => {
                  const updated = [...prev.rule.cond_group];
                  updated[index].type = value;
                  return {
                    ...prev,
                    rule: {
                      ...prev.rule,
                      cond_group: updated,
                    },
                  };
                })
              }
            >
              {typeArr.map(item => (
                <Option value={item.value} key={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>

            {listItem.type === 'variable' && (
              <div className="mg-r-12">
                <VarNameSelect
                  value={listItem.var_name}
                  onChange={(value: string) =>
                    setFormValues(prev => {
                      const updated = [...prev.rule.cond_group];
                      updated[index].var_name = value;
                      return {
                        ...prev,
                        rule: {
                          ...prev.rule,
                          cond_group: updated,
                        },
                      };
                    })
                  }
                />
              </div>
            )}

            {listItem.type === 'llm' && (
              <Select
                className="mg-r-12"
                value={listItem.json_parser}
                placeholder={t('common.select')}
                filterOption={(input, option) =>
                  (option?.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={value =>
                  setFormValues(prev => {
                    const updated = [...prev.rule.cond_group];
                    updated[index].json_parser = value;
                    return {
                      ...prev,
                      rule: {
                        ...prev.rule,
                        cond_group: updated,
                      },
                    };
                  })
                }
              >
                {parseArr.map(item => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            )}

            {listItem.json_parser === '2' && listItem.type !== 'variable' && (
              <Input
                className="mg-r-12"
                value={listItem.var_name}
                placeholder={t('common.input')}
                onChange={e =>
                  setFormValues(prev => {
                    const updated = [...prev.rule.cond_group];
                    updated[index].var_name = e.target.value;
                    return {
                      ...prev,
                      rule: {
                        ...prev.rule,
                        cond_group: updated,
                      },
                    };
                  })
                }
              />
            )}

            <Select
              className="mg-r-12"
              value={listItem.condition}
              placeholder={t('flowForm.selectOperator')}
              filterOption={(input, option) =>
                (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              onChange={value =>
                setFormValues(prev => {
                  const updated = [...prev.rule.cond_group];
                  updated[index].condition = value;
                  return {
                    ...prev,
                    rule: {
                      ...prev.rule,
                      cond_group: updated,
                    },
                  };
                })
              }
            >
              {conditionRule.map(item => (
                <Option value={item.value} key={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>

            {!['A', 'B'].includes(listItem.condition) && (
              <Input
                className="mg-r-12"
                value={listItem.value}
                maxLength={50}
                placeholder={['E', 'F'].includes(listItem.condition) ? t('flowForm.multiValueSeparator') : t('common.input')}
                onChange={e =>
                  setFormValues(prev => {
                    const updated = [...prev.rule.cond_group];
                    updated[index].value = e.target.value;
                    return {
                      ...prev,
                      rule: {
                        ...prev.rule,
                        cond_group: updated,
                      },
                    };
                  })
                }
              />
            )}

            <span className="iconfont shanchuquan" onClick={() => deleteCondition(index)} />
          </div>
        ))}
        <div className="iconfont jiahao" onClick={addCondition}>
          {t('flowForm.addCondition')}
        </div>
      </Form.Item>

      <Form.Item label={t('flowForm.executeLogic')} name={['rule', 'cond_type']}>
        <Radio.Group
          value={formValues.rule.cond_type}
          onChange={e => {
            setFormValues({
              ...formValues,
              rule: { ...formValues.rule, cond_type: e.target.value },
            });
          }}
        >
          <Radio value="all">{t('flowForm.allCondition')}</Radio>
          <Radio value="any">{t('flowForm.anyCondition')}</Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});
ConditionRuleForm.displayName = 'ConditionRuleForm';
export default ConditionRuleForm;
