import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button, Table, Modal, Tooltip } from 'antd';
import type { TableColumnsType } from 'antd';
import Authorization from './Authorization';
import { queryAgentList } from '@/api/agent';
import { fetchTiktokList, deleteTiktok } from '@/api/channel';
import tikTokPng from '@/assets/images/TikTok.png';
import './tiktok.css';

const TikTok: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [agentMap, setAgentMap] = useState<any>([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0 });
  const [showCharacter, setShowCharacter] = useState(true);
  const authorizationRef = useRef<{ openAuthorization: (value: any) => void }>(null);
  const handleConfig = (record: any) => {
    setShowCharacter(false);
    if (authorizationRef.current) {
      authorizationRef.current.openAuthorization(record);
    }
  };
  const handleDel = (record: any) => {
    console.log(record);
    Modal.confirm({
      title: '确定要删除吗?',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        // deleteTiktok({ account_id: record.account_id }).then(res => {
        //   if (res.code == 200) {
        //     message.success('删除成功');
        //     ffetchLogs({ page: pagination.current, size: pagination.pageSize });
        //   } else {
        //     message.error(res.message || '删除失败');
        //   }
        // });
      },
    });
  };
  const handleAuthorization = (record: any) => {
    console.log(record);
    setShowCharacter(false);
    if (authorizationRef.current) {
      authorizationRef.current.openAuthorization(null);
    }
  };
  const saveAuthorization = (record: any) => {
    console.log(record);
  };
  const columns: TableColumnsType = [
    { title: 'TikTok企业号名称', dataIndex: 'account_name', key: 'account_name' },
    {
      title: 'LLM Agent',
      dataIndex: 'agent_id',
      key: 'agent_id',
      render: (text: any, record: any) => <span>{agentMap[record.agent_id]?.name}</span>,
    },
    {
      title: (
        <Tooltip title="TikTok账号授权成功的有效期为1年，到期后需重新授权" placement="top">
          授权时间<span className="iconfont xinxi"></span>
        </Tooltip>
      ),
      dataIndex: 'auth_time',
      key: 'auth_time',
    },
    {
      title: '授权状态',
      dataIndex: 'auth_status',
      key: 'auth_status',
      render: (text: any, record: any) => (
        <span className={`auth-status ${record.auth_status == 'EXPIRED' ? 'auth-expired' : ''}`}>
          {record.auth_status == 'NOT_EXPIRED'
            ? '未过期'
            : record.auth_status === 'EXPIRED'
              ? '已过期'
              : ''}
        </span>
      ),
    },
    { title: '最新更新时间', dataIndex: 'update_time', key: 'update_time' },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      render: (text: any, record: any) => (
        <>
          <Button type="link" onClick={() => handleConfig(record)}>
            配置
          </Button>
          <Button type="link" onClick={() => handleDel(record)}>
            删除
          </Button>
          {record.auth_status === 'EXPIRED' && (
            <Button type="link" onClick={() => handleAuthorization(record)}>
              重新授权
            </Button>
          )}
        </>
      ),
    },
  ];
  const bindTikTok = () => {
    setShowCharacter(false);
    if (authorizationRef.current) {
      authorizationRef.current.openAuthorization(null);
    }
  };
  // 绑定之后返回
  const onGoBack = () => {
    setShowCharacter(true);
    fetchLogs({ page: pagination.current, size: pagination.pageSize });
  };
  const fetchLogs = useCallback(
    async (params = {}) => {
      setLoading(true);
      // const result = await fetchTiktokList({ ...params });
      // if (result.code === 200) {
      //   setData(result.data.result);
      // }
      const arr = [
        {
          account_id: '5feb7c2f7a794cce96b6ac614ff11455',
          account_name: '企业名字',
          agent_id: '81d7444843384b3fbad8e60a557c783d',
          auth_status: 'EXPIRED',
          auth_time: '2024-05-16 11:39:49',
          corp_id: null,
          create_time: null,
          create_user: null,
          delete: false,
          enable_welcome_msg: true,
          update_time: '2024-05-16 11:39:49',
          update_user: null,
        },
      ];
      // @ts-expect-error 先忽略
      setData(arr);
      setLoading(false);
    },
    [pagination]
  );

  useEffect(() => {
    fetchLogs({ page: pagination.current, size: pagination.pageSize });
  }, [fetchLogs, pagination.current, pagination.pageSize]);

  const handleTableChange = (newPagination: any) => {
    fetchLogs({
      page: newPagination.current,
      size: newPagination.pageSize,
    });
  };
  const getAgentList = () => {
    queryAgentList({ page: 1, size: 10000 }).then((res: any) => {
      if (res.code === 200) {
        setAgentList(res.data.result || []);
        handleAgentMap(res.data.result || []);
      }
    });
  };
  const handleAgentMap = (arr: any) => {
    const agentMapList = {} as any;
    arr.map((item: any) => {
      agentMapList[item.agent_id] = item;
    });
    console.log(agentMapList);
    setAgentMap(agentMapList);
  };
  useEffect(() => {
    getAgentList(); // 获取所有agent列表
  }, []);

  return (
    <div className="tiktok-box">
      {showCharacter && (
        <>
          <div className="tiktok-list-top flex">
            <img className="tiktok-log" src={tikTokPng} alt="" />
            <span className="tiktok-title-box">
              <p className="tiktok-title">TikTok</p>
              <p className="tiktok-tip">
                在此处绑定您的TikTok企业号后，即可使用Agent回复TikTok企业号的私信信息
              </p>
            </span>
            <Button type="primary" onClick={bindTikTok}>
              绑定TikTok
            </Button>
          </div>
          <div className="tiktok-list">
            <Table
              columns={columns}
              dataSource={data}
              loading={loading}
              pagination={pagination}
              onChange={handleTableChange}
              rowKey="sid"
              bordered
            />
          </div>
        </>
      )}
      <Authorization
        style={{ display: showCharacter ? 'none' : 'block' }}
        onSave={saveAuthorization}
        ref={authorizationRef}
        onGoBack={onGoBack}
        agentList={agentList}
      />
    </div>
  );
};

export default TikTok;
