import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Select, InputNumber, message, Row, Col, Switch, Radio, Input } from 'antd';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent, selectModelEnum } from '@/store/modules/agentTemplate';
import ScenePreAction from '@/components/AgentForm/ScenePreAction';
import { saveEngine } from '@/api/agent';
import './config.scss';
import { DataItem } from '@/store/modules/types';
import StandardStrategyForAgent from '@/components/AgentForm/StandardStrategyForAgent';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const ConfigComponent: React.FC = () => {
  const { t } = useTranslation();
  const formRef = useRef<any>(null);
  const preActionRef = useRef(null); // 场景前置动作
  const [form] = Form.useForm();
  const [initForm, setInitForm] = useState({
    message_interval: 1,
    tool_llm: 'doubao-pro-32k',
    default_sub_tool_llm: 'doubao-pro-32k',
    rag_llm: 'doubao-pro-32k',
    is_enable_rewrite: false,
  });
  const [preActionForm, setPreActionForm] = useState({
    is_enable_function: false,
    is_enable_entity: false,
    function_name: '',
    entity_arr: [],
  });
  const [imgFileData, setImgFileData] = useState<any>({
    floor_reply: t('agentEngine.config.floorReply'),
    strategy_type: 'answer',
    kb_rule_cate: '3',
    skill_num: '',
    turn_human_msg: '',
    ask_human_conf: {
      queues: [],
      remark: '',
      busy_answer: t('agentEngine.config.floorReply'),
    },
  });
  const [attachFileData, setAttachFileData] = useState<any>({
    floor_reply: t('agentEngine.config.floorReply'),
    strategy_type: 'answer',
    kb_rule_cate: '3',
    skill_num: '',
    turn_human_msg: '',
    ask_human_conf: {
      queues: [],
      remark: '',
      busy_answer: t('agentEngine.config.floorReply'),
    },
  });


  const rules = {
    tool_llm: [{ required: true, message: t('common.select')  }],
    default_sub_tool_llm: [{ required: true, message: t('common.select') }],
    rag_llm: [{ required: true, message: t('common.select') }],
  };
  const currentAgent = useAppSelector(selectCurrentAgent);
  const modelEnum = selectModelEnum();
  const save = async () => {
    try {
      await formRef.current.validateFields();
      // @ts-expect-error unknown
      const pass2 = await preActionRef.current?.validateFormFields();
      const info = currentAgent.currentAgent;
      const response = await saveEngine({
        agent_id: info.agent_id,
        ...initForm,
        ...pass2,
        img_file_config: imgFileData,
        attach_file_config: attachFileData
      });
      if (response.code === 200) {
        message.success(t('common.saveSuccess'));
      } else {
        message.error(t('common.saveFailed'));
      }
    } catch (error) {
      console.error(error);
      message.error(t('common.formValidationFailed'));
    }
  };

  const initData = () => {
    const {
      is_enable_entity,
      is_enable_function,
      function_name,
      entity_arr,
      message_interval,
      tool_llm,
      default_sub_tool_llm,
      rag_llm,
      is_enable_rewrite,
      img_file_config,
      attach_file_config
    } = currentAgent.currentAgent || {};
    setInitForm({
      message_interval: message_interval || 1,
      tool_llm: tool_llm || 'doubao-pro-32k',
      default_sub_tool_llm: default_sub_tool_llm || 'doubao-pro-32k',
      rag_llm: rag_llm || 'doubao-pro-32k',
      is_enable_rewrite: is_enable_rewrite || false,
    });
    setPreActionForm({
      is_enable_entity: is_enable_entity || false,
      is_enable_function: is_enable_function || false,
      function_name: function_name || '',
      entity_arr: entity_arr || [],
    });
    if (img_file_config) {
      setImgFileData(img_file_config);
    }
    if (attach_file_config) {
      setAttachFileData(attach_file_config);
    }
  };

  // 标准策略
  const standardRef = useRef(null);
  
  const changeData = (data: any, type: 'img' | 'file') => {
    if (data.strategy_type === 'answer') {
      data.kb_rule_cate = '3';
    }
    if (type === 'img') {
      setImgFileData({ ...data });
    } else {
      setAttachFileData({ ...data });
    }
  };
  

  useEffect(() => {
    initData();
  }, []);
  useEffect(() => {
    form.setFieldsValue(initForm);
  }, [initForm]);

  const dataChange = (e: any, field: string) => {
    setInitForm(prev => ({ ...prev, [field]: e.target.value }));
  };

  return (
      <div className="config">
        <div className="bg-white rounded">
          <div className="config-title">
            {t('agentEngine.config.defaultModel')}
            <Button type="primary" onClick={save} style={{float: 'right'}}>
              {t('common.save')}
            </Button>
          </div>
          <Form
              style={{padding: 16}}
              ref={formRef}
              form={form}
              initialValues={initForm}
              layout="vertical"
              onFinish={save}
              onValuesChange={changedValues => setInitForm(prev => ({...prev, ...changedValues}))}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label={t('agentEngine.config.roleSceneModel')} name="tool_llm" rules={rules.tool_llm}>
                  <Select placeholder={t('common.selectPrompt', { title: t('agentEngine.config.roleSceneModel') })}>
                    {modelEnum.map((item: string, index: number) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                    label={t('agentEngine.config.replyModel')}
                    name="default_sub_tool_llm"
                    rules={rules.default_sub_tool_llm}
                >
                  <Select placeholder={t('common.selectPrompt', { title: t('agentEngine.config.replyModel') })}>
                    {modelEnum.map((item: string, index: number) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t('agentEngine.config.RAGReplyModel')} name="rag_llm" rules={rules.rag_llm}>
                  <Select placeholder={t('common.selectPrompt', { title: t('agentEngine.config.RAGReplyModel') })}>
                    {modelEnum.map((item: string, index: number) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="bg-white rounded" style={{paddingBottom: 16}}>
          <div className="config-title">{t('agentEngine.config.agentPreAction')}</div>
          <div className="scenePreAction">
            <ScenePreAction ref={preActionRef} actionText="engine" formState={preActionForm}/>
            <div className="pre-label before:bg-primary_blue px-5" style={{marginTop: -16}}>
              <span className="ml-4">{t('agentEngine.config.latestUserIntent')}：</span>
              <Switch
                  checked={initForm.is_enable_rewrite}
                  onChange={checked =>
                      setInitForm((prev: any) => ({...prev, is_enable_rewrite: checked}))
                  }
              />
            </div>
          </div>
        </div>

        <div className="bg-white rounded" style={{paddingBottom: 16}}>
          <div className="config-title">{t('agentEngine.config.userSendImageFile')}</div>
          <div className="scenePreAction mx-5 mt-5">
            <div className="mt-5">
              <div className="mb-2">{t('agentEngine.config.userSendImage')}</div>
              <StandardStrategyForAgent showImageOption={true} ref={standardRef} data={imgFileData} changeData={(data) => changeData(data, 'img')}/>
            </div>
            <div className="mt-5 mb-2">
              <div className="mb-2">{t('agentEngine.config.userSendFile')}</div>
              <StandardStrategyForAgent ref={standardRef} data={attachFileData} changeData={(data) => changeData(data, 'file')}/>
            </div>
          </div>
        </div>

        <div className="bg-white rounded">
          <div className="config-title">{t('agentEngine.config.otherSettings')}</div>  
          <div className="form">
            <div className="el-form-item">
              <label className="el-form-item__label">
                <div className="pre-label before:bg-primary_blue flex items-center">
                  <span className="ml-4">{t('agentEngine.config.messageIntervalReply')}</span>
                </div>
              </label>
            </div>
            <div className="tip">
              {t('agentEngine.config.messageIntervalTip')}
              <InputNumber
                  min={0}
                  precision={0}
                  value={initForm.message_interval}
                  onChange={value => setInitForm((prev: any) => ({...prev, message_interval: value}))}
              />
              {t('agentEngine.config.secondsLater')}
            </div>
          </div>
        </div>
      </div>
  );
};

export default ConfigComponent;
