import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, Radio, Tag, message, Modal, Drawer, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { savePrompt } from '@/api/agent';
import { cloneDeep } from 'lodash';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import Panel from '@/components/Panel/index';
import { JudgmentType } from '@/store/modules/types';
import JudgmentStrategy from '@/components/JudgmentStrategy';
import './promptWord.scss';
import { produce } from 'immer';
interface promptForm {
  tool_prompt: string;
  polish_prompt: string;
  json_field: string;
  json_parser: string;
  personality: string;
  image_floor_prompt: string;
  image_prompt: string;
  polish_json_parser: string;
  personality_json_parser: string;
  polish_json_field: string;
  personality_json_field: string;
  think_chain_config: any;
}
const PromptWord: React.FC = () => {
  const { t } = useTranslation();
  const [formInfo, setFormInfo] = useState<any>({
    tool_prompt: '',
    polish_prompt: '',
    json_field: '',
    json_parser: '2',
    personality: '',
    image_floor_prompt: '',
    image_prompt: '',
    polish_json_parser: '1',
    personality_json_parser: '1',
    polish_json_field: '',
    personality_json_field: '',
    think_chain_config: {},
  });
  const [dialogVisible, setDialogVisible] = useState(false);
  const [bigType, setBigType] = useState<string>('');
  const [bigAuxiliary, setBigAuxiliary] = useState<boolean>(false);
  const [auxiliary, setAuxiliary] = useState<boolean>(false);
  const [form] = Form.useForm<promptForm>();
  const currentAgent = useAppSelector(selectCurrentAgent);
  const openInputDialog = (type: string) => {
    setBigTypeText(form.getFieldValue(type));
    setBigType(type);
    setDialogVisible(true);
  };
  const filtersTitle = (type: string) => {
    const typeMap = {
      tool_prompt: t('agentEngine.promptWord.rolePrompt'),
      polish_prompt: t('agentEngine.promptWord.polishPrompt'),
      personality: t('agentEngine.promptWord.knowledgeBasePrompt'),
      image_prompt: t('agentEngine.promptWord.imagePrompt'),
      image_floor_prompt: t('agentEngine.promptWord.imageFloorPrompt'),
      rewrite_prompt: t('agentEngine.promptWord.userLatestIntentPrompt'),
    } as any;
    return typeMap[type];
  };
  const handleAuxiliary = (type: string) => {
    if (type) {
      setBigAuxiliary(true);
      setAuxiliary(false);
    } else {
      setAuxiliary(true);
      setBigAuxiliary(false);
    }
  };
  const saveBtn = async () => {
    try {
      const validValues = await form.validateFields();
      const info = currentAgent.currentAgent;
      const actionForm = cloneDeep(validValues);
      const agent_id = info.agent_id;
      const res = await savePrompt({
        agent_id,
        ...actionForm,
      });
      if (res.code === 200) {
        message.success(t('common.saveSuccess'));
        setOpen(false);
        setFormInfo({ ...formInfo, think_chain_config: modifyData });
      } else {
        message.error(res.message ? res.message : t('common.saveFailed'));
      }
    } catch (error) {
      console.log('Form validation failed:', error);
    }
  };
  const onValuesChange = (data: any) => {
    setFormInfo({ ...formInfo, data });
  };

  const defaultChain = {
    rule_list: [],
    floor_reply: t('agentEngine.promptWord.floorReply'),
    strategy_type: 'answer',
    kb_rule_cate: '3',
    skill_num: '',
    turn_human_msg: '',
    ask_human_conf: {
      queues: [],
      remark: '',
      busy_answer: t('agentEngine.promptWord.floorReply'),
    },
  };
  const initData = () => {
    const {
      tool_prompt,
      polish_prompt,
      json_field,
      personality_json_field,
      json_parser,
      personality_json_parser,
      polish_json_parser,
      polish_json_field,
      personality,
      image_prompt,
      image_floor_prompt,
      rewrite_prompt,
    } = currentAgent.currentAgent as any;
    let config = currentAgent.currentAgent.think_chain_config as JudgmentType;
    if (currentAgent.currentAgent.think_chain_config) {
      config = {
        rule_list: config?.rule_list || [],
        floor_reply: config?.floor_reply || t('agentEngine.promptWord.floorReply'),
        strategy_type: config?.strategy_type || 'answer',
        kb_rule_cate: config?.kb_rule_cate || '3',
        skill_num: config?.skill_num || '',
        turn_human_msg: config?.turn_human_msg || '',
        ask_human_conf: {
          queues: config?.ask_human_conf?.queues || [],
          remark: config?.ask_human_conf?.remark || '',
          busy_answer:
            config?.ask_human_conf?.busy_answer ||
            t('agentEngine.promptWord.floorReply'),
          group_type: config?.ask_human_conf?.group_type || 'agent',
          queue_data: config?.ask_human_conf?.queue_data || ''
        },
      };
    } else {
      config = defaultChain;
    }
    const obj = {
      tool_prompt: tool_prompt || '',
      polish_prompt: polish_prompt || '',
      json_field: json_field || '',
      json_parser: json_parser || '2',
      personality: personality || '',
      image_prompt: image_prompt || '',
      image_floor_prompt: image_floor_prompt || '',
      rewrite_prompt: rewrite_prompt || '',
      polish_json_parser: polish_json_parser || '1',
      polish_json_field: polish_json_field || '',
      personality_json_parser: personality_json_parser || '1',
      personality_json_field: personality_json_field || '',
      think_chain_config: config,
    };
    form.setFieldsValue(obj);
    setFormInfo(obj);
  };

  const [open, setOpen] = useState(false);

  const openDrawer = () => {
    const data = {
      ...formInfo.think_chain_config
    }
    if (data.ask_human_conf && data.ask_human_conf.group_type == null) {
      data.ask_human_conf.group_type = 'agent';
    }
    setModifyData(data);
    setOpen(true);
  };

  const judgmentRef = useRef<any | null>(null);

  const onSaveJudge = async () => {
    if (modifyData.kb_rule_cate === '12') {
      try {
        await judgmentRef.current.checkFields();
        form.setFieldValue('think_chain_config', modifyData);
        saveBtn();
      } catch (e) {
        console.log(e);
      }
    } else {
      form.setFieldValue('think_chain_config', modifyData);
      saveBtn();
    }
  };
  const [modifyData, setModifyData] = useState<any>({});
  const changeData = (data: any) => {
    if (data.strategy_type === 'answer') {
      data.kb_rule_cate = '3';
    }
    setModifyData({ ...data });
  };

  const [bigTypeText, setBigTypeText] = useState('');

  useEffect(() => {
    initData();
  }, []);
  return (
    <div className="promptWord">
      <Form
        className="demo-ruleForm"
        onValuesChange={onValuesChange}
        form={form}
        layout="vertical"
        initialValues={formInfo}
      >
        <div className="reply">
          <div className="tip">
            <div className="common-title reply-title">
              <span className="require">*</span>{t('agentEngine.promptWord.rolePrompt')}
              <Tag className="tag-light">{'{system_conditions}'}</Tag>
              <Button onClick={saveBtn} className="saveBtn" type="primary">
                {t('common.save')}
              </Button>
            </div>
            <Form.Item
              name="tool_prompt"
              rules={[{ required: true, message: t('common.required') }]}
              className="custom-label"
              label={
                <div className="label-div">
                  <span className="label-btn">
                    <span
                      className="iconfont quanping"
                      onClick={() => openInputDialog('tool_prompt')}
                    >
                      {t('common.fullScreen')}
                    </span>
                    <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                      {t('common.auxiliaryInfo')}
                    </span>
                  </span>
                </div>
              }
            >
              <Input.TextArea rows={5} maxLength={10000} showCount />
            </Form.Item>
          </div>
        </div>
        <div style={{ marginBottom: 30 }}>
          <Form.Item name="json_parser">
            <Radio.Group
              value={formInfo.json_parser}
              onChange={e => form.setFieldsValue({ json_parser: e.target.value })} // Fixing the event handler
            >
              <Radio.Button value="1">{t('agentEngine.promptWord.allContentOutput')}</Radio.Button>
              <Radio.Button value="2">{t('agentEngine.promptWord.jsonParse')}</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {form.getFieldValue('json_parser') === '2' && (
            <Form.Item name="json_field">
              <Input placeholder={t('agentEngine.promptWord.inputParameterName')} />
            </Form.Item>
          )}
        </div>
        <div className="reply">
          <div className="tip">
            <div className="common-title reply-title">
              {t('agentEngine.promptWord.standardPolishReplyPrompt')}
              <Tag className="tag-light">{'{polish_text}'}</Tag>
            </div>
            <Form.Item
              name="polish_prompt"
              className="custom-label"
              label={
                <div className="label-div">
                  <span className="label-btn">
                    <span
                      className="iconfont quanping"
                      onClick={() => openInputDialog('polish_prompt')}
                    >
                      {t('common.fullScreen')}
                    </span>
                    <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                      {t('common.auxiliaryInfo')}
                    </span>
                  </span>
                </div>
              }
            >
              <Input.TextArea rows={5} maxLength={10000} showCount />
            </Form.Item>
          </div>
          <div style={{ marginBottom: 30 }}>
            <Form.Item name="polish_json_parser">
              <Radio.Group value={formInfo.polish_json_parser}>
                <Radio.Button value="1">{t('agentEngine.promptWord.allContentOutput')}</Radio.Button>
                <Radio.Button value="2">{t('agentEngine.promptWord.jsonParse')}</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {form.getFieldValue('polish_json_parser') === '2' && (
              <Form.Item name="polish_json_field">
                <Input placeholder={t('agentEngine.promptWord.inputParameterName')} />
              </Form.Item>
            )}
          </div>
        </div>
        <div className="reply">
          <div className="tip">
            <div className="common-title reply-title">
              {t('agentEngine.promptWord.knowledgeBaseReplyPrompt')}
              <Tag className="tag-light">{'{context_str}'}</Tag>
            </div>
            <Form.Item
              name="personality"
              className="custom-label"
              label={
                <div className="label-div">
                  <span className="label-btn">
                    <span
                      className="iconfont quanping"
                      onClick={() => openInputDialog('personality')}
                    >
                        {t('common.fullScreen')}
                    </span>
                    <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                      {t('common.auxiliaryInfo')}
                    </span>
                  </span>
                </div>
              }
            >
              <Input.TextArea rows={5} maxLength={10000} showCount />
            </Form.Item>
          </div>
          <div style={{ marginBottom: 30 }}>
            <Form.Item name="personality_json_parser">
              <Radio.Group value={formInfo.personality_json_parser}>
                <Radio.Button value="1">{t('agentEngine.promptWord.allContentOutput')}</Radio.Button>
                <Radio.Button value="2">{t('agentEngine.promptWord.jsonParse')}</Radio.Button>
                <Radio.Button value="3">{t('agentEngine.promptWord.judgmentStrategy')}</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {form.getFieldValue('personality_json_parser') === '2' && (
              <Form.Item name="personality_json_field">
                <Input placeholder={t('agentEngine.promptWord.inputParameterName')} />
              </Form.Item>
            )}
            {form.getFieldValue('personality_json_parser') === '3' && (
              <Button type="text" style={{ color: '#1677ff' }} onClick={openDrawer}>
                {t('agentEngine.promptWord.judgmentStrategy')}
              </Button>
            )}
            <Drawer
              closeIcon={false}
              open={open}
              title={t('agentEngine.promptWord.judgmentStrategy')}
              width={800}
              extra={
                <Space>
                  <Button onClick={() => setOpen(false)}>{t('common.cancel')}</Button>
                  <Button type="primary" onClick={onSaveJudge}>
                    {t('common.save')}
                  </Button>
                </Space>
              }
            >
              <JudgmentStrategy ref={judgmentRef} data={modifyData} changeData={changeData} />
            </Drawer>
          </div>
        </div>
        <div className="reply">
          <div className="tip">
            <div className="common-title reply-title">{t('agentEngine.promptWord.imagePrompt')}</div>
            <Form.Item
              name="image_prompt"
              className="custom-label"
              label={
                <div className="label-div">
                  <span className="label-btn">
                    <span
                      className="iconfont quanping"
                      onClick={() => openInputDialog('image_prompt')}
                    >
                      {t('common.fullScreen')}
                    </span>
                  </span>
                </div>
              }
            >
              <Input.TextArea rows={5} maxLength={10000} showCount />
            </Form.Item>
          </div>
        </div>
        <div className="reply">
          <div className="tip">
            <div className="common-title reply-title">
              {t('agentEngine.promptWord.imageFloorPrompt')}
              <Tag className="tag-light">{'{picture_text}'}</Tag>
            </div>
            <Form.Item
              name="image_floor_prompt"
              className="custom-label"
              label={
                <div className="label-div">
                  <span className="label-btn">
                    <span
                      className="iconfont quanping"
                      onClick={() => openInputDialog('image_floor_prompt')}
                    >
                      {t('common.fullScreen')}
                    </span>
                  </span>
                </div>
              }
            >
              <Input.TextArea rows={5} maxLength={10000} showCount />
            </Form.Item>
          </div>
        </div>
        <div className="reply">
          <div className="tip">
            <div className="common-title reply-title">{t('agentEngine.promptWord.userLatestIntentPrompt')}</div>
            <Form.Item
              name="rewrite_prompt"
              className="custom-label"
              label={
                <div className="label-div">
                  <span className="label-btn">
                    <span
                      className="iconfont quanping"
                      onClick={() => openInputDialog('rewrite_prompt')}
                    >
                      {t('common.fullScreen')}
                    </span>
                  </span>
                </div>
              }
            >
              <Input.TextArea rows={5} maxLength={10000} showCount />
            </Form.Item>
          </div>
        </div>
        <div className="hidden">
          <Form.Item name="think_chain_config">
            <div></div>
          </Form.Item>
        </div>
      </Form>
      {auxiliary && <Panel className="prompt-panel" onClosePanel={() => setAuxiliary(false)} />}
      <Modal
        open={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        footer={null}
        width="100%"
        className="full-screen-modal"
        style={{ top: 0 }}
      >
        <div className="modal-header mb-3">
          <span>{filtersTitle(bigType)}</span>
          <span
            className="iconfont fuzhuxinxi auxiliary-icon"
            onClick={() => handleAuxiliary('big')}
          >
            {t('common.auxiliaryInfo')}
          </span>
        </div>
        <Input.TextArea
          value={bigTypeText}
          onChange={e => {
            form.setFieldValue(bigType, e.target.value);
            setBigTypeText(e.target.value);
          }}
          rows={22}
          maxLength={5000}
          showCount
          placeholder={t(`common.inputPrompt`, { name: filtersTitle(bigType) })}
        />
        {bigAuxiliary && (
          <Panel className="big-content-panel" onClosePanel={() => setBigAuxiliary(false)} />
        )}
        <div className="modal-footer text-right mt-6">
          <Button type="primary" onClick={() => setDialogVisible(false)}>
            {t('common.close')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default PromptWord;
