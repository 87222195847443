import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Form, Input, Select, Checkbox, Button } from 'antd';
import { t } from 'i18next';
import { PlusOutlined } from '@ant-design/icons';
import Panel from '@/components/Panel';
import VarNameSelect from '@/components/AgentForm/VarNameSelect';

const { Option } = Select;

interface VariableFormProps {
  form: {
    name: string;
    form: Array<{
      var_name: string;
      entity_type: string;
      entity_name: string;
      value: string;
      is_array: boolean;
    }>;
  };
  id: string;
  aiEntityList: Array<{ entity_id: string; entity_desc: string; entity_name: string }>;
}
interface FormNode {
  name: string;
  form: any;
}

const VariableForm = forwardRef<any, VariableFormProps>(({ form, id, aiEntityList }, ref) => {
  const [auxiliary, setAuxiliary] = useState(false);
  const [formNode, setFormNode] = useState<FormNode>({
    name: t('flowForm.nodeAssignment'),
    form: [],
  });
  const [formRef] = Form.useForm();

  const variableArr = [
    { label: t('flowForm.saveEntity'), value: '1' },
    { label: t('flowForm.directAssignment'), value: '2' },
    { label: t('flowForm.incrementVariable'), value: '3' },
    { label: t('flowForm.saveLLMResult'), value: '4' },
  ];

  const addVariable = () => {
    const newForm = formNode.form || [];
    newForm.push({
      var_name: undefined,
      entity_type: '1',
      entity_name: undefined,
      value: '',
      entity_type_4_rule: 0,
      entity_type_4_value: '',
      is_array: false,
    });
    setFormNode({ ...formNode, form: newForm });
  };

  const deleteVariable = (index: number) => {
    const newForm = formNode.form;
    newForm.splice(index, 1);
    setFormNode({ ...formNode, form: newForm });
  };
  const validate = async () => {
    try {
      const values = await formRef.validateFields();
      console.log(values);
      if (values) {
        return formNode;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    setFormNode({ ...formNode, ...form });
    formRef.setFieldsValue({ name: form.name, form: form.form });
  }, [form]);
  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <div className="container px-5 pt-5 pb-5 bg-white rounded">
      <Form form={formRef} layout="vertical" initialValues={form}>
        <Form.Item label={t('flowForm.nodeName')} name="name" rules={[{ required: true, message: t('agent.nameRequired') }]}>
          <Input
            value={formNode.name}
            onChange={e => setFormNode(prev => ({ ...prev, name: e.target.value }))}
            maxLength={50}
            placeholder={t('agent.namePlaceholder')}
          />
        </Form.Item>

        <Form.Item>
          <div className="pre-label before:bg-primary_blue flex items-center font-bold">
            <span>{t('common.variable')}</span>
            <span
              className="iconfont fuzhuxinxi"
              onClick={() => setAuxiliary(true)}
              style={{ marginLeft: 10, fontSize: 12, color: '#909399', cursor: 'pointer' }}
            >
              &nbsp;{t('common.auxiliaryInfo')}
            </span>
          </div>
        </Form.Item>

        {formNode.form &&
          formNode.form.map((listItem: any, index: number) => (
            <div className="variable-box" key={index}>
              <div className="mg-r-12">
                <VarNameSelect
                  value={listItem.var_name}
                  onChange={value => {
                    const newForm = [...formNode.form];
                    newForm[index].var_name = value;
                    setFormNode({ ...formNode, form: newForm });
                  }}
                />
              </div>
              <Select
                className="mg-r-12"
                value={listItem.entity_type}
                onChange={value => {
                  const newForm = [...formNode.form];
                  newForm[index].entity_type = value;
                  setFormNode({ ...formNode, form: newForm });
                }}
                placeholder={t('common.select')}
                filterOption={true}
              >
                {variableArr.map(item => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
              {listItem.entity_type === '4' && (
                <>
                  <Select
                    value={listItem.entity_type_4_rule}
                    onChange={value => {
                      const newForm = [...formNode.form];
                      newForm[index].entity_type_4_rule = value;
                      setFormNode({ ...formNode, form: newForm });
                    }}
                    placeholder={t('common.select')}
                    filterOption={true}
                  >
                    <Option value={0}>{t('common.all')}</Option>
                    <Option value={1}>{t('common.specified')}</Option>
                  </Select>
                  {listItem.entity_type_4_rule == 1 && (
                    <Input
                      className="mg-r-12"
                      value={listItem.entity_type_4_value}
                      onChange={e => {
                        const newForm = [...formNode.form];
                        newForm[index].entity_type_4_value = e.target.value;
                        setFormNode({ ...formNode, form: newForm });
                      }}
                      maxLength={50}
                      placeholder={t('common.input')}
                    />
                  )}
                </>
              )}
              {listItem.entity_type === '1' && (
                <>
                  <Select
                    className="mg-r-12"
                    value={listItem.entity_name}
                    onChange={value => {
                      const newForm = [...formNode.form];
                      newForm[index].entity_name = value;
                      setFormNode({ ...formNode, form: newForm });
                    }}
                    placeholder={t('common.select')}
                    filterOption={true}
                  >
                    {aiEntityList.map(item => (
                      <Option key={item.entity_id} value={item.entity_id}>
                        { item.entity_desc || item.entity_name}
                      </Option>
                    ))}
                  </Select>
                  <Checkbox
                    className="mg-r-12"
                    checked={listItem.is_array}
                    onChange={e => {
                      const newForm = [...formNode.form];
                      newForm[index].is_array = e.target.checked;
                      setFormNode({ ...formNode, form: newForm });
                    }}
                  >
                    {t('common.array')}
                  </Checkbox>
                </>
              )}
              {listItem.entity_type === '2' && (
                <Input
                  className="mg-r-12"
                  value={listItem.value}
                  onChange={e => {
                    const newForm = [...formNode.form];
                    newForm[index].value = e.target.value;
                    setFormNode({ ...formNode, form: newForm });
                  }}
                  maxLength={50}
                  placeholder={t('common.input')}
                />
              )}
              {listItem.entity_type === '3' && (
                <span className="mg-r-12 normal-text">{t('common.variableAdd1')}</span>
              )}
              <span className="iconfont shanchuquan" onClick={() => deleteVariable(index)} />
            </div>
          ))}
        <Button type="dashed" onClick={addVariable} block icon={<PlusOutlined />}>
          {t('common.add')}
        </Button>
      </Form>

      {auxiliary && <Panel className="var-panel" onClosePanel={() => setAuxiliary(false)} />}
    </div>
  );
});
VariableForm.displayName = 'VariableForm';
export default VariableForm;
