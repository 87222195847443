import React, { useState, useEffect, useRef } from 'react';
import { Upload, Button, Image, message } from 'antd';
import { UploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getOssToken } from '@/api/agent';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import docImg from '@/assets/images/doc.png';
import { useAppSelector } from '@/store/hooks';
import './index.scss';

interface UploadFile {
  uid?: string;
  name?: string;
  url?: string;
  status?: string;
  type?: string;
}

interface Props {
  propList: UploadFile[];
  updateUploadList: (list: UploadFile[]) => void;
}

const UploadComponent: React.FC<Props> = ({ propList, updateUploadList }) => {
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const [uploadList, setUploadList] = useState<UploadFile[]>(propList);

  useEffect(() => {
    setUploadList(propList);
  }, [propList]);

  const beforeUpload = (file: File) => {
    let isPass = true;
    if (file.name.indexOf('.js') > -1 || file.name.indexOf('.html') > -1) {
      isPass = false;
    }
    const isLimitSize = file.size / 1024 / 1024 > 50;
    if (!isPass) {
      message.error('上传失败！该文件类型禁止上传');
      return Upload.LIST_IGNORE;
    }
    if (isLimitSize) {
      message.error('上传失败！附件大小不得超过50M');
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const customUpload = async ({ file }: any) => {
    try {
      const res: any = await getOssToken({
        agent_id: currentAgent.agent_id,
      });
      const formData = new FormData();
      const name = file.name || '';
      const key = `${res.dir}${name}`;
      formData.append('ossaccessKeyId', res.accessid);
      formData.append('host', res.host);
      formData.append('policy', res.policy);
      formData.append('key', key);
      formData.append('signature', res.signature);
      formData.append('file', file);

      await axios.post(res.host, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': `attachment; filename*=UTF-8''${encodeURIComponent(name)}`,
        },
      });

      message.success('上传成功');
      const type = name.split('.');
      const newFile = {
        uid: file.uid,
        url: `${res.host}/${key}`,
        name,
        type: type[type.length - 1],
      };
      setUploadList([...uploadList, newFile]);
      updateUploadList([...uploadList, newFile]);
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('上传失败');
    }
  };

  const removeList = (data: UploadFile) => {
    const newArr = uploadList.filter(i => i.url !== data.url);
    setUploadList(newArr);
    updateUploadList(newArr);
  };

  return (
    <>
      <Upload
        className="oss-upload-box"
        customRequest={customUpload}
        beforeUpload={beforeUpload}
        showUploadList={false}
      >
        <span className="iconfont a-shangchuanfujian"></span>
        <span className="upload-text">上传附件</span>
      </Upload>
      {uploadList.map((item, index) => (
        <div key={index} className="file-list-box flex items-center justify-between">
          <div>
            <Image style={{ width: 20, height: 20 }} src={docImg} />
            <span className="file-name">{item.name}</span>
          </div>
          <CloseCircleOutlined className="iconfont quxiao-mian" onClick={() => removeList(item)} />
        </div>
      ))}
    </>
  );
};

export default UploadComponent;
