import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { checkUrls } from '@/api/km'; // 根据项目实际路径调整
import { useTranslation } from 'react-i18next'; // 添加这行

interface FormValues {
  source_url: string; // 网址URL
  chunk_size: number; // 分片切割字符限制
  overlap: number; // 分片重叠字符
}

interface ChildProps {
  onSave: (data: FormValues) => void;
}
// 定义暴露给父组件的方法类型
interface ChildMethods {
  openDialog: (value: any) => void;
}
// 使用`React.forwardRef`来获取`ref`传递
const UrlKmModal = forwardRef<ChildMethods, ChildProps>((props, ref) => {
  const { t } = useTranslation(); // 添加这行
  const [isVisible, setIsVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [filename, setFilename] = useState('');
  const [checkError, setCheckError] = useState(false);

  const [form] = Form.useForm();

  // 打开弹窗
  const openDialog = (data: any) => {
    setIsVisible(true);
    setCheckError(false);
    if (data) {
      setIsEdit(true);
      form.setFieldsValue({
        source_url: data.file_download_url,
        overlap: data.overlap,
        chunk_size: data.chunk_size,
      });
      setFilename(data.file_name);
    } else {
      form.resetFields();
      setIsEdit(false);
      setFilename('');
    }
  };
  const closeDialog = () => setIsVisible(false);
  // 通过`useImperativeHandle`暴露特定的方法给父组件
  useImperativeHandle(ref, () => ({
    // 将`openDialog`方法暴露给父组件
    openDialog,
  }));
  const searchUrl = async () => {
    try {
      const values = await form.validateFields(['source_url']);
      const res = (await checkUrls({
        source_url: values.source_url,
        chunk_size: values.chunk_size || 256,
        overlap: values.overlap || 50,
      })) as any;
      if (res.code === 200) {
        setFilename(res.data.title);
        setCheckError(false);
      } else {
        setCheckError(true);
      }
    } catch (error) {
      setCheckError(true);
    }
  };

  const handleSave = async () => {
    try {
      const values: FormValues = await form.validateFields();
      if (!values) {
        return;
      }
      if (values.chunk_size / 2 < values.overlap) {
        message.error(t('knowledge.overlapError') as any);
        return;
      }
      if (!checkError) {
        props.onSave(values);
        setIsVisible(false); // 或实际保存逻辑
      } else {
        message.error(t('knowledge.urlError') as any);
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <Modal
      title={isEdit ? t('knowledge.reParseWebpage') : t('knowledge.addUrl')}
      open={isVisible}
      className="url-km-modal"
      onCancel={closeDialog}
      width={600}
      footer={[
        <Button key="back" onClick={closeDialog}>
          {t('common.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={handleSave}>
          {t('common.save')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ source_url: '', chunk_size: 256, overlap: 50 }}
      >
        <Form.Item
          className="custom-form-item"
          name="source_url"
          label={
            <>
              <span>{t('knowledge.urlLabel')}</span>
              <span className="right-tip">{t('knowledge.urlTip')}</span>
            </>
          }
          rules={[{ required: true, message: t('knowledge.urlRequired') }]}
        >
          <Input placeholder={t('knowledge.urlPlaceholder')} onBlur={searchUrl} />
        </Form.Item>
        {filename && !checkError && (
          <div className="url-title">
            <span className="iconfont wangyelianjie" />
            {filename}
          </div>
        )}
        {checkError && (
          <div className="error-box">
            <p>
              <span className="iconfont jinggao" />
              {t('knowledge.parseError')}
            </p>
            <div className="url-tip">{t('knowledge.parseErrorTip')}</div>
          </div>
        )}
        <Form.Item
          name="chunk_size"
          className="custom-form-item"
          label={
            <>
              <span>chunk_size</span>
              <span className="right-tip">{t('knowledge.chunkSizeTip')}</span>
            </>
          }
          rules={[
            { required: true, message: t('knowledge.chunkSizePlaceholder') },
            {
              pattern: /^(12[89]|1[3-9][0-9]|[23][0-9]{2}|4[0-9]{2}|50[0-9]|51[0-2])$/,
              message: t('knowledge.chunkSizeError'),
            },
          ]}
        >
          <Input placeholder={t('knowledge.chunkSizePlaceholder')} />
        </Form.Item>
        <Form.Item
          name="overlap"
          className="custom-form-item"
          label={
            <>
              <span>overlap</span>
              <span className="right-tip">{t('knowledge.overlapTip')}</span>
            </>
          }
          rules={[{ required: true, message: t('knowledge.overlapPlaceholder') }]}
        >
          <Input placeholder={t('knowledge.overlapPlaceholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
UrlKmModal.displayName = 'UrlKmModal';
export default UrlKmModal;
