import React, { useState, useEffect, useRef } from 'react';
import type { MenuProps, TableColumnsType } from 'antd';
import axios from 'axios';
import { Button, Input, message, Table, Modal, Dropdown, Switch, Pagination } from 'antd';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import Carts from './Carts';
import AddKm from './AddKm';
import ImportKm from './ImportKm';
import UrlKm from './UrlKm';
import OnlineDoc from './OnlineDoc';
import { convert_bytes } from '@/utils/common';
import {
  queryKb,
  queryFiles,
  deleteFiles,
  updateFileStatus,
  deleteKb,
  saveKb,
  saveFiles,
  getDocDetail,
} from '@/api/km';
import { getOssToken } from '@/api/agent';
import './km.scss';
import emptyKm from '@/assets/images/km.png';
import pdfImg from '@/assets/images/PDF.png';
import docImg from '@/assets/images/doc.png';
import excelImg from '@/assets/images/excel.png';
import txtImg from '@/assets/images/TXT.png';
import codeImg from '@/assets/images/code2.png';
import onlineDocImg from '@/assets/images/onlineDoc.png';
import editErrorImg from '@/assets/images/editError.png';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { useLocation } from 'react-router-dom';

const KmManager = () => {
  const { t } = useTranslation();
  const [showRight, setShowRight] = useState(false);
  const [showCharacter, setShowCharacter] = useState(true);
  const [kbList, setKbList] = useState([]);
  const [currentKb, setCurrentKb] = useState<any>(null);
  const [currentKm, setCurrentKm] = useState<any>(null);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
  });
  const addKmRef = useRef<{ openDialog: (value: any) => void }>(null);
  const urlKmRef = useRef<{ openDialog: (data: any) => void }>(null);
  const importKmRef = useRef<{ openDialog: () => void }>(null);
  const onlineDocRef = useRef<{ openDialog: (data: any) => void }>(null);
  interface queryForm {
    file_name: string;
  }
  const [query, setQuery] = useState<queryForm>({ file_name: '' });
  const currentAgent = useAppSelector(selectCurrentAgent);
  const location: any = useLocation();
  const urlParams: any = new URLSearchParams(location.search);
  useEffect(() => {
    fetchKbList();
  }, []);

  useEffect(() => {
    if (currentKb) fetchKmList();
  }, [currentKb, pagination.currentPage, pagination.pageSize, query.file_name]);
  // 新增网页链接
  const addUrl = (data: any) => {
    setCurrentKm(data);
    if (urlKmRef.current) {
      urlKmRef.current.openDialog(data);
    }
  };
  // 导入知识点
  const importExcel = () => {
    setCurrentKm(null);
    if (importKmRef.current) {
      importKmRef.current.openDialog();
    }
  };
  // 在线文档
  const onlineDoc = (data: any) => {
    setCurrentKm(data);
    if (onlineDocRef.current) {
      onlineDocRef.current.openDialog(data);
    }
    setShowCharacter(false);
  };
  // 新建知识库
  const addKmList = () => {
    if (addKmRef.current) {
      addKmRef.current.openDialog(null);
    }
  };
  const items: MenuProps['items'] = [
    // {
    //   key: '1',
    //   label: (
    //     <span onClick={importExcel}>
    //       <span className="iconfont wenben-xian" />
    //       &nbsp; 本地文件
    //     </span>
    //   ),
    // },
    // {
    //   key: '2',
    //   label: (
    //     <span onClick={() => addUrl(null)}>
    //       <span className="iconfont wangyelianjie" />
    //       &nbsp; 网页链接
    //     </span>
    //   ),
    // },
    // {
    //   key: '3',
    //   label: (
    //     <span onClick={() => onlineDoc(null)}>
    //       <span className="iconfont zaixianwendang" />
    //       &nbsp; 在线文档
    //     </span>
    //   ),
    // },
  ];
  const cartsRef = useRef<any>(null);
  const clearParams = () => {
    // const currentURL = window.location.href;
    // const baseUrl = currentURL.split('?')[0];
    // window.history.replaceState({}, document.title, baseUrl);
  };
  const fetchKbList = async () => {
    const response = (await queryKb({
      page: 1,
      per_page: 99999,
      title: '', // 需要title参数以正确获取数据
    })) as any;
    if (response.code === 200) {
      const list = response.data?.list || [];
      setKbList(list);
      if (list.length > 0) {
        if (urlParams.get('kb_id')) {
          const kb_id = urlParams.get('kb_id');
          const i = list.findIndex((item: any) => item.id === kb_id);
          console.log(i);
          cartsRef.current && cartsRef.current?.handleItemClick(list[i > -1 ? i : 0]);
        } else {
          cartsRef.current && cartsRef.current?.handleItemClick(list[0]);
        }
        if (urlParams.get('doc_id')) {
          autoEdit(urlParams.get('doc_id'));
        }
        if (urlParams.get('kb_id') || urlParams.get('doc_id')) {
          clearParams();
        }
      }
    } else {
        message.error(response.message || t('knowledge.getKbFailed'));
    }
  };
  const [openModal, setOpenModal] = useState(false);
  const autoEdit = async (id: string) => {
    try {
      const result = await getDocDetail({ document_id: id });
      if (result.code === 200 && result.data) {
        handleEdit(result.data);
      } else {
        if (result.data === null) {
          setOpenModal(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 点击左侧卡片，搜索右侧知识库文件列表
  const handleClickItem = (data: any) => {
    setCurrentKb(data);
    setPagination({ ...pagination, currentPage: 1 });
    setShowRight(true);
    setQuery({ file_name: '' });
  };
  const fetchKmList = async () => {
    if (!currentKb) return;
    const response = (await queryFiles({
      kb_id: currentKb.id,
      file_name: query.file_name,
      page: pagination.currentPage,
      per_page: pagination.pageSize,
    })) as any;
    if (response.code === 200) {
      setTableData(response.data?.list || []);
      setPagination({
        ...pagination,
        totalCount: response.data?.count || 0,
        totalPages: response.data?.pages || 0,
      });
    }
  };
  const handlePageChange = (page: number, pageSize?: number) => {
    setPagination({ ...pagination, currentPage: page, pageSize: pageSize || 10 });
  };
  // 删除知识点
  const handleDel = (data: any) => {
    Modal.confirm({
      title: t('common.confirmDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        deleteFiles({ id: data.id }).then(res => {
          if (res.code == 200) {
            message.success(t('common.deleteSuccess'));
            fetchKmList();
          } else {
            message.error(res.message || t('common.deleteFailed'));
          }
        });
      },
    });
  };
  // 编辑知识点
  const handleEdit = (data: any) => {
    if (data.file_suffix === 'html') {
      // url链接
      addUrl(data);
    } else if (data.file_suffix === 'md') {
      // 在线文档
      onlineDoc(data);
    }
  };
  // 在线文档返回
  const onGoBack = () => {
    setShowCharacter(true);
    fetchKmList();
  };
  // 保存在线文档
  const saveOnlineDoc = async (data: any) => {
    const res = (await getOssToken({
      agent_id: currentAgent.currentAgent.agent_id,
    })) as any;
    const blob = new Blob([data.value], { type: 'text/markdown' });
    // 获取文件大小
    const fileSize = blob.size;
    const formData = new FormData();
    const name = data.name || '';
    const key = `${res.dir}${name}`;
    formData.append('ossaccessKeyId', res.accessid);
    formData.append('host', res.host);
    formData.append('policy', res.policy);
    formData.append('key', key);
    formData.append('signature', res.signature);
    formData.append('file', blob);
    try {
      await axios.post(res.host, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': `attachment; filename*=UTF-8''${encodeURIComponent(name)}`,
        },
      });
      const actionForm = {
        chunk_size: data.chunk_size,
        overlap: data.overlap,
        bytes: fileSize,
        kb_id: currentKb.id,
        filename: `${data.name}.md`,
        source_url: `${res.host}/${key}`,
        is_website: false, //是否从网页拉去【此处默认填写false,后端要求】
      } as any;
      if (currentKm && currentKm.document_id) {
        actionForm.doc_id = currentKm.document_id;
      }
      saveFiles(actionForm).then((res: any) => {
        if (res.code == 200) {
          fetchKmList();
          message.success(t('common.saveSuccess'));
          setShowCharacter(true);
        } else {
          message.error(res.message || t('knowledge.parseFailed'));
        }
      });
    } catch (error) {
      message.error(t('common.saveFailed'));
    }
  };
  // 保存网页链接
  const saveUrlKm = (data: any) => {
    const actionForm = {
      chunk_size: data.chunk_size,
      overlap: data.overlap,
      kb_id: currentKb.id,
      filename: '',
      source_url: data.source_url,
      is_website: true, //是否从网页拉去【此处默认填写true,后端要求】
    } as any;
    if (currentKm && currentKm.document_id) {
      actionForm.doc_id = currentKm.document_id;
    }
    saveFiles(actionForm).then((res: any) => {
      if (res.code == 200) {
        fetchKmList();
      } else {
        message.error(res.message || t('knowledge.addUrlFailed'));
      }
    });
  };
  // 保存导入知识库
  const saveImportKm = (data: any) => {
    const promises = [] as any;
    data.file.fileList.forEach((item: any) => {
      const dataForm = new FormData();
      dataForm.append('chunk_size', data.chunk_size + '');
      dataForm.append('overlap', data.overlap + '');
      dataForm.append('kb_id', currentKb.id);
      dataForm.append('bytes', item.size + '');
      dataForm.append('file', item.originFileObj);
      const promise = saveFiles(dataForm);
      promises.push(promise);
    });
    Promise.all(promises)
      .then((res: any) => {
        const resList = res || [];
        const obj = resList.find((i: any) => i.code != 200);
        if (obj) {
          message.error(obj.message || t('knowledge.importFailed'));
        } else {
          message.success(t('knowledge.importSuccess'));
        }
        fetchKmList();
      })
      .catch(() => {
        message.error(t('knowledge.importFailed'));
      });
  };
  // 保存知识库
  const saveKm = async (data: any) => {
    const actionData = cloneDeep(data);
    if (!actionData.id) {
      delete actionData.id;
    }
    const res = await saveKb(actionData);
    if (res.code == 200) {
      message.success(t('common.saveSuccess'));
      fetchKbList();
      // addKmRef.value.closeDialog();
    } else {
      message.error(res.message || t('common.saveFailed'));
    }
  };
  // 删除知识库
  const delCart = (id: string) => {
    deleteKb({ id }).then(res => {
      if (res.code == 200) {
        message.success(t('common.deleteSuccess'));
        fetchKbList();
      } else {
        message.error(res.message || t('common.deleteFailed'));
      }
    });
  };
  // 编辑知识库
  const editCart = (data: any) => {
    if (addKmRef.current) {
      addKmRef.current.openDialog(data);
    }
  };
  // 修改知识点的状态
  const handleStatusChange = async (record: any) => {
    const response = await updateFileStatus({
      id: record.id,
      status: record.shelve_status == 1 ? 0 : 1,
    });
    if (response.code === 200) {
      message.success(t('common.updateSuccess'));
      fetchKmList();
    } else {
      message.error(response.message || t('common.updateFailed'));
    }
  };
  const onSearch = (value: any) => {
    setQuery({ file_name: value });
    fetchKmList();
  };
  const columns: TableColumnsType = [
    {
      title: t('knowledge.name'),
      dataIndex: 'file_name',
      key: 'file_name',
      fixed: 'left',
      ellipsis: true,
      width: 180,
      render: (text: string, record: any) => (
        <>
          {['txt', 'pdf', 'xls', 'xlsx', 'doc', 'docx', 'html', 'md'].includes(
            record.file_suffix
          ) && (
            <img
              className="file-img"
              src={
                record.file_suffix == 'txt'
                  ? txtImg
                  : record.file_suffix == 'pdf'
                    ? pdfImg
                    : ['xls', 'xlsx'].includes(record.file_suffix)
                      ? excelImg
                      : ['doc', 'docx'].includes(record.file_suffix)
                        ? docImg
                        : record.file_suffix == 'html'
                          ? codeImg
                          : record.file_suffix == 'md'
                            ? onlineDocImg
                            : ''
              }
            />
          )}
          {record.status == 1 ? (
            <a href={record.file_download_url} target="_blank" rel="noreferrer">
              {text}
            </a>
          ) : (
            <span>{record.file_name}</span>
          )}
        </>
      ),
    },
    {
      title: t('knowledge.parseStatus'),
      dataIndex: 'status',
      key: 'status',
      render: (status: any) => (
        <>
          {status == 0 ? (
            <span style={{ color: '#6200ff' }}>{t('knowledge.parsing')}</span>
          ) : status == 1 ? (
            <span style={{ color: '#009336' }}>{t('knowledge.parseSuccess')}</span>
          ) : status == 2 ? (
            <span style={{ color: '#f00' }}>{t('knowledge.parseFailed')}</span>
          ) : status == 3 ? (
            <span style={{ color: '#1677ff' }}>{t('knowledge.reParsing')}</span>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: t('knowledge.shelfStatus'),
      dataIndex: 'shelve_status',
      key: 'shelve_status',
      render: (text: any, record: any) => (
        <Switch
          size="small"
          defaultChecked
          checked={record.shelve_status == 1 ? true : false}
          onChange={() => handleStatusChange(record)}
        ></Switch>
      ),
    },
    { title: t('knowledge.format'), dataIndex: 'file_suffix', key: 'file_suffix' },
    {
      title: t('knowledge.size'),
      key: 'bytes',
      render: (text: any, record: any) => <span>{convert_bytes(record.bytes)}</span>,
    },
    { title: t('knowledge.characterCount'), dataIndex: 'characters', key: 'characters' },
    {
      title: t('knowledge.lastUpdateTime'),
      key: 'update_datetime',
      width: 200,
      render: (text: any, record: any) => <span>{record.update_datetime.replace('T', ' ')}</span>,
    },
    {
      title: t('common.operation'),
      key: 'action',
      fixed: 'right',
      width: 130,
      render: (text: any, record: any) => (
        <>
          <Button
            type="link"
            disabled={['html', 'md'].includes(record.file_suffix) ? false : true}
            onClick={() => handleEdit(record)}
          >
            {t('common.edit')}
          </Button>
          <Button style={{ padding: 0 }} danger type="link" onClick={() => handleDel(record)}>
            {t('common.delete')}
          </Button>
        </>
      ),
    },
  ];
  return (
    <div className="km-manager h-full">
      <div style={{ display: showCharacter ? 'block' : 'none' }} className="character h-full">
        <div className="km-contain-box h-full">
          <div className="km-left">
            <div className="add-wrap">
              <div onClick={addKmList} className="iconfont jiahao add-btn">
                {t('knowledge.createKnowledgeBase')}
              </div>
            </div>
            <div className="carts">
              <Carts
                kbList={kbList}
                handleClickItem={handleClickItem}
                delCart={delCart}
                editCart={editCart}
                ref={cartsRef}
              />
            </div>
          </div>
          <div className="km-right">
            {showRight ? (
              <div className="km-right-container h-full flex flex-col justify-between">
                <div>
                  <div className="top">
                    <Input.Search
                      placeholder={t('knowledge.searchKnowledgeName')}
                      onSearch={onSearch}
                      enterButton
                      style={{ width: 200 }}
                    />
                    {/*<Dropdown menu={{ items }} placement="bottom" className="add-dropdown">*/}
                      <span className="add-dropdown add-up-btn" onClick={() => onlineDoc(null)}>
                        <span className="iconfont xinjian" />
                        {t('knowledge.createKnowledgeBase')}
                      </span>
                    {/*</Dropdown>*/}
                  </div>
                  <Table
                    size="small"
                    style={{ marginTop: 16 }}
                    dataSource={tableData}
                    columns={columns}
                    scroll={{ x: 1500, y: 600 }}
                    bordered
                    pagination={false}
                  />
                </div>
                <div className="flex justify-end pr-6">
                  <Pagination
                    style={{ marginTop: 16, textAlign: 'right' }}
                    showSizeChanger
                    showQuickJumper
                    current={pagination.currentPage}
                    total={pagination.totalCount}
                    pageSize={pagination.pageSize}
                    showTotal={totalCount => t('knowledge.totalItems', { count: totalCount })}
                    onChange={(page, pageSize) => handlePageChange(page, pageSize)}
                  />
                </div>
              </div>
            ) : (
              <div className="empty-right">
                <div>
                  <img src={emptyKm} />
                  <p>{t('knowledge.emptyPrompt')}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddKm onSave={saveKm} ref={addKmRef} />
      <ImportKm onSave={saveImportKm} ref={importKmRef} />
      <UrlKm onSave={saveUrlKm} ref={urlKmRef} />
      <OnlineDoc
        style={{ display: showCharacter ? 'none' : 'block' }}
        onSave={saveOnlineDoc}
        ref={onlineDocRef}
        onGoBack={onGoBack}
      />
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        className="editErrorModal"
        title=""
        footer={null}
      >
        <div className="text-center">
          <img src={editErrorImg} className="editErrorImg inline-block" />
          <p className="font-bold text-xl text-gray-500">{t('knowledge.editError')}</p>
        </div>
      </Modal>
    </div>
  );
};

export default KmManager;
