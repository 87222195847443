import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../';
import {
  queryFunctionList,
  queryAllAiEntity,
  queryVarListByAgent,
  addVarListByAgent,
} from '@/api/agent';
import { message } from 'antd';
import { produce } from 'immer';

interface EntityItemType {
  entity_name: string;
  entity_desc: string;
  regex: string;
  create_datetime?: string;
  entity_id: string;
}

export interface VAR_LIST_TYPE {
  varname: string;
  agent_id: string;
  description: string;
}

// 定义 slice state 的类型
interface CounterState {
  funcOptions: [];
  allAiEntity: EntityItemType[];
  varList: VAR_LIST_TYPE[];
}

// 使用该类型定义初始 state
const initialState: CounterState = {
  // 函数
  funcOptions: [],
  // 实体
  allAiEntity: [],
  // 变量
  varList: [],
};

// 显式指定 queryAllAiEntityAction 的类型
export const queryAllAiEntityAction = createAsyncThunk(
  'async-options/queryAllAiEntity',
  async (agent_id: string, { dispatch }) => {
    // 显式指定 agent_id 的类型为 string
    const response = await queryAllAiEntity({
      page: 1,
      size: 1000,
      agent_id,
    });
    return response.data.result || [];
  }
);

export const queryFunctionListAction = createAsyncThunk(
  'async-options/queryFunctionList',
  async (agent_id: string, { dispatch }) => {
    const response = await queryFunctionList({
      page: 1,
      size: 9999,
      otherName: '',
      bot_id: '68abbfa2de722a659b1c875c9c7d1a22',
      agent_id,
    });
    return response.data.result || [];
  }
);

/**
 * @description: 查询变量列表
 * @param {*} agent_id
 * @return {*}
 */
export const queryVarListByAgentAction = createAsyncThunk(
  'async-options/queryVarListByAgent',
  async (agent_id: string, { dispatch }) => {
    const response = await queryVarListByAgent({
      agent_id,
    });
    return response.data.result || [];
  }
);

/**
 * @description: 更新变量到服务端
 * @param {*} param1
 * @return {*}
 */
export const updateVarListByAgentAction = createAsyncThunk(
  'async-options/updateVarListByAgent',
  async (params: any, { dispatch }) => {
    const response = await addVarListByAgent(params);
    return params;
  }
);

export const asyncOptionSlice = createSlice({
  name: 'asyncOption',
  initialState,
  reducers: {
    persistence(state) {
      const funcOptions = window.localStorage.getItem('funcOptions');
      const allAiEntity = window.localStorage.getItem('allAiEntity');
      state.funcOptions = JSON.parse(funcOptions || '[]');
      state.allAiEntity = JSON.parse(allAiEntity || '[]');
    },
  },
  extraReducers: builder => {
    builder.addCase(queryAllAiEntityAction.fulfilled, (state, action) => {
      state.allAiEntity = action.payload;
      window.localStorage.setItem('allAiEntity', JSON.stringify(state.allAiEntity));
    });
    builder.addCase(queryVarListByAgentAction.fulfilled, (state, action) => {
      state.varList = action.payload;
    });
    builder.addCase(queryFunctionListAction.fulfilled, (state, action) => {
      state.funcOptions = action.payload;
    });
    builder.addCase(updateVarListByAgentAction.fulfilled, (state, action) => {
      state.varList.push(action.payload);
    });
  },
});

// export const {} = asyncOptionSlice.actions;

// selectors 等其他代码可以使用导入的 `RootState` 类型
export const selectOptions = (state: RootState) => state.asyncOption;

export default asyncOptionSlice.reducer;
