import { NonIndexRouteObject } from 'react-router-dom';
import i18n from '../contexts/locales/i18n';
export interface ServiceRoutesObject extends NonIndexRouteObject {
  pathname: string;
  children?: ServiceRoutesObject[];
  firstMenu?: boolean; // 是否是第一层的菜单（无钻入）用于菜单分类和刷新
  noLink?: boolean; // 是否跳转
  icon?: string;
  noShow?: boolean; // 是否显示二级菜单位置
  /**
   * @description: 通过sign方式登录时，隐藏此菜单
   */
  hideWithSignLogin?: boolean;
}

// 后端上线路由权限后，把这份数据交给后端维护，前端只需要把新增的路由交给后端更新即可！
// 后端上线后还需要做一件事，把没有权限的路由，前端页面显示403
const serviceRoutes = (): ServiceRoutesObject[] => [
  {
    path: '/agentService/workspace',
    pathname: 'router.workspace',
    firstMenu: true,
    noShow: true,
  },

  {
    path: '/agentService/llmAgent',
    pathname: 'router.llmAgent',
    firstMenu: true,
    noShow: true,
  },
  // {
  //   path: '/account/accountManager',
  //   pathname: '员工管理',
  //   firstMenu: true,
  //   noShow: true,
  // },

  {
    path: '/account',
    pathname: 'router.account',
    firstMenu: true,
    noLink: true,
    icon: 'zhanghaoguanli',
    children: [
      {
        firstMenu: true,
        path: '/account/accountManager',
        pathname: 'router.accountManager',
      },
      {
        firstMenu: true,
        path: '/account/accountGroup',
        pathname: 'router.accountGroup',
      },
      {
        firstMenu: true,
        path: '/account/channelIntegrations',
        pathname: 'router.channelIntegrations',
      },
      {
        firstMenu: true,
        path: '/account/systemSetting',
        pathname: 'router.systemSetting',
      }
      // {
      //   firstMenu: true,
      //   path: '/account/websiteIntegrations',
      //   pathname: '网站集成',
      // }
    ],
  },
  {
    path: '/account/userSetting',
    pathname: 'router.userSetting',
    firstMenu: true,
    noShow: true, // 设置为 true，使其不在任何导航中显示
    // hideWithSignLogin: true, // 如果需要在 sign 登录时隐藏
  },

  {
    path: '/agentService/agentManager',
    pathname: 'router.agentManager',
    firstMenu: false,
    noLink: true,
    icon: 'agentguanli',
    children: [
      {
        path: '/agentService/agentManager/agentEngine',
        pathname: 'router.agentEngine',
        hideWithSignLogin: true
      },
      {
        path: '/agentService/agentManager/knowledgeManager',
        pathname: 'router.knowledgeManager',
      },
      // {
      //   path: '/agentService/agentManager/tableManager',
      //   pathname: '表格管理',
      // },
      {
        path: '/agentService/agentManager/functionService',
        pathname: 'router.functionService',
      },
      {
        path: '/agentService/agentManager/entityManager',
        pathname: 'router.entityManager',
      },
    ],
  },
  {
    path: '/agentTrain',
    pathname: 'router.agentTrain',
    noLink: true,
    hideWithSignLogin: true,
    icon: 'zhanghaoguanli',
    children: [
      {
        path: '/agentTrain/trainWorkbench',
        pathname: 'router.trainWorkbench',
      },
    ],
  },

  {
    path: '/dataAnalysis',
    pathname: 'router.dataAnalysis',
    firstMenu: false,
    noLink: true,
    hideWithSignLogin: true,
    icon: 'shujufenxi',
    children: [
      {
        path: '/dataAnalysis/sessionLog',
        pathname: 'router.sessionLog',
      },
    ],
  },
  {
    path: '/marketingCustomer',
    pathname: 'router.marketingCustomer',
    firstMenu: false,
    noLink: true,
    icon: 'yewushezhi',
    children: [
      {
        path: '/marketingCustomer/customerRetention',
        pathname: 'router.customerRetention',
      },
    ],
  },
  {
    path: '/businessSetup',
    pathname: 'router.businessSetup',
    firstMenu: false,
    noLink: true,
    icon: 'yewushezhi',
    children: [
      {
        path: '/businessSetup/sessionProcess',
        pathname: 'router.sessionProcess',
      },
      {
        path: '/businessSetup/askHuman',
        pathname: 'router.askHuman',
      },
      {
        path: '/businessSetup/blackList',
        pathname: 'router.blackList',
      },
    ],
  },
];

export default serviceRoutes;

/**
 * @description: 通过sign方式登陆的路由列表
 * @param {ServiceRoutesObject} routes
 * @return {*}
 */
export function getRoutesBySignLogin(routes: ServiceRoutesObject[]) {
  return routes.filter(item => {
    if (item.children) {
      item.children = getRoutesBySignLogin(item.children);
    }
    return !item.hideWithSignLogin;
  });
}
