import React, { FC, useState, useEffect } from 'react';
import { Form, Input, Select, Upload, Button, Checkbox, ColorPicker,message, UploadFile } from 'antd';
import { DownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { queryAgentList } from "@/api/agent";
import OssAvatarUpload from "@/components/OssAvatarUpload";
import kefuAvatar from '@/assets/images/kefu-default.png';
import userDefaultAvatar from '@/assets/images/user-default.png';
import { useTranslation } from 'react-i18next';

const DEFAULT_COLOR = '#ffffff';

interface WebsiteEditProps {
  record: {
    uid?: string;
    corp_id?: string;
    sdk_name?: string;
    sdk_description?: string;
    agent_id?: string;
    sdk_logo?: string;
    consult_button_color?: string;
    theme_color?: string;
    navigation_color_start?: string;
    navigation_color_end?: string;
    customer_service_name?: string;
    message_area_bg_color?: string;
    agent_message_bubble_bg?: string;
    agent_message_bubble_border?: string;
    agent_message_text_color?: string;
    user_message_bubble_bg?: string;
    user_message_bubble_border?: string;
    user_message_text_color?: string;
    agent_avatar?: string;
    user_avatar?: string;
    sdk_url?: string;
    sdk_embed_code?: string;
  };
  onSave?: (values: any) => void;
  onBack?: () => void;
}

const WebsiteEdit: FC<WebsiteEditProps> = ({ record, onSave, onBack }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [agentList, setAgentList] = useState([]);
  const [formValues, setFormValues] = useState<Partial<WebsiteEditProps['record']>>(record);
  const [agentAvatar, setAgentAvatar] = useState<UploadFile | undefined>(record.agent_avatar ? { uid: '-1', name: 'avatar', url: record.agent_avatar } : undefined);
  const [userAvatar, setUserAvatar] = useState<UploadFile | undefined>(record.user_avatar ? { uid: '-1', name: 'avatar', url: record.user_avatar } : undefined);
  const [sdkLogo, setSdkLogo] = useState<UploadFile | undefined>(record.sdk_logo ? { uid: '-1', name: 'logo', url: record.sdk_logo } : undefined);

  const onValuesChange = (changedValues: any, allValues: any) => {
    console.log("onValuesChange", onValuesChange);
    const processedValues = Object.keys(allValues).reduce<Record<string, any>>((acc, key) => {
      if (allValues[key] && typeof allValues[key].toHexString === 'function') {
        acc[key] = allValues[key].toHexString();
      } else {
        acc[key] = allValues[key];
      }
      return acc;
    }, {});
    setFormValues(processedValues);
  };

  useEffect(() => {
    form.setFieldsValue(record);
    setFormValues(record); // 添加这行来初始化 formValues
    setAgentAvatar(record.agent_avatar ? { uid: '-1', name: 'avatar', url: record.agent_avatar } : undefined);
    setUserAvatar(record.user_avatar ? { uid: '-1', name: 'avatar', url: record.user_avatar } : undefined);
    setSdkLogo(record.sdk_logo ? { uid: '-1', name: 'logo', url: record.sdk_logo } : undefined);
    fetchAgentList();
  }, [record]);

  const fetchAgentList = async () => {
    try {
      const res = await queryAgentList({ page: 1, size: 10000 });
      if (res.code === 200) {
        setAgentList(res.data.result || []);
      }
    } catch (error) {
      console.error('Failed to fetch agent list:', error);
    }
  };

  const onFinish = (values: any) => {
    console.log('Form values:', values);
    const processedValues = Object.keys(values).reduce<Record<string, any>>((acc, key) => {
      if (values[key] && typeof values[key].toHexString === 'function') {
        acc[key] = values[key].toHexString();
      } else {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    
    if (onSave) {
      processedValues.uid = record.uid
      onSave(processedValues);
    }
  };

  const handleGoBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="h-full">
      <div className="p-2">
        <Button 
          icon={<ArrowLeftOutlined />} 
          onClick={handleGoBack}
          style={{ marginBottom: '20px' }}
        >
          {t('common.back')}
        </Button>
        <h1 className="text-2xl font-bold mb-6">{t('account.editZendeskSDKConfig')}</h1>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '2', marginRight: '20px', maxHeight: '80vh', overflowY: 'auto' }}>
            <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={onValuesChange} initialValues={record}>
              <Form.Item name="sdk_name" label={t('common.name')} rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="sdk_description" label={t('common.description')}>
                <Input.TextArea />
              </Form.Item>
              <Form.Item name="agent_id" label={t('common.agent')} rules={[{ required: true, message: t('common.selectAgent') }]}>
              <Select placeholder={t('common.selectPrompt', { title: t('common.agent') })}>
                {agentList.map((agent: any) => (
                    <Select.Option key={agent.agent_id} value={agent.agent_id}>
                      {agent.name}
                    </Select.Option>
                ))}
              </Select>
              </Form.Item>
              <Form.Item name="sdk_logo" label={t('account.sdkLogo')} layout="horizontal">
                <OssAvatarUpload
                  avatar={sdkLogo}
                  updateUploadList={data => {
                    if (data.length > 0 && data[0].url) {
                      const newUrl = data[0].url;
                      form.setFieldsValue({ sdk_logo: newUrl });
                      setSdkLogo({ uid: '-1', name: 'logo', url: newUrl });
                      onValuesChange({ sdk_logo: newUrl }, form.getFieldsValue());
                    }
                  }}
                />
              </Form.Item>
              <div style={{ display: 'flex'}}>
                <Form.Item name="theme_color" label={t('account.themeColor')} layout="horizontal" style={{  marginRight: '40px' }}>
                  <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
                </Form.Item>
                <Form.Item name="consult_button_color" label={t('account.consultButtonColor')} layout="horizontal">
                <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
                </Form.Item>
              </div>
              <div style={{ display: 'flex'}}>
                <Form.Item name="navigation_color_start" label={t('account.navigationColor')} style={{ marginRight: '20px', marginBottom: 0 }} layout="horizontal">
                  <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
                </Form.Item>
                <Form.Item name="navigation_color_end" style={{ marginRight: '20px', marginBottom: 0 }} layout="horizontal">
                  <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
                </Form.Item>
                <Form.Item name="customer_service_name" label={t('account.customerServiceName')} layout="horizontal" rules={[{ required: true, message: t('account.customerServiceNameRequired') }]}>
                  <Input placeholder={t('account.customerServiceNamePlaceholder')} />
                </Form.Item>
              </div>
              <Form.Item name="message_area_bg_color" label={t('account.messageAreaBgColor')} layout="horizontal">
                <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
              </Form.Item>
              <div style={{ display: 'flex'}}>
                <Form.Item name="agent_message_bubble_bg" label={t('account.agentMessageBubbleBg')} layout="horizontal" className='mr-4'>
                  <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
                </Form.Item>
                <Form.Item name="agent_message_bubble_border" label={t('account.agentMessageBubbleBorder')} layout="horizontal" className='mr-4'>
                  <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
                </Form.Item>
                <Form.Item name="agent_message_text_color" label={t('account.agentMessageTextColor')} layout="horizontal">
                  <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
                </Form.Item>
              </div>
              <div style={{ display: 'flex' }}>
                <Form.Item name="user_message_bubble_bg" label={t('account.userMessageBubbleBg')} layout="horizontal" className='mr-4'>
                  <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
                </Form.Item>
                <Form.Item name="user_message_bubble_border" label={t('account.userMessageBubbleBorder')} layout="horizontal" className='mr-4'>
                  <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
                </Form.Item>
                <Form.Item name="user_message_text_color" label={t('account.userMessageTextColor')} layout="horizontal">
                  <ColorPicker defaultValue={DEFAULT_COLOR} size='small'/>
                </Form.Item>
              </div>
              <div style={{ display: 'flex' }}>
                <Form.Item name="agent_avatar" label={t('account.agentAvatar')} layout="horizontal" style={{ marginRight: '40px' }}>
                  <OssAvatarUpload
                    avatar={agentAvatar}
                    updateUploadList={data => {
                      if (data.length > 0 && data[0].url) {
                        const newUrl = data[0].url;
                        form.setFieldsValue({ agent_avatar: newUrl });
                        setAgentAvatar({ uid: '-1', name: 'avatar', url: newUrl });
                        onValuesChange({ agent_avatar: newUrl }, form.getFieldsValue());
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name="user_avatar" label={t('account.userAvatar')} layout="horizontal">
                  <OssAvatarUpload
                    avatar={userAvatar}
                    updateUploadList={data => {
                      if (data.length > 0 && data[0].url) {
                        const newUrl = data[0].url;
                        form.setFieldsValue({ user_avatar: newUrl });
                        setUserAvatar({ uid: '-1', name: 'avatar', url: newUrl });
                        onValuesChange({ user_avatar: newUrl }, form.getFieldsValue());
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <Form.Item name="sdk_url" label={t('account.sdkUrl')}>
                <Input disabled />
              </Form.Item>
              <Form.Item name="sdk_embed_code" label={t('account.sdkEmbedCode')}>
                <Input.TextArea disabled rows={6} />
              </Form.Item>
              <div style={{ height: '200px' }}></div>
            </Form>
          </div>
          <div style={{ flex: '1' }}>
              <div style={{ 
                border: '1px solid #d9d9d9', 
                borderRadius: '10px', 
                width: '350px',
                height: '600px',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                backgroundColor: DEFAULT_COLOR,
              }}>
                <div style={{
                  background: `linear-gradient(to right, ${formValues.navigation_color_start || '#1890ff'}, ${formValues.navigation_color_end || '#1890ff'})`,
                  color: DEFAULT_COLOR,
                  padding: '10px',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                }}>
                  <h3 style={{ margin: 0 }}>{formValues.customer_service_name || t('account.deepFlowCustomerService')}</h3>
                </div>
                <div style={{ 
                  backgroundColor: formValues.message_area_bg_color || DEFAULT_COLOR,
                  padding: '10px',
                  flexGrow: 1,
                  overflowY: 'auto'
                }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginBottom: '10px'
                  }}>
                    <img src={formValues.agent_avatar || kefuAvatar} alt="Agent" style={{ width: '32px', height: '32px', marginRight: '10px', borderRadius: '50%' }} />
                    <div style={{
                      backgroundColor: formValues.agent_message_bubble_bg || '#e6f7ff',
                      color: formValues.agent_message_text_color || '#000',
                      padding: '10px',
                      borderRadius: '0 10px 10px 10px',
                      maxWidth: '70%'
                    }}>
                      {t('account.sdkEmbedCodeTip')}
                    </div>
                  </div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                    marginBottom: '10px'
                  }}>
                    <div style={{
                      backgroundColor: formValues.user_message_bubble_bg || '#f5f5f5',
                      color: formValues.user_message_text_color || '#000',
                      padding: '10px',
                      borderRadius: '10px 0 10px 10px',
                      maxWidth: '70%'
                    }}>
                      {t('account.sdkEmbedCodeTip')}
                    </div>
                    <img src={formValues.user_avatar || userDefaultAvatar} alt="User" style={{ width: '32px', height: '32px', marginLeft: '10px', borderRadius: '50%' }} />
                  </div>
                </div>
                <div style={{
                  padding: '10px',
                  backgroundColor: formValues.message_area_bg_color || DEFAULT_COLOR,
                }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '20px',
                    padding: '5px',
                  }}>
                    <input 
                      type="text" 
                      placeholder={t('common.inputMessage')} 
                      style={{ 
                        flex: 1, 
                        padding: '8px 15px',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                      }} 
                    />
                    <button style={{
                      backgroundColor: formValues.consult_button_color || '#1890ff',
                      color: DEFAULT_COLOR,
                      border: 'none',
                      width: '36px',
                      height: '36px',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      marginLeft: '5px',
                    }}>
                      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 5L12 19M12 5L5 12M12 5L19 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              
              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between',width: '350px' }}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  {t('common.publish')}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  style={{ 
                    backgroundColor: formValues.consult_button_color || '#1890ff',
                    color: '#ffffff',
                    borderRadius: '50%',
                    width: '48px',
                    height: '48px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z" fill="currentColor"/>
                    <path d="M7 9H17V11H7V9ZM7 12H14V14H7V12Z" fill="currentColor"/>
                  </svg>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default WebsiteEdit;