import React, { useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { ConfigProvider, Button } from 'antd';
import { LanguageProvider, useLanguage } from '@/contexts/languageContext';
import './style/index.scss';
// import i18n from './contexts/locales/i18n';
import '@/contexts/locales/i18n';

const antDesignTheme = {
  token: {
    colorPrimary: '#1972F5'
  },
  components: {
    Menu: {
      itemColor: '#767E92',
      itemActiveBg: 'rgba(25, 114, 245, 0.1)',
      itemMarginInline: 0,
      itemBorderRadius: 0,
      subMenuItemBg: '#fff'
    },
    Tabs: {
      horizontalItemPadding: '16px 0'
    },
    Pagination: {
      itemBg: '#F0F2F5',
      itemActiveBg: '#1972F5',
    }
  }
}

function AppContent() {
  const { currentLanguage } = useLanguage();

  return (
    <ConfigProvider theme={antDesignTheme} locale={currentLanguage.locale}>
      <div className="App w-full h-full">
        <RouterProvider router={router} />
      </div>
    </ConfigProvider>
  );
}

function App() {
  return (
    <LanguageProvider>
      <AppContent />
    </LanguageProvider>
  );
}

export default App;
