import React, { useState } from 'react';
import { List, Button, Modal, Form, Input, message, Switch, Tooltip, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store/hooks';
import { setCurrentAgent } from '@/store/modules/agentTemplate';
import './LLMAgentList.scss';
import { copyAgentTemplate, deleteAgentTemplate, saveAgentTemplate } from '@/api/agent';

interface Agent {
  [x: string]: string | undefined;
  name: string;
  desc?: string;
  agent_id: string;
  update_time?: string;
  avatar?: string;
}
interface Props {
  agents: Agent[];
  refreshList: () => void;
}

const LLMAgentList: React.FC<Props> = ({ agents, refreshList }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  // ... 保留原有的handleCopyLLMAgent, handleDeleteLLMAgent, goAgentTemplate, handleEdit, handleSaveLLMAgent 函数 ...
  /**
   * @description: 复制
   * @return {*}
   */
  const handleCopyLLMAgent = async (agent: Agent) => {
    Modal.confirm({
      title: t('common.confirmCopy'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        try {
          const form = {
            agent_id: agent.agent_id,
          };
          const { code } = await copyAgentTemplate(form);
          if (code === 200) {
            message.success(t('common.copySuccess'));
            refreshList();
          } else {
            message.error(t('common.copyFailed'));
          }
        } catch (error) {
          message.error(t('common.copyFailed'));
        }
      },
    });
  };

  /**
   * @description: 删除
   * @return {*}
   */
  const handleDeleteLLMAgent = async (agent: Agent) => {
    Modal.confirm({
      title: t('common.confirmDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        try {
          const { code } = await deleteAgentTemplate({
            agent_id: agent.agent_id,
          });
          if (code === 200) {
            message.success(t('common.deleteSuccess'));
            refreshList();
          } else {
            message.error(t('common.deleteFailed'));
          }
        } catch (error) {
          message.error(t('common.deleteFailed'));
        }
      },
    });
  };

  /**
   * @description: 跳转页面
   * @return {*}
   */
  const goAgentTemplate = (agent: Agent) => {
    // eventBus.emit('jumpToSecondMenu');
    dispatch(setCurrentAgent(agent));
    nav('/agentService/agentManager/agentEngine');
  };

  /**
   * @description: 编辑弹窗
   * @return {*}
   */
  const handleEdit = (agent: Agent) => {
    form.setFieldsValue(agent);
    setIsModalVisible(true);
  };

  /**
   * @description: 编辑保存
   * @return {*}
   */
  const handleSaveLLMAgent = async () => {
    try {
      const values = await form.validateFields();
      if (!values) {
        return;
      }
      const formValues = form.getFieldsValue(true);
      const { code } = await saveAgentTemplate({
        agent_id: '',
        ...formValues,
      });
      if (code === 200) {
        form.resetFields();
        message.success(t('common.saveSuccess'));
        setIsModalVisible(false);
        refreshList();
      } else {
        message.error(t('common.saveFailed'));
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <div className="llm-agent-list">
      <List
        itemLayout="horizontal"
        dataSource={agents}
        renderItem={(agent) => (
          <List.Item
            actions={[
              <Button type="link" size="small" key="preview"
              className='px-0 !text-sm-12'
              onClick={() => window.open(`http://121.43.110.212/h5/#/?agent_id=${agent.agent_id}`, '_blank')}>
                {t('common.preview')}
              </Button>,
              <Button type="link" size="small" key="edit" className='px-0 !text-sm-12' onClick={(e) => { e.stopPropagation(); handleEdit(agent); }}>
                {t('common.edit')}
              </Button>,
              <Button type="link" size="small" key="copy" className='px-0 !text-sm-12' onClick={(e) => { e.stopPropagation(); handleCopyLLMAgent(agent); }}>
                {t('common.copy')}
              </Button>
            ]}
            onClick={() => goAgentTemplate(agent)}
          >
            <List.Item.Meta
              avatar={<Avatar src={agent.avatar || 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'} />}
              title={
                <Tooltip title={agent.name}>
                  <span className="llm-agent-name font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
                    {agent.name}
                  </span>
                </Tooltip>
              }
              description={
                <>
                {agent.desc && (
                  <Tooltip title={agent.desc}>
                    <p className="min-h-6 leading-6 h-6 whitespace-nowrap overflow-hidden text-ellipsis">
                      {agent.desc}
                    </p>
                  </Tooltip>
                )}
                  <Tooltip title={agent.agent_id}>
                    <p className="!text-sm-12 min-h-6 leading-6 h-6 whitespace-nowrap overflow-hidden text-ellipsis">
                      Agent ID: {agent.agent_id}
                    </p>
                  </Tooltip>
                  <p className="!text-sm-12 min-h-6 leading-6 h-6 whitespace-nowrap overflow-hidden text-ellipsis">
                    {t('agent.updateTime')}: {agent.update_time}
                  </p>
                </>
              }
            />
          </List.Item>
        )}
      />
      <Modal
        title={t('agent.editLLMAgent')}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsModalVisible(false)}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleSaveLLMAgent}> 
            {t('common.save')}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="agent_id" label="Agent ID">
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="name"
            label={t('agent.name')}
            rules={[{ required: true, message: t('agent.nameRequired') }]}
          >
            <Input maxLength={50} showCount placeholder={t('agent.namePlaceholder')} />
          </Form.Item>
          <Form.Item name="desc" label={t('agent.desc')}>
            <Input.TextArea rows={3} maxLength={256} showCount placeholder={t('agent.descPlaceholder')} />
          </Form.Item>
          <Form.Item name="enable_test_number" valuePropName="checked" label={t('agent.enableTestNumber')}>
            <Switch onChange={(val: any) => {
              console.log(val);
              if(val === true) {
                form.setFieldsValue({ test_number: Math.floor(100000 + Math.random() * 900000)+'' });
              }else {
                form.setFieldsValue({test_number: ''});
              }
            }} />
          </Form.Item>
          <Form.Item
              name="test_number"
              label={t('agent.testNumber')}
          >
            <Input placeholder={t('agent.testNumberPlaceholder')} disabled />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default LLMAgentList;
