import React, { useState, useEffect } from 'react';
import { Form, Switch, Input, Button, message } from 'antd';
import {t} from 'i18next'
import './chatConfig.scss';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { saveChatConfig } from '@/api/agent';

interface ChatConfigForm {
  enable_welcome_msg: boolean;
  welcome_msg: string;
}

const ChatConfig: React.FC = () => {
  const [form] = Form.useForm<ChatConfigForm>();
  const [initForm, setInitForm] = useState<ChatConfigForm>({
    enable_welcome_msg: false,
    welcome_msg: t('common.welcomeMsgDefault'),
  });
  const currentAgent = useAppSelector(selectCurrentAgent);
  useEffect(() => {
    // 载入现有配置
    const { enable_welcome_msg, welcome_msg } = currentAgent.currentAgent;
    const initData = {
      enable_welcome_msg: enable_welcome_msg || false,
      welcome_msg: welcome_msg || t('common.welcomeMsgDefault'),
    };
    setInitForm(initData);
    form.setFieldsValue(initData);
  }, []);

  const save = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      if (!values.welcome_msg) {
        values.welcome_msg = t('common.welcomeMsgDefault');
      }
      const agent_id = currentAgent.currentAgent.agent_id; // 获取当前agent id的逻辑
      saveChatConfig({ agent_id, ...values }).then(res => {
        if (res.code === 200) {
          message.success(t('common.saveSuccess'));
        } else {
          message.error(res.message ? res.message : t('common.saveFailed'));
        }
      });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <div className="chat-config">
      <div className="bg-white rounded">
        {/* <div className="config-title">欢迎语</div> */}
        <Form style={{ padding: 16 }} form={form} layout="horizontal" initialValues={initForm}>
          <div className="flex justify-between items-center pb-4 mb-4" style={{ borderBottom: '1px solid #E4E7ED' }}>
            <Form.Item
              style={{margin: 0}}
              name="enable_welcome_msg"
              valuePropName="checked"
              label={<span className="text-base bold font-medium">{t('common.welcome')}</span>}
            >
              <Switch size="small" />
            </Form.Item>
            <Button type="primary" onClick={save} style={{ float: 'right' }}>
              {t('common.save')}
            </Button>
          </div>
          <Form.Item
            style={{marginBottom: 0}}
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.enable_welcome_msg !== currentValues.enable_welcome_msg
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue('enable_welcome_msg') ? (
                <Form.Item name="welcome_msg" label={t('common.welcomeMsg')}>
                  <Input.TextArea rows={4} maxLength={500} showCount placeholder={t('common.inputWelcomeMsg')} />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ChatConfig;
