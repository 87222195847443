import React, { useEffect, useRef, useState } from 'react';
import { Button, Pagination, Input, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import EntryCard from './EntryCard';
import AddOrEditEntry from './AddOrEditEntry';
import { queryEntries, saveEntry } from '@/api/km';
import './entry.scss';
type Item = {
  id?: string;
  term: string;
  aliases: Array<string>;
  definition?: string;
};

export default function EntryMananger() {
  const { t } = useTranslation();
  const entryRef = useRef<{ openDialog: (value: any) => void; closeDialog: () => void }>(null);
  const [queryForm] = Form.useForm();
  const [query, setQuery] = useState<{ query_str: string }>({ query_str: '' });
  const [list, setList] = useState<Item[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageConfig, setPageConfig] = useState({
    page: 1,
    per_page: 20,
  });
  useEffect(() => {
    getListData();
  }, [pageConfig.page, pageConfig.per_page, query]);

  const getListData = async () => {
    try {
      const { code, data } = await queryEntries({ ...pageConfig, ...query });
      if (code === 200) {
        setList(data.list);
        setTotalCount(data.count);
      }
    } catch (error) {
      console.error(error);
      message.info(t('agent.getListFailed'));
    }
  };
  // 新建词典
  const showModal = () => {
    if (entryRef.current) {
      entryRef.current.openDialog(null);
    }
  };
  // 保存词典
  const handleSave = (emitData: any) => {
    saveEntry(emitData).then((res: any) => {
      if (res.code === 200) {
        refreshList();
        message.success(t('common.saveSuccess'));
        if (entryRef.current) {
          entryRef.current.closeDialog();
        }
      } else {
        message.error(res.message || t('common.saveFailed'));
      }
    });
  };

  function onPageChange(page: number, per_page: number) {
    setPageConfig({
      page,
      per_page,
    });
  }

  function refreshList() {
    getListData();
  }

  return (
    <div className="entry-box h-full">
      <div className="py-6">
        <Form
          form={queryForm}
          layout="inline"
          initialValues={{ query_str: '' }}
          style={{ justifyContent: 'flex-end' }}
        >
          <Form.Item label="" name="query_str">
            <Input
              placeholder={t('common.searchWord')}
              onBlur={getListData}
              onPressEnter={getListData}
              onChange={e => setQuery({ ...query, query_str: e.target.value })}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={showModal}>
              {t('common.create')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="entry-card-list px-6">
        {list.map((item: Item) => {
          return <EntryCard key={item.id} item={item} refreshList={refreshList} />;
        })}
      </div>
      <div className="mt-3 pr-5 px-6 flex justify-end">
        <Pagination
          style={{ marginTop: 16, textAlign: 'right' }}
          showSizeChanger
          showQuickJumper
          total={totalCount}
          current={pageConfig.page}
          pageSize={pageConfig.per_page}
          showTotal={totalCount => t('common.totalItems', { count: totalCount })}
          onChange={(page, pageSize) => onPageChange(page, pageSize)}
        />
      </div>
      <AddOrEditEntry ref={entryRef} onSave={handleSave} />
    </div>
  );
}
