import React from 'react';
import { Upload, message } from 'antd';
import axios from 'axios';
import { getOssToken } from '@/api/agent';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { useAppSelector } from '@/store/hooks';
import './scss/index.scss';

interface Props {
  className: string;
  sendImage: (data: any) => void;
}

const UploadImage: React.FC<Props> = ({ sendImage, className }) => {
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const beforeUpload = (file: File) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('上传失败！该文件类型禁止上传');
      return Upload.LIST_IGNORE;
    }
    const isLimitSize = file.size / 1024 / 1024 > 20;
    if (isLimitSize) {
      message.error('上传失败！附件大小不得超过20M');
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const customUpload = async ({ file }: any) => {
    try {
      const res = (await getOssToken({
        agent_id: currentAgent.agent_id,
      })) as any;
      const formData = new FormData();
      const name = file.name || '';
      const key = `${res.dir}${name}`;
      formData.append('ossaccessKeyId', res.accessid);
      formData.append('host', res.host);
      formData.append('policy', res.policy);
      formData.append('key', key);
      formData.append('signature', res.signature);
      formData.append('file', file);

      await axios.post(res.host, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': `attachment; filename*=UTF-8''${encodeURIComponent(name)}`,
        },
      });

      const type = name.split('.');
      sendImage({
        url: `${res.host}/${key}`,
        name,
        type: type[type.length - 1],
      });
    } catch (error) {
      message.error('上传失败');
    }
  };

  return (
    <Upload
      className={'upload-box ' + className}
      showUploadList={false}
      action="#"
      customRequest={customUpload}
      beforeUpload={beforeUpload}
    >
      <span className="iconfont a-tupian-xian1x" />
    </Upload>
  );
};

export default UploadImage;
