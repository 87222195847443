import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Input, Radio, Select, Button, Modal, message } from 'antd';
import { t } from 'i18next';
import Panel from '@/components/Panel'; // Assumption: You have a similar `Panel` component in React
import OssUpload from '@/components/OssUpload'; // Assumption: You have OssUpload component in React
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent, selectModelEnum } from '@/store/modules/agentTemplate';

const { TextArea } = Input;
const { Option } = Select;

interface ReplyFormProps {
  form: Record<string, any>;
  id: string;
  kbList: Array<{ id: string; title: string }>;
}

interface FormNode {
  name: string;
  strategy_type: string;
  keyboard_scene: string;
  cate: string;
  direct_answer: string;
  net_search_prompt: string;
  skill_num: string;
  turn_human_msg: string;
  polish_prompt: string;
  llm_prompt: string;
  tool_llm: string;
  json_parser: string;
  json_field: string;
  kbs: Array<string>;
  answer_res_list: any;
}

const ReplyForm = forwardRef<any, ReplyFormProps>(({ form, id, kbList }, ref) => {
  const [formRef] = Form.useForm();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [auxiliary, setAuxiliary] = useState<boolean>(false);
  const [bigAuxiliary, setBigAuxiliary] = useState<boolean>(false);
  const [bigType, setBigType] = useState<string>('');
  const currentAgent = useAppSelector(selectCurrentAgent);
  const modelEnum = selectModelEnum();

  const [formNode, setFormNode] = useState<any>({
    name: t('flowForm.replyNode'),
    strategy_type: 'answer',
    keyboard_scene: '',
    cate: '3',
    direct_answer: '',
    net_search_prompt: t('agentEngine.role.netSearchPrompt'),
    skill_num: '',
    turn_human_msg: '',
    polish_prompt: '',
    llm_prompt: '',
    tool_llm: 'minimax',
    json_parser: '1',
    json_field: '',
    kbs: [],
    answer_res_list: [],
  });

  useEffect(() => {
    formRef.setFieldsValue(form);
    setFormNode({ ...formNode, ...form });
  }, [form]);

  const rules = {
    name: [{ required: true, message: t('agent.inputNodeName') }],
    llm_prompt: [{ required: true, message: t('agentEngine.inputAnswerPrompt') }],
    direct_answer: [{ required: true, message: t('agentEngine.inputScriptContent') }],
    polish_prompt: [{ required: true, message: t('agentEngine.inputPolishContent') }],
    net_search_prompt: [{ required: true, message: t('agentEngine.inputSearchPrompt'), trigger: 'blur' }],
    json_field: [{ required: true, message: t('agentEngine.inputParamName') }],
  };

  const validate = async () => {
    try {
      const values = await formRef.validateFields();
      console.log(values);
      return values;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const openInputDialog = (type: string) => {
    setBigType(type);
    setDialogVisible(true);
  };

  const filtersTitle = (type: string) => {
    const typeMap: { [key: string]: string } = {
      net_search_prompt: t('agentEngine.replyType4'),
      direct_answer: t('agentEngine.scriptContent'),
      llm_prompt: t('agentEngine.answerPrompt'),
      polish_prompt: t('agentEngine.polishContent'),
    };
    return typeMap[type];
  };

  const handleAuxiliary = (type: string) => {
    if (type) {
      setBigAuxiliary(true);
      setAuxiliary(false);
    } else {
      setAuxiliary(true);
      setBigAuxiliary(false);
    }
  };
  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <>
      <Form
        form={formRef}
        className="px-5 pt-5 pb-1 bg-white rounded"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={formNode}
      >
        <Form.Item label={t('flowForm.nodeName')} name="name" rules={rules.name}>
          <Input
            value={formNode.name}
            onChange={e => setFormNode((prev: any) => ({ ...prev, name: e.target.value }))}
            autoComplete="off"
            maxLength={50}
            showCount
            placeholder={t('common.input')}
          />
        </Form.Item>

        <Form.Item label={
          <div className="flex items-center mb-2">
            <svg className="icon text-base" aria-hidden="true">
              <use xlinkHref="#celveleixing" />
            </svg>
            <span className="ml-1">{t('agentEngine.config.agentPreAction')}</span>
          </div>
        } name="strategy_type">
          <Radio.Group
            value={formNode.strategy_type}
            onChange={e => {
              setFormNode((prev: any) => ({ ...prev, strategy_type: e.target.value }));
              formRef.setFieldValue('strategy_type', e.target.value);
            }}
          >
            <Radio value="answer">{t('common.reply')}</Radio>
            <Radio value="action">Action</Radio>
          </Radio.Group>
        </Form.Item>

        {formNode.strategy_type === 'answer' && (
          <>
            <Form.Item name="cate" label={
              <div className="flex items-center mb-2">
                <svg className="icon text-base" aria-hidden="true">
                  <use xlinkHref="#huifuleixing" />
                </svg>
                <span className="ml-1">{t('agentEngine.replyType')}</span>
              </div>
            }>
              <Radio.Group
                value={formNode.cate}
                onChange={e => {
                  setFormNode((prev: any) => ({ ...prev, cate: e.target.value }));
                  formRef.setFieldValue('cate', e.target.value);
                }}
              >
                <Radio value="1">{t('agentEngine.replyType3')}</Radio>
                <Radio value="3">{t('agentEngine.replyType1')}</Radio>
                <Radio value="4">{t('agentEngine.replyType2')}</Radio>
                <Radio value="8">{t('knowledge.knowledge')}</Radio>
                <Radio value="11">{t('agentEngine.replyType4')}</Radio>
              </Radio.Group>
            </Form.Item>
            {formNode.cate === '11' && (
              <Form.Item
                name="net_search_prompt"
                rules={rules.net_search_prompt}
                className="custom-label"
                label={
                  <div className="label-div">
                    <span>{t('agentEngine.searchPrompt')}</span>
                    <span className="label-btn">
                      <span
                        className="iconfont quanping"
                        onClick={() => openInputDialog('net_search_prompt')}
                      >
                        {t('common.fullScreen')}
                      </span>
                    </span>
                  </div>
                }
              >
                <TextArea
                  value={formNode.net_search_prompt}
                  onChange={e =>
                    setFormNode((prev: any) => ({ ...prev, net_search_prompt: e.target.value }))
                  }
                  rows={3}
                  maxLength={5000}
                  showCount
                  placeholder={t('agentEngine.inputSearchPrompt')}
                />
              </Form.Item>
            )}
            {formNode.cate === '3' && (
              <Form.Item
                name="direct_answer"
                rules={rules.direct_answer}
                className="custom-label"
                label={
                  <div className="label-div">
                    <span>{t('agentEngine.scriptContent')}</span>
                    <span className="label-btn">
                      <span
                        className="iconfont quanping"
                        onClick={() => openInputDialog('direct_answer')}
                      >
                        {t('common.fullScreen')}
                      </span>
                      <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                        {t('common.auxiliaryInfo')}
                      </span>
                    </span>
                  </div>
                }
              >
                <TextArea
                  value={formNode.direct_answer}
                  onChange={e =>
                    setFormNode((prev: any) => ({ ...prev, direct_answer: e.target.value }))
                  }
                  rows={3}
                  maxLength={5000}
                  showCount
                  placeholder={t('agentEngine.inputScriptContent')}
                />
              </Form.Item>
            )}

            {['1', '4', '8'].includes(formNode.cate) && (
              <>
                {formNode.cate === '1' && (
                  <Form.Item
                    name="llm_prompt"
                    rules={rules.llm_prompt}
                    className="custom-label"
                    label={
                      <div className="label-div">
                        <span>{t('agentEngine.answerPrompt')}</span>
                        <span className="label-btn">
                          <span
                            className="iconfont quanping"
                            onClick={() => openInputDialog('llm_prompt')}
                          >
                            {t('common.fullScreen')}
                          </span>
                          <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                            {t('common.auxiliaryInfo')}
                          </span>
                        </span>
                      </div>
                    }
                  >
                    <TextArea
                      value={formNode.llm_prompt}
                      onChange={e =>
                        setFormNode((prev: any) => ({ ...prev, llm_prompt: e.target.value }))
                      }
                      rows={3}
                      maxLength={5000}
                      showCount
                      placeholder={t('agentEngine.inputAnswerPrompt')}
                    />
                  </Form.Item>
                )}

                <Form.Item 
                label={             <div className="flex items-center mb-2">
                <svg className="icon text-base" aria-hidden="true">
                  <use xlinkHref="#huidamoxing" />
                </svg>
                <span className="ml-1">{t('role.answerModel')}</span>
              </div>}
                name="tool_llm">
                  <Select
                    value={formNode.tool_llm}
                    onChange={value => {
                      setFormNode((prev: any) => ({ ...prev, tool_llm: value }));
                      formRef.setFieldValue('tool_llm', value);
                    }}
                    placeholder={t('common.select')}
                    style={{ width: '100%' }}
                  >
                    {modelEnum.map((item: string, index: number) => (
                      <Option value={item} key={index}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {formNode.cate === '1' && (
                  <>
                    <Form.Item name="json_parser">
                      <Radio.Group
                        value={formNode.json_parser}
                        onChange={e =>
                          setFormNode((prev: any) => ({ ...prev, json_parser: e.target.value }))
                        }
                      >
                        <Radio.Button value="1">{t('promptWord.outputAllContent')}</Radio.Button>
                        <Radio.Button value="2">{t('promptWord.jsonParse')}</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    {formNode.json_parser === '2' && (
                      <Form.Item name="json_field" rules={rules.json_field}>
                        <Input
                          value={formNode.json_field}
                          onChange={e =>
                            setFormNode((prev: any) => ({ ...prev, json_field: e.target.value }))
                          }
                          placeholder={t('agentEngine.inputParamName')}
                        />
                      </Form.Item>
                    )}
                  </>
                )}
              </>
            )}

            {formNode.cate === '4' && (
              <Form.Item
                name="polish_prompt"
                rules={rules.polish_prompt}
                className="custom-label"
                label={
                  <div className="label-div">
                    <span>{t('agentEngine.polishContent')}</span>
                    <span className="label-btn">
                      <span
                        className="iconfont quanping"
                        onClick={() => openInputDialog('polish_prompt')}
                      >
                        {t('common.fullScreen')}
                      </span>
                      <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                        {t('common.auxiliaryInfo')}
                      </span>
                    </span>
                  </div>
                }
              >
                <TextArea
                  value={formNode.polish_prompt}
                  onChange={e =>
                    setFormNode((prev: any) => ({ ...prev, polish_prompt: e.target.value }))
                  }
                  rows={3}
                  maxLength={5000}
                  showCount
                  placeholder={t('agentEngine.inputPolishContent')}
                />
              </Form.Item>
            )}

            {formNode.cate === '8' && (
              <Form.Item name="kbs" label={t('agentEngine.knowledgeBaseName')}>
                <Select
                  value={formNode.kbs}
                  onChange={value => setFormNode((prev: any) => ({ ...prev, kbs: value }))}
                  mode="multiple"
                  placeholder={t('common.select')}
                  style={{ width: '100%' }}
                >
                  {kbList.map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
        )}

        {formNode.strategy_type === 'action' && (
          <>
            <Form.Item name="cate" label={
              <div className="flex items-center mb-2">
                <svg className="icon text-base" aria-hidden="true">
                  <use xlinkHref="#huifuleixing" />
                </svg>
                <span className="ml-1">{t('agentEngine.replyType')}</span>
              </div>
            }>
              <Radio.Group
                value={formNode.cate}
                onChange={e => {
                  setFormNode((prev: any) => ({ ...prev, cate: e.target.value }));
                  formRef.setFieldValue('cate', e.target.value);
                }}
              >
                <Radio value="5">{t('flowForm.humanService')}</Radio>
                <Radio value="6">{t('flowForm.keyboardService')}</Radio>
                <Radio value="7">{t('flowForm.hangupService')}</Radio>
              </Radio.Group>
            </Form.Item>

            {formNode.cate === '5' && (
              <>
                <Form.Item name="skill_num" label={t('flowForm.prioritySkillGroup')}>
                  <Input
                    value={formNode.skill_num}
                    onChange={e =>
                      setFormNode((prev: any) => ({ ...prev, skill_num: e.target.value }))
                    }
                    autoComplete="off"
                    maxLength={50}
                    showCount
                    placeholder={t('flowForm.inputPrioritySkillGroup')}
                  />
                </Form.Item>
                <Form.Item name="turn_human_msg" label={t('flowForm.replyToUser')}>
                  <Input.TextArea
                    value={formNode.turn_human_msg}
                    onChange={e =>
                      setFormNode((prev: any) => ({ ...prev, turn_human_msg: e.target.value }))
                    }
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    maxLength={5000}
                    showCount
                    placeholder={t('flowForm.inputReplyToUser')}
                  />
                </Form.Item>
              </>
            )}

            {formNode.cate === '6' && (
              <Form.Item name="keyboard_scene" label={t('flowForm.keyboardScene')}>
                <Input
                  value={formNode.keyboard_scene}
                  onChange={e =>
                    setFormNode((prev: any) => ({ ...prev, keyboard_scene: e.target.value }))
                  }
                  autoComplete="off"
                  maxLength={50}
                  showCount
                  placeholder={t('flowForm.inputKeyboardScene')}
                />
              </Form.Item>
            )}
          </>
        )}

        {['1', '3', '4'].includes(formNode.cate) && (
          <Form.Item
            name="answer_res_list"
            className="custom-form-item"
            label={
              <>
                <span>{t('flowForm.attachment')}</span>
                <span className="right-tip">{t('flowForm.attachmentTip')}</span>
              </>
            }
          >
            <div className="oss-upload">
              <OssUpload
                // eslint-disable-next-line react/prop-types
                propList={formNode?.answer_res_list || []}
                updateUploadList={data => {
                  formRef.setFieldValue('answer_res_list', data);
                }}
              />
            </div>
          </Form.Item>
        )}
      </Form>

      {auxiliary && <Panel className="reply-panel" onClosePanel={() => setAuxiliary(false)} />}

      <Modal
        open={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        footer={null}
        width="100%"
        className="full-screen-modal"
        style={{ top: 0 }}
      >
        <div className="modal-header mb-3">
          <span>{filtersTitle(bigType)}</span>
          <span
            className="iconfont fuzhuxinxi auxiliary-icon"
            onClick={() => handleAuxiliary('big')}
          >
            {t('common.auxiliaryInfo')}
          </span>
        </div>
        <TextArea
          value={formNode[bigType]}
          onChange={e => {
            formRef.setFieldValue(bigType, e.target.value);
            setFormNode((prev: any) => ({ ...prev, [bigType]: e.target.value }));
          }}
          rows={22}
          maxLength={5000}
          showCount
          placeholder={`${t('common.input')}${filtersTitle(bigType)}`}
        />
        {bigAuxiliary && (
          <Panel className="big-content-panel" onClosePanel={() => setBigAuxiliary(false)} />
        )}
        <div className="modal-footer text-right mt-6">
          <Button type="primary" onClick={() => setDialogVisible(false)}>
            {t('common.close')}
          </Button>
        </div>
      </Modal>
    </>
  );
});
ReplyForm.displayName = 'ReplyForm';
export default ReplyForm;
