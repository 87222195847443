import { configureStore } from '@reduxjs/toolkit';
import user from './modules/user';
import agentTemplate, { agentTemplateSlice } from './modules/agentTemplate';
import asyncOption from './modules/asyncOptions';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['modelEnum'], // blacklist ['modelEnum']
};

const agentTemplatePersistConfig = {
  key: 'agentTemplate',
  storage,
  blacklist: ['modelEnum'], // Ensure modelEnum is not persisted
};

const rootReducer = persistCombineReducers(persistConfig, {
  user,
  agentTemplate: persistReducer(agentTemplatePersistConfig, agentTemplateSlice.reducer),
  asyncOption,
});

export const store = configureStore({
  reducer: rootReducer,
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
