import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Form, Input } from 'antd';
import { t } from 'i18next';
import { cloneDeep } from 'lodash';
// import { useAgentForm } from './hooks/use-agent-form'; // 假设这是你转换自Vue的useAgentForm的钩子

interface SceneInfoFormProps {
  formState: Record<string, any>;
  type?: string;
  ref?: any;
}

const SceneInfoForm: React.FC<SceneInfoFormProps> = forwardRef(function SceneInfoForm(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { formState, type } = props;
  const [form] = Form.useForm();

  const rules = {
    name: [{ required: true, message: t('agent.nameRequired'), trigger: 'blur' }],
    desc: [{ required: true, message: t('agent.sceneDescPlaceholder'), trigger: 'blur' }],
    input: [{ required: true, message: t('agent.inputKeyPlaceholder'), trigger: 'blur' }],
  };

  // 替换为使用Ant Design Form的方式来初始化表单
  useEffect(() => {
    form.setFieldsValue(formState);
  }, [formState]);

  function getFormFieldsValue() {
    return form.getFieldsValue(true);
  }
  async function validateFormFields() {
    return await form.validateFields();
  }
  useImperativeHandle(
    ref,
    () => {
      return {
        getFormFieldsValue,
        validateFormFields,
      };
    },
    []
  );

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={formState}
      layout="vertical"
    >
      <div className="px-5 pt-5 pb-1 bg-white rounded">
        <h4 className="mb-5 text-text_color_black text-base font-medium">
          {type === 'scenes' ? t('agent.sceneInfo') : t('agent.roleInfo')}
        </h4>
        <Form.Item
          label={type === 'scenes' ?  t('agent.sceneName') : t('agent.roleName')}
          name="name"
          rules={rules.name}
        >
          <Input autoComplete="off" maxLength={50} showCount placeholder={t('common.input')} />
        </Form.Item>
        <Form.Item
          label={type === 'scenes' ? t('agent.sceneDesc') : t('agent.roleDesc')}
          name="desc"
          rules={rules.desc}
        >
          <Input.TextArea rows={3} showCount maxLength={500} placeholder={ t('agent.remarkPlaceholder')} />
        </Form.Item>
        <Form.Item label={ t('agent.inputKey')}name="input" rules={rules.input}>
          <Input autoComplete="off" showCount maxLength={50} placeholder={t('common.input')} />
        </Form.Item>
      </div>
    </Form>
  );
});

export default SceneInfoForm;
