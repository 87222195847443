import React, { useState, useRef, useEffect } from 'react';
import { Drawer, Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import SceneInfoForm from '@/components/AgentForm/SceneInfoForm';
import ScenePreAction from '@/components/AgentForm/ScenePreAction';
import ConditionalStrategy from '@/components/AgentForm/ConditionalStrategy';
import StandardStrategy from '@/components/AgentForm/StandardStrategy';
import { cloneDeep } from 'lodash';
import { DataItem } from '@/store/modules/types';

interface MainCartProps {
  visible: boolean;
  form: any;
  toolChildMaps: any[];
  onUpdateVisible: (visible: boolean) => void;
  onUpdateInfo: (form: DataItem) => void;
}

const ScenesDrawerAdd: React.FC<MainCartProps> = ({
  visible,
  form,
  toolChildMaps,
  onUpdateVisible,
  onUpdateInfo,
}) => {
  const { t } = useTranslation();
  const [drawerVisible, setDrawerVisible] = useState(visible);
  const [copiedForm, setCopiedForm] = useState(cloneDeep(form));

  const sceneInfoRef = useRef<any>(null);
  const preActionRef = useRef<any>(null);
  const conditionFormRef = useRef<any>(null);
  const standardFormRef = useRef<any>(null);

  useEffect(() => {
    setDrawerVisible(visible);
    setCopiedForm(cloneDeep(form));
  }, [visible, form]);

  const cancel = () => {
    setDrawerVisible(false);
    onUpdateVisible(false);
  };

  const handleSaveAnswerModel = async () => {
    try {
      const pass1 = await sceneInfoRef.current.validateFormFields();
      const pass2 = await preActionRef.current.validateFormFields();
      const pass3 = await standardFormRef.current.validateFormFields();
      const pass4 = await conditionFormRef.current.validateFormFields();

      if (pass1 && pass2 && pass3 && pass4) {
        onUpdateInfo({
          ...form,
          ...pass1,
          ...pass2,
          ...pass3,
          ...pass4,
        });
      }
    } catch (error) {
      console.log('err--', error);
    }
  };

  return (
    <Drawer
      open={drawerVisible}
      width={800}
      onClose={cancel}
      className="custom-drawer"
      placement="right"
      title={`${form.uid ? t('agentEngine.roleParts.editScene') : t('agentEngine.roleParts.addScene')}`}
      extra={
        <Space>
          <Button onClick={cancel}>{t('common.cancel')}</Button>
          <Button type="primary" onClick={handleSaveAnswerModel}>
            {t('common.save')}
          </Button>
        </Space>
      }
    >
      {drawerVisible && (
        <>
          <div>
            <SceneInfoForm type="scenes" ref={sceneInfoRef} formState={copiedForm} />
          </div>
          <div className="mt-5">
            <ScenePreAction ref={preActionRef} formState={copiedForm} />
          </div>
          <div className="mt-5 px-5 pt-5 pb-1 bg-white rounded">
            <h4 className="text-text_color_black text-base font-medium">
              {t('agentEngine.roleParts.sceneModel')}
            </h4>
            <ConditionalStrategy ref={conditionFormRef} formState={copiedForm} />
          </div>
          <div className="mt-5">
            <StandardStrategy ref={standardFormRef} formState={copiedForm} />
          </div>
        </>
      )}
    </Drawer>
  );
};

export default ScenesDrawerAdd;
