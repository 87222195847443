import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import { AgentTemplateType } from './types';
import { generateUUID } from '@/utils/common';
import { produce } from 'immer';
const initData: AgentTemplateType = {
  agent_id: '',
  uid: '',
  tool_llm: '',
  default_sub_tool_llm: '',
  rag_llm: '',
};
const initialState = {
  currentAgent: initData,
  modelEnum: [
    // 'gpt4',
    'gpt4o',
    'gpt-4o-mini',
    // 'baidu',
    // 'minimax',
    // 'minimax6',
    // 'baichuan',
    // 'qwen-7b-chat',
    // 'qwen-14b-chat',
    // 'qwen-72b-chat',
    'gpt3.5-turbo',
    // 'groq-LLaMA2',
    // 'groq-Mixtral',
    // 'skylark2-pro-32k',
    'deepseek-chat',
    // 'abab6.5-chat',
    // 'abab6.5s-chat',
    'doubao-pro-4k',
    'doubao-lite-4k',
    'doubao-pro-32k',
    'doubao-lite-32k',
    'dubao-pro-128k',
    'doubao-lite-128k',
    // 'pangu',
  ],
};
export const agentTemplateSlice = createSlice({
  name: 'agentTtemplate',
  initialState: {
    currentAgent: initialState.currentAgent,
  },
  reducers: {
    setCurrentAgent: (state, action) => {
      return produce(state, draftState => {
        draftState.currentAgent = action.payload;
        window.localStorage.setItem('currentAgent', JSON.stringify(draftState.currentAgent));
      });
    },
    resetCurrentAgent: state => {
      state.currentAgent = initData;
    },
    persistence: () => {
      const current = window.localStorage.getItem('currentAgent');
      if (current) {
        setCurrentAgent(JSON.parse(current));
      }
    },
    // 新增/编辑tool_map的子项数据，都直接传入整个对象即可
    updateToolMapItem: (state, action) => {
      const { uid } = action.payload;
      if (!state.currentAgent.tool_map) {
        state.currentAgent.tool_map = [];
      }
      // 新增
      if (!uid) {
        state.currentAgent.tool_map.push({
          ...action.payload,
          uid: generateUUID(),
        });
      } else {
        // 编辑
        const index = state.currentAgent.tool_map.findIndex(i => i.uid === uid);
        if (index >= 0) {
          state.currentAgent.tool_map[index] = {
            ...action.payload,
          };
        }
      }
    },
  },
});

export const { setCurrentAgent, resetCurrentAgent } = agentTemplateSlice.actions;

// selectors 等其他代码可以使用导入的 `RootState` 类型
export const selectCurrentAgent = (state: RootState) => state.agentTemplate;
// 导出 modelEnum
export const selectModelEnum = () => initialState.modelEnum;

export default agentTemplateSlice.reducer;
