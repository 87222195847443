// FunctionService.tsx

import React, { useState, useEffect } from 'react';
import { Button, Drawer, Table, Pagination, Form, Input, Modal, message, Tooltip } from 'antd';
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { queryFunctionList, deleteFission, createFission, putFission } from '@/api/agent';
import { getAppId, generateUUID } from '@/utils/common';
import MonacoEditor from './MonacoEditor';
import { Base64 } from 'js-base64';
import { cloneDeep } from 'lodash';
import './func.scss';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import Panel from '@/components/Panel/index';
import { useTranslation } from 'react-i18next';

const FunctionService: React.FC = () => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [editDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [fullDialogVisible, setFullDialogVisible] = useState<boolean>(false);
  const [operationType, setOperationType] = useState<string>('add');
  const [currentId, setCurrentId] = useState<string>('');
  const [bigAuxiliary, setBigAuxiliary] = useState<boolean>(false);
  const [auxiliary, setAuxiliary] = useState<boolean>(false);
  // const formRef = useRef<any>();
  const currentAgent = useAppSelector(selectCurrentAgent);
  const agent_id = currentAgent.currentAgent.agent_id;
  interface fucForm {
    id: string;
    name: string;
    otherName: string;
    description: string;
    body: string;
    returnParams: any;
  }
  const baseForm = {
    otherName: '',
    description: '',
    body: '',
    returnParams: [''],
  };
  const [form] = Form.useForm<fucForm>();

  const columns = [
    { title: t('functionService.functionName'), dataIndex: 'name', key: 'name', width: 380 },
    {
      title: t('functionService.functionAlias'),
      dataIndex: 'otherName',
      key: 'otherName',
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      render: (otherName: string) => (
        <Tooltip placement="topLeft" title={otherName}>
          {otherName}
        </Tooltip>
      ),
    },
    {
      title: t('common.description'),
      dataIndex: 'description',
      key: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: (description: string) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
    { title: t('functionService.updateTime'), dataIndex: 'update_datetime', key: 'update_datetime', width: 300 },
    {
      title: t('common.operation'),
      key: 'action',
      width: 130,
      render: (text: any, record: any) => (
        <>
          <Button type="link" size="small" onClick={() => handleEdit(record)}>
            {t('common.edit')}
          </Button>
          <Button
            type="link"
            danger
            size="small"
            onClick={() => deleteHandle(record.id, record.name)}
          >
            {t('common.delete')}
          </Button>
        </>
      ),
    },
  ];
  useEffect(() => {
    getList();
  }, [page, pageSize]);

  const getList = async () => {
    setLoading(true);
    const res = (await queryFunctionList({
      page,
      per_page: pageSize,
      otherName: '',
      agent_id: agent_id,
      bot_id: getAppId(),
    })) as any; // 假设getCurrentAgent为当前AgentID
    if (res.code === 200) {
      setTableData(res.data?.result || []);
      setTotalCount(res.total_count || 0);
    }
    setLoading(false);
  };
  const handleReturnParams = (data: any) => {
    const newArr = [] as any;
    data.forEach((item: any) => {
      newArr.push(item.value);
    });
    return newArr;
  };
  const handleEdit = (row?: any) => {
    setEditDialogVisible(true);
    if (!row) {
      // 添加逻辑
      setOperationType('add');
      setCurrentId('');
      form.setFieldsValue({ ...baseForm, id: '', name: `function-${generateUUID()}` });
    } else {
      // 编辑逻辑
      setOperationType('edit');
      const obj = cloneDeep(row) as any;
      setCurrentId(obj.id);
      obj.returnParams = handleReturnParams(obj.returnParams);
      form.setFieldsValue(obj);
    }
  };
  const onPageChange = (newPage: number, newSize: number) => {
    setPage(newPage);
    setPageSize(newSize);
  };

  // 全屏
  const handleFullScreen = () => {
    setFullDialogVisible(true);
  };
  const handleAuxiliary = (type: string) => {
    if (type) {
      setBigAuxiliary(true);
      setAuxiliary(false);
    } else {
      setAuxiliary(true);
      setBigAuxiliary(false);
    }
  };

  const deleteHandle = async (id: string, functionName: string) => {
    Modal.confirm({
      title: t('common.confirmDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        const res = await deleteFission({ id, function_name: functionName });
        if (res.code === 200) {
          message.success(t('common.deleteSuccess'));
          setPage(1); // 重置为第一页
        } else {
          message.error(res.message);
        }
      },
    });
  };
  const changeBody = (body: any) => {
    form.setFieldsValue({
      body: body,
    });
  };
  const saveData = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      console.log(values);
      let res: any = null;
      const params = {
        id: currentId,
        bot_id: getAppId(),
        agent_id: agent_id,
        function_name: values.name,
        function_nickname: values.otherName,
        description: values.description,
        parameter: values.returnParams
          .map(function (item: any) {
            return item;
          })
          .join('##'),
        function_body: Base64.encode(values.body),
      };
      if (operationType == 'add') {
        res = await createFission(params);
      } else {
        //put_fission
        res = await putFission(params);
      }
      if (res.code == 200) {
        message.success(t('common.saveSuccess'));
        setEditDialogVisible(false);
        getList();
      } else {
        message.error(res.description);
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };
  return (
    <div className="func-manager main-container p-5 h-full overflow-y-scroll">
      <div className="title">
        <div className="title-text">{t('functionService.title')}</div>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => handleEdit()}>
          {t('functionService.addFunction')}
        </Button>
      </div>
      <div className="account-content">
        <Table
          size="small"
          dataSource={tableData}
          columns={columns}
          rowKey="id"
          scroll={{ x: 1500, y: 600 }}
          pagination={false}
          loading={loading}
          bordered
        />
        <Pagination
          style={{ marginTop: 16, textAlign: 'right' }}
          className="justify-end"
          showSizeChanger
          showQuickJumper
          total={totalCount}
          pageSize={pageSize}
          showTotal={count => t('common.totalItems', { count })}
          onChange={(page, pageSize) => onPageChange(page, pageSize)}
        />
      </div>
      <Drawer
        title={<span>{operationType == 'add' ? t('functionService.addFunction') : t('functionService.editFunction')}</span>}
        width={800}
        onClose={() => setEditDialogVisible(false)}
        open={editDialogVisible}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setEditDialogVisible(false)} style={{ marginRight: 8 }}>
              {t('common.cancel')}
            </Button>
            <Button onClick={saveData} type="primary">
              {t('common.save')}
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={t('functionService.functionName')}
            rules={[{ required: true, message: t('functionService.inputFunctionName') }]}
          >
            <Input disabled placeholder={t('functionService.inputFunctionName')} />
          </Form.Item>
          <Form.Item
            name="otherName"
            label={t('functionService.functionAlias')}
            rules={[{ required: true, message: t('functionService.inputFunctionAlias') }]}
          >
            <Input placeholder={t('functionService.inputFunctionAlias')} />
          </Form.Item>
          <Form.Item name="description" label={t('common.description')}>
            <Input.TextArea placeholder={t('common.inputPrompt', { title: t('common.description') })} rows={2} />
          </Form.Item>
          <Form.Item
            name="body"
            rules={[{ required: true, message: t('functionService.inputFunction') }]}
            className="custom-label"
            label={
              <div className="label-div">
                <span>{t('functionService.functionBody')}</span>
                <span className="label-btn">
                  <span className="iconfont quanping" onClick={handleFullScreen}>
                    {t('common.fullScreen')}
                  </span>
                  <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                    {t('common.auxiliaryInfo')}
                  </span>
                </span>
              </div>
            }
          >
            <MonacoEditor
              editorHeight="200px"
              value={form.getFieldsValue().body}
              onChange={body => changeBody(body)}
            />
          </Form.Item>
          <Form.List
            name="returnParams"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error(t('functionService.atLeastOneReturnVariable')));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item label={index === 0 ? t('functionService.returnVariable') : ''} required={false} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t('functionService.inputReturnVariable'),
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        addonBefore={index + 1}
                        placeholder={t('functionService.inputReturnVariable')}
                        addonAfter={
                          fields.length > 1 ? (
                            <DeleteOutlined onClick={() => remove(field.name)} />
                          ) : null
                        }
                        style={{ width: '60%' }}
                      />
                    </Form.Item>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    {t('functionService.addParameter')}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
        {auxiliary && <Panel onClosePanel={() => setAuxiliary(false)} />}
      </Drawer>
      <Modal
        open={fullDialogVisible}
        onCancel={() => setFullDialogVisible(false)}
        footer={null}
        width="100%"
        className="full-screen-modal"
        style={{ top: 0 }}
      >
        <div className="modal-header mb-3">
          <span>{t('functionService.editFunction')}</span>
          <span
            className="iconfont fuzhuxinxi auxiliary-icon"
            onClick={() => handleAuxiliary('big')}
          >
            {t('common.auxiliaryInfo')}
          </span>
        </div>
        <div className="big-monaco-editor">
          <MonacoEditor
            editorHeight="100%"
            value={form.getFieldsValue().body}
            onChange={body => changeBody(body)}
          />
        </div>
        {bigAuxiliary && (
          <Panel className="big-content-panel" onClosePanel={() => setBigAuxiliary(false)} />
        )}
        <div className="modal-footer text-right mt-6">
          <Button type="primary" onClick={() => setFullDialogVisible(false)}>
            {t('common.close')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default FunctionService;
