import React, { useState, useMemo } from 'react';
import { Collapse } from 'antd';
import './scss/TextCollapse.scss';

interface Message {
  debug_info?: Array<any>;
  debug?: Array<any>;
}

interface Props {
  message: Message;
}

const { Panel } = Collapse;

const TextCollapse: React.FC<Props> = ({ message }) => {
  const [flag, setFlag] = useState<string>('');

  const isRenderText = useMemo(() => {
    const debug_info = message.debug_info || message.debug;
    return debug_info && Array.isArray(debug_info) && debug_info.length > 0;
  }, [message]);

  const renderObject = useMemo(() => {
    let arr: Array<any> = [];
    if (isRenderText) {
      const debug_info = message.debug_info || message.debug || [];
      debug_info.forEach(i => {
        if (i.add_info) {
          const { reference } = i.add_info;
          if (reference) {
            arr = reference;
          }
        }
      });
    }
    return arr;
  }, [isRenderText, message]);

  return (
    <div>
      {isRenderText && renderObject.length > 0 && (
        <div className="text-collapse">
          <Collapse activeKey={flag} onChange={key => setFlag(key as string)} accordion>
            <Panel
              header={
                <div>
                  <span style={{ color: '#909398' }} className="iconfont wangluo" />
                  <span style={{ color: '#909398', marginLeft: '8px' }}>网络搜索</span>
                </div>
              }
              key="1"
            >
              {renderObject.map((item: any) => (
                <div
                  key={item.idx}
                  className="p-2 mb-2 rounded"
                  style={{ color: '#909398', backgroundColor: '#f4f4f5' }}
                >
                  {item.site_name}
                </div>
              ))}
            </Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default TextCollapse;
