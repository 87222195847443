import React, { useState, useEffect } from 'react';
import { useReactFlow } from 'reactflow';
import { generateUUID } from '@/utils/common';
import { cloneDeep } from 'lodash';

let currentItem = {} as any;

/**
 * @returns {string} - A unique id.
 */
function getId() {
  return `${currentItem.flowType}_${generateUUID()}`;
}

// State management using React's useState
const useDragAndDrop = () => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const { addNodes, project } = useReactFlow();

  useEffect(() => {
    const handleDragEnd = () => {
      setIsDragging(false);
      setIsDragOver(false);
      document.removeEventListener('drop', handleDragEnd);
    };

    if (isDragging) {
      document.body.style.userSelect = 'none';
    } else {
      document.body.style.userSelect = '';
    }

    return () => {
      document.removeEventListener('drop', handleDragEnd);
    };
  }, [isDragging]);

  const onDragStart = (event: React.DragEvent, data: any) => {
    currentItem = cloneDeep(data);
    if (event.dataTransfer) {
      event.dataTransfer.setData('application/reactflow', data.type);
      event.dataTransfer.effectAllowed = 'move';
    }
    setIsDragging(true);
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    if (currentItem.type) {
      setIsDragOver(true);

      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'move';
      }
    }
  };

  const onDragLeave = () => {
    setIsDragOver(false);
  };

  const onDrop = (event: React.DragEvent) => {
    // 获取掉落的位置
    const reactFlowBounds = (event.target as HTMLElement).getBoundingClientRect();
    const position = project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const nodeId = getId();
    const newNode = {
      id: nodeId,
      type: currentItem.type,
      position,
      data: currentItem,
    } as any;

    addNodes(newNode);
  };

  return {
    isDragOver,
    isDragging,
    onDragStart,
    onDragLeave,
    onDragOver,
    onDrop,
  };
};

export default useDragAndDrop;
