import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Form, Input, Button, Switch, Select, Row, Col } from 'antd';
import { t } from 'i18next';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { entityTypeOptions } from '@/constant/options';
import './scss/ScenePreAction.scss'; // 假设样式由SCSS转换，并进行了适当的修改以匹配Ant Design
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  queryAllAiEntityAction,
  queryFunctionListAction,
  selectOptions,
} from '@/store/modules/asyncOptions';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { cloneDeep } from 'lodash';
import { produce } from 'immer';
import VarNameSelect from './VarNameSelect';
import Panel from '@/components/Panel/index';
const { Option } = Select;

interface EntityItem {
  var_name: string;
  entity_name?: string;
  entity_type: number;
  value?: string;
}

interface ScenePreActionProps {
  type?: string;
  actionText?: string;
  formState?: any; // 根据实际情况声明更精确的类型
  ref?: any;
}

// 假设有一个hooks作用是获取所有AI实体和函数
// useAsyncOptionsHook 可能需要你用React Hook的形式重新实现
// const { getAllAiEntity, getFuncOptions } = useAsyncOptionsHook()

const ScenePreAction: React.FC<ScenePreActionProps> = forwardRef(
  function ScenePreAction(props, ref) {
    // eslint-disable-next-line react/prop-types
    const { type, actionText, formState } = props;
    const dispatch = useAppDispatch();
    const currentAgent = useAppSelector(selectCurrentAgent);
    const allAiEntity = useAppSelector(selectOptions).allAiEntity;
    const funcOptions = useAppSelector(selectOptions).funcOptions;

    const [form] = Form.useForm();
    const [ScenePreForm, setScenePreActionForm] = useState<any>({});
    useEffect(() => {
      form.setFieldsValue(formState);
      setScenePreActionForm(cloneDeep(formState));
    }, [formState]);
    const [entities, setEntities] = useState<EntityItem[]>([]);
    // 表单项布局
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    const onFinish = (values: any) => {
      console.log('Received values of form:', values);
    };

    useEffect(() => {
      dispatch(queryAllAiEntityAction(currentAgent.currentAgent.agent_id));
      dispatch(queryFunctionListAction(currentAgent.currentAgent.agent_id));
    }, [currentAgent]);

    const entityItem = {
      // 实体
      var_name: undefined,
      // 变量
      entity_name: undefined,
      // region_num: "",
      value: '',
      entity_type: 1,
    };
    function addEntityItem() {
      const arr = form.getFieldValue('entity_arr');
      arr.push(entityItem);
      form.setFieldValue('entity_arr', arr);
      setScenePreActionForm({
        ...ScenePreForm,
        entity_arr: arr,
      });
    }
    function changeVarName(index: number) {}

    function getFormFieldsValue() {
      return form.getFieldsValue(true);
    }
    async function validateFormFields() {
      return await form.validateFields();
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          getFormFieldsValue,
          validateFormFields,
        };
      },
      []
    );

    const [auxiliary, setAuxiliary] = useState(false);
    const handleAuxiliary = () => {
      setAuxiliary(true);
    };
    return (
      <Form
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
        layout="horizontal"
        name="scenePreActionForm"
        labelCol={{
          span: 2.5,
        }}
      >
        <div className="scene-pre-form-body px-5 pt-5 pb-1 bg-white rounded">
          {type !== 'engine' && (
            <h3 className="pb-2 font-normal">
              {actionText === 'role' ? t('scenePreAction.rolePreAction') : t('scenePreAction.scenePreAction')}
            </h3>
          )}
          <Form.Item
            label={
              <div className="pre-label before:bg-primary_blue">
                <span className="ml-4">{t('scenePreAction.variableAssignment')}</span>
              </div>
            }
            name="is_enable_entity"
            valuePropName="checked"
          >
            <Switch
              checked={ScenePreForm.is_enable_entity}
              onChange={checked => {
                form.setFieldValue('is_enable_entity', checked);
                const arr = form.getFieldValue('entity_arr');
                const data = { ...ScenePreForm, is_enable_entity: checked };
                if (!arr.length) {
                  arr.push(entityItem);
                  form.setFieldValue('entity_arr', arr);
                  data.entity_arr = arr;
                }
                setScenePreActionForm(data);
              }}
            />
            <span
              className="iconfont fuzhuxinxi ml-2 cursor-pointer text-xs"
              onClick={handleAuxiliary}
              style={{ color: '#909399' }}
            >
              &nbsp;{t('common.auxiliaryInfo')}
            </span>
          </Form.Item>
          {ScenePreForm.is_enable_entity && (
            <Form.List name="entity_arr">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row key={field.key} gutter={12}>
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'var_name']}
                          rules={[{ required: true, message: t('scenePreAction.inputVariableName') }]}
                        >
                          <VarNameSelect
                            value={ScenePreForm.entity_arr[index].var_name}
                            onChange={() => changeVarName(index)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'entity_type']}
                          rules={[{ required: true, message: t('scenePreAction.inputVariableType') }]}
                        >
                          <Select
                            placeholder={t('scenePreAction.selectVariableType')}
                            onChange={val => {
                              const entity_arr = form.getFieldValue('entity_arr');
                              setScenePreActionForm(
                                produce((draft: any) => {
                                  draft.entity_arr = entity_arr;
                                })
                              );
                            }}
                          >
                            {entityTypeOptions.map(option => (
                              <Option value={option.value} key={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        {ScenePreForm.entity_arr[index].entity_type === 1 && (
                          <Form.Item
                            {...field}
                            name={[field.name, 'entity_name']}
                            rules={[{ required: true, message: t('scenePreAction.selectEntity') }]}
                          >
                            <Select placeholder={t('scenePreAction.selectEntity')}>
                              {allAiEntity.map(option => (
                                <Option value={option.entity_id} key={option.entity_id}>
                                  {option.entity_id.startsWith('system_ent')
                                    ? option.entity_desc
                                    : option.entity_name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                        {ScenePreForm.entity_arr[index].entity_type === 2 && (
                          <Form.Item
                            {...field}
                            name={[field.name, 'value']}
                            rules={[{ required: true, message: t('scenePreAction.inputConstant') }]}
                          >
                            <Input placeholder={t('scenePreAction.inputConstant')} />
                          </Form.Item>
                        )}
                        {ScenePreForm.entity_arr[index].entity_type === 3 && (
                          <p>{t('scenePreAction.variableNumberIncrease1')}</p>
                        )}
                      </Col>
                      <Col span={2}>
                        <div className="flex items-center" style={{ height: '32px' }}>
                          <MinusCircleOutlined
                            className="dynamic-delete-button text-base"
                            onClick={() => remove(field.name)}
                          />
                        </div>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={addEntityItem} block icon={<PlusOutlined />}>
                      {t('scenePreAction.addVariable')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}

          <Form.Item
            label={
              <div className="pre-label before:bg-primary_blue">
                <span className="ml-4">{t('scenePreAction.functionService')}</span>
              </div>
            }
            name="is_enable_function"
            valuePropName="checked"
          >
            <Switch
              checked={ScenePreForm.is_enable_function}
              onChange={checked =>
                setScenePreActionForm({ ...ScenePreForm, is_enable_function: checked })
              }
            />
          </Form.Item>
          {ScenePreForm.is_enable_function && (
            <Form.Item name="function_name" rules={[{ required: true, message: t('scenePreAction.selectFunction') }]}>
              <Select placeholder={t('scenePreAction.selectFunction')}>
                {funcOptions.map((option: any) => (
                  <Option value={option.name} key={option.id}>
                    {option.otherName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>
        {auxiliary && <Panel onClosePanel={() => setAuxiliary(false)} />}
      </Form>
    );
  }
);

export default ScenePreAction;
