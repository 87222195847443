import React, { useState, useEffect, useRef } from 'react';
import { Drawer, Button, Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import SceneInfoForm from '@/components/AgentForm/SceneInfoForm';
import ScenePreAction from '@/components/AgentForm/ScenePreAction';
import { DataItem } from '@/store/modules/types';

interface ClassificationAddProps {
  visible: boolean;
  form: DataItem;
  toolMaps: Array<any>;
  onUpdateVisible: (val: boolean) => void;
  onUpdateInfo: (info: DataItem) => void;
}

const ClassificationAdd: React.FC<ClassificationAddProps> = ({
  visible,
  form,
  toolMaps,
  onUpdateInfo,
  onUpdateVisible,
}) => {
  const { t } = useTranslation();

  const [drawerVisible, setDrawerVisible] = useState(visible);
  const [sceneInfoForm, setSceneInfoForm] = useState({
    name: form.name,
    desc: form.desc,
    input: form.input,
  });
  const [preActionForm, setPreActionForm] = useState({
    is_enable_entity: form.is_enable_entity,
    entity_arr: form.entity_arr || [],
    entity_type: form.entity_type,
    is_enable_function: form.is_enable_function,
    function_name: form.function_name,
  });

  const sceneInfoRef = useRef<any>(null);
  const preActionRef = useRef<any>(null);

  useEffect(() => {
    setDrawerVisible(visible);
  }, [visible]);

  useEffect(() => {
    setSceneInfoForm({
      name: form.name || '',
      desc: form.desc || '',
      input: form.input || '',
    });
    setPreActionForm({
      is_enable_entity: form.is_enable_entity || false,
      entity_arr: form.entity_arr || [],
      entity_type: form.entity_type || '',
      is_enable_function: form.is_enable_function || false,
      function_name: form.function_name || '',
    });
  }, [form]);

  const handleSaveAnswerModel = async () => {
    try {
      const sceneInfoValid = await sceneInfoRef.current?.validateFormFields();
      const preActionValid = await preActionRef.current?.validateFormFields();

      if (sceneInfoValid && preActionValid) {
        onUpdateInfo({
          ...form,
          ...sceneInfoValid,
          ...preActionValid,
        });
        onUpdateVisible(false);
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  return (
    <Drawer
      open={drawerVisible}
      onClose={() => onUpdateVisible(false)}
      width={800}
      className="custom-drawer"
      placement="right"
      title={`${form.uid ? t('agentEngine.role.editClassificationRole') : t('agentEngine.role.addClassificationRole')}`}
      extra={
        <Space>
          <Button onClick={() => onUpdateVisible(false)}>{t('common.cancel')}</Button>
          <Button type="primary" onClick={handleSaveAnswerModel}>
            {t('common.save')}
          </Button>
        </Space>
      }
    >
      {drawerVisible && (
        <div>
          <SceneInfoForm ref={sceneInfoRef} formState={sceneInfoForm} />
          <div className="mt-5">
            <ScenePreAction ref={preActionRef} actionText="role" formState={preActionForm} />
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default ClassificationAdd;
