import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Select, Table, Pagination, Modal, message, Alert } from 'antd';
import { t } from 'i18next';
import { PlusOutlined } from '@ant-design/icons';
import {
  getAccountList,
  getRoleList,
  addAccountUser,
  updateAccountUser,
  deleteAccountUser,
  queryQueue,
} from '@/api/account';

const { Option } = Select;
interface accountFormFields {
  name: string;
  nickname: string;
  password: string;
  rePassword: string;
  role_ids: Array<any>;
  queue_ids: Array<any>;
}
const AccountManager: React.FC = () => {
  const [form] = Form.useForm();
  const [queryForm] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [rolesList, setRolesList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currentObj, setCurrentObj] = useState({ id: '' });
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const formRef = useRef<any>();

  const fetchGroupList = async () => {
    const filters = { filters: [] } as any;
    const newFilters = JSON.stringify(filters);
    const str = `?q=${newFilters}&page=1&results_per_page=1000`;
    const res: any = await queryQueue(str);
    setGroupList(res.objects || []);
  };
  useEffect(() => {
    fetchRoleList();
    fetchGroupList();
    fetchAccountList();
  }, []);
  // useEffect(() => {
  //   fetchAccountList();
  // }, [currentPage, pageSize]);

  const onPageChange = (val: number, newSize: number) => {
    setCurrentPage(val);
    setPageSize(newSize);
    fetchAccountList(val, newSize);
  };
  const handleTableData = (data: any) => {
    data.forEach((item: any) => {
      if (item.roles) {
        item.rolesName = item.roles.map((role: any) => role.name).join('、');
      }
      if (item.queues) {
        item.queuesName = item.queues.map((op: any) => op.queue_name).join('、');
      }
    });
    return data;
  };
  const fetchAccountList = async (page?: number, pageSize?: number) => {
    // API调用获取账号列表
    const filters = { filters: [] } as any;
    const { nickname, roles } = queryForm.getFieldsValue(true);
    if (nickname) {
      //%为模糊搜索，不加的话是完全匹配
      filters.filters.push({
        name: 'nickname',
        op: 'like',
        val: `%${nickname}%`,
      });
    }
    if (roles && roles.length > 0) {
      filters.filters.push({ name: 'role', op: 'in', val: roles });
    }
    const newFilters = JSON.stringify(filters);
    const str = `?q=${newFilters}&page=${page || currentPage}&results_per_page=${pageSize || pageSize}`;
    await getAccountList(str).then((res: any) => {
      setTableData(handleTableData(res.objects || []));
      setTotalCount(res.num_results || 0);
    });
  };

  const fetchRoleList = async () => {
    // API调用获取角色列表
    await getRoleList('').then((res: any) => {
      setRolesList(res.objects || []);
    });
  };

  const onSearch = () => {
    // 根据搜索条件重新获取账号列表
    setCurrentPage(1);
    fetchAccountList();
  };

  const onAddOrEdit = (record: any) => {
    setEditDialogVisible(true);
    if (record) {
      setIsEdit(true);
      setCurrentObj(record);
      form.setFieldsValue(record);
      const rolesArr = record.roles?.map((item: any) => item.role_id);
      const queueArr = record.queues?.map((item: any) => item.queue_id);
      form.setFieldsValue({ role_ids: rolesArr, queue_ids: queueArr });
    } else {
      setCurrentObj({ id: '' });
      form.resetFields();
      setIsEdit(false);
    }
  };

  const onDelete = async (id: string) => {
    // API调用删除账号
    Modal.confirm({
      title: 'Confirm',
      content: t('common.confirmDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        await deleteAccountUser(id);
        message.success(t('common.deleteSuccess'));
        fetchAccountList();
      },
    });
  };
  // 随机字符
  const randomWord = (min: number, max: number) => {
    let str = '';
    let range = min;
    const arr = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    // 随机产生
    range = Math.round(Math.random() * (max - min)) + min;
    for (let i = 0; i < range; i++) {
      const pos = Math.round(Math.random() * (arr.length - 1));
      str += arr[pos];
    }
    return str;
  };
  // 自动生成密码
  const updatePassword = () => {
    const _pattern = '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$';
    let _pwd = randomWord(8, 11);
    while (!_pwd.match(_pattern)) {
      _pwd = randomWord(8, 11);
    }
    const teStr = '#_!';
    const _numRan = Math.floor(Math.random() * 4);
    const _arrayStr = _pwd.split('');
    _arrayStr.splice(_numRan, 0, teStr.charAt(_numRan));
    form.setFieldsValue({
      password: _arrayStr.join(''),
    });
  };
  const onSave = async () => {
    try {
      const values = await form.validateFields();
      formRef.current.validateFields().then((values: accountFormFields) => {
        let actionFun = addAccountUser;
        const actionForm = {
          name: values.name,
          nickname: values.nickname,
          password: values.password,
          role_ids: values.role_ids,
          queue_ids: values.queue_ids,
        } as any;
        if (isEdit) {
          actionForm.id = currentObj.id;
          actionFun = updateAccountUser;
        }
        actionFun(actionForm)
          .then(() => {
            message.success(t('common.saveSuccess'));
            setEditDialogVisible(false);
            setCurrentPage(1);
            fetchAccountList();
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 400) {
              message.error(error.response.data.message);
            } else {
              message.error(t('common.saveFailed'));
            }
          });
      });
    } catch (errorInfo) {
      console.log('验证失败:', errorInfo);
    }
  };

  const columns = [
    { title: t('common.name'), dataIndex: 'nickname', key: 'nickname' },
    { title: t('common.accountName'), dataIndex: 'name', key: 'name' },
    { title: t('common.accountID'), dataIndex: 'corp_id', key: 'corp_id', width: 300 },
    { title: t('common.role'), dataIndex: 'rolesName', key: 'rolesName' },
    { title: t('common.group'), dataIndex: 'queuesName', key: 'rolesName' },
    {
      title: t('common.action'),
      key: 'action',
      render: (_text: any, record: any) => (
        <>
          <Button type="link" size="small" onClick={() => onAddOrEdit(record)}>
            {t('common.edit')}
          </Button>
          <Button type="link" danger size="small" onClick={() => onDelete(record.id)}>
            {t('common.delete')}
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="p-4 h-full overflow-y-scroll">
      <Alert
        style={{ marginBottom: 10 }}
        showIcon
        closable
        message={t('common.createAccountBeforeConfigGroup')}
        type="warning"
      />
      <Form form={queryForm} layout="inline" onFinish={onSearch} style={{ marginBottom: 16 }}>
        <Form.Item name="nickname" label={t('common.nickname')}>
          <Input placeholder={t('common.inputNickname')} onChange={onSearch} />
        </Form.Item>
        <Form.Item name="roles" label={t('common.role')}>
          <Select
            mode="multiple"
            onChange={onSearch}
            placeholder={t('common.selectRole')}
            style={{ width: 200 }}
          >
            {rolesList.map((role: any) => (
              <Option key={role.role_id} value={role.role_id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => onAddOrEdit(null)}>
            {t('common.addAccount')}
          </Button>
        </Form.Item>
      </Form>
      <Table bordered size="small" scroll={{ y: 600 }} columns={columns} dataSource={tableData} pagination={false} rowKey="id" />
      <Pagination
        style={{ marginTop: 16, textAlign: 'right' }}
        showSizeChanger
        showQuickJumper
        total={totalCount}
        current={currentPage}
        pageSize={pageSize}
        showTotal={totalCount => `共 ${totalCount} 条`}
        onChange={(page, pageSize) => onPageChange(page, pageSize)}
      />
      <Modal
        title={isEdit ? t('common.editAccount') : t('common.addAccount')}
        open={editDialogVisible}
        onCancel={() => setEditDialogVisible(false)}
        footer={[
          <Button key="back" onClick={() => setEditDialogVisible(false)}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={onSave}>
            {t('common.save')}
          </Button>,
        ]}
      >
        <Form form={form} ref={formRef} layout="vertical">
          <Form.Item
            name="nickname"
            label={t('common.nickname')}
            rules={[{ required: true, message: t('common.inputNickname') }]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
          <Form.Item
            name="name"
            label={t('common.accountName')}
            rules={[
              { required: true, message: t('common.inputAccountName') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve(new Error(t('common.inputAccountName')));
                  } else if (value.replace(/^[a-zA-Z0-9]+$/, '')) {
                    return Promise.reject(new Error(t('common.accountNameOnlyLettersAndNumbers')));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
          {/* 如果是添加账号的情况下才需要填写密码 */}
          {!isEdit && (
            <>
              <Form.Item
                name="password"
                className="custom-label"
                label={
                  <div className="label-div">
                    <span>{t('login.password')}</span>
                    <span className="label-btn">
                      <span className="iconfont suiji" onClick={updatePassword}>
                        {t('common.generateRandomCode')}
                      </span>
                    </span>
                  </div>
                }
                rules={[
                  { required: true, message: t('login.inputPassword') },
                  {
                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/,
                    message: t('login.passwordLength6To12'),
                  },
                ]}
                hasFeedback
              >
                <Input.Password allowClear />
              </Form.Item>
              <Form.Item
                name="rePassword"
                label={t('common.confirmPassword')}
                dependencies={['password']}
                hasFeedback
                rules={[
                  { required: true, message: t('login.inputPasswordAgain') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('login.passwordNotMatch')));
                    },
                  }),
                ]}
              >
                <Input.Password allowClear />
              </Form.Item>
            </>
          )}
          <Form.Item
            name="role_ids"
            label={t('common.role')}
            rules={[{ required: true, message: t('common.selectRole') }]}
          >
            <Select mode="multiple" placeholder={t('common.selectRole')}>
              {rolesList.map((role: any) => (
                <Option key={role.role_id} value={role.role_id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="queue_ids"
            label={t('common.group')}
            rules={[{ required: true, message: t('common.selectGroup') }]}
          >
            <Select mode="multiple" placeholder={t('common.selectGroup')}>
              {groupList.map((queue: any) => (
                <Option key={queue.queue_id} value={queue.queue_id}>
                  {queue.queue_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AccountManager;
