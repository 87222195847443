import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, DatePicker, Select, Table, Pagination } from 'antd';
import { t } from 'i18next';
import { queryWorkbench, updateWorkbenchStatus } from '@/api/agent'; // This needs to be changed based on the actual API import path in your React project
import SessionDetail from '../../dataAnalysis/sessionLog/SessionDetail';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import dayjs from 'dayjs';
import './trainWorkbench.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface DataItem {
  sid: string;
  agent_name: string;
  source: number;
  source_name: string;
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
  billing: string;
  start_time: string;
  last_time: string;
}

const trainStatus = [
  { label: t('common.all'), value: null },
  { label: t('agentTrain.trainWorkbench.trained'), value: 1 },
  { label: t('agentTrain.trainWorkbench.toTrain'), value: 0 },
];
const trainSources = [
  { label: t('common.all'), value: null },
  { label: t('agentTrain.trainWorkbench.ragNoRecall'), value: 'rag_not_recall' },
  { label: t('agentTrain.trainWorkbench.ragTurnHuman'), value: 'rag_turn_human' },
  { label: t('agentTrain.trainWorkbench.ragFloor'), value: 'rag_floor' },
  { label: t('agentTrain.trainWorkbench.cateFloor'), value: 'cate_floor' },
];
const trainWorkbench: React.FC = () => {
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DataItem[]>([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0 });
  const [form] = Form.useForm();
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const columns = [
    { title: t('agentTrain.trainWorkbench.sessionId'), dataIndex: 'sid', key: 'sid' },
    {
      title: t('agentTrain.trainWorkbench.lastDialogueTime'),
      dataIndex: 'msg_time',
      key: 'msg_time',
      render: (text: any, row: any) => {
        return row.msg_time;
      },
    },
    {
      title: t('agentTrain.trainWorkbench.trainSource'),
      key: 'train_source',
      render: (text: any, row: any) => {
        return (
          row.train_source &&
          row.train_source
            .map((item: any) => {
              let str = '';
              trainSources.map((i: any) => {
                if (i.value === item) {
                  str = i.label;
                }
              });
              return str;
            })
            .join('、')
        );
      },
    },
    {
      title: t('agentTrain.trainWorkbench.trainStatus'),
      key: 'train_status',
      render: (text: any, row: any) => {
        return (
          <span className={`train-status-span ${row.train_status ? 'train-status-1' : ''}`}>
            {row.train_status ? t('agentTrain.trainWorkbench.trained') : t('agentTrain.trainWorkbench.toTrain')}
          </span>
        );
      },
    },
    {
      title: t('common.operation'),
      dataIndex: '',
      key: 'op',
      render: (row: any) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setAgent(row);
                setDetailVisible(true);
                handleSelectedRowId(row.sid);
              }}
            >
              {t('common.details')}
            </Button>
            <Button
              type="link"
              disabled={row.train_status ? true : false}
              onClick={() => {
                changeTrainStatus(row);
              }}
            >
              {t('agentTrain.trainWorkbench.completeTraining')}
            </Button>
          </>
        );
      },
    },
  ];
  const [detailVisible, setDetailVisible] = useState(false);
  const [agent, setAgent] = useState({});
  const onPageChange = (newPage: number, newSize: number) => {
    setPagination({ ...pagination, current: newPage, pageSize: newSize });
  };
  const changeTrainStatus = (row: any) => {
    updateWorkbenchStatus({ sid: row.sid, agent_id: currentAgent.agent_id, train_status: 1 }).then(
      (res: any) => {
        if (res.code == 200) {
          fetchLogs({
            ...form.getFieldsValue(),
            page: 1,
            size: pagination.pageSize,
          });
        }
      }
    );
  };
  const fetchLogs = useCallback(
    async (params: any) => {
      setLoading(true);
      const data: any = {
        ...params,
        agent_id: currentAgent.agent_id,
        train_source: params.train_source || [],
        train_status: params.train_status,
      };
      const { time_range } = params as any;
      if (time_range && time_range.length) {
        data.start_time = time_range[0].format('YYYY-MM-DD') + ' 00:00:00';
        data.end_time = time_range[1].format('YYYY-MM-DD') + ' 23:59:59';
      }
      delete data.time_range;
      const result = await queryWorkbench(data);
      if (result.code === 200) {
        setData(result.data.result);
        setPagination({ ...pagination, total: result.data.total_count });
      }
      setLoading(false);
    },
    [pagination.current, pagination.pageSize]
  );
  useEffect(() => {
    fetchLogs({
      ...form.getFieldsValue(),
      page: pagination.current,
      size: pagination.pageSize,
      time_range: [dayjs().subtract(7, 'day'), dayjs()],
      ...form.getFieldsValue(),
    });
  }, [fetchLogs, pagination.current, pagination.pageSize]);

  useEffect(() => {
    const localId = window.localStorage.getItem('currentSid') || '';
    setSelectedRowId(localId);
  }, []);

  const handleTableChange = (newPagination: any) => {
    fetchLogs({
      page: newPagination.current,
      size: newPagination.pageSize,
      ...form.getFieldsValue(),
    });
  };

  const onSearch = () => {
    fetchLogs({
      ...form.getFieldsValue(),
      page: 1,
      size: pagination.pageSize,
    });
  };
  const handleSelectedRowId = (val: any) => {
    setSelectedRowId(val);
    window.localStorage.setItem('currentSid', val);
  };

  return (
    <div className="p-4 h-full overflow-y-scroll">
      <Form
        form={form}
        className="mb-5"
        layout="inline"
        onFinish={onSearch}
        initialValues={{
          time_range: [dayjs().subtract(7, 'day'), dayjs()],
          train_status: 0,
          train_source: [],
        }}
      >
        <Form.Item name="sid" label={t('agentTrain.trainWorkbench.sessionId')}>
          <Input placeholder={t('common.inputPrompt', { title: t('agentTrain.trainWorkbench.sessionId') })} allowClear />
        </Form.Item>
        <Form.Item name="time_range" label={t('agentTrain.trainWorkbench.startTime')}>
          <RangePicker defaultValue={[dayjs().subtract(7, 'day'), dayjs()]} />
        </Form.Item>
        <Form.Item name="train_status" label={t('agentTrain.trainWorkbench.trainStatus')}>
          <Select placeholder={t('common.select')} allowClear style={{ minWidth: 120 }}>
            {trainStatus.map(item => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="train_source" label={t('agentTrain.trainWorkbench.trainSource')}>
          <Select placeholder={t('common.select')} mode="multiple" allowClear style={{ minWidth: 120 }}>
            {trainSources.map(item => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('common.search')}
          </Button>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        onChange={handleTableChange}
        rowKey="sid"
        bordered
        rowClassName={record => (record.sid === selectedRowId ? 'session-selected-row' : '')}
      />
      <Pagination
        style={{ marginTop: 16, textAlign: 'right' }}
        showSizeChanger
        showQuickJumper
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={total => t('common.totalItems', { count: total })}
        onChange={(page, pageSize) => onPageChange(page, pageSize)}
      />
      <SessionDetail
        agent={agent}
        dialog={detailVisible}
        setDialog={setDetailVisible}
        setSelectedRowId={handleSelectedRowId}
        query={form.getFieldsValue()}
        moduleFlag="workbench"
      ></SessionDetail>
    </div>
  );
};

export default trainWorkbench;
