import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '@/contexts/languageContext';
import LanguageSwitcher from '@/components/LanguageSwitcher/languageSwitcher';

import { useNavigate } from 'react-router-dom';
import { setToken } from '@/utils/auth';
import { getLogin, getUserInfo } from '@/api/user';
import './login.scss';
import { useAppDispatch } from '@/store/hooks';
import { setLoginData, setStoreToken, setUserName, setRoles, setLoginType } from '@/store/modules/user';
import { resetCurrentAgent } from '@/store/modules/agentTemplate';
interface LoginFormFields {
  username: string;
  password: string;
}
const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const { setLanguage } = useLanguage(); // 设置用户语言

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('zh'); // 新增：用于记录选中的语言
  const navigate = useNavigate();
  const formRef = useRef<any>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    // 监听Enter键提交表单，对应Vue组件中的onkeypress
    const handleEnterKey = (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        onLogin();
      }
    };
    window.addEventListener('keypress', handleEnterKey);
    return () => {
      window.removeEventListener('keypress', handleEnterKey);
    };
  }, []);
  const loginByUsername = async (data: any) => {
    try {
      const res: any = await getLogin(data);
      if (res && res.code === 200) {
        dispatch(resetCurrentAgent());
        dispatch(setLoginType('account'))
        const data1 = {
          // username: data.username,
          // // 一个用户可能有多个角色
          // roles: ['admin'],
          accessToken: res.data as any,
          refreshToken: 'eyJhbGciOiJIUzUxMiJ9.adminRefresh',
          expires: new Date('2033/10/30 00:00:00'),
        };
        dispatch(setStoreToken(res.data));
        setToken(data1);
        const res1: any = await getUserInfo();
        if (res1.code === 200) {
          const data2 = {
            ...data1,
            username: res1.data.nickname,
            roles: res1.data.roles.map((item: any) => item.name),
            // roles: ['Agent协助']
          };
          setToken(data2);
          dispatch(setUserName(data2.username));
          dispatch(setRoles(data2.roles));
          dispatch(setLoginData(data));
          setLoading(false);
          // 设置用户语言,TODO: 需要后端支持
          const userLanguage = res1.data.language || res1.data.system_language || 'zh';
          setLanguage(userLanguage);

          // navigate('/agentService/workspace', { replace: true });
          if (data2.roles.length === 1 && data2.roles.includes('Agent协助')) {
            navigate('/agentService/workspace', { replace: true });
          } else {
            navigate('/agentService/llmAgent', { replace: true });
          }
          message.success(t('login.loginSuccess'));
        } else {
          message.error(t('login.loginFailed'));
        }
      } else {
        message.error(t('login.loginFailed'));
      }
    } catch (e) {
      setLoading(false);
    }

    // getLogin(data)
    //   .then((res: any) => {
    //     if (res && res.code === 200) {
    //       // 暂时改造
    //       // setToken(data.data);
    //       const data1 = {
    //         // username: data.username,
    //         // // 一个用户可能有多个角色
    //         // roles: ['admin'],
    //         accessToken: res.data as any,
    //         refreshToken: 'eyJhbGciOiJIUzUxMiJ9.adminRefresh',
    //         expires: new Date('2033/10/30 00:00:00'),
    //       };
    //       setToken(data1);
    //       const user = await getUserInfo()
    //       // dispatch(setStoreToken(res.data));
    //       // dispatch(setLoginData(data));
    //       // setLoading(false);
    //       // navigate('/agentService/llmAgent', { replace: true });
    //       // message.success('登录成功');
    //
    //     }
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //   });
  };
  const onLogin = () => {
    formRef.current.validateFields().then((values: LoginFormFields) => {
      const { username, password } = values;
      console.log(username, password); // 发起登录请求，此处需替换成实际的登录逻辑
      loginByUsername({
        username,
        password,
      });
    });
  };

  const switchLanguage = (lang: string) => {  // 定义切换语言的函数
    setLanguage(lang);
    setSelectedLanguage(lang);
    document.documentElement.lang = lang;
    console.log(`切换到语言: ${lang}`);
  };
  
  // (lang: string) => {
  //   console.log(`切换到语言: ${lang}`);
  // };
  return (
    <div className="select-none login-container" lang={selectedLanguage}>
      <div className="login-img banner">
        <h1>DeepFlow <span className="gradient-text" data-lang="banner-title">Agent</span></h1>
        <h2 data-lang="banner-subtitle">{t('login.banner-subtitle')}</h2>
        {/* <div className="color-bg"></div> */}
        {/* <div className="img-bg"></div> */}
      </div>
      <div className="login-box">
        <div className='language-switcher'>
          <LanguageSwitcher />
        </div>
        {/* <div className='language-switcher' onClick={(e) => {  // 修改语言切换器的点击处理
          const target = e.target as HTMLElement;
          if (target.dataset.lang) {
            switchLanguage(target.dataset.lang);
          }
        }}>
          <span className={`language-option ${selectedLanguage === 'zh'?'selected' : ''}`} data-lang="zh">中文</span>
          <span className={`language-option ${selectedLanguage === 'en'?'selected' : ''}`} data-lang="en">EN</span>
        </div> */}
        <div className="login-form">
          <div className="login-title">{t('login.title')}</div>
          <Form
            ref={formRef}
            name="loginForm"
            initialValues={{ remember: true }}
            onFinish={onLogin}
          >
            <Form.Item name="username" rules={[{ required: true, message: t('login.inputUsername') }]}>
              <Input placeholder={t('login.username')} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: t('login.inputPassword') },
                {
                  pattern:
                    /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[()])+$)(?!^.*[\u4E00-\u9FA5].*$)([^(0-9a-zA-Z)]|[()]|[a-z]|[A-Z]|[0-9]){8,18}$/,
                    message: t('login.passwordPattern'),
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder={t('login.password')} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button login-btn"
                loading={loading}
              >
                {t('login.submit')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default LoginForm;
