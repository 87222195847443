// import React, { useState, useEffect, useRef } from 'react';
import React, { useState, useEffect } from 'react';
import { Tabs, message } from 'antd';
import { useTranslation } from 'react-i18next';
import CharacterScene from './parts/roleParts/CharacterScene';
import PromptWord from './parts/promptWord';
import PromptConfig from './parts/config';
import RagConfig from './parts/ragConfig';
// import ChatConfig from './parts/chatConfig';
import VoiceConfig from './parts/voiceConfig';
import ActiveGuidance from './parts/activeGuidance';
import { queryAgentConfig } from '@/api/agent';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectCurrentAgent, setCurrentAgent } from '@/store/modules/agentTemplate';
import './index.scss'

const { TabPane } = Tabs;

const AgentEngine = () => {
  const { t } = useTranslation();

  const [activeName, setActiveName] = useState('role');
  const dispatch = useAppDispatch();
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;

  const getData = async () => {
    const agent_id = currentAgent.agent_id; // 从context或state管理获取
    if (agent_id) {
      try {
        const { code, data, message: msg } = await queryAgentConfig({ agent_id });
        console.log(data);
        if (code === 200) {
          // setState逻辑处理
          // 调用子组件方法，例如: roleComp.current.queryAgentRoleFn();
          dispatch(setCurrentAgent(data));
        } else {
          message.error(msg || t('common.queryFailed'));
        }
      } catch (error) {
        message.error(t('common.queryFailed'));
      }
    }
  };
  useEffect(() => {
    getData();
  }, [currentAgent.agent_id]);

  return (
    <div className="agentEngine h-full">
      <Tabs defaultActiveKey="role" tabBarStyle={{ padding: '0 16px', marginBottom: '0', backgroundColor: '#fff' }} onChange={setActiveName}>
        <TabPane tab={t('agentEngine.role.name')} key="role">
          <div className="tab-content-container" style={{ display: activeName === 'role' ? 'block' : 'none' }}>
            <CharacterScene />
          </div>
        </TabPane>
        <TabPane tab={t('agentEngine.activeGuidance.title')} key="activeGuidance">
          <div className="tab-content-container" style={{ display: activeName === 'activeGuidance' ? 'block' : 'none' }}>
            <ActiveGuidance />
          </div>
        </TabPane>
        <TabPane tab={t('agentEngine.promptWord.title')} key="promptWord">
          <div className="tab-content-container" style={{ display: activeName === 'promptWord' ? 'block' : 'none' }}>
            <PromptWord />
          </div>
        </TabPane>
        <TabPane tab={t('agentEngine.engineSet')} key="engineSet">
          <div className="tab-content-container" style={{ display: activeName === 'engineSet' ? 'block' : 'none' }}>
            <PromptConfig />
          </div>
        </TabPane>
        <TabPane tab={t('agentEngine.RAGSet')} key="RAGSet">
          <div className="tab-content-container" style={{ display: activeName === 'RAGSet' ? 'block' : 'none' }}>
            <RagConfig />
          </div>
        </TabPane>
        {/* <TabPane tab="对话设置" key="chatSet">
          <div style={{ display: activeName === 'chatSet' ? 'block' : 'none' }}>
            <ChatConfig />
          </div>
        </TabPane> */}
        <TabPane tab={t('agentEngine.voiceSet.name')} key="voiceSet">
          <div className="tab-content-container" style={{ display: activeName === 'voiceSet' ? 'block' : 'none' }}>
            <VoiceConfig />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AgentEngine;
