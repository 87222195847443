import React, { useState, useEffect, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Table,
  Button,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Tag,
  Popover,
  Checkbox,
  Divider,
} from 'antd';
import { getAccountList, queryQueue, addQueue, putQueue, deleteQueue } from '@/api/account';
import { produce } from 'immer';
import './accountGroup.scss';
import { useTranslation } from 'react-i18next';

const AccountGroupIndex: React.FC = () => {
  const { t } = useTranslation();
  const [editDialogFormVisible, setEditDialogFormVisible] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [queryForm] = Form.useForm();
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [query, setQuery] = useState<{ queue_name: string }>({ queue_name: '' });
  const [state, setState] = useState<{
    tableData: any[];
    currentPage: number;
    pageSize: number;
    totalPages: number;
    totalCount: number;
  }>({
    tableData: [],
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  });

  const [accountForm, setAccountForm] = useState<{
    queue_name: string;
    id: string;
    users: string[];
  }>({
    queue_name: '',
    id: '',
    users: [],
  });

  const [tags, setTags] = useState<{ name: string; value: string }[]>([]);
  const [checkList, setCheckList] = useState<string[]>([]);
  const [allCheckbox, setAllCheckbox] = useState<boolean>(false);
  const [agentList, setAgentList] = useState<any>([]);

  const onPageChange = (val: number, newSize: number) => {
    // console.log(newSize)
    // setState({ ...state, currentPage: val, pageSize: newSize });
    fetchGroupList(val, newSize);
  };

  const selectAll = (flag: boolean) => {
    setAllCheckbox(flag);
    if (flag) {
      setCheckList([...agentList.map((i: any) => i.user_id)]);
    } else {
      setCheckList([]);
    }
  };

  const handleEdit = async (row: any) => {
    setEditDialogFormVisible(true);
    if (row == null) {
      setIsEdit(false);
      setAccountForm({ queue_name: '', id: '', users: [] });
      form.setFieldsValue({
        queue_name: '',
        users: [],
      });
      setTags([]);
      setCheckList([]);
    } else {
      setIsEdit(true);
      const users = row.users.map((r: any) => r.user_id);
      setAccountForm({
        queue_name: row.queue_name,
        id: row.id,
        users,
      });
      form.setFieldsValue({
        queue_name: row.queue_name,
        users,
      });
      const newTags = row.users.map((op: any) => ({ name: op.name, value: op.user_id }));
      setTags(newTags);
      setCheckList(row.users.map((r: any) => r.user_id));
    }
  };

  const saveData = async () => {
    try {
      const values = await form.validateFields();
      const user_ids = [...checkList];
      setFormLoading(true);
      if (!isEdit) {
        await addQueue({ queue_name: values.queue_name, user_ids });
        message.success(t('common.addSuccess'));
        setFormLoading(false);
        setEditDialogFormVisible(false);
        setState({ ...state, currentPage: 1 });
        fetchGroupList(1);
      } else {
        await putQueue({ queue_name: values.queue_name, id: accountForm.id, user_ids });
        message.success(t('common.saveSuccess'));
        setFormLoading(false);
        setEditDialogFormVisible(false);
        fetchGroupList(1);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        message.error(error.response.data.message);
      } else {
        message.error(t('common.saveFailed'));
      }
      setFormLoading(false);
    }
  };

  const handleDel = async (id: string) => {
    Modal.confirm({
      title: t('common.confirmDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        await deleteQueue(id);
        message.success(t('common.deleteSuccess'));
        setState({ ...state, currentPage: 1 });
        fetchGroupList(1);
      },
      onCancel: () => {
        message.error(t('common.deleteFailed'));
      },
    });
  };

  const normalFetch = () => {
    setState({ ...state, currentPage: 1 });
    fetchGroupList(1);
  };

  useEffect(() => {
    fetchAccountList();
  }, []);

  // useEffect(() => {
  //   fetchGroupList();
  // }, [state.currentPage]);

  useEffect(() => {
    const newTags = checkList.map(val => ({ name: transName(val), value: val }));
    setTags(newTags);
  }, [checkList]);

  const agentData = useRef<any>([]);

  const fetchAccountList = async () => {
    const filters = { filters: [] };
    const newFilters = JSON.stringify(filters);
    const str = `?q=${newFilters}&page=1&results_per_page=1000`;
    try {
      const res: any = await getAccountList(str);
      setAgentList(res.objects || []);
      agentData.current = res.objects;
      fetchGroupList();
    } catch (err) {
      console.error(err);
    }
  };

  const transName = (id: string) => {
    let name = '';
    for (const agent of agentData.current) {
      if (agent.user_id === id) {
        name = agent.nickname;
      }
    }
    return name;
  };

  const handleTableData = (data: any[]) => {
    data.forEach((item: any) => {
      if (item.update_time) {
        item.update_time = item.update_time.replace('T', ' ');
      }
      if (item.update_user) {
        item.update_userName = transName(item.update_user);
      }
      if (item.users && item.users.length > 0) {
        const usersName = item.users.map((ops: any) => ops.nickname);
        item.users_name = usersName;
      }
    });
    return data;
  };

  const fetchGroupList = async (page?: number, pageSize?: number) => {
    const filters = { filters: [], order_by: [] } as any;
    filters.order_by.push({
      field: 'update_time',
      direction: 'desc',
    });
    if (query.queue_name) {
      filters.filters.push({
        name: 'queue_name',
        op: 'like',
        val: `%${query.queue_name}%`,
      });
    }
    const newFilters = JSON.stringify(filters);
    const str = `?q=${newFilters}&page=${page || state.currentPage}&results_per_page=${pageSize || state.pageSize}`;
    const res: any = await queryQueue(str);
    const formattedData = handleTableData(res.objects || []);
    setState({
      ...state,
      tableData: formattedData,
      currentPage: res.page || 1,
      totalCount: res.num_results || 0,
      pageSize: pageSize || state.pageSize,
    });
  };

  const closeTag = (o: any, index: number) => {
    const newCheckList = [...checkList];
    newCheckList.splice(index, 1);
    setCheckList(newCheckList);
  };

  return (
    <div className="account main-container account-group h-full overflow-y-scroll">
      <div className="title">
        <Form
          form={queryForm}
          layout="inline"
          initialValues={{ queue_name: query.queue_name }}
          style={{ marginBottom: 16 }}
        >
          <Form.Item label={t('account.grop.groupName')} name="queue_name">
            <Input
              placeholder={t('common.input')}
              onBlur={normalFetch}
              onPressEnter={normalFetch}
              onChange={e => setQuery({ ...query, queue_name: e.target.value })}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => handleEdit(null)}>
              {t('account.grop.addGroup')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="account-content">
        <Table size="small" scroll={{ y: 600 }} dataSource={state.tableData} bordered rowKey="id" pagination={false}>
          <Table.Column title={t('account.grop.groupName')} dataIndex="queue_name" />
          <Table.Column title={t('account.grop.groupCode')} dataIndex="queue_code" />
          <Table.Column title={t('account.lastUpdateTime')} dataIndex="update_time" />
          <Table.Column title={t('account.lastOperator')} dataIndex="update_userName" />
          <Table.Column
            title={t('account.grop.members')}
            render={(text, record: any) => (
              <span>{record.users_name ? record.users_name.join('/') : ''}</span>
            )}
          />
          <Table.Column
            title={t('common.operation')}
            render={(text, record: any) => (
              <>
                <Button type="link" onClick={() => handleEdit(record)}>
                  {t('common.edit')}
                </Button>
                {record.category !== 0 && (
                  <Button type="link" onClick={() => handleDel(record?.id)}>
                    {t('common.delete')}
                  </Button>
                )}
              </>
            )}
          />
        </Table>
        <Pagination
          style={{ marginTop: 16, textAlign: 'right' }}
          showSizeChanger
          showQuickJumper
          current={state.currentPage}
          total={state.totalCount}
          pageSize={state.pageSize}
          showTotal={totalCount => t('common.totalItems', { count: totalCount })}
          onChange={(page, pageSize) => onPageChange(page, pageSize)}
        />
      </div>
      <Modal
        open={editDialogFormVisible}
        title={isEdit ? t('account.grop.editGroup') : t('account.grop.addGroup')}
        onCancel={() => setEditDialogFormVisible(false)}
        footer={[
          <Button key="back" onClick={() => setEditDialogFormVisible(false)}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" type="primary" loading={formLoading} onClick={saveData}>
            {t('common.save')}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" initialValues={accountForm}>
          <Form.Item
            label={t('account.grop.groupName')}
            name="queue_name"
            rules={[{ required: true, message: t('account.grop.inputGroupName') }]}
          >
            <Input placeholder={t('account.grop.inputGroupName')} maxLength={10} />
          </Form.Item>
          <Form.Item label={t('account.grop.selectAgent')}>
            <div>
              {tags.map((tag, index) => (
                <Tag
                  key={tag.name}
                  className="group-tag"
                  closable
                  onClose={() => closeTag(tag, index)}
                >
                  {tag.name}
                </Tag>
              ))}
              <Popover
                trigger="click"
                content={
                  <div style={{ width: '500px' }}>
                    <Checkbox
                      checked={allCheckbox}
                      className="group-tag-choices"
                      onChange={e => selectAll(e.target.checked)}
                    >
                      {t('common.selectAll')}
                    </Checkbox>
                    <Divider style={{ margin: '12px 0' }} />
                    <Checkbox.Group
                      value={checkList}
                      onChange={list => setCheckList(list as string[])}
                    >
                      {agentList.map((item: any) => (
                        <Checkbox
                          style={{ width: '18%' }}
                          className="group-tag-choices"
                          key={item.user_id}
                          value={item.user_id}
                        >
                          {item.nickname}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </div>
                }
              >
                <Button className="button-add-tag">{t('account.grop.addAgent')}</Button>
              </Popover>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AccountGroupIndex;
