import { http } from '../service/http';

type Result = {
  code: number;
  description?: string;
  data: any;
  debug?: any;
  log?: any;
  agent_info?: any;
  message?: string;
  msg_time?: string;
  response_time?: string;
  start_time?: string;
  total_count?: number;
};

export const getAgentList = () => {
  return http.request<Result>('get', '/agent/all_template');
};

export const queryAgentList = (params: any) => {
  return http.request<Result>('post', '/agent/query_agent', {
    data: params,
  });
};

export const saveAgentTemplate = (params: object) => {
  return http.request<Result>('post', '/agent/save_agent', {
    data: params,
  });
};

export const copyAgentTemplate = (params: object) => {
  return http.request<Result>('post', '/agent/copy_agent', {
    data: params,
  });
};

export const queryAgentConfig = (params: object) => {
  return http.request<Result>('post', '/agent/query_agent_config', {
    data: params,
  });
};

export const queryAgentRole = (params: object) => {
  return http.request<Result>('post', '/agent/query_agent_role', {
    data: params,
  });
};
export const moveAgentRole = (params: object) => {
  return http.request<Result>('post', '/agent/move_agent_role', {
    data: params,
  });
};

export const updateAgentTemplate = (params: object) => {
  return http.request<Result>('post', '/agent/update_template', {
    data: params,
  });
};
export const deleteAgentTemplate = (params: object) => {
  return http.request<Result>('post', '/agent/delete_template', {
    data: params,
  });
};

export const savePrompt = (params: object) => {
  return http.request<Result>('post', '/agent/save_prompt', {
    data: params,
  });
};

export const saveEngine = (params: object) => {
  return http.request<Result>('post', '/agent/save_engine', {
    data: params,
  });
};

export const saveRagConfig = (params: object) => {
  return http.request<Result>('post', '/agent/save_rag_config', {
    data: params,
  });
};

export const saveChatConfig = (params: object) => {
  return http.request<Result>('post', '/agent/save_chat_config', {
    data: params,
  });
};

export const saveAgentRole = (params: object) => {
  return http.request<Result>('post', '/agent/save_agent_role', {
    data: params,
  });
};

export const bindAgentFloor = (params: object) => {
  return http.request<Result>('post', '/agent/bind_role_floor', {
    data: params,
  });
};

export const bindCateFloor = (params: object) => {
  return http.request<Result>('post', '/agent/bind_cate_floor', {
    data: params,
  });
};

export const deleteRole = (params: object) => {
  return http.request<Result>('post', '/agent/delete_role', {
    data: params,
  });
};

export const roleSort = (params: object) => {
  return http.request<Result>('post', '/agent/role_sort', {
    data: params,
  });
};

// 获取函数列表
export const queryFunctionList = (params: object) => {
  return http.request<Result>('get', '/agent/get_fission', { params });
};

export const queryAllAiEntity = (params: object) => {
  return http.request<Result>('post', '/agent/query_all_ai_entity', {
    data: params,
  });
};
export const queryVarListByAgent = (params: object) => {
  return http.request<Result>('get', '/agent/varlist', { params });
};
export const createFission = (params: object) => {
  return http.request<Result>('post', '/agent/create_fission', {
    data: params,
  });
};
export const putFission = (params: object) => {
  return http.request<Result>('put', '/agent/put_fission', { data: params });
};
export const getFission = (params: { id: string }) => {
  return http.request<Result>('get', '/agent/get_single_fission?id=' + params?.id);
};
export const deleteFission = (params: object) => {
  return http.request<Result>('delete', '/agent/delete_fission', {
    data: params,
  });
};
export const addVarListByAgent = (params: object) => {
  return http.request<Result>('post', '/agent/varlist', { data: params });
};
export const getAiEntity = (params: object) => {
  return http.request<Result>('post', '/agent/query_all_ai_entity', {
    data: params,
  });
};
export const saveEntity = (params: object) => {
  return http.request<Result>('post', '/agent/edit_ai_entity', {
    data: params,
  });
};
export const delEntity = (params: object) => {
  return http.request<Result>('post', '/agent/delete_ai_entity', {
    data: params,
  });
};
export const getLogList = (params: object) => {
  return http.request<Result>('post', '/agent/query_session_log', {
    data: params,
  });
};
export const getLogDetail = (params: object) => {
  return http.request<Result>('post', '/agent/query_all_session_detail', {
    data: params,
  });
};
export const queryLlm = (params: object) => {
  return http.request<Result>('post', '/agent/query_llm', {
    data: params,
  });
};
export const queryWithHistory = (params: object) => {
  return http.request<Result>('post', '/agent/query_with_history', {
    data: params,
  });
};
export const saveFlow = (params: object) => {
  return http.request<Result>('post', '/agent/save_flow', {
    data: params,
  });
};
export const upFlow = (params: object) => {
  return http.request<Result>('post', '/agent/up_flow', {
    data: params,
  });
};
export const updateRoleStatus = (params: object) => {
  return http.request<Result>('post', '/agent/update_role_status', {
    data: params,
  });
};
export const saveGuidance = (params: object) => {
  return http.request<Result>('post', '/agent/save_guidance', {
    data: params,
  });
};
export const queryGuidanceList = (params: object) => {
  return http.request<Result>('post', '/agent/query_guidance_list', {
    data: params,
  });
};
export const guidanceSort = (params: object) => {
  return http.request<Result>('post', '/agent/guidance_sort', {
    data: params,
  });
};
// 刷新baserow的token
export const refreshBaserowToken = (params: object) => {
  return http.request<Result>('post', '/agent/refresh_baserow_token', {
    data: params,
  });
};
export const getBaserowInfo = (params: object) => {
  return http.request<Result>('post', '/agent/get_baserow_info', {
    data: params,
  });
};
export const initSession = (params: object) => {
  return http.request<Result>('post', '/agent/init_session', {
    data: params,
  });
};
export const closeSession = (params: object) => {
  return http.request<Result>('post', '/agent/close_session', {
    data: params,
  });
};
export const getOssToken = (params: object) => {
  return http.request<Result>('post', '/agent/get_oss_token', {
    data: params,
  });
};
export const saveVoiceConfig = (params: object) => {
  return http.request<Result>('post', '/agent/save_small_talk_config', {
    data: params,
  });
};
export const saveAskHumanConfig = (params: object) => {
  return http.request<Result>('post', '/agent/save_biz_ask_human_config', {
    data: params,
  });
};
export const queryWorkbench = (params: object) => {
  return http.request<Result>('post', '/agent/query_workbench', {
    data: params,
  });
};
export const updateWorkbenchStatus = (params: object) => {
  return http.request<Result>('post', '/agent/update_workbench_status', {
    data: params,
  });
};
export const addBlack = (params: object) => {
  return http.request<Result>('post', '/agent/black_list/add', {
    data: params,
  });
};

export const queryBlackList = (params: object) => {
  return http.request<Result>('post', '/agent/black_list/query_list', {
    data: params,
  });
};

export const unblockBlack = (params: object) => {
  return http.request<Result>('post', '/agent/black_list/unblock', {
    data: params,
  });
};

export const saveFanRetention = (params: object) => {
  return http.request<Result>('post', '/agent/save_fan_retention_scripts', {
    data: params,
  });
};

export const queryFanList = (params: object) => {
  return http.request<Result>('post', '/agent/list_fan_retention_scripts', {
    data: params,
  });
};

export const sortFanList = (params: object) => {
  return http.request<Result>('post', '/agent/sort_fan_retention_scripts', {
    data: params,
  });
};

export const deleteFanRetention = (params: object) => {
  return http.request<Result>('post', '/agent/delete_fan_retention_scripts', {
    data: params,
  });
};

export const saveFrsConfig = (params: object) => {
  return http.request<Result>('post', '/agent/save_frs_config', {
    data: params,
  });
};
export const copyAgentRole = (params: object) => {
  return http.request<Result>('post', '/agent/copy_agent_role', {
    data: params,
  });
};
export const querySessionDetailAround = (params: object) => {
  return http.request<Result>('post', '/agent/query_session_detail_around', {
    data: params,
  });
};
export const queryWorkbenchDetailAround = (params: object) => {
  return http.request<Result>('post', '/agent/query_workbench_detail_around', {
    data: params,
  });
};
