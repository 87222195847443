import React, { MutableRefObject, useState } from 'react';
import { Input } from 'antd';
import UploadImage from './UploadImage'; // Assuming UploadImage is a separate component
import './scss/index.scss';
import { generateUUID } from '@/utils/common';
import { cloneDeep } from 'lodash';
import { produce } from 'immer';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';

const { TextArea } = Input;

interface Props {
  sid: MutableRefObject<string>;
  setSid: (sid: string) => void;
  messages: any[];
  setMessages: (data: any) => void;
  addMessage2List: (message: any) => void;
  addImage2List: (url: string) => void;
}

const SendMsgBox: React.FC<Props> = ({
  sid,
  setSid,
  messages,
  setMessages,
  addMessage2List,
  addImage2List,
}) => {
  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const [msgCon, setMsgCon] = useState<string>('');

  const handleTextareaKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      console.log(msgCon);

      sendMsg();
    }
  };

  // 发送图片消息
  const sendImage = (data: any) => {
    const imgMsg = {
      type: 'user',
      text: '',
      content_type: 'image',
      origin_message: data.url,
    };
    addMessage2List(imgMsg);
    sendMsg(imgMsg);
    addImage2List(data.url);
  };

  // 发送问答消息
  const sendMsg = async (msg?: any) => {
    if (!msgCon && !msg) {
      // 没有发送内容 || 正在发请求 retrun掉
      return;
    }
    if (!sid.current) {
      // 发送消息的时候没有会话id，创建一个，后续发消息不再生成
      setSid(generateUUID());
    }
    const arr = cloneDeep([...messages]);
    if (!msg) {
      addMessage2List({
        type: 'user',
        text: msgCon,
      });
      arr.push({
        type: 'user',
        text: msgCon,
      });
    } else {
      arr.push(msg);
    }
    const newArr: any[] = [];
    arr.forEach((item: any) => {
      let temObj = { type: item.type, text: item.text };
      if (['image'].includes(item.content_type)) {
        temObj = {
          ...temObj,
          ...{
            content_type: item.content_type,
            origin_message: item.origin_message,
          },
        };
      }
      newArr.push(temObj);
    });
    setMsgCon('');
    const params = {
      sid: sid.current,
      agent_id: currentAgent.agent_id,
      messages: newArr,
      user_args: {},
      stream: true, // 流式输出
    };
    getStream(params); // 流式请求
  };

  async function getStream(data: any) {
    const token = window.sessionStorage.getItem('token');
    try {
      const qid = generateUUID();
      setMessages((prevMessages: any[]) =>
        produce(prevMessages, draft => {
          draft.splice(draft.length, 0, {
            type: 'assistant',
            text: '正在输入中...',
            qid,
            debug: [],
            log: {},
            msg_time: '',
          });
        })
      );

      const response = await fetch('/agent/query_with_history', {
        method: 'POST',
        body: JSON.stringify(data),
        // @ts-expect-error known
        headers: new Headers({ 'Content-Type': 'application/json', Token: token }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const reader = response?.body?.getReader();
      const textDecoder = new TextDecoder();
      let result = true;
      let str = '';
      let preChunk = '';
      let isEndFlag = true;
      while (result) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { done, value } = (await reader?.read()) || {};
        if (done) {
          console.log('Stream ended');
          result = false;
          break;
        }
        const chunkText = textDecoder.decode(value);
        const arr = chunkText.split('\n');
        arr.forEach(item => {
          if (item) {
            // 最后一个字符串不为}，则默认切片不完整，保存与下次拼接使用（这种方法不严谨，但已经能解决大部分场景的问题）
            if (item[item.length - 1] === '}') {
              const chunkCon = preChunk ? preChunk + item : item;
              const obj = JSON.parse(chunkCon);
              if (obj.code === 4013) {
                // 消息排队处理中（多条消息排队的情况，忽略掉，不在页面显示内容）
                setMessages((prevMessages: any[]) =>
                  produce(prevMessages, draft => {
                    draft.forEach((item: { qid: any }, index: any) => {
                      if (item.qid == qid) {
                        draft.splice(index, 1);
                      }
                    });
                  })
                );
                return false;
              }
              if (!obj.is_end) {
                isEndFlag = false;
                if (!obj.code) {
                  // 若后端返回有code,就是异常情况
                  str += obj.reply || '';
                } else {
                  str += obj.message;
                }
              } else {
                // 更新上一条手动发送消息的时间
                if (isEndFlag && obj.reply) {
                  // 上来就只有一条数据is_end为true的情况都是异常，需要把消息插进去。
                  str += obj.reply || '';
                }
                handleCutomerMsgTime(qid, obj);
                if (obj.answer_res_list && obj.answer_res_list.length > 0) {
                  // 有配置文件列表

                  addMessage2List({
                    showFileList: true,
                    type: 'assistant',
                    msg_time: obj.response_time,
                    text: '文件消息',
                    debug: obj.debug,
                    log: obj.log,
                    code: obj.code,
                    answer_res_list: obj.answer_res_list,
                  });
                }
              }
              obj.text = str;
              handleCutomerMsgTime(qid, obj);
              updateMessageInfo(qid, obj);
              preChunk = '';
            } else {
              preChunk += item;
            }
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
  const handleCutomerMsgTime = (qid: any, obj: any) => {
    if (!obj.msg_time) {
      return false;
    }
    setMessages((prevMessages: any[]) =>
      produce(prevMessages, draft => {
        draft.forEach((item: { qid: any; msg_time: any }, index: any) => {
          if (item.qid == qid) {
            draft[index - 1].msg_time = obj.msg_time || '';
          }
        });
      })
    );
  };
  const updateMessageInfo = (qid: any, obj: any) => {
    setMessages((prevMessages: any[]) =>
      produce(prevMessages, draft => {
        draft.forEach(
          (item: { qid: any; msg_time: any; text: any; debug: any; log: any; code: any }) => {
            if (item.qid === qid) {
              item.msg_time = obj.response_time;
              item.text = obj.text || '';
              item.debug = obj.debug;
              item.log = obj.log;
              item.code = obj.code;
            }
          }
        );
      })
    );
  };
  return (
    <div className="send-chat-box-wrap">
      <div className="send-chat-box">
        <div className="operate-top">
          <UploadImage sendImage={sendImage} className="image-icon" />
          <span className="send-btn" onClick={() => sendMsg()}>
            <span className="iconfont fasong" />
          </span>
        </div>
        <TextArea
          className="send-msg-textarea"
          value={msgCon}
          onChange={e => setMsgCon(e.target.value)}
          autoSize={{ minRows: 2, maxRows: 2 }}
          placeholder="请输入内容"
          onKeyDown={handleTextareaKeydown}
        />
      </div>
    </div>
  );
};

export default SendMsgBox;
