import React from 'react';
import MonacoEditor from 'react-monaco-editor';
interface EditorProps {
  value: string;
  editorHeight: string;
  onChange: (value: string) => void;
}
const Editor = ({ value, onChange, editorHeight }: EditorProps) => {
  const editorDidMount = (editor: any) => {
    // Editor挂载完成后的操作，例如自动聚焦、设置语言等
    editor.focus();
  };
  const options = {
    selectOnLineNumbers: true,
    roundedSelection: false,
    readOnly: false,
    cursorStyle: 'line',
    automaticLayout: true, // 响应式布局
  };
  return (
    <MonacoEditor
      height={editorHeight}
      language="python" // 根据需要更改语言
      theme="vs-dark" // 主题
      value={value}
      // @ts-expect-error 先忽略
      options={options}
      onChange={onChange}
      editorDidMount={editorDidMount}
    />
  );
};
export default Editor;
