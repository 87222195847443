import React, { useRef } from 'react';
import { Card, Tooltip, Modal, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { deleteEntry, saveEntry } from '@/api/km';
import AddOrEditEntry from './AddOrEditEntry';
interface Item {
  id?: string;
  term: string;
  aliases: Array<string>;
  definition?: string;
  update_datetime?: string;
}
interface Props {
  item: Item;
  refreshList: () => void;
}

const { Meta } = Card;
const EntryCard: React.FC<Props> = ({ item, refreshList }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const entryRef = useRef<{ openDialog: (value: any) => void; closeDialog: () => void }>(null);

  /**
   * @description: 删除
   * @return {*}
   */
  const handleDeleteLLMAgent = async () => {
    Modal.confirm({
      title: t('common.confirmDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        try {
          const { code } = await deleteEntry({
            id: item.id,
          });
          if (code === 200) {
            message.success(t('common.deleteSuccess'));
            refreshList();
          } else {
              message.error(t('common.deleteFailed'));
          }
        } catch (error) {
          message.error(t('common.deleteFailed'));
        }
      },
    });
  };

  /**
   * @description: 编辑弹窗
   * @return {*}
   */
  const handleEdit = () => {
    if (entryRef.current) {
      entryRef.current.openDialog(item);
    }
  };
  const handleSave = (emitData: any) => {
    saveEntry(emitData).then((res: any) => {
      if (res.code === 200) {
        refreshList();
        message.success(t('common.saveSuccess'));
        if (entryRef.current) {
          entryRef.current.closeDialog();
        }
      } else {
        message.error(res.message || t('common.saveFailed'));
      }
    });
  };

  return (
    <div className="entry-card inline-block mr-4 mb-4 cursor-pointer relative">
      <div className="card-top-line absolute top-0 z-10 w-full"></div>
      <Card>
        <Meta
          title={
            <div className="card-header flex justify-between items-center">
              <Tooltip title={item.term}>
                <span className="entry-name font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
                  {item.term}
                </span>
              </Tooltip>
              <div className="entry-operate">
                <span
                  className="iconfont bianji"
                  onClick={e => {
                    e.stopPropagation();
                    handleEdit();
                  }}
                />
                <span
                  className="iconfont shanchu"
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteLLMAgent();
                  }}
                />
              </div>
            </div>
          }
        ></Meta>
        <Tooltip title={item.aliases ? item.aliases.join(',') : ''}>
          <div className="card-tags h-6 mt-3 mb-1.5 overflow-hidden">
            {item.aliases &&
              item.aliases.map((i: string, index: number) => {
                return (
                  <span
                    style={{ backgroundColor: '#F5F7FA', color: '#909399', lineHeight: '24px'}}
                    className="inline-block px-1 rounded-sm text-xs mr-1.5 h-6"
                    key={index.toString() + i}
                  >{`# ${i}`}</span>
                );
              })}
          </div>
        </Tooltip>
        <Tooltip title={item.definition}>
          <p
            style={{ marginBottom: 8 }}
            className="entry-content min-h-6 leading-6 h-6"
          >
            {item.definition}
          </p>
        </Tooltip>
        <p className="update-time min-h-6 leading-6 h-6 whitespace-nowrap overflow-hidden text-ellipsis">
          {item.update_datetime && item.update_datetime.replace('T', ' ')}
        </p>
      </Card>
      <AddOrEditEntry ref={entryRef} onSave={handleSave} />
    </div>
  );
};
export default EntryCard;
