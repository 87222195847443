import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import { t } from 'i18next';
import { Form, Input, Table, Select, Button, Drawer, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import AddOrEditRule from './AddOrEditRule';
import { generateUUID } from '@/utils/common';
import { conditionRule } from '@/constant/options';
import Panel from '@/components/Panel';
import Sortable, { SortableEvent } from 'sortablejs';
import { cloneDeep } from 'lodash';
const { Option } = Select;

interface ConditionFormProps {
  form: Record<string, any>;
  id: string;
}

interface Rule {
  id?: string;
  name?: string;
  condition?: string;
}

const ConditionForm = forwardRef<any, ConditionFormProps>(({ form, id }, ref) => {
  const [formRef] = useForm();
  const [dataSource, setDataSource] = useState<Rule[]>([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [auxiliary, setAuxiliary] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const ruleRef = useRef<any>(null);
  const sort: any = useRef(null);
  const normalRule = {
    name: '',
    rule: {
      cond_group: [
        {
          condition: 'B',
          value: '',
          var_name: 'action',
          json_parser: '2',
          type: 'llm',
        },
      ],
      cond_type: 'all',
    },
  };
  const [currentRule, setCurrentRule] = useState<any>(normalRule);

  const filterCondition = (row: any) => {
    const cond_group = row.rule ? row.rule.cond_group || [] : [];
    let str = '';
    cond_group.forEach((conItem: any) => {
      let ruleLabel = '';
      if (conItem.type == 'variable') {
        // 类型选择变量 展示： 变量名称+运算符+值
        const rule = conditionRule.find(c => c.value === conItem.condition);
        if (rule) ruleLabel = rule.label;
        str += `${conItem.var_name}${ruleLabel}${conItem.value}，`;
      } else if (conItem.type == 'llm') {
        // 类型选择LLM结果
        if (conItem.json_parser == '1') {
          // 选择全部信息 展示： LLM结果+运算符+值
          str += `{t('flowForm.llmResult')}${ruleLabel}${conItem.value}，`;
        } else {
          // 选择指定信息 展示：输入的变量名称+运算符+值
          str += `${conItem.var_name}${ruleLabel}${conItem.value}，`;
        }
      }
    });
    return (str = str.substr(0, str.length - 1));
  };
  const handleEdit = (record: any) => {
    setDialogVisible(true);
    setIsEdit(true);
    setEditId(record.id);
    setCurrentRule(record);
  };
  const handleAdd = () => {
    setDialogVisible(true);
    setIsEdit(false);
    setCurrentRule(normalRule);
    ruleRef.current?.resetFields();
  };
  const handleDelete = (id: string) => {
    const newDataSource = dataSource.filter((item: any) => item.id !== id);
    setDataSource(newDataSource);
  };
  const handleSave = async () => {
    try {
      const values = await ruleRef.current?.validate();
      const newName = values.name;
      // 检查是否有重复的名称
      const isDuplicate = dataSource.some(rule => rule.name === newName && rule.id !== editId);
      if (isDuplicate) {
        message.error(t('flowForm.chainCheck.duplicateStrategyName'));
        return;
      }
      if (isEdit && editId) {
        setDataSource((prev: any) =>
          prev.map((item: any) => (item.id === editId ? { ...item, ...values } : item))
        );
      } else {
        // 没设置过，说明是新增，创建条件的id，用于后面编辑数据更新
        setDataSource((prev: any) => [...prev, { ...values, id: `condition_${generateUUID()}` }]);
      }
      setDialogVisible(false);
      message.success(t('common.saveSuccess'));
    } catch (error) {
      console.error(error);
    }
  };
  const initTableRowDrag = useCallback(() => {
    sort.current?.destroy();
    const tbody: HTMLElement | null = document.querySelector(
      '.condition-draggable .ant-table-content tbody'
    );
    if (tbody) {
      sort.current = new Sortable(tbody, {
        draggable: '.condition-draggable .ant-table-row',
        onEnd({ newIndex, oldIndex }) {
          if (newIndex === oldIndex) {
            return;
          }
          if (newIndex === undefined || oldIndex === undefined) {
            return;
          }
          const newList = cloneDeep(dataSource);
          const [removedItem] = newList.splice(oldIndex, 1);
          newList.splice(newIndex, 0, removedItem);
          setDataSource(newList);
        },
      });
    }
  }, [dataSource]);
  useEffect(() => {
    formRef.setFieldsValue({ name: form.name || t('flowSideBar.conditionNode') });
    setDataSource(form?.value || []);
  }, [form]);
  useEffect(() => {
    initTableRowDrag();
  }, [initTableRowDrag, dataSource]);
  const validate = async () => {
    try {
      const values = await formRef.validateFields();
      return { value: dataSource, ...values };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  useImperativeHandle(ref, () => ({
    validate,
  }));
  // 自定义头部组件
  const CustomDrawerHeader = ({ isEdit, onSave, onCancel }: any) => {
    return (
      <div className="flex justify-between items-center">
        <div className="title-top">
          <span>{isEdit ? t('flowForm.editCondition') : t('flowForm.addCondition')}</span>
          <span className="iconfont fuzhuxinxi condition-icon" onClick={() => setAuxiliary(true)}>
            {t('common.auxiliaryInfo')}
          </span>
        </div>
        <div>
          <Button style={{ marginRight: 12 }} onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button type="primary" onClick={onSave}>
            {t('common.save')}
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Form
      form={formRef}
      layout="vertical"
      className="px-5 pt-5 pb-1 bg-white rounded"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ name: t('flowSideBar.conditionNode') }}
    >
      <Form.Item label={t('flowSideBar.conditionNode')} name="name" rules={[{ required: true, message: t('agent.nameRequired') }]}>
        <Input maxLength={50} placeholder={t('common.input')} />
      </Form.Item>
      <div className="pb-5">
        {t('flowForm.conditionRule')}{' '}
        <span className="iconfont jiahao" onClick={handleAdd}>
          <span>{t('flowForm.addConditionRule')}</span>
        </span>
      </div>
      <Table
        className="condition-draggable"
        dataSource={dataSource}
        bordered
        rowKey="id"
        pagination={false}
      >
        <Table.Column title={t('flowForm .sort')} dataIndex="sort" render={() => <span>::</span>} />
        <Table.Column title={t('flowForm.ruleName')} dataIndex="name" />
        <Table.Column
          title={t('flowForm.chainCheck.condition')}
          dataIndex="condition"
          render={(_, row: any) => {
            return <span>{filterCondition(row)}</span>;
          }}
        />
        <Table.Column
          title="操作"
          render={(text, record: any) => (
            <div>
              <Button type="link" onClick={() => handleEdit(record)}>
                {t('common.edit')}
              </Button>
              <Button type="link" danger onClick={() => handleDelete(record.id)}>
                {t('common.delete')}
              </Button>
            </div>
          )}
        />
      </Table>
      <p className="tip-p">{t('flowForm.chainCheck.tip')}</p>
      <Drawer
        title={
          <CustomDrawerHeader
            isEdit={isEdit}
            onSave={handleSave}
            onCancel={() => setDialogVisible(false)}
          />
        }
        open={dialogVisible}
        destroyOnClose
        className="condition-drawer custom-drawer"
        width={700}
        onClose={() => setDialogVisible(false)}
      >
        <AddOrEditRule ref={ruleRef} form={currentRule} id={id} />
        {auxiliary && <Panel className="reply-panel" onClosePanel={() => setAuxiliary(false)} />}
      </Drawer>
    </Form>
  );
});
ConditionForm.displayName = 'ConditionForm';
export default ConditionForm;
