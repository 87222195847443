import { t } from 'i18next';
// 实体类型选项
export const entityTypeOptions = [
  { value: 1, label: t('constant.options.saveEntity') },
  { value: 2, label: t('constant.options.directAssignment') },
  { value: 3, label: t('constant.options.incrementVariable') },
];

export const CateEnum = {
  DIRECT_ANSWER: '1',
  CATE: '2',
  FIXED_SPEECH: '3',
  POLISH: '4',
};

// 角色类型 2.0目前只有回答和分类
export const roleTypeOptions = [
  {
    label: t('constant.options.answer'),
    value: CateEnum.DIRECT_ANSWER,
  },
  {
    label: t('constant.options.category'),
    value: CateEnum.CATE,
  },
  {
    label: t('constant.options.fixedSpeech'),
    value: CateEnum.FIXED_SPEECH,
  },
  {
    label: t('constant.options.polish'),
    value: CateEnum.POLISH,
  },
];

// 标准策略表单的类型
export const cateTypes = [
  {
    type: 'answer',
    options: [
      {
        label: t('constant.options.llmAnswer'),
        value: '1',
      },
      {
        label: t('constant.options.fixedSpeech'),
        value: '3',
      },
      {
        label: t('constant.options.polish'),
        value: '4',
      },
      {
        label: t('constant.options.knowledgeBaseAnswer'),
        value: '8',
      },
      {
        label: t('constant.options.webSearchAnswer'),
        value: '11',
      },
    ],
  },
  {
    type: 'action',
    options: [
      {
        label: t('constant.options.handleService'),
        value: '5',
      },
      {
        label: t('constant.options.keyboardService'),
        value: '6',
      },
      {
        label: t('constant.options.hangupService'),
        value: '7',
      },
      {
        label: t('constant.options.askHuman'),
        value: '12',
      },
    ],
  },
];

export const cateForFanTypes = [
  {
    type: 'answer',
    options: [
      {
        label: t('constant.options.llmAnswer'),
        value: '1',
      },
      {
        label: t('constant.options.fixedSpeech'),
        value: '3',
      },
      {
        label: t('constant.options.polishReply'),
        value: '4',
      },
    ],
  },
  {
    type: 'action',
    options: [
      {
        label: t('constant.options.handleService'),
        value: '5',
      },
      {
        label: t('constant.options.keyboardService'),
        value: '6',
      },
      {
        label: t('constant.options.hangupService'),
        value: '7',
      },
      {
        label: t('constant.options.askHuman'),
        value: '12',
      },
    ],
  },
];

// 条件判断
export const conditionRule = [
  {
    label: t('constant.options.empty'),
    value: 'A',
  },
  {
    label: t('constant.options.notEmpty'),
    value: 'B',
  },
  {
    label: t('constant.options.equal'),
    value: 'C',
  },
  {
    label: t('constant.options.notEqual'),
    value: 'D',
  },
  { label: t('constant.options.include'), value: 'E' },
  { label: t('constant.options.notInclude'), value: 'F' },
  { label: t('constant.options.greaterThan'), value: 'GT' },
  { label: t('constant.options.lessThan'), value: 'LT' },
  { label: t('constant.options.lessThanOrEqual'), value: 'LTE' },
  { label: t('constant.options.greaterThanOrEqual'), value: 'GTE' },
  { label: t('constant.options.regex'), value: 'REGX' },
];
