import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, Input, message, Modal, Pagination, Select, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { deleteZendeskSdkConfig, queryZendeskSdkConfig, saveZendeskSdkConfig } from '@/api/account';
import { queryAgentList } from "@/api/agent";
import { useNavigate } from "react-router-dom";
import WebsiteEdit from './edit';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
interface ZendeskConfigFormFields {
  sdk_name: string;
  sdk_description: string;
}

const Website: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [queryForm] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [currentObj, setCurrentObj] = useState({ uid: '' });
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [showWebsiteEdit, setShowWebsiteEdit] = useState(false);
  const formRef = useRef<any>();
  const nav = useNavigate();

  useEffect(() => {
    fetchZendeskSdkConfigList();
  }, []);

  const onPageChange = (val: number, newSize: number) => {
    setCurrentPage(val);
    setPageSize(newSize);
    fetchZendeskSdkConfigList(val, newSize);
  };

  const handleTableData = (data: any) => {
    return data;
  };

  const fetchZendeskSdkConfigList = async (page?: number, pageSize?: number) => {
    await queryZendeskSdkConfig(page, pageSize).then((res: any) => {
      setTableData(handleTableData(res.data.result || []));
      setTotalCount(res.total_count || 0);
    });
  };

  const onSearch = () => {
    setCurrentPage(1);
    fetchZendeskSdkConfigList();
  };

  const onAddOrEdit = (record: any) => {
    if (record) {
      setShowWebsiteEdit(true);
      setCurrentObj(record);
    } else {
      setEditDialogVisible(true);
      setCurrentObj({ uid: '' });
      form.resetFields();
      setIsEdit(false);
    }
  };

  const onDelete = async (uid: string) => {
    Modal.confirm({
      title: t('common.deleteConfirm'),
      content: t('common.deleteConfirmContent'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        await deleteZendeskSdkConfig(uid);
        message.success(t('common.deleteSuccess'));
        fetchZendeskSdkConfigList();
      },
    });
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const actionFun = saveZendeskSdkConfig;
      const actionForm = {
        sdk_name: values.sdk_name,
        sdk_description: values.sdk_description,
      } as any;
      if (isEdit) {
        actionForm.uid = currentObj.uid;
      }
      actionFun(actionForm)
        .then(() => {
          message.success(t('common.saveSuccess'));
          setEditDialogVisible(false);
          setCurrentPage(1);
          fetchZendeskSdkConfigList();
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            message.error(error.response.data.message);
          } else {
            message.error(t('common.saveFailed'));
          }
        });
    } catch (errorInfo) {
      console.log('验证失败:', errorInfo);
    }
  };

  const columns = [
    { title: t('account.name'), dataIndex: 'sdk_name', key: 'sdk_name' },
    { title: t('common.desc'), dataIndex: 'sdk_description', key: 'sdk_description' },
    { title: t('account.lastUpdateTime'), dataIndex: 'update_time', key: 'update_time' },
    {
      title: t('common.operation'),
      key: 'action',
      render: (_text: any, record: any) => (
        <>
          <Button type="link" size="small" onClick={() => onAddOrEdit(record)}>
            {t('common.edit')}
          </Button>
          <Button type="link" danger size="small" onClick={() => onDelete(record.uid)}>
            {t('common.delete')}
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="p-4 h-full overflow-y-scroll bg-white rounded">
      {!showWebsiteEdit ? (
        <>
          <Form form={queryForm} layout="inline" onFinish={onSearch} style={{ marginBottom: 16 }}>
            <Form.Item>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => onAddOrEdit(null)}>
                {t('account.addWebsite')}
              </Button>
            </Form.Item>
          </Form>
          <Table bordered size="small" scroll={{ y: 600 }} columns={columns} dataSource={tableData} pagination={false} rowKey="id" />
          <Pagination
            style={{ marginTop: 16, textAlign: 'right' }}
            showSizeChanger
            showQuickJumper
            total={totalCount}
            current={currentPage}
            pageSize={pageSize}
            showTotal={totalCount => t('knowledge.totalItems', { count: totalCount })}
            onChange={(page, pageSize) => onPageChange(page, pageSize)}
          />
          <Modal
            title={isEdit ? t('account.editZendeskConfig') : t('account.addZendeskConfig')}
            open={editDialogVisible}
            onCancel={() => setEditDialogVisible(false)}
            footer={[
              <Button key="back" onClick={() => setEditDialogVisible(false)}>
                {t('common.cancel')}
              </Button>,
              <Button key="submit" type="primary" onClick={onSave}>
                {t('common.save')}
              </Button>,
            ]}
          >
            <Form form={form} ref={formRef} layout="vertical">
              <Form.Item
                name="sdk_name"
                label={t('common.name')}
                rules={[{ required: true, message: t('common.inputPrompt', { title: t('common.name') }) }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="sdk_description"
                label={t('common.desc')}
              >
                <Input.TextArea />
              </Form.Item>
            </Form>
          </Modal>
        </>
      ) : (
        <WebsiteEdit 
          record={currentObj} 
          onBack={() => {setShowWebsiteEdit(false)}} 
          onSave={(values) => {
            saveZendeskSdkConfig(values).then(() => {
              message.success(t('common.saveSuccess'));
              setShowWebsiteEdit(false);
              fetchZendeskSdkConfigList()
            }).catch((error) => {
              message.error(t('common.saveFailed') + ': ' + error.message);
            });
          }}
        />
      )}
    </div>
  );
};

export default Website;
