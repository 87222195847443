import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Drawer, Button, Form, Input, Switch, Radio, Select, Modal, message, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { generateUUID } from '@/utils/common';
import Panel from '@/components/Panel';
import { saveAgentRole } from '@/api/agent';
import { selectCurrentAgent, selectModelEnum } from '@/store/modules/agentTemplate';
import { useAppSelector } from '@/store/hooks';

// 定义类型
interface SForm {
  name: string;
  desc: string;
  input: string;
  polish_prompt: string;
  llm_prompt: string;
  rule_cate: string;
  tool_llm: string;
  direct_answer: string;
  net_search_prompt: string;
  active_guidance: boolean;
  status: number;
  uid: string;
}

// 定义Props类型
interface Props {
  isSenesFlow: boolean;
  pid: any;
  fetchList: (id: string) => void;
  goToFlow: (data: any) => void;
}
interface ChildProps {
  isSenesFlow: boolean;
  pid: any;
  fetchList: (id: string) => void;
  goToFlow: (data: any) => void;
}
// 定义暴露给父组件的方法类型
interface ChildMethods {
  openFlowDialog: (value?: any, type?: string) => void;
}

const FlowComponent = forwardRef<ChildMethods, ChildProps>((props, ref) => {
  const { t } = useTranslation();

  const { isSenesFlow, pid, fetchList, goToFlow } = props;
  const currentAgent = useAppSelector(selectCurrentAgent);
  const ModelEnum = selectModelEnum();
  // 状态管理
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [bigType, setBigType] = useState<string>('');
  const [propsType, setPropsType] = useState<string>('');
  const [auxiliary, setAuxiliary] = useState<boolean>(false);
  const [bigAuxiliary, setBigAuxiliary] = useState<boolean>(false);

  const [form] = Form.useForm();

  const initialSForm: SForm = {
    name: '',
    desc: '',
    input: '',
    polish_prompt: '',
    llm_prompt: '',
    rule_cate: '3',
    tool_llm: '',
    direct_answer: '',
    net_search_prompt: t('agentEngine.role.netSearchPrompt'),
    active_guidance: false,
    status: 0,
    uid: '',
  };
  const [sForm, setSForm] = useState<any>(initialSForm);

  const handleSaveAnswerModel = async () => {
    try {
      await form.validateFields();
      const obj = {
        uid: sForm.uid || generateUUID(),
        pid: propsType ? pid : '',
        role_type: '2',
        cate: '10',
        agent_id: currentAgent.currentAgent.agent_id,
      };
      const relObj = { ...sForm, ...obj };
      const res = await saveAgentRole(relObj);
      if (res.code === 200) {
        if (isEdit) {
          setDrawerVisible(false);
          fetchList(propsType ? pid : '');
          return;
        }
        Modal.confirm({
          title: t('common.prompt'),
          okText: t('common.confirm'),
          cancelText: t('common.cancel'),
          content: t('agentEngine.role.confirmEdit'),
          onOk() {
            setDrawerVisible(false);
            goToFlow({
              info: relObj,
              type: propsType ? 'scenes' : '',
            });
            fetchList(propsType ? pid : '');
          },
          onCancel() {
            setDrawerVisible(false);
            fetchList(propsType ? pid : '');
          },
        });
      } else {
        message.error(res.message || t('common.addFailed'));
      }
    } catch (error) {
      console.error('Validation Failed:', error);
    }
  };

  const handleAuxiliary = (type: string) => {
    if (type) {
      setBigAuxiliary(true);
      setAuxiliary(false);
    } else {
      setAuxiliary(true);
      setBigAuxiliary(false);
    }
  };

  const openInputDialog = (type: string) => {
    setBigType(type);
    setDialogVisible(true);
  };

  const openFlowDialog = (data?: any, type?: string) => {
    setDrawerVisible(true);
    setPropsType(type || '');
    if (data) {
      setIsEdit(true);
      setSForm({ ...sForm, ...data });
      form.setFieldsValue({ ...sForm, ...data });
    } else {
      setIsEdit(false);
      setSForm(initialSForm);
      form.setFieldsValue(initialSForm);
    }
  };

  const filtersTitle = (type: string) => {
    const typeMap: { [key: string]: string } = {
      net_search_prompt: t('agentEngine.role.netSearch'),
      direct_answer: t('agentEngine.role.flowContent'),
      llm_prompt: t('agentEngine.role.answerPrompt'),
      polish_prompt: t('agentEngine.role.polishPrompt'),
    };
    return typeMap[type] || '';
  };
  // 通过`useImperativeHandle`暴露特定的方法给父组件
  useImperativeHandle(ref, () => ({
    // 将`openFlowDialog`方法暴露给父组件
    openFlowDialog,
  }));
  return (
    <div>
      <Drawer
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        closable={false}
        width={800}
        className="custom-drawer"
        title={`${isEdit ? t('agent.addAgentFlowRole') : t('agent.editAgentFlowRole')}${propsType ? t('agent.sceneInfo') : t('agent.roleInfo')}`}
        extra={
          <Space>
            <Button onClick={() => setDrawerVisible(false)}>{t('common.cancel')}</Button>
            <Button type="primary" onClick={handleSaveAnswerModel}>
              {t('common.save')}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={sForm}
          scrollToFirstError
          onValuesChange={(changedValues, allValues) => setSForm((prev: any) => ({...sForm,...allValues}))}
        >
          <div className="px-5 pt-5 pb-1 bg-white rounded">
            <h4 className="mb-5 text-text_color_black text-base font-medium">
              {propsType ? t('agent.sceneInfo') : t('agent.roleInfo')}
            </h4>
            <Form.Item
              label={propsType ? t('agent.sceneName') : t('agent.roleName')}
              name="name"
              rules={[{ required: true, message: t('agent.nameRequired') }]}
            >
              <Input maxLength={50} placeholder={t('common.inputPrompt', { title: '' })} />
            </Form.Item>
            <Form.Item
              label={propsType ? t('agent.sceneDesc') : t('agent.roleDesc')}
              name="desc"
              rules={[{ required: true, message: t('agent.descRequired') }]}
            >
              <Input.TextArea rows={3} maxLength={500} placeholder={t('agent.remarkPlaceholder')} />
            </Form.Item>
            <Form.Item
              label={t('agentEngine.inputKey')}
              name="input"
              rules={[{ required: true, message: t('agentEngine.inputKeyPlaceholder') }]}
            >
              <Input maxLength={50} placeholder={t('common.inputPrompt', { title: '' })} />
            </Form.Item>
          </div>
          {isSenesFlow && (
            <div className="mt-5">
              <div className="px-5 pt-5 pb-1 bg-white rounded">
                <Form.Item
                  label={
                    <h4
                      className="mb-5 text-text_color_black text-base font-medium"
                      style={{ marginTop: 4 }}
                    >
                      {t('agentEngine.role.activeGuidance')}
                    </h4>
                  }
                  name="active_guidance"
                  valuePropName="checked"
                >
                  <Switch/>
                </Form.Item>
                {sForm.active_guidance && (
                  <>
                    <Form.Item label={
                    <div className="flex items-center mb-2">
                    <svg className="icon text-base" aria-hidden="true">
                      <use xlinkHref="#huifuleixing" />
                    </svg>
                    <span className="ml-1">{t('agentEngine.replyType')}</span>
                  </div>
                    } name="rule_cate">
                      <Radio.Group>
                        <Radio value="3">{t('agentEngine.replyType1')}</Radio>
                        <Radio value="4">{t('agentEngine.replyType2')}</Radio>
                        <Radio value="1">{t('agentEngine.replyType3')}</Radio>
                        <Radio value="11">{t('agentEngine.replyType4')}</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {sForm.rule_cate === '11' && (
                      <Form.Item
                      rules={[{ required: true, message: t('agentEngine.role.netSearchPromptKey') }]}
                      className="custom-label"
                      label={
                        <div className="label-div">
                          <span>{t('agentEngine.role.netSearchPromptKey')}</span>
                          <span className="label-btn">
                            <span
                              className="iconfont quanping"
                              onClick={() => openInputDialog('net_search_prompt')}
                            >
                              {t('common.fullScreen')}
                            </span>
                          </span>
                        </div>
                      }
                      name="net_search_prompt">
                        <Input.TextArea rows={3} maxLength={10000} placeholder={t('agentEngine.role.netSearchPromptKeyPlaceholder')} />
                      </Form.Item>
                    )}
                    {sForm.rule_cate === '3' && (
                      <Form.Item
                      rules={[{ required: true, message: t('agentEngine.role.flowContent') }]}
                      className="custom-label"
                label={
                  <div className="label-div">
                    <span>{t('agentEngine.role.flowContent')}</span>
                    <span className="label-btn">
                      <span
                        className="iconfont quanping"
                        onClick={() => openInputDialog('direct_answer')}
                      >
                        {t('common.fullScreen')}
                      </span>
                      <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                        {t('common.auxiliaryInfo')}
                      </span>
                    </span>
                  </div>
                }
                       name="direct_answer">
                        <Input.TextArea rows={3} maxLength={5000} placeholder={t('agentEngine.role.flowContentPlaceholder')} />
                      </Form.Item>
                    )}
                    {sForm.rule_cate === '1' && (
                      <>
                        <Form.Item 
                        label={
                          <div className="flex items-center mb-2">
                          <svg className="icon text-base" aria-hidden="true">
                            <use xlinkHref="#huidamoxing" />
                          </svg>
                          <span className="ml-1">{t('agentEngine.role.answerModel')}</span>
                        </div>
                        }
                         name="tool_llm">
                          <Select placeholder={t('common.selectPrompt', { title: '' })}>
                            {ModelEnum.map((option, index) => (
                              <Select.Option key={index} value={option}>
                                {option}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: t('agentEngine.role.answerPrompt') }]}
                                              className="custom-label"
                                              label={
                                                <div className="label-div">
                                                  <span>{t('agentEngine.role.answerPrompt')}</span>
                                                  <span className="label-btn">
                                                    <span
                                                      className="iconfont quanping"
                                                      onClick={() => openInputDialog('llm_prompt')}
                                                    >
                                                      {t('common.fullScreen')}
                                                    </span>
                                                    <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                                                      {t('common.auxiliaryInfo')}
                                                    </span>
                                                  </span>
                                                </div>
                                              }
                         name="llm_prompt">
                          <Input.TextArea
                            rows={3}
                            maxLength={5000}
                            placeholder={t('agentEngine.role.answerPromptPlaceholder')}
                          />
                        </Form.Item>
                      </>
                    )}
                    {sForm.rule_cate === '4' && (
                      <Form.Item
                      className="custom-label"
                label={
                  <div className="label-div">
                    <span>{t('agentEngine.role.polishPrompt')}</span>
                    <span className="label-btn">
                      <span
                        className="iconfont quanping"
                        onClick={() => openInputDialog('polish_prompt')}
                      >
                        {t('common.fullScreen')}
                      </span>
                      <span className="iconfont fuzhuxinxi" onClick={() => handleAuxiliary('')}>
                        {t('common.auxiliaryInfo')}
                      </span>
                    </span>
                  </div>
                }
                       name="polish_prompt">
                        <Input.TextArea rows={3} maxLength={5000} placeholder={t('common.polishPromptPlaceholder')} />
                      </Form.Item>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          <div className="mt-5">
            <div className="px-5 pt-5 pb-1 bg-white rounded">
              <h4 className="mb-5 text-text_color_black text-base font-medium">{t('status.effectiveStatus')}</h4>
              <Form.Item label={t('status.effectiveStatus')} name="status" valuePropName="checked">
              <Switch/>
              </Form.Item>
            </div>
          </div>
          {auxiliary && <Panel onClosePanel={() => setAuxiliary(false)} />}
        </Form>
      </Drawer>
      <Modal
        open={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        footer={null}
        width="100%"
        className="full-screen-modal"
        style={{ top: 0 }}
      >
        <div className="modal-header mb-3">
          <span>{filtersTitle(bigType)}</span>
          <span
            className="iconfont fuzhuxinxi auxiliary-icon"
            onClick={() => handleAuxiliary('big')}
          >
            {t('common.auxiliaryInfo')}
          </span>
        </div>
        <Input.TextArea
          value={sForm[bigType]}
          onChange={(e: any) => {
            form.setFieldValue(bigType, e.target.value);
            setSForm((prev: any) => ({ ...prev, [bigType]: e.target.value }));
          }}
          rows={22}
          maxLength={5000}
          showCount
          placeholder={`${t('common.inputPrompt', { title: filtersTitle(bigType) })}`}
        />
        {bigAuxiliary && (
          <Panel className="big-content-panel" onClosePanel={() => setBigAuxiliary(false)} />
        )}
        <div className="modal-footer text-right mt-6">
          <Button type="primary" onClick={() => setDialogVisible(false)}>
            {t('common.close')}
          </Button>
        </div>
      </Modal>
    </div>
  );
});
FlowComponent.displayName = 'FlowComponent';
export default FlowComponent;
