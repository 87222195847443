import React, { FC, useState } from 'react';
import { AgentWorkspace } from '../../../npm/7moor-agent-workspace.js';
import '../../../npm/style.css';
import { useAppSelector } from '@/store/hooks';
import { selectLoginData } from '@/store/modules/user';
import './workspace.css';
import { decode } from 'js-base64';

const AgentWorkspacePage: FC = () => {
  const encLoginData: any = useAppSelector(selectLoginData);

  const loginData = {
    username: '',
    password: '',
  };
  if (encLoginData) {
    try {
      const data = JSON.parse(decode(encLoginData));
      loginData.username = data.username;
      loginData.password = data.password;
    } catch (error) {
      /* empty */
    }
  }
  return (
    <>
      {loginData.username && (
        <div className="outerAgentDiv">
          <AgentWorkspace username={loginData.username} password={loginData.password} />
        </div>
      )}
    </>
  );
};

export default AgentWorkspacePage;
