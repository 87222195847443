import React, { useState, useEffect, useRef, FC, useImperativeHandle, forwardRef } from 'react';
import {
  Form,
  Switch,
  Button,
  Table,
  Tooltip,
  Drawer,
  Input,
  Select,
  Radio,
  message,
  Row,
  Col,
} from 'antd';
import { t } from 'i18next';
import { PlusOutlined, MinusCircleOutlined, HolderOutlined } from '@ant-design/icons';
import Sortable, { SortableEvent } from 'sortablejs';
import { conditionRule, cateTypes } from '@/constant/options';
import VarNameSelect from './VarNameSelect';
import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import StandardStrategy from './StandardStrategy';
import StandardStrategyForFan from '@/components/AgentForm/StandardStrategyForFan';
import { useAppSelector } from '@/store/hooks';
import { selectCurrentAgent } from '@/store/modules/agentTemplate';
import { DataItem } from '@/store/modules/types';
import { DEFAULT_ASK_HUMAN_CONFIG } from '@/constant/agent';

const { Column } = Table;
const { Option } = Select;

enum CondType {
  ALL = 'all',
  ANY = 'any',
}

// 定义条件规则组类型
interface ConditionGroup {
  varName: string | null;
  condition: string | null;
  value: string;
}
interface ConditionFormProps {
  formState: Record<string, any>;
  type?: string;
  ref?: any;
}

const ConditionalStrategy: FC<ConditionFormProps> = forwardRef(
  function ConditionalStrategy(props, ref) {
    // eslint-disable-next-line react/prop-types
    const { formState } = props;
    const currentAgent = useAppSelector(selectCurrentAgent);
    const [conditionForm, setConditionForm] = useState({
      is_condition_answer: false,
      condition_answer_arr: [] as any[],
    });

    // eslint-disable-next-line react/prop-types
    const [standardType] = useState<any>(props?.type || '1');

    useEffect(() => {
      // @ts-expect-error unknown
      setConditionForm(cloneDeep(formState));
      // eslint-disable-next-line react/prop-types
      form.setFieldsValue(formState);
    }, [formState]);
    const [drawerVisible, setDrawerVisible] = useState(false);

    // useEffect(() => {
    //   // 示例代码：在组件挂载后初始化拖动排序
    //   initTableRowDrag();
    // }, []);

    // Ant Design Form实例引用
    const [form] = Form.useForm();
    // 当前正在编辑的条件策略的索引 -1 新增 其他-
    const [editIndex, setEditIndex] = useState<number>(-1);

    // 添加规则操作
    const handleAddRule = () => {
      setEditIndex(-1);
      setSubForm(subInitForm);
      subFormRef.setFieldsValue(subInitForm);
      setDrawerVisible(true);
    };

    // 删除规则操作
    const handleDeleteRule = (index: number) => {
      const newArr = [...conditionForm.condition_answer_arr];
      newArr.splice(index, 1);
      setConditionForm({ ...conditionForm, condition_answer_arr: newArr });
    };

    // 编辑操作
    const handleEditRule = (index: number, rule: any) => {
      setDrawerVisible(true);
    };

    // 保存规则操作
    const saveRule = () => {
      subFormRef
        .validateFields()
        .then(async values => {
          // 保存逻辑
          standardFormRef.current
            // @ts-expect-error unknown
            ?.validateFormFields({ validateOnly: true })
            .then((res: any) => {
              const subFormValue = subFormRef.getFieldsValue(true);
              // @ts-expect-error unknown
              const standardFormValue = standardFormRef.current?.getFormFieldsValue();
              const params = {
                name: subFormValue.name,
                rule: {
                  ...subFormValue.rule,
                  ...standardFormValue,
                },
              };
              const { condition_answer_arr = [] } = conditionForm;
              const isHas = condition_answer_arr.find(i => i.name === params.name);
              if (isHas && editIndex === -1) {
                message.error(t('components.agentForm.varNameSelect.ruleNamesTip'));
                return;
              }
              if (editIndex === -1) {
                setConditionForm(
                  produce(draft => {
                    draft.condition_answer_arr = [...draft.condition_answer_arr, params];
                    form.setFieldValue(
                      'condition_answer_arr',
                      cloneDeep(draft.condition_answer_arr)
                    );
                  })
                );
              } else {
                setConditionForm(
                  produce(draft => {
                    draft.condition_answer_arr.splice(editIndex, 1, params);
                  })
                );
                const arr = cloneDeep(conditionForm.condition_answer_arr);
                arr.splice(editIndex, 1, params);
                form.setFieldValue('condition_answer_arr', arr);
              }
              setDrawerVisible(false);
            })
            .catch(() => {});
        })
        .catch(info => {
          console.log('Validate Failed:', info);
        });
    };

    // 取消操作
    const cancel = () => {
      setDrawerVisible(false);
    };

    /**
     * @description: 删除规则
     * @param {number} i
     * @return {*}
     */
    function handleDelete(i: number) {
      const condition_answer_arr = cloneDeep(conditionForm.condition_answer_arr);
      condition_answer_arr.splice(i, 1);
      setConditionForm(
        produce(draft => {
          draft.condition_answer_arr = condition_answer_arr
        })
      );
      form.setFieldValue('condition_answer_arr', condition_answer_arr);
    }

    /**
     * @description: 编辑规则
     * @param {number} index
     * @param {any} record
     * @return {*}
     */
    function handleEdit(index: number, record: any) {
      setEditIndex(index);
      console.log(record);
      const newEdit = cloneDeep(record);
      setSubForm(newEdit);
      subFormRef.setFieldsValue(newEdit);
      setDrawerVisible(true);
    }

    function showRuleText(row: any): string {
      let text = '';
      const cond_group = row.rule ? row.rule.cond_group || [] : [];
      cond_group.forEach((i: any) => {
        const { condition, value, varName } = i;
        let ruleLabel = '';
        const rule = conditionRule.find(c => c.value === condition);
        if (rule) ruleLabel = rule.label;
        text += `${varName} ${ruleLabel} ${value || ''},`;
      });
      return text.substring(0, text.length - 1);
    }

    function showRuleCateText(row: any) {
      const allTypes: {
        label: string;
        value: string;
      }[] = [];
      cateTypes.forEach(c => allTypes.push(...c.options));
      const type = allTypes.find(i => i.value === row.rule.rule_cate) || {
        label: '',
      };
      return type.label || '';
    }
    function showPromptText(row: any) {
      const { rule_cate, llm_prompt, direct_answer, polish_prompt, answer_prompt } = row.rule || {};
      let text = '';
      switch (rule_cate) {
        case '1':
          text = llm_prompt;
          break;
        case '3':
          text = direct_answer;
          break;
        case '4':
          text = polish_prompt;
          break;
        default:
          break;
      }
      if (!text && answer_prompt) {
        text = answer_prompt;
      }
      return text || '';
    }
    // 添加规则初始化数据
    const subInitForm = {
      name: '',
      rule: {
        cond_group: [
          {
            varName: null,
            value: '',
            condition: null,
          },
        ],
        cond_type: 'all',
        json_parser: '',
        json_field: '',
        name: '',
        rule_cate: '',
        // ...
        strategy_type: '',
        tool_llm: '',
        keyboard_scene: '',
        skill_num: '',
        turn_human_msg: '',
        llm_prompt: '',
        direct_answer: '',
        polish_prompt: '',
        answer_prompt: '',
        kbs: [],
        think_chain_config: {},
        ask_human_conf: cloneDeep(DEFAULT_ASK_HUMAN_CONFIG)
      },
    };
    const [subFormRef] = Form.useForm();
    const [subForm, setSubForm] = useState(subInitForm);

    function handleAddCondition() {
      setSubForm(
        produce(draft => {
          draft.rule.cond_group = [
            ...draft.rule.cond_group,
            {
              varName: null,
              condition: null,
              value: '',
            },
          ];
        })
      );
      console.log(subForm);
    }
    function handleRemoveCondition(i: number) {

      const arr = cloneDeep(subForm.rule.cond_group)
      arr.splice(i, 1);
      setSubForm(
        produce(draft => {
          draft.rule.cond_group = arr
          subFormRef.setFieldValue('rule', cloneDeep({
            ...subForm.rule,
            cond_group: arr
          }))
        })
      );
    }

    function onSubFormValuesChange(values: any) {
      // 更新rule，动态更新条件判断（等于/不等于）后面的输入框
      if (values.rule) {
        const rule = subFormRef.getFieldValue('rule');
        setSubForm(
          produce(draft => {
            draft.rule.cond_group = [...rule.cond_group];
          })
        );
      }
    }
    function changeVarName(i: number) {}

    // 标准策略
    const standardFormRef = useRef(null);
    const [standardForm, setStandardForm] = useState<DataItem>({});
    useEffect(() => {
      if (subForm) {
        const { rule } = subForm;
        // 兼容老数据
        // eslint-disable-next-line react/prop-types
        let strategy_type = formState.strategy_type;
        // eslint-disable-next-line react/prop-types
        if (!strategy_type && formState.rule_cate) {
          // eslint-disable-next-line react/prop-types
          if (['1', '3', '4'].includes(formState.rule_cate)) {
            strategy_type = 'answer';
          } else {
            strategy_type = 'action';
          }
        }
        // 兼容老数据answer_prompt
        let llm_prompt, direct_answer, polish_prompt;
        if (rule.rule_cate === '1') {
          llm_prompt = rule.answer_prompt;
        }
        if (rule.rule_cate === '3') {
          direct_answer = rule.answer_prompt;
        }
        if (rule.rule_cate === '4') {
          polish_prompt = rule.answer_prompt;
        }
        if (rule.ask_human_conf && rule.ask_human_conf.group_type == null) {
          rule.ask_human_conf.group_type = 'agent';
        }
        setStandardForm({
          strategy_type: strategy_type || 'answer',
          rule_cate: rule.rule_cate || '1',
          tool_llm: rule.tool_llm || 'default',
          json_parser: rule.json_parser || '1',
          json_field: rule.json_field || '',
          skill_num: rule.skill_num || '',
          turn_human_msg: rule.turn_human_msg || '',
          keyboard_scene: rule.keyboard_scene || '',
          llm_prompt: rule.llm_prompt || llm_prompt || '',
          direct_answer: rule.direct_answer || direct_answer || '',
          polish_prompt: rule.polish_prompt || polish_prompt || '',
          kbs: rule.kbs || [],
          think_chain_config: rule.think_chain_config || {},
          ask_human_conf: rule.ask_human_conf || cloneDeep(DEFAULT_ASK_HUMAN_CONFIG)
        });
      }
    }, [subForm]);

    function getFormFieldsValue() {
      return form.getFieldsValue(true);
    }
    async function validateFormFields() {
      return await form.validateFields();
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          getFormFieldsValue,
          validateFormFields,
        };
      },
      []
    );

    const initTableRowDrag = () => {
      const tbody: HTMLElement | null = document.querySelector(
        '.condition-draggable .ant-table-content tbody'
      );
      if (tbody) {
        Sortable.create(tbody, {
          //  可被拖拽的子元素
          draggable: '.condition-draggable .ant-table-row',
          onEnd({ newIndex, oldIndex }) {
            if (newIndex === oldIndex) {
              return;
            }
            if (newIndex === undefined || oldIndex === undefined) {
              return;
            }
            console.log(conditionForm);
            const arr = cloneDeep(conditionForm);
            const currRow = arr.condition_answer_arr.splice(oldIndex, 1)[0];
            arr.condition_answer_arr.splice(newIndex, 0, currRow);
            form.setFieldValue('condition_answer_arr', arr.condition_answer_arr);
          },
        });
      }
    };
    useEffect(() => {
      if (conditionForm.condition_answer_arr.length) {
        initTableRowDrag();
      }
    }, [conditionForm]);
    return (
      <div className="condition-form pt-5 pb-1 bg-white rounded">
        <Form
          form={form}
          layout="horizontal"
          initialValues={{ is_condition_answer: conditionForm.is_condition_answer }}
        >
          <Form.Item
            label={
              <div className="pre-label before:bg-primary_blue">
                <span className="ml-4">{t('flowForm.conditionStrategy')}</span>
              </div>
            }
            name="is_condition_answer"
            valuePropName="checked"
          >
            <Switch
              value={conditionForm.is_condition_answer}
              onChange={checked =>
                setConditionForm({ ...conditionForm, is_condition_answer: checked })
              }
            />
          </Form.Item>
          <div className="hidden">
            <Form.Item name="condition_answer_arr"></Form.Item>
          </div>
        </Form>
        {conditionForm.is_condition_answer && (
          <div>
            <div className="flex justify-between">
              <h4 className="font-bold text-sm text-text_color_black2">{t('flowForm.conditionRule')}</h4>
              <Button type="link" onClick={handleAddRule} icon={<PlusOutlined />}>
                {t('flowForm.addConditionRule')}
              </Button>
            </div>
            <div className="condition-draggable">
              <Table bordered dataSource={conditionForm.condition_answer_arr}>
                <Column
                  title={t('flowForm.sort')}
                  render={() => {
                    return (
                      <span className="drag-icon">
                        <HolderOutlined style={{ cursor: 'move' }} />
                      </span>
                    );
                  }}
                />
                <Column title={t('flowForm.ruleName')} width={160} ellipsis dataIndex="name" key="name" />
                <Column
                  title={t('flowForm.chainCheck.condition')}
                  dataIndex="condition"
                  key="condition"
                  render={(_, row: any) => {
                    return <span>{showRuleText(row)}</span>;
                  }}
                />
                <Column
                  title={t('flowForm.responseStrategy')} 
                  dataIndex="replyStrategy"
                  key="replyStrategy"
                  render={(_, row: any) => {
                    return <span>{showRuleCateText(row)}</span>;
                  }}
                />
                <Column
                  title={t('flowForm.talkingSkills')}
                  width={200}
                  ellipsis
                  render={(_, row: any) => {
                    return (
                      <Tooltip title={showPromptText(row)}>
                        <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                          {showPromptText(row)}
                        </span>
                      </Tooltip>
                    );
                  }}
                />
                <Column
                  title={t('common.operation')}
                  key="action"
                  width={140}
                  render={(text, record, index) => (
                    <>
                      <Button type="link" size="small" onClick={() => handleEdit(index, record)}>
                        {t('common.edit')}
                      </Button>
                      <Button type="link" danger size="small" onClick={() => handleDelete(index)}>
                        {t('common.delete')}
                      </Button>
                    </>
                  )}
                />
              </Table>
            </div>
            <Drawer
              title={t('flowForm.addConditionRule')}
              placement="right"
              onClose={cancel}
              open={drawerVisible}
              width={800}
              footer={
                <div style={{ textAlign: 'right' }}>
                  <Button onClick={cancel} style={{ marginRight: 8 }}>
                    {t('common.cancel')}
                  </Button>
                  <Button onClick={saveRule} type="primary">
                    {t('common.save')}
                  </Button>
                </div>
              }
            >
              <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                form={subFormRef}
                initialValues={subForm}
                onValuesChange={onSubFormValuesChange}
              >
                <Form.Item
                  name="name"
                  label={t('flowForm.ruleName')}
                  rules={[{ required: true, message: t('flowForm.inputRuleName') }]}
                >
                  <Input maxLength={50} showCount placeholder={t('common.input')} />
                </Form.Item>

                <Row gutter={12}>
                  <Form.Item name={['rule.cond_group']} noStyle>
                    <Col span={24}>
                      <div className="font-bold inline-block">
                        <span>{t('flowForm.chainCheck.condition')}</span>
                      </div>
                    </Col>
                  </Form.Item>
                </Row>

                {subForm.rule.cond_group.map((item, index) => (
                  <Row gutter={12} key={index}>
                    <Col span={1}>
                      <Form.Item>{(index + 1).toString() + '：'}</Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={['rule', 'cond_group', index, 'varName']}>
                        <VarNameSelect value={item.varName} onChange={() => changeVarName(index)} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item name={['rule', 'cond_group', index, 'condition']}>
                        <Select placeholder={t('flowForm.selectOperator')}>
                          {conditionRule.map((i, idx) => (
                            <Option key={idx} value={i.value}>
                              {i.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      {['C', 'D', 'E', 'F', 'GT', 'LT', 'LTE', 'GTE', 'REGX'].includes(
                        // @ts-expect-error known
                        subForm.rule.cond_group[index]?.condition
                      ) && (
                        <Form.Item name={['rule', 'cond_group', index, 'value']}>
                          <Input placeholder={t('common.input')} />
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={2}>
                      {subForm.rule.cond_group.length > 1 && (
                        <div
                          className="flex items-center h-8 cursor-pointer"
                          onClick={() => handleRemoveCondition(index)}
                        >
                          <MinusCircleOutlined />
                        </div>
                      )}
                    </Col>
                  </Row>
                ))}

                <Row gutter={12}>
                  <div className="pb-4">
                    <Button type="primary" icon={<PlusOutlined />} onClick={handleAddCondition}>
                      {t('flowForm.addCondition')}
                    </Button>
                  </div>
                </Row>

                <Row gutter={12}>
                  <Form.Item name={['rule', 'cond_type']} label={t('flowForm.executeLogic')}>
                    <Radio.Group>
                      <Radio value={CondType.ALL}>{t('flowForm.allCondition')}</Radio>
                      <Radio value={CondType.ANY}>{t('flowForm.anyCondition')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Row>
              </Form>
              <div>
                {standardType === '2' ? (
                  <StandardStrategyForFan
                    ref={standardFormRef}
                    formState={standardForm}
                    showTitle={false}
                    isConditionModule
                  />
                ) : (
                  <StandardStrategy
                    ref={standardFormRef}
                    formState={standardForm}
                    showTitle={false}
                    isConditionModule
                  />
                )}
              </div>
            </Drawer>
          </div>
        )}
      </div>
    );
  }
);

export default ConditionalStrategy;
