import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Input,
  Button,
  Dropdown,
  Menu,
  Select,
  Modal,
  notification,
  Pagination,
  MenuProps,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined, PlusOutlined, CloseCircleFilled } from '@ant-design/icons';
import Sortable from 'sortablejs';
import { cloneDeep, isEmpty } from 'lodash';
import { generateDataItem, generateUUID } from '@/utils/common';
import {
  queryAgentRole,
  saveAgentRole,
  deleteRole,
  bindAgentFloor,
  updateRoleStatus,
  bindCateFloor,
  queryAgentConfig,
  copyAgentRole,
  roleSort,
  moveAgentRole,
} from '@/api/agent';
import Carts from './Carts';
import MainCarts from './mainCarts';
import AnswerRoleAdd from './AnswerRoleAdd';
import FlowRoleAdd from './FlowRoleAdd';
import ClassificationAdd from './ClassificationAdd';
import ScenesDrawerAdd from './ScenesDrawerAdd';
import FlowCanvas from './FlowCanvas';
import './CharacterScene.scss';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import { selectCurrentAgent, setCurrentAgent } from '@/store/modules/agentTemplate';
import { DataItem } from '@/store/modules/types';
import { produce } from 'immer';

const { Option } = Select;
const { confirm } = Modal;
const CharacterScene = () => {
  const { t } = useTranslation();

  const currentAgent = useAppSelector(selectCurrentAgent).currentAgent;
  const dispatch = useAppDispatch();
  const [info, setInfo] = useState({
    pocketRole: 'ask_human',
    dirtyScene: 'ask_human',
    roleName: '',
    currentClassificationInfo: [] as DataItem[],
  });
  const [toolMaps, setToolMaps] = useState<DataItem[]>([]);
  const [allToolMaps, setAllToolMaps] = useState<DataItem[]>([]);
  const [toolChildMaps, setToolChildMaps] = useState([]);
  const [toolChildSelectMaps, setToolChildSelectMaps] = useState<DataItem[]>([]);
  const [showFlow, setShowFlow] = useState(false);
  const [flowData, setFlowData] = useState<DataItem | null>(null);
  const [answerDrawerVisible, setAnswerDrawerVisible] = useState(false);
  const [answerRoleData, setAnswerRoleData] = useState<DataItem | null>(null);
  const [classificationRoleData, setClassificationRoleData] = useState<DataItem>({});
  const [classificationVisible, setClassificationVisible] = useState(false);
  const [scenesDrawerVisible, setScenesDrawerVisible] = useState(false);
  const [isSenesFlow, setIsSenesFlow] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [answerRoleAddModule, setAnswerRoleAddModule] = useState<string>('');
  const [allCurrentClassificationInfo, setAllCurrentClassificationInfo] = useState<DataItem>({});
  const pagedListRef = useRef<HTMLDivElement>(null);
  const [curDragRoleUid, setCurDragRoleUid] = useState<string>('');
  const [curDragSceneUid, setCurDragSceneUid] = useState<string>('');
  const flowRoleRef = useRef<{ openFlowDialog: (data?: any, type?: string) => void }>(null);

  useEffect(() => {
    queryAgentRoleFn();
  }, []);

  async function queryAgentRoleFn(pid = '') {
    const agent_id = currentAgent.agent_id;
    if (agent_id) {
      try {
        const {
          code,
          data,
          message: resMsg,
        } = await queryAgentRole({
          agent_id,
          page: 1,
          size: 1000,
          name: !pid ? info.roleName : '',
          pid,
        });
        if (code === 200) {
          if (!pid) {
            // 兜底角色列表
            setToolMaps(data.result || []);
            if (info.roleName === '') {
              // 一级
              const newArr: DataItem[] = [];
              setInfo(
                produce(draft => {
                  if (currentAgent.floor === null) {
                    draft.pocketRole = 'ask_human';
                  } else {
                    draft.pocketRole = currentAgent.floor || '';
                  }
                })
              );
              data.result.forEach((item: DataItem) => {
                // @ts-expect-error known
                if (item.status == 1) {
                  // 过滤启动状态的角色
                  newArr.push(item);
                }
              });
              setAllToolMaps(newArr);
            }
          } else {
            // 二级
            setInfo(
              produce(draft => {
                draft.currentClassificationInfo = cloneDeep(data.result || []);
              })
            );
            setToolChildMaps(cloneDeep(data.result || []));
            const resultArr = cloneDeep(data.result || []);
            const newArr: DataItem[] = [];
            resultArr.forEach((item: DataItem) => {
              if (item.status == '1') {
                // 过滤启动状态的场景
                newArr.push(item);
              }
            });
            setToolChildSelectMaps(newArr);
            setTotalCount(data.total_count);
          }
        } else {
          message.error(resMsg ? resMsg : t('common.queryFailed'));
        }
      } catch (error) {
        console.log(error);
        message.error(t('common.queryFailed'));
      }
    }
  }

  /**
   * @description: 兜底角色下拉数据过滤
   * @return {*}
   */
  useEffect(() => {
    if (info.roleName === '') {
      const newArr: DataItem[] = [];
      setInfo(
        produce(draft => {
          if (currentAgent.floor === null) {
            // 没有设置过，默认ask_human
            draft.pocketRole = 'ask_human';
          } else {
            draft.pocketRole = currentAgent.floor || '';
          }
        })
      );
      toolMaps.forEach(item => {
        if (item.status == '1') {
          // 过滤启动状态的角色
          newArr.push(item);
        }
      });
      setAllToolMaps(newArr);
    }
  }, [toolMaps, currentAgent.floor]);

  const changeRole = (data: DataItem[], val: string) => {
    const relInfo = currentAgent as any;
    bindAgentFloor({
      agent_id: relInfo.agent_id,
      floor: val,
    }).then(res => {
      if (res.code === 200) {
        message.success(t('common.saveSuccess'));
        queryAgentConfig({
          agent_id: relInfo.agent_id,
        }).then((configRes: any) => {
          if (configRes.code === 200) {
            dispatch(setCurrentAgent(configRes.data));
          }
        });
      } else {
        message.error(res.message ? res.message : t('common.saveFailed'));
      }
      // emit('refreshConfig');
    });
  };
  const copyCart = (data: { uid: string; type: string }) => {
    const { uid, type } = data;
    const info = currentAgent as any;
    copyAgentRole({ agent_id: info.agent_id, uid })
      .then((res: any) => {
        if (res.code === 200) {
          message.success(t('common.copySuccess'));
          if (type === 'scenes') {
            queryAgentRoleFn(allCurrentClassificationInfo.uid);
          } else {
            setAllCurrentClassificationInfo({});
            queryAgentRoleFn();
          }
        } else {
          message.error(res.message ? res.message : t('common.copyFailed'));
        }
      })
      .catch(() => {
        message.error(t('common.copyFailed'));
      });
  };
  const delCart = (data: { uid: string; type: string; isMainCart: boolean }) => {
    const { uid, type, isMainCart } = data;
    const info = currentAgent as any;
    let pid = '';
    if (type) {
      pid = allCurrentClassificationInfo.uid || '';
    }
    deleteRole({
      agent_id: info.agent_id,
      pid,
      uid,
    }).then(res => {
      if (res.code === 200) {
        message.success(t('common.deleteSuccess'));
        if (isMainCart) {
          queryAgentRoleFn(allCurrentClassificationInfo.uid);
        } else {
          setAllCurrentClassificationInfo({});
          queryAgentRoleFn();
        }
      } else {
        message.error(res.message ? res.message : t('common.deleteFailed'));
      }
    });
  };

  const handleDelCart = ({
    uid,
    type,
    isMainCart,
  }: {
    type: string;
    uid: string;
    isMainCart: boolean;
  }) => {
    const info = currentAgent as any;
    let pid = '';
    if (type) {
      pid = allCurrentClassificationInfo.uid || '';
    }
    deleteRole({
      agent_id: info.agent_id,
      pid,
      uid,
    }).then(res => {
      if (res.code === 200) {
        message.success(t('common.deleteSuccess'));
        if (isMainCart) {
          queryAgentRoleFn(allCurrentClassificationInfo.uid);
        } else {
          setAllCurrentClassificationInfo({});
          queryAgentRoleFn();
        }
      } else {
        message.error(res.message ? res.message : t('common.deleteFailed'));
      }
    });
  };

  const handleAddRoleClick = (roleType: string) => {
    // 根据角色类型显示相应的弹窗
    switch (roleType) {
      case 'answer':
        setAnswerDrawerVisible(true);
        break;
      case 'flow':
        // 显示流程角色添加逻辑...
        break;
      case 'classification':
        setClassificationVisible(true);
        break;
      default:
        break;
    }
  };

  // 上线||下线操作
  const changeStatus = (params: { info: DataItem; type: string }) => {
    const { info, type } = params;
    console.log(type);
    let pid = '';
    if (type) {
      pid = allCurrentClassificationInfo.uid || '';
    }
    updateRoleStatus({
      uid: info.uid,
      pid: pid,
      agent_id: currentAgent.agent_id,
      // @ts-expect-error known
      status: info.status == 0 ? 1 : 0,
    }).then(res => {
      if (res.code === 200) {
        // @ts-expect-error known
        message.success(t('status.changeSuccess', { status: info.status == 0 ? t('status.online') : t('status.offline') }));
        queryAgentRoleFn(pid);
      } else {
        // @ts-expect-error known
        message.error(res.message ? res.message : t('status.changeFailed', { status: info.status == 0 ? t('status.online') : t('status.offline') }));
      }
    });
  };

  /**
   * @description: 点击卡片
   * @return {*}
   */
  function handleEditCart(cart: DataItem) {
    if (cart.role_type == '2') {
      // 流程角色直接跳转到画布页面
      goToFlow({ info: cart, type: '' });
      return false;
    }
    let role_type = cart.role_type;
    if (!role_type && cart.cate) {
      if (['1', '3', '4'].includes(cart.cate)) {
        role_type = '0';
      }
      if (cart.cate === '2') {
        role_type = '1';
      }
    }
    switch (role_type) {
      // 回答
      case '0':
        setAnswerRoleAddModule('answer');
        setAnswerRoleData(cart);
        setAnswerDrawerVisible(true);
        setInfo(
          produce(draft => {
            draft.currentClassificationInfo = [];
            draft.dirtyScene = '';
          })
        );
        setAllCurrentClassificationInfo({});
        break;
      // 分类
      case '1':
        setAllCurrentClassificationInfo(cart);
        // info.currentClassificationInfo = cart.child;
        // page.page = 1;
        setInfo(
          produce(draft => {
            if (cart.floor === null) {
              draft.dirtyScene = 'ask_human';
            } else {
              draft.dirtyScene = cart.floor || '';
            }
          })
        );
        queryAgentRoleFn(cart.uid);
        break;
      default:
        break;
    }
  }
  function handleEditDrawer(cart: DataItem) {
    if (cart.role_type == '2') {
      setIsSenesFlow(true);
      if (flowRoleRef.current) {
        flowRoleRef.current.openFlowDialog(cart);
      }
    } else {
      setClassificationVisible(true);
      setClassificationRoleData(cart);
    }
  }
  function refreshRole() {
    queryAgentRoleFn();
  }
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <span onClick={answerBtn}>
          <span className="iconfont huidajiaose" />
          &nbsp; {t('agentEngine.role.answer', { name: t('agentEngine.role.name') })}
        </span>
      ),
    },
    {
      key: '2',
      label: (
        <span onClick={classificationBtn}>
          <span className="iconfont agentfuwu" />
          &nbsp; {t('agentEngine.role.classification', { name: t('agentEngine.role.name') })}
        </span>
      ),
    },
    {
      key: '3',
      label: (
        <span onClick={() => flowBtn('')}>
          <span className="iconfont agentguanli" />
          &nbsp; {t('agentEngine.role.flow', { name: t('agentEngine.role.name') })}
        </span>
      ),
    },
  ];

  function answerBtn() {
    setAnswerRoleAddModule('answer');
    set_role_type('0');
    const initObj = generateDataItem({
      // @ts-expect-error unknown
      pid: '',
      // @ts-expect-error unknown
      role_type: '0',
    });
    setAnswerRoleData({
      ...initObj,
      tool_llm: 'default',
    });
    setAnswerDrawerVisible(true);
  }
  function classificationBtn() {
    set_role_type('1');
    setClassificationRoleData({});
    setClassificationVisible(true);
  }
  const [role_type, set_role_type] = useState<string>('');
  const [page, setPage] = useState({
    page: 1,
    limit: 10,
  });

  // 添加+更新方法
  const updateInfo = (infoData: DataItem) => {
    if (infoData.uid) {
      saveAgentRole(infoData).then(res => {
        if (res.code === 200) {
          message.success(t('common.editSuccess'));
          queryAgentRoleFn(!infoData.pid || infoData.pid === '0' ? '' : infoData.pid);
          setAnswerDrawerVisible(false);
          setClassificationVisible(false);
        } else {
          message.error(res.message ? res.message : t('common.editFailed'));
        }
      });
    } else {
      const relInfo = currentAgent as any;
      // 新增
      if (role_type === '0') {
        // 回答角色
        const obj = {
          uid: generateUUID(),
          pid: '',
          role_type: '0',
          agent_id: relInfo.agent_id,
        };
        const relObj = Object.assign(obj, infoData);
        saveAgentRole(relObj).then(res => {
          if (res.code === 200) {
            message.success(t('common.addSuccess'));
            queryAgentRoleFn();
            setAnswerDrawerVisible(false);
          } else {
            message.error(res.message ? res.message : t('common.addFailed'));
          }
        });
      } else if (role_type === '1') {
        // 分类角色
        const obj = {
          uid: generateUUID(),
          pid: '',
          role_type: '1',
          cate: '2',
          agent_id: relInfo.agent_id,
        };
        const relObj = Object.assign(obj, infoData);
        saveAgentRole(relObj).then(res => {
          if (res.code === 200) {
            message.success(t('common.addSuccess'));
            queryAgentRoleFn();
            setClassificationVisible(false);
          } else {
            message.error(res.message ? res.message : t('common.addFailed'));
          }
        });
      } else {
        // 新增场景 (分类下
        const obj = {
          uid: generateUUID(),
          pid: allCurrentClassificationInfo.uid,
          role_type: '1',
          cate: '2',
          agent_id: relInfo.agent_id,
        };
        const relObj = Object.assign(obj, infoData);
        saveAgentRole(relObj).then(res => {
          if (res.code === 200) {
            message.success(t('common.addSuccess'));
            setPage(
              produce(draft => {
                draft.page = 1;
              })
            );
            queryAgentRoleFn(allCurrentClassificationInfo.uid);
            setScenesDrawerVisible(false);
          } else {
            message.error(res.message ? res.message : t('common.addFailed'));
          }
        });
      }
    }
  };

  const [isShowRightTop, setIsShowRightTop] = useState(false);
  const [isShowAddScenesBtn, setIsShowAddScenesBtn] = useState(false);
  const [pagedList, setPagedList] = useState<any>([]);
  const sort: any = useRef(null);
  useEffect(() => {
    setIsShowAddScenesBtn(
      allCurrentClassificationInfo.role_type === '1' || allCurrentClassificationInfo.cate === '2'
    );
    setIsShowRightTop(!isEmpty(allCurrentClassificationInfo));
  }, [allCurrentClassificationInfo]);

  useEffect(() => {
    const start = (page.page - 1) * page.limit;
    const end = page.page * page.limit;
    setPagedList(toolChildMaps.slice(start, end));
  }, [page, toolChildMaps]);

  const changeSceneFloor = (value: any) => {
    const relInfo = currentAgent;
    bindCateFloor({
      agent_id: relInfo.agent_id,
      floor: value,
      uid: allCurrentClassificationInfo.uid,
    }).then(res => {
      if (res.code === 200) {
        message.success(t('common.saveSuccess'));
        queryAgentRoleFn();
        setInfo(
          produce(draft => {
            draft.dirtyScene = value || '';
          })
        );
      } else {
        message.error(res.message ? res.message : t('common.saveFailed'));
      }
    });
  };

  const [scenesInitData, setScenesInitData] = useState({});
  const addScenes = () => {
    set_role_type('');
    const initObj = generateDataItem({
      // @ts-expect-error known
      pid: allCurrentClassificationInfo.uid,
    });
    setScenesInitData({
      ...initObj,
      tool_llm: 'default',
    });
    setScenesDrawerVisible(true);
  };
  // 添加流程角色弹窗
  const flowBtn = (type?: string) => {
    if (type) {
      setIsSenesFlow(false);
    } else {
      setIsSenesFlow(true);
    }
    if (flowRoleRef.current) {
      flowRoleRef.current.openFlowDialog(null, type);
    }
  };
  const handleCopyScenes = (data: any) => {
    console.log(data);
    confirm({
      title: t('common.confirmCopy'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk: async () => {
        const { uid } = data;
        copyCart(data);
      },
    });
  };
  const handleDelScenes = (params: { uid: string; type: string }) => {
    const { uid, type } = params;
    confirm({
      title: t('common.confirmDeleteScene'),
      // content: t('common.confirmDelete'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      type: 'warning',
      onOk: async () => {
        handleDelCart({ uid, type, isMainCart: true });
      },
    });
  };

  const editScenes = (params: { info: DataItem; type: string }) => {
    const { info, type } = params;
    if (info.role_type == '2') {
      setIsSenesFlow(false);
      if (flowRoleRef.current) {
        flowRoleRef.current.openFlowDialog(info, type);
      }
    } else {
      setAnswerRoleData(info);
      setAnswerRoleAddModule('scenes');
      setAnswerDrawerVisible(true);
    }
  };

  const handleSizeChange = (current: number, size: number) => {
    // your handleSizeChange logic here
    setPage(
      produce(draft => {
        draft.page = 1;
        draft.limit = size;
      })
    );

    const start = (page.page - 1) * page.limit;
    const end = page.page * page.limit;
    console.log(start, end);
    setPagedList(toolChildMaps.slice(start, end));
  };

  const handleCurrentChange = (current: number, size: number) => {
    // your handleCurrentChange logic here

    setPage(
      produce(draft => {
        draft.page = current;
      })
    );

    const start = (page.page - 1) * page.limit;
    const end = page.page * page.limit;
    setPagedList(toolChildMaps.slice(start, end));
  };
  // 点击卡片进入画布，或者新建流程角色成功之后跳转画布
  // @ts-expect-error 先忽略
  const goToFlow = ({ info, type }) => {
    if (type) {
      setIsSenesFlow(false);
    } else {
      setIsSenesFlow(true);
    }
    setFlowData(info);
    setShowFlow(true);
  };
  // 流程画布点击返回
  const goBack = () => {
    setShowFlow(false);
    if (!isSenesFlow) {
      // 场景里面钻入的，刷新场景列表
      queryAgentRoleFn(allCurrentClassificationInfo.uid);
    } else {
      // 角色里面钻入的，刷新角色列表
      queryAgentRoleFn();
    }
  };
  async function moveAgentRoleFn(uid = '', pid = '0') {
    try {
      const agent_id = currentAgent.agent_id;
      const {
        code,
        data,
        message: resMsg,
      } = await moveAgentRole({
        agent_id,
        uid,
        pid,
      });
      if (code === 200) {
        queryAgentRoleFn();
        queryAgentRoleFn(allCurrentClassificationInfo.uid);
      } else {
        message.error(resMsg || t('common.moveFailed'));
      }
    } catch (err) {
      message.error(t('common.moveFailed'));
    }
  }
  const initSceneDrag = useCallback(() => {
    sort.current?.destroy();
    if (pagedListRef.current) {
      sort.current = new Sortable(pagedListRef.current, {
        draggable: '.paged-draggable .mainCart',
        onStart({ item }) {
          setCurDragSceneUid(item.dataset.uid as string);
        },
        onEnd({ newIndex, oldIndex }) {
          setCurDragSceneUid('');
          if (newIndex === oldIndex || newIndex === undefined || oldIndex === undefined) {
            return;
          }

          let moveType = '';
          let data: any[] = [];
          if (oldIndex > newIndex) {
            moveType = 'up';
            data = [
              {
                uid: pagedList[oldIndex].uid,
                sort: pagedList[oldIndex].sort,
              },
              {
                uid: pagedList[newIndex].uid,
                sort: pagedList[newIndex].sort,
              },
            ];
          } else {
            moveType = 'down';
            data = [
              {
                uid: pagedList[newIndex].uid,
                sort: pagedList[newIndex].sort,
              },
              {
                uid: pagedList[oldIndex].uid,
                sort: pagedList[oldIndex].sort,
              },
            ];
          }

          roleSort({
            data: data,
            move_type: moveType,
            agent_id: currentAgent.agent_id,
            pid: allCurrentClassificationInfo.uid,
          }).then(res => {
            if (res.code === 200) {
              queryAgentRoleFn(allCurrentClassificationInfo.uid);
            }
          });
        },
      });
    }
  }, [pagedList]);
  useEffect(() => {
    initSceneDrag();
  }, [initSceneDrag, pagedList]);
  function moveToRole() {
    if (!curDragSceneUid) {
      return;
    }
    const curScene = pagedList.filter((el: any) => el.uid === curDragSceneUid)[0];
    if (!curScene) {
      return;
    }
    if (curDragSceneUid === info.dirtyScene) {
      message.error(t('agentEngine.role.sceneBasicNo2Role'));
      return;
    }
    moveAgentRoleFn(curDragSceneUid);
  }
  function onCartDrag(uid: string = '') {
    setCurDragRoleUid(uid);
  }
  function moveToScene() {
    if (!curDragRoleUid || !allCurrentClassificationInfo.uid) {
      return;
    }
    const curRole = toolMaps.filter(el => el.uid === curDragRoleUid)[0];
    if (!curRole) {
      return;
    }
    if (curRole.role_type === '1') {
      message.error(t('agentEngine.role.roleClasslNo2Scene'));
      return;
    }
    if (info.pocketRole === curDragRoleUid) {
      message.error(t('agentEngine.role.roleBasicNo2Scene'));
      return;
    }
    moveAgentRoleFn(curDragRoleUid, allCurrentClassificationInfo.uid);
  }

  return (
    <div className="characterScene">
      <div className="characterScene-contain flex">
        <div className="left">
          <div className="flex items-center roleName">
            <Input
              value={info.roleName}
              onChange={e => setInfo({ ...info, roleName: e.target.value })}
              placeholder={t('agentEngine.role.roleName')}
              size="small"
              suffix={
                <Button
                  className="text-btn"
                  type="text"
                  icon={<SearchOutlined />}
                  onClick={() => queryAgentRoleFn()}
                />
              }
            />
            <Dropdown menu={{ items }} placement="bottomLeft">
              <Button
                className="role-plus"
                type="primary"
                icon={<PlusOutlined />}
                size="large"
              ></Button>
            </Dropdown>
          </div>
          <div className="roleSelect">
            <span className="title">{t('agentEngine.role.roleBasic')}</span>
            <Select
              defaultValue={info.pocketRole}
              value={info.pocketRole}
              onChange={val => {
                setInfo(
                  produce(draft => {
                    draft.pocketRole = val;
                  })
                );
                changeRole(toolMaps, val);
              }}
            >
              {allToolMaps.map(item => (
                <Option key={item.uid} value={item.uid}>
                  {item.name}
                </Option>
              ))}
              <Option value="">
                <div className="flex items-center">
                  <CloseCircleFilled style={{ color: 'red', fontSize: 16 }} />
                  <span className="ml-1">{t('agentEngine.role.unknown')}</span>
                </div>
              </Option>
              <Option value="ask_human">
                <div className="flex items-center">
                  <span className="iconfont AH" style={{ color: '#1677ff' }}></span>
                  <span className="ml-1">Ask Human</span>
                </div>
              </Option>
            </Select>
          </div>
          <div className="carts">
            <div style={{ height: 'calc(100vh - 296px)' }} className="overflow-y-scroll">
              <Carts
                selectName={info.roleName}
                toolMaps={toolMaps}
                delCart={data => delCart(data)}
                copyCart={data => copyCart(data)}
                changeStatus={data => changeStatus(data)}
                edit={(cart: DataItem) => {
                  handleEditCart(cart);
                }}
                editCart={cart => handleEditDrawer(cart)}
                refreshRole={() => refreshRole()}
                moveToRole={() => moveToRole()}
                onCartDrag={uid => onCartDrag(uid)}
              />
            </div>
          </div>
        </div>
        <div className="main flex-1 pl-4">
          <div className="cartList">
            {isShowRightTop && (
              <div className="top flex items-center justify-between">
                {/* <div className="total-text">共{totalCount}个场景</div> */}
                <div className="total-text">{t('agentEngine.role.totalScenes', { count: totalCount, scene: 'scene' })}</div>
                <div className="flex items-center">
                  <div className="roleSelect">
                    <span className="title">{t('agentEngine.role.sceneBasic')}</span>
                    <Select
                      value={info.dirtyScene}
                      disabled={!info.currentClassificationInfo.length}
                      className="m-2"
                      placeholder={t('common.select')}
                      style={{ width: 150 }}
                      onChange={(val: string) => changeSceneFloor(val)}
                    >
                      {toolChildSelectMaps.map(item => (
                        <Select.Option key={item.uid} value={item.uid}>
                          {item.name}
                        </Select.Option>
                      ))}
                      <Select.Option value="">
                        <div className="flex items-center">
                          <CloseCircleFilled style={{ color: 'red', fontSize: 16 }} />
                          <span className="ml-1">{t('agentEngine.role.unknown')}</span>
                        </div>
                      </Select.Option>
                      <Option value="ask_human">
                        <div className="flex items-center">
                          <span className="iconfont AH" style={{ color: '#1677ff' }}></span>
                          <span className="ml-1">Ask Human</span>
                        </div>
                      </Option>
                    </Select>
                  </div>
                  {isShowAddScenesBtn && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1" onClick={addScenes}>
                            <span className="iconfont huidajiaose drop-iconfont" />
                            &nbsp;{t('agentEngine.role.answer', { name: t('common.scene') })}
                          </Menu.Item>
                          <Menu.Item key="2" onClick={() => flowBtn('internal')}>
                            <span className="iconfont agentguanli drop-iconfont" />
                            &nbsp;{t('agentEngine.role.flow', { name: t('common.scene') })}
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <Button type="link" className="addBtn">
                        <PlusOutlined className="plus" />
                        {t('agentEngine.role.add', { name: t('common.scene') })}
                      </Button>
                    </Dropdown>
                  )}
                </div>
              </div>
            )}
            {
              <div
                style={{ height: 'calc(100vh - 274px)', overflow: 'auto', paddingRight: '10px' }}
                ref={pagedListRef}
                className="paged-draggable"
                onDrop={moveToScene}
              >
                {toolMaps.length !== 0 && !info.currentClassificationInfo.length && (
                  <div className="mt-40">
                    <div className="flex justify-center">
                      <img src={require('@/assets/images/message_1.png')} />
                    </div>
                    <p className="text-center text-sm" style={{ color: '#3d3d3d' }}>
                      {t('agentEngine.role.chooseRole')}
                    </p>
                  </div>
                )}
                {!toolMaps.length && !info.currentClassificationInfo.length && (
                  <div className="mt-40">
                    <div className="flex justify-center">
                      <img src={require('@/assets/images/user_1.png')} />
                    </div>
                    <p className="text-center text-sm" style={{ color: '#3d3d3d' }}>
                      {t('agentEngine.role.createRole')}
                    </p>
                  </div>
                )}
                {info.currentClassificationInfo.length > 0 &&
                  pagedList.map((item: any) => (
                    <MainCarts
                      key={item.uid}
                      info={item}
                      uid={info.dirtyScene}
                      goToFlow={goToFlow}
                      onChangeStatus={changeStatus}
                      onDelScenes={handleDelScenes}
                      onCopyScenes={handleCopyScenes}
                      onEditScenes={editScenes}
                    />
                  ))}
              </div>
            }
            {info.currentClassificationInfo.length > 0 && (
              <div className="pagination">
                <Pagination
                  className="justify-end"
                  current={page.page}
                  pageSize={page.limit}
                  pageSizeOptions={[10, 20, 50]}
                  showSizeChanger
                  showQuickJumper
                  total={totalCount}
                  onChange={handleCurrentChange}
                  onShowSizeChange={handleSizeChange}
                />
              </div>
            )}
          </div>
        </div>
        <AnswerRoleAdd
          visible={answerDrawerVisible}
          onClose={() => setAnswerDrawerVisible(false)}
          form={answerRoleData as DataItem}
          updateInfo={updateInfo}
          updateVisible={val => setAnswerDrawerVisible(val)}
        />
        <ScenesDrawerAdd
          visible={scenesDrawerVisible}
          form={scenesInitData}
          toolChildMaps={toolChildMaps}
          onUpdateInfo={updateInfo}
          onUpdateVisible={val => setScenesDrawerVisible(val)}
        />
        <ClassificationAdd
          visible={classificationVisible}
          form={classificationRoleData}
          toolMaps={toolMaps}
          onUpdateInfo={updateInfo}
          onUpdateVisible={val => setClassificationVisible(val)}
        />
        <FlowRoleAdd
          fetchList={queryAgentRoleFn}
          goToFlow={goToFlow}
          isSenesFlow={isSenesFlow}
          ref={flowRoleRef}
          pid={allCurrentClassificationInfo.uid}
        />
        {showFlow && (
          <FlowCanvas
            isSenesFlow={isSenesFlow}
            propsFlowData={flowData}
            pid={allCurrentClassificationInfo.uid}
            emitGoBack={goBack}
          />
        )}
      </div>
    </div>
  );
};

export default CharacterScene;
