import ChatConfig from './chatConfig/chatConfig';
import { Tabs } from 'antd';
import {t} from 'i18next'
import TabPane from 'antd/es/tabs/TabPane';
import React, { FC } from 'react';

const sessionProcess: FC = () => {
  return (
    <div className="h-full" style={{backgroundColor: '#FAFAFA'}}>
      <div className="agentEngine">
        <Tabs defaultActiveKey="role" tabBarStyle={{ padding: '0 16px', marginBottom: '0', backgroundColor: '#fff', borderRadius: '10px 10px 0 0' }}>
          <TabPane tab={t('common.welcome')} key="chatSet">
            <div>
              <ChatConfig />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default sessionProcess;
