import React, { Suspense, useState, useEffect } from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '@/assets/images/logo.png';
import { Dropdown, Image, Layout, Menu, MenuProps, Space, theme, Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '@/components/LanguageSwitcher/languageSwitcher';

import {
  SettingOutlined,
  CommentOutlined,
  RobotOutlined,
} from '@ant-design/icons';
import serviceRoutes, { getRoutesBySignLogin, ServiceRoutesObject } from '@/router/serviceRoutes';
import './layout.scss';
import eventBus from '@/utils/eventBus';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectCurrentAgent, resetCurrentAgent } from '@/store/modules/agentTemplate';
import { removeToken, sessionKey } from '@/utils/auth';
import { setStoreToken, selectRoles, selectUsername, selectLoginType } from '@/store/modules/user';
import { useSelector } from 'react-redux';
import TestChat from '@/pages/agentService/agentManager/agentEngine/parts/roleParts/TestChat';

const { Header, Content, Sider } = Layout;

const LayoutCom: React.FC = () => {
  const { t } = useTranslation();
  interface MenuItem {
    key: string;
    // 其他属性...
  }
  interface NavItem {
    key: string;
    icon: React.ReactNode;
    path: string;
  }
  const [firstMenus, setFirstMenus] = useState<MenuItem[]>([]);
  const [secondMenus, setSecondMenus] = useState([]);
  const [selectedNav, setSelectedNav] = useState('workspace');
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const [showSider, setShowSider] = useState(false);
  
  const [firstPath, setFirstPath] = useState<any>([]);
  const [goIntoSecond, setGoIntoSecond] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  
  const location = useLocation();
  const [isUserSettingActive, setIsUserSettingActive] = useState(false);
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);
  // 是否通过sign方式登陆
  const isSignLogin = useAppSelector(selectLoginType) === 'sign'

  useEffect(() => {
    if (firstMenus.length > 0) {
      setSelectedNav(navItems[0].key);
    }
  }, [firstMenus]);

  // const u = useAppSelector(username);
  // console.log(u)
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const currentAgent = useAppSelector(selectCurrentAgent);

  const getParentMenuKeys = (path: string) => {
    if (goIntoSecond) {
      return secondMenus.map((item: any) => item.key);
    } else {
      const keys = [path.substring(0, path.lastIndexOf('/'))];
      return keys;
    }
  };
  const [defaultKey, setDefaultKey] = useState([location.pathname]);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState(getParentMenuKeys(location.pathname));
  
  // 监听路由变化
  useEffect(() => {
    const isUserSetting = location.pathname === '/account/userSetting';
    setIsUserSettingActive(isUserSetting);
    console.warn(':::::::location.pathname:', location.pathname, isUserSetting, isUserSettingActive)
    if (isUserSetting) {
      setSelectedNav(''); // 当路由为 '/account/userSetting' 时，清空 selectedNav
    } else {
      // 根据当前路径设置 selectedNav
      // 这里需要根据你的路由结构来确定具体的逻辑
      // /account/accountGroup
      // /account/accountManager
      // /account/channelIntegrations
      // /account/accountManager


      if (location.pathname.includes('/agentService/workspace')) {
        setSelectedNav('workspace');
      } else if (location.pathname.includes('/agentService/llmAgent')) {
        setSelectedNav('llmAgent');
      } else if (location.pathname.includes('/account/accountManager')) {
        setSelectedNav('accountManager');
      }
      // 可以继续添加其他路由的判断...
    }
  }, [location.pathname]);
  // 新增导航
  useEffect(() => {
    if (defaultKey.length > 0) {
      // 获取 defaultOpenKeys 中的路径
      const currentPath = defaultKey[0];
      // 在 navItems 中查找匹配的项
      const matchedNavItem = navItems.find(item => currentPath.includes(item.path.split('/').pop() || ''));
      
      if (matchedNavItem) {
        console.log('matchedNavItem:', matchedNavItem)
        if(matchedNavItem.key === 'accountManager') {
          setShowSider(false);
          setDefaultOpenKeys(getParentMenuKeys(location.pathname));
        } else {
          setShowSider(true);
        }
        setSelectedNav(matchedNavItem.key);
      }
    }
  }, [defaultKey, navItems]);
  /**
   * @description: 模拟获取后端路由，后端上线路由权限后更新为正式接口！
   * @return {*}
   */
  function getServiceRoutes() {
    return new Promise(resolve => {
      const serviceRouteData = serviceRoutes();
      window.setTimeout(() => {
        resolve({
          code: 200,
          data: isSignLogin ? getRoutesBySignLogin(serviceRouteData) : serviceRouteData,
        });
      }, 500);
    });
  }

  const goBakFirst = () => {
    setGoIntoSecond(false);
    nav('/agentService/llmAgent');
  };
  const handleMenu = (arr?: any) => {
    const firstArr = arr || firstPath;
    if (firstArr.length <= 0) {
      return false;
    }
    if (firstArr && !firstArr.includes(location.pathname) && currentAgent.currentAgent.agent_id) {
      // 如果当前路由不是一级菜单的
      setGoIntoSecond(true);
    } else {
      setGoIntoSecond(false);
    }
    setDefaultKey([location.pathname]);
    setDefaultOpenKeys(getParentMenuKeys(location.pathname));
  };

  const selectRoles1 = useSelector((state: any) => state.user.roles);

  useEffect(() => {
    getServiceRoutes().then((res: any) => {
      let arr = res.data || [];
      if (selectRoles1.length === 1 && selectRoles1[0] === 'Agent协助') {
        arr = arr.filter((item: any) => item.path === '/agentService');
        arr[0].children = arr[0].children.filter(
          (ops: any) => ops.path === '/agentService/workspace'
        );
      }
      const first = [] as any;
      const second = [] as any;
      const firstPathArr = [] as any;
      let navItems: any[] = [];
      // 新增导航
      if(arr.length === 1) {
        navItems = [
          { key: 'workspace', icon: <CommentOutlined />, path: '/agentService/workspace' }
        ];
      } else {
        navItems = [
          { key: 'workspace', icon: <CommentOutlined />, path: '/agentService/workspace' },
          { key: 'llmAgent', icon: <RobotOutlined />, path: '/agentService/llmAgent' },
          { key: 'accountManager', icon: <SettingOutlined />, path: '/account/accountManager'}
        ];
      }

      arr.forEach((item: any) => {
        // 检查 noShow 属性
        /* if (item.noShow) {
          return; // 如果 noShow 为 true,跳过此项
        } */

        if (item.firstMenu) {
          firstPathArr.push(item.path);
          if (item.children) {
            const childrenMenu = [] as any;
            item.children.forEach((childItem: any) => {
              if (childItem.firstMenu) {
                firstPathArr.push(childItem.path);
              }
              childrenMenu.push({
                key: childItem.path,
                label: <Link to={childItem.path}>{t(childItem.pathname)}</Link>,
              });
            });
            first.push({
              key: item.path,
              label: item.noLink ? t(item.pathname) : <Link to={item.path}>{t(item.pathname)}</Link>,
              icon: <span className={`iconfont ${item.icon}`}></span>,
              children: childrenMenu,
            });
          } else {
            first.push({
              key: item.path,
              label: <Link to={item.path}>{t(item.pathname)}</Link>,
              icon: <span className={`iconfont ${item.icon}`}></span>,
            });
          }
        } else {
          if (item.children) {
            const childrenMenu = [] as any;
            item.children.forEach((childItem: any) => {
              childrenMenu.push({
                key: childItem.path,
                label: <Link to={childItem.path}>{t(childItem.pathname)}</Link>,
              });
            });
            second.push({
              key: item.path,
              label: item.noLink ? (t(item.pathname)) : <Link to={item.path}>{t(item.pathname)}</Link>,
              // icon: <span className={`iconfont ${item.icon}`}></span>,
              children: childrenMenu,
            });
          } else {
            second.push({
              key: item.path,
              label: <Link to={item.path}>{t(item.pathname)}</Link>,
              // icon: <span className={`iconfont ${item.icon}`}></span>,
            });
          }
        }
      });
      
      setFirstMenus(first);
      setSecondMenus(second);
      setFirstPath(firstPathArr);
      setNavItems(navItems);
      handleMenu(firstPathArr);
    });
  }, [selectRoles1, t]);
  // useEffect(() => {
  //   const jumpToSecondMenu = () => {
  //     setGoIntoSecond(true);
  //   };
  //   eventBus.on('jumpToSecondMenu', jumpToSecondMenu);
  //   return () => {
  //     eventBus.off('jumpToSecondMenu', jumpToSecondMenu);
  //   };
  // }, []);
  useEffect(() => {
    handleMenu();
  }, [location]);
  const dropDownItems: MenuProps['items'] = [
    {
      key: 'userSetting',
      label: <Link to="/account/userSetting">{t('common.userSetting')}</Link>,
    },
    {
      key: '1',
      danger: true,
      label: <span onClick={logout}>{t('common.logout')}</span>,
    },
  ];
  function toAskHuman() {
    nav('/agentService/workspace');
    Modal.destroyAll();
  }
  function logout() {
    const win = window as any;
    if (win.agentWorkspace) {
      if (
        win.agentWorkspace.sessionNum &&
        win.agentWorkspace.loginStatus &&
        win.agentWorkspace.sessionNum > 0
      ) {
        Modal.confirm({
          content: t('common.logoutConfirm'),
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <Button onClick={toAskHuman}>{t('common.toProcess')}</Button>
            </>
          ),
        });
        return false;
      }
    }
    dispatch(resetCurrentAgent());
    dispatch(setStoreToken(''));
    removeToken();
    window.location.href = '/login';
  }
  // 下钻菜单展开全部菜单
  useEffect(() => {
    setDefaultOpenKeys(getParentMenuKeys(location.pathname));
  }, [secondMenus])
  return (
    <Layout style={{ height: '100%' }}>
      <Sider width={60} className="N-sider-nav text-center">
        <div className="flex flex-col h-full">
          <div className="py-6">
            <img src={logo} alt="logo" className="logo-nav mx-auto" />
          </div>  
          
          <Menu 
            mode="vertical" 
            className="flex-1 bg-all-none"
            selectedKeys={[selectedNav]}
          >
            {navItems.map(({ key, icon, path }) => (
              <Menu.Item
                key={key}
                icon={React.isValidElement(icon) 
                  ? React.cloneElement(icon as React.ReactElement, { style: { fontSize: '1.4rem' } }) 
                  : icon}
                className={`!px-0 ${key !== 'nav3' ? '!mb-6' : ''}`}
                onClick={() => {
                  setSelectedNav(key);
                  nav(path);
                }}
              />
            ))}
          </Menu>
          
        </div>
      </Sider>

      <Layout>
        {
          !isSignLogin && (
            <Header className='flex flex-row justify-between' style={{ padding: 0, background: colorBgContainer, borderBottom: '1px solid #eee' }}>
              <div className="flex justify-between items-center">
                <div className="flex items-center language-switcher-container">
                  <LanguageSwitcher style={{ opacity: .2 }} />
                </div>
              </div>
                <div className="flex justify-end px-6">
                <div className="cursor-pointer">
                  <Dropdown menu={{ items: dropDownItems }}>
                    <div className="flex items-center" style={{ height: '64px' }}>
                      <Image
                        src={require('@/assets/images/bot.png')}
                        preview={false}
                        width={40}
                      ></Image>
                      <span className="ml-3">{username}</span>
                    </div>
                  </Dropdown>
                </div>
              </div>
              
            </Header>
          )
        }
        <Layout>
          <Sider
            theme='light'
            width={goIntoSecond || !showSider || isUserSettingActive ? 280 : 0}
            // width={(goIntoSecond && showSider) ? 280 : 60}
            collapsible={false}
            collapsed={collapsed}
            style={{
              borderRight: '1px solid #eee',
            }}
            className="menu-aside-container h-full overflow-y-scroll overflow-x-hidden"
            onCollapse={value => setCollapsed(value)}
          >
            {(goIntoSecond && !isSignLogin) && (
              <div className="nav-go-back" onClick={goBakFirst}>
                <span className="iconfont fanhui" />
                {!collapsed && <span>{currentAgent.currentAgent.name}</span>}
              </div>
            )}
            {goIntoSecond ? (
              <Menu
                className="nav-custom-menu"
                style={{ width: collapsed ? 'auto' : 280 }}
                selectedKeys={defaultKey}
                openKeys={defaultOpenKeys}
                onOpenChange={openKeys => {
                  setDefaultOpenKeys(openKeys);
                }}
                mode="inline"
                // theme="dark"
                items={secondMenus}
              />
            ) : (
              <Menu
                className={`nav-custom-menu N-hack ${isUserSettingActive ? 'userSetting' : ''}`}
                style={{ width: collapsed ? 'auto' : 280 }}
                selectedKeys={defaultKey}
                openKeys={defaultOpenKeys}
                onOpenChange={openKeys => {
                  setDefaultOpenKeys(openKeys);
                }}
                mode="inline"
                // theme="dark"
                items={firstMenus}
              />
            )}
          </Sider>
          <Content className="main-content">
            <Suspense fallback={<div>loading...</div>}>
              <Outlet />
            </Suspense>
            {goIntoSecond && <TestChat />}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutCom;
