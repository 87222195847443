import React from 'react';
import { Tag, Tooltip } from 'antd';
import { t } from 'i18next';
import { EditOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import './mainCarts.scss';
import { DataItem } from '@/store/modules/types';

interface MainCartProps {
  info: DataItem;
  uid: string;
  onDelScenes: (data: { uid: string; type: string }) => void;
  onCopyScenes: (data: { uid: string; type: string }) => void;
  onEditScenes: (data: { info: object; type: string }) => void;
  onChangeStatus: (data: { info: object; type: string }) => void;
  goToFlow: (data: { info: object; type: string }) => void;
}

const MainCart: React.FC<MainCartProps> = ({
  info,
  uid,
  onDelScenes,
  onCopyScenes,
  onEditScenes,
  onChangeStatus,
  goToFlow,
}) => {
  
  const copyScenes = () => {
    onCopyScenes({ uid: info.uid || '', type: 'scenes' });
  };
  const delScenes = () => {
    onDelScenes({ uid: info.uid || '', type: 'scenes' });
  };

  const editScenes = () => {
    onEditScenes({ info, type: 'scenes' });
  };

  const changeStatus = () => {
    onChangeStatus({ info, type: 'scenes' });
  };

  const handleGoToFlow = () => {
    goToFlow({ info, type: 'scenes' });
  };

  const showRoleType = (type: string) => {
    return type === '0' ? '回答' : type === '1' ? '分类' : '流程';
  };

  return (
    <div
      className="mainCart"
      data-uid={info.uid}
      onClick={e => {
        e.stopPropagation();
        editScenes();
      }}
    >
      <span className="iconfont tuodong-shu" />
      <div className="top">
        <span
          className={`status-span ${info.status == '0' ? 'disabled-span' : ''}`}
          onClick={e => {
            e.stopPropagation();
            changeStatus();
          }}
        >
          <span className="iconfont wancheng-mian"></span>
          {info.status == '0' ? t('status.offlineStatus') : t('status.onlineStatus')}
        </span>
        {info.role_type && (
          <Tag
            style={{ margin: '-3px 8px 0 0' }}
            color={info.role_type === '2' ? 'blue' : info.role_type === '1' ? 'green' : 'orange'}
          >
            {showRoleType(info.role_type)}
          </Tag>
        )}
        <div className="title">{info.name}</div>
        <div className="btn-wrap">
          {info.role_type === '2' && (
            <SettingOutlined
              className="iconfont agentguanli"
              onClick={e => {
                e.stopPropagation();
                handleGoToFlow();
              }}
            />
          )}
          <Tooltip title={info.status === '0' ? t('status.offline') : t('status.online')} placement="top">
            <span
              className={`iconfont status-btn ${info.status == '0' ? 'shangxian' : 'xiaxian'}`}
              onClick={e => {
                e.stopPropagation();
                changeStatus();
              }}
            ></span>
          </Tooltip>
          <span
            className="iconfont bianji edit-btn"
            onClick={e => {
              e.stopPropagation();
              editScenes();
            }}
          />
          <span
            className="iconfont shanchu del-icon"
            onClick={e => {
              e.stopPropagation();
              delScenes();
            }}
          />
          <span
            className="iconfont fuzhi"
            onClick={e => {
              e.stopPropagation();
              copyScenes();
            }}
          />
        </div>
      </div>
      <div className="describe">{info.desc}</div>
    </div>
  );
};

export default MainCart;
