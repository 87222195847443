import React, { FC } from 'react';
import { Image } from 'antd';

type propsType = {
  imageList: string[],
  message: any,
  messages: any []
}

const ImagePreview: FC<propsType> = ({ imageList, message, messages }) => {

  const images = messages.filter((item) => item.content_type === 'image');
  const current = images.findIndex((item) => item.id === message.id);
  
  return (
    <Image.PreviewGroup
      items={imageList}
      preview={{
        current: current,
      }}
    >
      <Image width={200} src={`${message.origin_message}?x-oss-process=image/resize,w_100,h_100`} />
    </Image.PreviewGroup>
  );
};

export default ImagePreview;
