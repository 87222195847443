import React from 'react';
import { Handle, Position, useReactFlow, useNodes } from 'reactflow';
import { generateUUID } from '@/utils/common';
import { Modal, message, Dropdown, MenuProps, Tooltip } from 'antd';
import { t } from 'i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type SlotProps = {
  id: string;
  type: string;
  label: string;
  data: any;
  xPos: number;
  yPos: number;
};

const { confirm } = Modal;

const MoreOutputNode = (slotProps: any) => {
  const { addNodes, deleteElements } = useReactFlow();
  const copyNode = (copyData: SlotProps) => {
    const newNode = {
      id: `${copyData.data.flowType}_${generateUUID()}`,
      type: copyData.type,
      position: {
        x: copyData.xPos,
        y: copyData.yPos + 100,
      },
      label: copyData.data.label,
      data: copyData.data,
    };
    addNodes([newNode]);
  };

  const deleteNode = (data: SlotProps) => {
    confirm({
      title: t('common.confirmDeleteNode'),
      icon: <ExclamationCircleOutlined />,
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onOk() {
        deleteElements({ nodes: [data] });
        message.success(t('common.deleteSuccess'));
      },
    });
  };
  const items: MenuProps['items'] = [
    {
      key: '1',
        label: <span onClick={() => copyNode(slotProps)}>{t('common.copy')}</span>,
    },
    {
      key: '2',
      label: <span onClick={() => deleteNode(slotProps)}>{t('common.delete')}</span>,
    },
  ];
  return (
    <div className="custom-node">
      {slotProps.data && (
        <>
          <Handle type="target" position={Position.Left} />
          <div className="node-div">
            <span
              className={`iconfont node-icon ${slotProps.data.iconfont}`}
              style={{ backgroundColor: slotProps.data.bgcolor }}
            />
            {slotProps.data.label}
            <Dropdown menu={{ items }}>
              <span className="el-dropdown-link">
                <span className="iconfont gengduo-heng" />
              </span>
            </Dropdown>
            {['condition', 'llmtask'].includes(slotProps.data.flowType) && (
              <>
                {['llmtask'].includes(slotProps.data.flowType) && (
                  <Tooltip
                    placement="top"
                    title={slotProps.data.form && slotProps.data.form.llm_prompt}
                  >
                    <span className="llm-prompt-span">
                      {slotProps.data.form && slotProps.data.form.llm_prompt}
                    </span>
                  </Tooltip>
                )}
                {(() => {
                  const dataList =
                    slotProps.data.flowType === 'condition'
                      ? slotProps.data.form?.value || []
                      : slotProps.data.form?.chain_check || [];
                  return dataList.map((item: any, index: number) => (
                    <React.Fragment key={item.id}>
                      <div className="filter-box">
                        <div className="text-div">{item.name}</div>
                      </div>
                      <Handle
                        type="source"
                        key={item.id}
                        position={Position.Right}
                        id={`${item.id}_custom`}
                        style={{
                          top:
                            (slotProps.data.flowType === 'condition' ? 20 : 70) + 45 * (index + 1),
                        }}
                      />
                    </React.Fragment>
                  ));
                })()}
                <div className="filter-box">
                  <div className="text-div">{t('common.default')}</div>
                </div>
                <Handle
                  type="source"
                  position={Position.Right}
                  id={`${slotProps.data.flowType}_defalut`}
                  style={{ bottom: 18, top: 'auto' }}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MoreOutputNode;
